import { Attachment, Button, Checkbox, DataSource, Label, Layout, Panel, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTenant extends Layout {
    attachmentBackgroundUmage: Attachment;
    attachmentLogo: Attachment;
    buttonGrantServiceUser: Button;
    buttonInviteMultiple: Button;
    buttonInviteUser: Button;
    buttonNewAccessToken: Button;
    buttonRevoke: Button;
    checkboxAllowMcLeod: Checkbox;
    labelHeaderLogo: Label;
    labelLoginBackground: Label;
    panelBackgroundImage: Panel;
    panelLogo: Panel;
    sourceTenant: DataSource;
    sourceTenantAccessTokens: DataSource;
    sourceTenantBranding: DataSource;
    sourceUsers: DataSource;
    tabAccessTokens: Tab;
    tabBranding: Tab;
    tabUsers: Tab;
    tableAccessTokens: Table;
    tableUsers: Table;
    tabsetTenant: Tabset;
    textboxBrowserTitle: Textbox;
    textboxCompanyName: Textbox;
    textboxHeaderBackgroundColor: Textbox;
    textboxHeaderTextColor: Textbox;
    textboxHomeUrl: Textbox;
    textboxLoginAdditionalText: Textbox;
    textboxLoginWelcomeMessage: Textbox;
}
