import { removeFromMetadataCache } from "@mcleod/core";
import { Button, Component, DataSource, DesignerInterface, Panel } from "@mcleod/components";
import { DesignerDataSource } from "./DesignerDataSource";
import { PanelOpenModel } from "./PanelOpenModel";
import { McLeodTailor } from "./custom/McLeodTailor";
import { LayoutDesignerTab } from "./LayoutDesignerTab";
import { AbstractUIDesigner } from "./AbstractUIDesigner";

export class DesignerDataSourcePanel extends Panel {
    private targetPanel: Panel;
    private buttonAdd: Button;
    private buttonModelView: Button;

    constructor(tab: LayoutDesignerTab, designer: AbstractUIDesigner, props?) {
        super(null, false);
        this._shouldAddDesignerContainerProperties = false;
        this.setProps({ fillRow: true, borderColor: "strokeSecondary", borderWidth: 1, marginTop: 16, ...props });
        this.targetPanel = new Panel({ fillRow: true });
        this.add(this.targetPanel);
        if ((designer instanceof McLeodTailor) !== true) {
            this.minHeight = 80;
            this.buttonAdd = new Button({ width: 140, height: 26, padding: 4, color: "primary", caption: "Add datasource", imageName: "add", rowBreak: false });
            this.buttonAdd.addClickListener(event => {
                tab.modified = true;
                let dsName: string;
                for (let nextNum = 1; dsName == null && nextNum < 1000; nextNum++)
                    if (tab.dataSources["source" + nextNum] == null)
                        dsName = "source" + nextNum;
                const dataSource = new DataSource({ id: dsName }, null, designer, tab);
                tab.dataSources[dataSource.id] = dataSource;
                const dsPanel = new DesignerDataSource(designer, dataSource);
                this.addDesignerDataSource(dsPanel);
                designer.selectComponent(dsPanel, false);
                this.showEndpointSelector(dataSource, designer);
            });
            this.add(this.buttonAdd);
        }
        this.buttonModelView = new Button({ width: 140, height: 26, padding: 4, color: "primary", caption: "View model", imageName: "magnifyingGlass", enabled: false });
        this.buttonModelView.addClickListener(() => {
            const comp = designer.selectedComponents[0];
            if (comp instanceof DesignerDataSource && comp.designerDataSource.url != null)
                designer.openModelDesigner(comp.designerDataSource.url);
        });
        this.add(this.buttonModelView);
    }

    addDesignerDataSource(value: DesignerDataSource) {
        this.targetPanel.add(value);
    }

    getDesignerDataSourceRecursiveChildren(): Component[] {
        return this.targetPanel.getRecursiveChildren();
    }


    setButtonModelViewVisibile(value: boolean) {
        if (this.buttonModelView != null)
            this.buttonModelView.visible = value;
    }

    enableButtonModelView(value: boolean) {
        if (this.buttonModelView != null)
            this.buttonModelView.enabled = value;
    }

    updateIds() {
        for (const dsPanel of this.targetPanel)
            (dsPanel as DesignerDataSource).updateId();
    }

    syncMetadata(url: string): Promise<any> {
        removeFromMetadataCache(url);
        const promises = [];
        for (const dsPanel of this.targetPanel) {
            if (dsPanel instanceof DesignerDataSource && dsPanel?.designerDataSource.url === url) {
                dsPanel.designerDataSource["metadata"] = null;
                promises.push(dsPanel.setCustomModelIdFromMetadata());
            }
        }
        return Promise.all(promises);
    }

    private async showEndpointSelector(dataSource: DataSource, designer: DesignerInterface) {
        const openPanel = new PanelOpenModel();
        await openPanel.showAsDialog({ title: "Select Endpoint" });
        const selectedNode = openPanel.getSelectedNode();
        if (selectedNode != null) {
            const path = openPanel.getResultValue();
            dataSource.url = path;
            designer.redisplayProp("url", path);
            designer.displayDataSourceTools();
        }
    }
}
