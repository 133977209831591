import { Company } from "../Company";
import { ModelDataChangeFilter } from "./ModelDataChangeFilter";
import { ModelDataChangeFilterData } from "./ModelDataChangeFilterData";
import { ModelDataChangeSingleFilterResult } from "./ModelDataChangeFilterResult";

export class ModelDataChangeCompanyFilter extends ModelDataChangeFilter {
    override get type(): string {
        return "COMPANY";
    }

    override evaluate(data: ModelDataChangeFilterData): ModelDataChangeSingleFilterResult {
        return Company.testCompanyId(data.values);
    }
}
