export const MODEL_DATA_CHANGE_DISCARD_RESULT = "discard";
export type ModelDataChangeSingleFilterResult = boolean | "discard";

export class ModelDataChangeMultipleFilterResult {
    private overallResult: ModelDataChangeSingleFilterResult = true;

    public update(singleFilterResult: ModelDataChangeSingleFilterResult) {
        if (singleFilterResult !== true)
            this.overallResult = singleFilterResult;
    }

    public isFailure(): boolean {
        return this.overallResult !== true;
    }

    public isDiscard(): boolean {
        return this.overallResult === MODEL_DATA_CHANGE_DISCARD_RESULT;
    }
}
