import { Layout, MainPageAccessor, MainPageDef, Panel, PanelStaticError } from "@mcleod/components";
import { PageHeader } from "../pageheader/PageHeader";
import { Router } from "../router/Router";
import { MainPageProps } from "./MainPageProps";
import {
    Api, DOMUtil, DynamicLoader, InstanceIdentifier, LogManager, ServiceAddresses,
    setDefaultErrorHandler, SettingsRegistry, UrlUtil
} from "@mcleod/core";
import { UnsavedDataListener } from "../UnsavedDataListener";
import { CommonDialogs } from "../CommonDialogs";
import { MainPageModule } from "./MainPageModule";

const log = LogManager.getLogger("common.mainpage.MainPage");

export abstract class MainPage extends Panel implements MainPageProps, MainPageDef {
    private static _instance: MainPage
    apiContext: string;
    settingsRoot: string;
    unauthSettingsRoot: string;
    authSettingsRoot: string;
    modules: MainPageModule[];
    onError: any;
    doNotReportNextError: boolean;

    public abstract get pageHeader(): PageHeader;
    public abstract get router(): Router;
    public abstract evaluateExternalLinkVisibility(topMostPanel: Panel, currentLayouts: Layout[]): void;

    protected constructor(props: Partial<MainPageProps>) {
        super({ padding: 0, fillRow: true, fillHeight: true, id: "main", ...props });
        this.setMainPageInstance();

        // Left over from the refactor from McLeodMainPage -> MainPage/WebMainPage
        // Style provided in MainPageStyles used to be at the very top of McLeodMainPage.
        // I have moved it into MainPageStyles. I do not believe these Styles are actually
        // getting applied. (Maybe they once were and are no longer applicable)
        // I'm going to leave them commented out for now and see if anyone chirps about it.
        // this.addClass(MainPageStyles.html);
        // this.addClass(MainPageStyles.body);
        // this.addClass(MainPageStyles["*"]);
        // this.addClass(MainPageStyles["*:before"]);
        // this.addClass(MainPageStyles["*:after"]);

        if (props?.apiContext != null) {
            ServiceAddresses.setContextPath(props.apiContext);
        }

        if (props == null) {
            throw new Error("MainPage requires a properties object to be passed in.");
        }

        if (props.modules == null) {
            throw new Error("MainPage requires an array of routable modules.");
        }

        for (const module of props.modules) {
            DynamicLoader.mapPathsToClasses(module.path, module.context);
        }
        DynamicLoader.mapPathsToClasses("components", require['context']("@mcleod/components/src", true));

        this._element.style.fontFamily = "Roboto, Helvetica, Arial, sans-serif";
        UnsavedDataListener.init();
        InstanceIdentifier._clear();

        this.searchUnauthSettings(props);

        setDefaultErrorHandler(error => {
            try {
                if (this.doNotReportNextError !== true)
                    CommonDialogs.showError(error);
            } finally {
                this.doNotReportNextError = false;
            }
        });
    }

    private searchUnauthSettings(props: Partial<MainPageProps>) {
        const root = this.unauthSettingsRoot || this.settingsRoot || "lme/";
        Api.search(
            root + "unauth-settings",
            { origin: window.location.origin },
            { sendAuth: false },
            ()=> {}
        ).then(async response => {
            this.handleUnauthResponse(response);
        }).catch(err => {
            if (props.onError == null) {
                this.add(
                    new PanelStaticError({
                        errorSummary: "Sorry, we can't connect you to the server.",
                        errorDetail: "The server may not be running or you may have lost network connectivity."
                    })
                );
            } else {
                props.onError(this, err);
            }
            log.error(err);
        });
    }

    private handleUnauthResponse(response: any) {
        SettingsRegistry.update("general_settings", response, false);
        this.add(this.router);
        this.router.displayRoute(
            window.location.pathname,
            UrlUtil.getPropsFromUrl(window.location.search)
        );
    }

    public static get mainLayout(): Layout {
        if (MainPage.instance == null) {
            return null;
        }

        let result = Router.instance.components[0];
        if (!(result instanceof Layout) && result?.["layout"] != null) {
            result = result["layout"];
        }
        if (result instanceof Layout) {
            return result;
        }
        return null;
    }

    private setMainPageInstance() {
        MainPage._instance = this;
        new MainPageAccessor(this);
    }

    public static get instance(): MainPage {
        return this._instance;
    }

    public get mainPage(): MainPage {
        return MainPage.instance;
    }

    public get mainPageHeight(): number {
        return DOMUtil.getComputedSize("height", MainPage.instance._element);
    }

    public get mainPageWidth(): number {
        return DOMUtil.getComputedSize("width", MainPage.instance._element);
    }

    public get routerHeight(): number {
        return DOMUtil.getComputedSize("height", MainPage.instance.router._element);
    }

    public get routerWidth(): number {
        return DOMUtil.getComputedSize("width", MainPage.instance.router._element);
    }

    public get pageHeaderHeight(): number {
        return DOMUtil.getComputedSize("height", MainPage.instance.pageHeader._element);
    }

    public get pageHeaderWidth(): number {
        return DOMUtil.getComputedSize("width", MainPage.instance.pageHeader._element);
    }

    overridePageHeaderZIndex(millis: number): void {
        this.pageHeader.overridePageHeaderZIndex(millis);
    }

    resetPageHeaderZIndex(): void {
        this.pageHeader.resetPageHeaderZIndex();
    }
}
