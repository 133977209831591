import { Button, ButtonVariant, Layout, Panel, PanelProps, ScreenStack } from "@mcleod/components";
import { ArrayUtil, CompanySettings, HelpLink, UserSettings, UserType, VerticalAlignment } from "@mcleod/core";
import { ExternalLinkLauncher } from "./ExternalLinkLauncher";
import { PageHeaderProvider } from "./pageheader/PageHeaderProvider";

export class HelpPanel extends Panel {
    private _buttonHelp: Button;

    constructor(props?: Partial<PanelProps>) {
        super({
            id: "helpButtonContainer",
            rowBreak: false,
            padding: 0,
            height: 60,
            verticalAlign: VerticalAlignment.CENTER,
            ...props
        });
        this.add(this.buttonHelp);
    }

    get buttonHelp(): Button {
        if (this._buttonHelp == null) {
            this._buttonHelp = new Button({
                id: "buttonHelp",
                tooltip: "Click here to open the eLearning Portal for this page.",
                imageHeight: 32,
                imageWidth: 32,
                height: 50,
                width: 50,
                backgroundColor: CompanySettings.get()?.header_bg_color,
                variant: ButtonVariant.round,
                imageName: "lightBulb",
                visible: false,
                rowBreak: false
            });

            // contains all the layouts from a single container with the highest z-index
            let currentLayouts = [];
            this._buttonHelp.addClickListener(
                () => ExternalLinkLauncher.showLinks(currentLayouts, this._buttonHelp)
            );
            ScreenStack.addCurrentLayoutsListener(
                (panel: Panel, layouts: Layout[]) => {
                    if (layouts?.length > 0 && !ArrayUtil.equals(currentLayouts, layouts)) {
                        currentLayouts = layouts;
                        this.evaluateExternalLinkVisibility(panel, currentLayouts);
                    }
                }
            );
        }
        return this._buttonHelp;
    }

    public evaluateExternalLinkVisibility(topMostPanel: Panel, currentLayouts: Layout[]) {
        // Only show the help button if the top-most layout contains a helplink, or
        // if some additional external link is available.
        // If the current panel / overlay doesn't contain any Layouts (Main Menu, Dialogs), then
        // set visibility based on the top-most layout.
        const links = ExternalLinkLauncher.discoverExternalLinks(currentLayouts);
        this._buttonHelp.visible = ArrayUtil.isEmptyArray(links) === false;
        this._buttonHelp.zIndex = 0;
        if (!this._buttonHelp.visible)
            return;

        const topZIndex = topMostPanel.getEffectiveZIndex() ?? 0;
        // if the top-most panel z-index is greater than 0, and there is a help link, set the z-index to be above the top-most panel.
        // This is most likely an overlay and the user should be able to click the button without dismissing the overlay.
        if (topZIndex > 0 && links.find(link => link instanceof HelpLink)) {
            this._buttonHelp.zIndex = topZIndex + 1;
        }
    }

    configurePortalHelp() {
        if (PageHeaderProvider.get().shouldDisplayHelpLinks()) {
            this.removeAll();
            let helpLink: string;
            switch (UserSettings.getUserType()) {
                case UserType.CUSTOMER: {
                    helpLink = "https://rise.articulate.com/share/jUf7NwAqAyyMmdyi19eqfsMCuqv_eQhK";
                    break;
                }
                case UserType.CARRIER: {
                    helpLink = "https://rise.articulate.com/share/nrYHV5FcTCdbZc6ry7FqN1KJ9ypCc2qr";
                    break;
                }
                case UserType.LOCATION: {
                    helpLink = "https://rise.articulate.com/share/N2z4ZEk9oCHSN0lfAL2_Mm5zlrLWODVM";
                    break;
                }
            }
            if (helpLink) {
                this._buttonHelp = new Button({
                    id: "buttonHelp",
                    tooltip: "Click here to open the eLearning Portal for this page.",
                    imageHeight: 32,
                    imageWidth: 32,
                    height: 50,
                    width: 50,
                    backgroundColor: CompanySettings.get()?.header_bg_color,
                    variant: ButtonVariant.round,
                    imageName: "lightBulb",
                    visible: true,
                    rowBreak: false,
                    link: helpLink
                });
            }
        }
    }
}
