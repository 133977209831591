/**
 * This class allows for concurrency control around async methods.  The following example includes a class with add data
 * and remove data actions, where only one of those actions can be in progress at any one time.
 * 
 * class SomeClass {
 *     private mutex = new Mutex();
 *     private sharedObject: any = null;

 *     private async addData(): Promise<void> {
 *         console.log("Starting add operation...");
 *         await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate delay
 *         this.sharedObject = { key: "value" };
 *         console.log("Add operation completed:", this.sharedObject);
 *     }
 *
 *     private removeData(): void {
 *         console.log("Starting remove operation...");
 *         this.sharedObject = null;
 *         console.log("Remove operation completed:", this.sharedObject);
 *     }
 *
 *     public async add(): Promise<void> {
 *         const release = await this.mutex.acquire();
 *         try {
 *             await this.addData();
 *         } finally {
 *             release();
 *         }
 *     }

 *     public async remove(): Promise<void> {
 *         const release = await this.mutex.acquire();
 *         try {
 *             this.removeData(); // Now synchronous
 *         } finally {
 *             release();
 *         }
 *     }
 * }
 * 
 * // Usage
 * function async run() {
 *     const someClass = new SomeClass();
 *     // Simulate concurrent calls
 *     someClass.add();    // This starts populating the object
 *     someClass.remove(); // This tries to nullify it but waits for add() to finish
 * }
 * run();
 */
export class Mutex {
    private _lock: Promise<void> = Promise.resolve();
  
    async acquire(): Promise<() => void> {
      let release: () => void;
  
      const lock = new Promise<void>((resolve) => {
        release = resolve;
      });
  
      const previousLock = this._lock;
      this._lock = previousLock.then(() => lock);
  
      await previousLock;
      if (!release) {
        throw new Error("Release function was not initialized, but this really won't ever happen.");
      }
      return release;
    }
  }
