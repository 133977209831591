import { MainPage } from "../mainpage/MainPage";
import { Router } from "./Router";
import { RouterProps } from "./RouterProps";

export class WebRouter extends Router {
    constructor(props: Partial<RouterProps>) {
        super(props);
    }

    static get instance(): WebRouter {
        if (Router.instance == null)
            new WebRouter({
                settingsRoot: MainPage.instance.authSettingsRoot || MainPage.instance.settingsRoot
            }); // The parent will set its own ._instance value.
        return Router.instance as WebRouter;
    }
}
