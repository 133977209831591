import { Button, DataSource, Layout, LayoutHeader, Switch, Table } from "@mcleod/components";

export abstract class AutogenLayoutUserList extends Layout {
    buttonLock: Button;
    buttonRevoke: Button;
    buttonRevokeAll: Button;
    buttonUnlock: Button;
    layoutheaderUserList: LayoutHeader;
    sourceUsers: DataSource;
    switchActive: Switch;
    tableSearch: Table;
}
