import { Panel } from "../panel/Panel";
import { LayoutHeaderPropDefinitions, LayoutHeaderProps } from "./LayoutHeaderProps";
import { Label } from "../label/Label";
import { ArrayUtil, HorizontalAlignment, StringUtil, VerticalAlignment, WindowTitle } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { DesignerInterface } from "../../base/DesignerInterface";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Layout } from "../layout/Layout";
import { DataSource, DataSourceMode } from "../../databinding/DataSource";
import { PanelProps } from "../panel/PanelProps";
import { DataSourceModeChangeEvent, DataSourceModeChangeListener } from "../../events/DataSourceModeChangeEvent";
import { HorizontalSpacer } from "../../page/HorizontalSpacer";
import { serializeComponents } from "../../serializer/ComponentSerializer";
import { ComponentDeserializer, DeserializeProps } from "../../serializer/ComponentDeserializer";

export class LayoutHeader extends Panel implements LayoutHeaderProps {
    private _panelTitle: Panel;
    private labelTitle: Label;
    private _title: string;
    private _layout: Layout;
    private _initialDataSourceMode: DataSourceMode;
    private readonly _dataSourceModeChangeListener: DataSourceModeChangeListener;
    private _addlPanelLeft: Panel;
    private _addlPanelRight: Panel;
    private _addlPanelBottom: Panel;
    private _designerAddlPanelLeftComponents: Component[];
    private _designerAddlPanelRightComponents: Component[];
    private _designerAddlPanelBottomComponents: Component[];

    constructor(props?: Partial<LayoutHeaderProps>, callSetProps: boolean = false) {
        super(props, callSetProps);
        this.contextMenuClickType = null;

        this.initComponents();
        this.setChildrenSerialized();
        this.addMountListener(() => { this.doOnMount(); });
        this.setProps({ ...this.defaultProps, ...props });
        this._dataSourceModeChangeListener = (event: DataSourceModeChangeEvent) => this.onDataSourceModeChange(event);
        this.updateComponentsForDesigner();
    }

    protected initComponents() {
        // Components initialized in getter, if null.
        this.add(
            this.panelTitle,
            this.addlPanelLeft,
            new HorizontalSpacer(),
            this.addlPanelRight,
            this.addlPanelBottom
        );
    }

    protected get panelTitle(): Panel {
        if (this._panelTitle == null) {
            this._panelTitle = new Panel({
                align: HorizontalAlignment.LEFT,
                verticalAlign: VerticalAlignment.CENTER,
                rowBreak: false,
                fillHeight: false,
                paddingLeft: 0
            });
            this.labelTitle = new Label({
                id: "labelTitle",
                themeKey: "label.pageTitle",
                text: this.title,
                fillHeight: false,
                rowBreak: false
            });
            this._panelTitle.add(this.labelTitle);
        }
        return this._panelTitle;
    }

    public get addlPanelLeft(): Panel {
        if (this._addlPanelLeft == null) {
            this._addlPanelLeft = new Panel({
                id: "panelDataHeaderAddlLeft",
                align: HorizontalAlignment.LEFT,
                verticalAlign: VerticalAlignment.CENTER,
                rowBreak: false,
                marginTop: 0,
                paddingTop: 0,
                _designer: this._designer
            });
        }
        return this._addlPanelLeft;
    }

    public addLeftTools(...tools: Component[]) {
        this.addlPanelLeft.add(...tools);
    }

    public removeLeftTool(tool: Component) {
        this.addlPanelLeft.remove(tool);
    }

    public get addlPanelRight(): Panel {
        if (this._addlPanelRight == null) {
            this._addlPanelRight = new Panel({
                id: "panelDataHeaderAddlRight",
                align: HorizontalAlignment.RIGHT,
                verticalAlign: VerticalAlignment.CENTER,
                rowBreak: true,
                margin: 0,
                padding: 0,
                _designer: this._designer
            });
        }
        return this._addlPanelRight;
    }

    public addRightTools(...tools: Component[]) {
        this.addlPanelRight.add(...tools);
    }

    public removeRightTool(tool: Component) {
        this.addlPanelRight.remove(tool);
    }

    public get addlPanelBottom(): Panel {
        if (this._addlPanelBottom == null) {
            this._addlPanelBottom = new Panel({
                id: "panelDataHeaderAddlBottom",
                align: HorizontalAlignment.LEFT,
                verticalAlign: VerticalAlignment.CENTER,
                fillRow: true,
                rowBreak: true,
                margin: 0,
                padding: 0,
                _designer: this._designer
            });
        }
        return this._addlPanelBottom;
    }

    public addBottomTools(...tools: Component[]) {
        this.addlPanelBottom.add(...tools);
    }

    public removeBottomTool(tool: Component) {
        this.addlPanelBottom.remove(tool);
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
        this.syncTitle();
    }

    public get layout(): Layout {
        return this._layout ?? this.getParentLayout();
    }

    public set layout(value: Layout) {
        this._layout = value;
        this.syncTitle();
    }

    public override get dataSource(): DataSource {
        return super.dataSource;
    }

    public override set dataSource(value: DataSource) {
        if (this.dataSource != null)
            this.dataSource.removeAfterModeChangeListener(this._dataSourceModeChangeListener);
        super.dataSource = value;
        this.syncTitle();
        if (value != null)
            value.addAfterModeChangeListener(this._dataSourceModeChangeListener);
    }

    protected get initialDataSourceMode(): DataSourceMode {
        return this._initialDataSourceMode;
    }

    protected onDataSourceModeChange(event: DataSourceModeChangeEvent) {
        // We have to track the initial mode of the DataSource so that we know if we
        // started out as an ADD or an UPDATE. By the time we post a row and onExecute()
        // is called, the DataSource will be in UPDATE mode, even if we just added a record.
        if (this.initialDataSourceMode == null && event.newMode !== DataSourceMode.NONE)
            this._initialDataSourceMode = event.newMode;
        this.syncTitle(event.newMode);
    }

    public get defaultProps(): Partial<PanelProps> {
        return { fillRow: true, marginBottom: 12 };
    }

    private get shouldShowTitle(): boolean {
        return this.layout?.hideTitle != null ? !this.layout?.hideTitle : true;
    }

    protected handleTitleVisibility() {
        if (this.shouldShowTitle !== false) {
            if (!this.contains(this._panelTitle))
                this.insert(this._panelTitle, 0);
        } else {
            this.remove(this._panelTitle);
        }
    }

    protected doOnMount() {
        this.syncTitle(this.layout?.mainDataSource?.mode);
    }

    public syncTitle(mode?: DataSourceMode) {
        mode = mode || this?.layout?.mainDataSource?.mode;
        const title = this.__designer == null ?
            (this.title || this.getLayoutTitle(mode)) : this.designerTitleFiller;
        this.labelTitle.text = title;

        this.handleTitleVisibility();
        if (this.shouldShowTitle !== false && StringUtil.isEmptyString(title) !== true && this.__designer == null)
            WindowTitle.set(title);
    }

    protected getLayoutTitle(mode?: DataSourceMode): string {
        return this.layout == null ? null : this.layout?.getTitleForMode(mode);
    }

    protected get designerTitleFiller(): string {
        return this.title || this.getLayoutTitle() || "[Static Title]";
    }

    protected buildAddlComponents() {
        this.buildAddlLeftComponents();
        this.buildAddlRightComponents();
        this.buildAddlBottomComponents();
    }

    private buildAddlLeftComponents() {
        this.addlPanelLeft.removeAll();
        this._designerAddlPanelLeftComponents?.forEach(c => this.addlPanelLeft.add(c));
    }

    private buildAddlRightComponents() {
        this.addlPanelRight.removeAll();
        this._designerAddlPanelRightComponents?.forEach(c => this.addlPanelRight.add(c));
    }

    private buildAddlBottomComponents() {
        this.addlPanelBottom.removeAll();
        this._designerAddlPanelBottomComponents?.forEach(c => this.addlPanelBottom.add(c));
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return LayoutHeaderPropDefinitions.getDefinitions();
    }

    override get serializationName(): string {
        return "layoutheader";
    }

    override get properName(): string {
        return "Layout Header";
    }

    override get _designer(): DesignerInterface {
        return super._designer;
    }

    override set _designer(value: DesignerInterface) {
        this._shouldAddDesignerContainerProperties = false;
        super._designer = value;
        if (value != null) {
            this.syncTitle();
            this.addlPanelLeft._designer = value;
            this.addlPanelRight._designer = value;
            this.addlPanelBottom._designer = value;
        }

        value?.addDesignerContainerProperties(this, 80, 34, null, false);
        this.updateComponentsForDesigner();
    }

    protected updateComponentsForDesigner() {
        this.labelTitle._interactionEnabled = this._designer == null;
    }

    // "deserialized" is used to distinguish a component defined
    // in the layout json vs components added programmatically.
    protected setChildrenSerialized() {
        this.forEveryChildComponent((c: Component) => c.deserialized = false);
    }

    _serializeNonProps(): string {
        let result = "";
        let panelLeft = "";
        let panelRight = "";
        let panelBottom = "";
        if (ArrayUtil.isEmptyArray(this.addlPanelLeft?.components) !== true)
            panelLeft += serializeComponents(this.addlPanelLeft, null);
        if (ArrayUtil.isEmptyArray(this.addlPanelRight?.components) !== true)
            panelRight += serializeComponents(this.addlPanelRight, null);
        if (ArrayUtil.isEmptyArray(this.addlPanelBottom?.components) !== true)
            panelBottom += serializeComponents(this.addlPanelBottom, null);

        if (StringUtil.isEmptyString(panelLeft) !== true ||
            StringUtil.isEmptyString(panelRight) !== true ||
            StringUtil.isEmptyString(panelBottom) !== true
        ) {
            result += "\"components\": [\n";
            if (StringUtil.isEmptyString(panelLeft) !== true)
                result += panelLeft + ",\n";
            if (StringUtil.isEmptyString(panelRight) !== true)
                result += panelRight + ",\n";
            if (StringUtil.isEmptyString(panelBottom) !== true)
                result += panelBottom + ",\n";
            result = result.substring(0, result.length - 2);
            result += "\n],\n";
        }
        return result;
    }

    async _deserializeSpecialProps(props: DeserializeProps): Promise<string[]> {
        this.setDataSourceFromDeserializeProps(props);

        const components = await new ComponentDeserializer({
            ...props,
            def: props.def.components,
            designer: this.__designer,
            defaultPropValues: null
        }).deserialize();

        if (components != null) {
            for (const component of components) {
                if (component.id === "panelDataHeaderAddlLeft" && component instanceof Panel) {
                    component.deserialized = false;
                    this.replace(this._addlPanelLeft, component);
                    this._addlPanelLeft = component;
                    this._designerAddlPanelLeftComponents = [...this._addlPanelLeft.components];
                    if (this.__designer != null)
                        this._addlPanelLeft.minWidth = 25;
                }
                if (component.id === "panelDataHeaderAddlRight" && component instanceof Panel) {
                    component.deserialized = false;
                    this.replace(this._addlPanelRight, component);
                    this._addlPanelRight = component;
                    this._designerAddlPanelRightComponents = [...this._addlPanelRight.components];
                    if (this.__designer != null)
                        this._addlPanelRight.minWidth = 25;
                }
                if (component.id === "panelDataHeaderAddlBottom" && component instanceof Panel) {
                    component.deserialized = false;
                    this.replace(this._addlPanelBottom, component);
                    this._addlPanelBottom = component;
                    this._designerAddlPanelBottomComponents = [...this._addlPanelBottom.components];
                    if (this.__designer != null)
                        this._addlPanelBottom.minHeight = 25;
                }
            }
        }
        return ["dataSource", "components"];
    }

    public override discoverIncludedComponents(): Component[] {
        return [this._addlPanelLeft, this._addlPanelRight, this._addlPanelBottom];
    }
}

ComponentTypes.registerComponentType("layoutheader", LayoutHeader.prototype.constructor);
