import { Alignment, Color, DisplayType, LeftOrRightAlignment, VerticalAlignment } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ImagePropDefinitions, ImageProps } from "../../components/image/ImageProps";
import { ReadMoreType } from "./ReadMoreType";
import { LabelShapeType } from "./LabelShapeType";

export interface LabelProps extends ComponentProps {
    allowSelect: boolean;
    busy: boolean;
    busySpinnerAlignment: LeftOrRightAlignment;
    busyWhenDataSourceBusy: boolean;
    collapseVerticallyWhenEmpty: boolean;
    displayType: string;
    imageAlign: LeftOrRightAlignment;
    imageColor: Color;
    imageMarginLeft: number;
    imageMarginRight: number;
    imageName: ImageName;
    imageHeight: number;
    imageRotation: number;
    imageWidth: number;
    imageProps: Partial<ImageProps>;
    link: string,
    linkHardRefresh: boolean;
    nullDisplayValue: "hide" | string;
    quickInfoLayout: string;
    readMoreType: ReadMoreType;
    scrollX: boolean;
    scrollY: boolean;
    text: string;
    verticalAlign: VerticalAlignment,
    wrap: boolean;
    shape: LabelShapeType;
}

let labelPropDefs: ComponentPropDefinitions;

export class LabelPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (labelPropDefs == null) {
            labelPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions(),
                ...ImagePropDefinitions.getContainedImagePropDefs(),
                allowSelect: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, description: "This toggles whether or not the user will be able to select the text in the Label to copy it.  Typically, if the user might want to copy the value (because it's some kind of data), we would leave this set to true.  If it's non-data, we would set it to false to improve the UX by not having the mouse cursor change when hovering over the Label." },
                busy: { type: PropType.bool, defaultValue: false, visibleInDesigner: false, description: "This toggles whether or not the label displays the spinner animation." },
                busySpinnerAlignment: { type: PropType.string, defaultValue: Alignment.LEFT, dropdownProps: { items: [ Alignment.LEFT, Alignment.RIGHT] }, visibleInMcLeodTailor: true, description: "This specifies if the label's 'busy' spinner should be shown to the left or right of the label's text.  Note that this is used only when the label doesn't already render an image; when an image is already present, the busy spinner will temporarily replace that image."},
                busyWhenDataSourceBusy: { type: PropType.bool, category: "Data", defaultValue: false, visibleInMcLeodTailor: true, description: "This toggles whether or not the label will automatically be set to busy when its bound datasource is busy." },
                collapseVerticallyWhenEmpty: { type: PropType.bool, visibleInMcLeodTailor: true, description: "This specifies whether this label should use vertical space even when its text value is empty.  This is usually used to maintain vertical alignment between components." },
                displayType: { type: PropType.string, dropdownProps: { items: Object.values(DisplayType) }, category: "Data", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "The display type controls the display and validation of the component." },
                format: { type: PropType.string, category: "Data", visibleInMcLeodTailor: true, description: "For some display types (numeric and date DisplayType), this controls the format that will be used to display data in this component." },
                imageAlign: { type: PropType.string, category: "Image", dropdownProps: { items: [ Alignment.LEFT, Alignment.RIGHT ] }, defaultValue: Alignment.LEFT, visibleInMcLeodTailor: true, description: "This controls where the Label's image (if present) will appear relative to the text." },
                link: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies another page to navigate to when the Label is clicked.  It also causes the Label to be rendered as a link." },
                linkHardRefresh: { type: PropType.bool, visibleInMcLeodTailor: true, description: "This toggles whether or not clicking this Label and navigating to its link will cause the browser to page to reload.  If set to false, the linked page will be loaded in the current Router.  This is much a much faster load but will not work with links to external sites.  This will have no effect unless the link property is also set." },
                nullDisplayValue: { type: PropType.string, category: "Data", visibleInMcLeodTailor: true, description: "This specifies the value that should be displayed if this Label's bound data is empty.  We often set this to something like 'N/A' when it makes sense for the UX of the particular Label.  Setting this property to 'hide' will cause this Label to be hidden when its data is empty." },
                quickInfoLayout: { type: PropType.layout, visibleInMcLeodTailor: true, description: "Specifies a layout that will be opened as a tooltip when the user hovers over the Label.  The mainDataSource of this layout will be searched with the value in this Label." },
                readMoreType: { type: PropType.string, dropdownProps: { items: Object.values(ReadMoreType) }, defaultValue: ReadMoreType.NONE, visibleInMcLeodTailor: true, description: "This specifies if the Label is allowed to display the Read More link, and if so, what happens when the link is clicked." },
                scrollX: { type: PropType.bool, category: "Layout", visibleInMcLeodTailor: true },
                scrollY: { type: PropType.bool, category: "Layout", visibleInMcLeodTailor: true },
                shape: {
                    type: PropType.string,
                    category: "Appearance",
                    dropdownProps: { items: Object.values(LabelShapeType)},
                    visibleInMcLeodTailor: true,
                    description: "This specifies the shape of the Label. When set, a CSS clip-path will be applied to define the Label's shape and may reduce its visible area."
                },
                text: { type: PropType.multilineString, visibleInMcLeodTailor: true, description: "This specifies the text that is to appear in the Label." },
                verticalAlign: { type: PropType.string, dropdownProps: { items: [VerticalAlignment.TOP, VerticalAlignment.CENTER, VerticalAlignment.BOTTOM] }, defaultValue: "center", category: "Appearance", visibleInMcLeodTailor: true, description: "This controls where the text of this Label will be aligned vertically." },
                wrap: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, description: "This toggles whether or not the Label will wrap its text if it is too long for its width.  This only applies to Labels with a limited width - otherwise a long text value will just keep increasing the width of the Label and will never wrap." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(labelPropDefs);
        }
        return labelPropDefs;
    }
}
