import { Panel } from "../components/panel/Panel";
import { Layout } from "../components/layout/Layout";
import { LogManager } from "@mcleod/core";

const log = LogManager.getLogger("base.MainPageAccessor");

export interface MainPageDef {
    readonly mainPage: Panel;
    readonly mainPageHeight: number;
    readonly mainPageWidth: number;
    readonly router: Panel;
    readonly routerHeight: number;
    readonly routerWidth: number;
    readonly pageHeader: Panel;
    readonly pageHeaderHeight: number;
    readonly pageHeaderWidth: number;
    overridePageHeaderZIndex: (millis: number) => void;
    resetPageHeaderZIndex: () => void;
    evaluateExternalLinkVisibility: (topMostPanel: Panel, currentLayouts: Layout[]) => void,
    doNotReportNextError: boolean
}

export class MainPageAccessor {
    private static _mainPageDef: MainPageDef

    constructor(mainPageDef: MainPageDef) {
        if (MainPageAccessor._mainPageDef == null)
            MainPageAccessor._mainPageDef = mainPageDef;
        else
            log.error("MainPageAccessor has already been defined. It will not be set again.")
    }

    static get def(): MainPageDef {
        return this._mainPageDef;
    }

    static get mainPage(): Panel {
        return this.def.mainPage;
    }

    static get mainPageHeight(): number {
        return this.def.mainPageHeight;
    }

    static get mainPageWidth(): number {
        return this.def.mainPageWidth;
    }

    static get router(): Panel {
        return this.def.router;
    }

    static get routerHeight(): number {
        return this.def.routerHeight;
    }

    static get routerWidth(): number {
        return this.def.routerWidth;
    }

    static get pageHeader(): Panel {
        return this.def.pageHeader;
    }

    static get pageHeaderHeight(): number {
        return this.def.pageHeaderHeight
    }

    static get pageHeaderWidth(): number {
        return this.def.pageHeaderWidth;
    }

    static overridePageHeaderZIndex(millis: number): void {
        this.def.overridePageHeaderZIndex(millis);
    }

    static resetPageHeaderZIndex(): void {
        this.def.resetPageHeaderZIndex();
    }
}
