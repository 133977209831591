import { DateUtil, FileUtil, ModelRow } from "@mcleod/core";
import { Panel } from "../../components/panel/Panel";
import { Label } from "../../components/label/Label";
import { PanelProps } from "../../components/panel/PanelProps";
import { ButtonProps } from "../../components/button/ButtonProps";
import { Button } from "../../components/button/Button";
import { ReportViewer } from "./ReportViewer";
import { LayoutHeader } from "../../components/layoutheader/LayoutHeader";

export class ReportPreview extends Panel {
    private _header: LayoutHeader;
    private buttonDownloadPDF: Button;
    private buttonDownloadExcel: Button;
    private readonly panelReportViewer: ReportViewer;
    private imageBytes: string = null;
    private excelBytes: string = null;

    constructor(reportName: string, panelProps?: Partial<PanelProps>) {
        super({ id: "panelReportPreview", padding: 0, ...panelProps });
        this.setupHeader();
        this.reportName = reportName;
        this.add(this._header);
        this.panelReportViewer = new ReportViewer();
        this.add(this.panelReportViewer);
    }

    public get reportName(): string {
        return this._header.title ?? "";
    }

    public set reportName(value: string) {
        this._header.title = value;
    }

    private setupHeader() {
        this._header = new LayoutHeader({
            id: "layoutheaderReportPreview",
            fillRow: true,
            padding: 0,
            margin: 0
        });
        this.createDownloadPDFImage();
        this.createDownloadExcelImage();
        this._header.addRightTools(this.createExportLabel(), this.buttonDownloadPDF, this.buttonDownloadExcel);
    }

    private createExportLabel(): Label {
        return new Label({
            id: "labelExport",
            text: "Export as:",
            color: "subtle.darker",
            fontBold: true,
            rowBreak: false
        });
    }

    private createDownloadPDFImage() {
        this.buttonDownloadPDF = new Button({
            ...this.getDownloadImageProps(),
            id: "imageDownloadPDF",
            imageName: "pdfDisabled",
            rowBreak: false,
            onClick: () => this.downloadPDF()
        });
    }

    private downloadPDF() {
        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        FileUtil.downloadBase64AsFile(this.imageBytes, `Report ${this.reportName} ${todaysDate}.pdf`);
    }

    private createDownloadExcelImage() {
        this.buttonDownloadExcel = new Button({
            ...this.getDownloadImageProps(),
            id: "imageDownloadExcel",
            imageName: "excelDisabled",
            onClick: () => this.downloadExcel()
        });
    }

    private downloadExcel() {
        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        FileUtil.downloadBase64AsFile(this.excelBytes, `Report ${this.reportName} ${todaysDate}.xlsx`);
    }

    private getDownloadImageProps(): Partial<ButtonProps> {
        return {
            enabled: false,
            height: 40,
            width: 40,
            padding: 0,
            borderWidth: 0,
            imageName: "excelDisabled",
            imageHeight: 32,
            imageWidth: 32
        };
    }

    private enableDownloads(value: boolean) {
        this.buttonDownloadPDF.enabled = value;
        this.buttonDownloadPDF.imageName = value ? "pdf" : "pdfDisabled";

        this.buttonDownloadExcel.enabled = value;
        this.buttonDownloadExcel.imageName = value ? "excel" : "excelDisabled";
    }

    public setData(row: ModelRow) {
        try {
            if (row != null) {
                this.reportName = row.get("report_name");
                this.imageBytes = row.get("pdf_output");
                this.excelBytes = row.get("excel_output");
                this.panelReportViewer.imageBytes = row.get("pdf_output");
            }
            else {
                this.imageBytes = null;
                this.excelBytes = null;
                this.panelReportViewer.imageBytes = "";
            }
        }
        finally {
            this.enableDownloads(this.imageBytes != null);
        }
    }

    public clearData() {
        this.setData(null);
    }

    public beginSearch() {
        this.panelReportViewer.searching = true;
        this.clearData();
        this.enableDownloads(false);
    }

    public endSearch(row: ModelRow) {
        this.panelReportViewer.searching = false;
        this.setData(row);
    }
}
