import { StringUtil } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { DesignerInterface } from "../../base/DesignerInterface";
import { Label } from "../label/Label";
import { LabelProps } from "../label/LabelProps";
import { List } from "../list/List";
import { ListItem } from "../list/ListItem";
import { ContextMenu } from "./ContextMenu";
import { ContextMenuLabelPropDefinitions, ContextMenuLabelProps } from "./ContextMenuLabelProps";
import { ComponentUtil } from "../../base/ComponentUtil";

export class ContextMenuLabel extends Label implements ContextMenuLabelProps {
    private _list: ContextMenu;
    private _sourceComponent: Component;
    private _separatorAfter: boolean;
    private _separatorListItem: ListItem;

    constructor(sourceComponent: Component, props?: Partial<LabelProps>) {
        super({});
        this.sourceComponent = sourceComponent;
        if (props?.text != null) {
            this.text = props.text;
            delete props.text;
        }
        this.applyDefaultOverrideProps();
        this.setProps(props);
    }

    override get _designer(): DesignerInterface {
        return super._designer;
    }

    override set _designer(value: DesignerInterface) {
        super._designer = value;
        this.deserialized = value != null;
    }

    get separatorAfter(): boolean {
        return this._separatorAfter;
    }

    set separatorAfter(value: boolean) {
        if (this._separatorAfter === value)
            return;
        this._separatorAfter = value;
        this.syncSeparator();
    }

    get separatorListItem(): ListItem {
        return this._separatorListItem;
    }

    syncSeparator() {
        if (this.separatorAfter) {
            this._separatorListItem ??=  List.createSeparator();
            this.syncSeparatorComponent();
            this.insertSeparatorIfNeeded();
        } else if (this._separatorListItem != null) {
            this._list?.removeItem(this._separatorListItem);
            this._separatorListItem = null;
        }
    }

    syncSeparatorComponent() {
        this._separatorListItem?.renderedComponent?.setProps({
            id:`${this.id }_separator`,
            visible: this._designer ? true : this.visible
        });
    }

    private insertSeparatorIfNeeded() {
        const shouldInsert = this._list != null && this._list.items.find((item: ListItem) => item === this.separatorListItem) == null;
        if (!shouldInsert)
            return;

        const newItems = [];
        for (const item of this._list.items) {
            newItems.push(item);
            if (item.renderedComponent === this)
                newItems.push(this.separatorListItem);
        }
        this._list.items = newItems;
    }

    override get visible(): boolean {
        return super.visible;
    }

    public set visible(value: boolean) {
        super.visible = value;
        this.syncSeparatorComponent();
    }

    get owner(): any {
        return super.owner ?? this.sourceComponent?.owner;
    }

    set owner(value: any) {
        super.owner = value;
    }

    get sourceComponent(): Component {
        return this._sourceComponent;
    }

    set sourceComponent(value: Component) {
        this._sourceComponent = value;
    }

    protected _matchIdToValue(oldValue: string, value: string, idPrefix: string = this.sourceComponent?.id) {
        if (this._designer?.redisplayProp == null || StringUtil.isEmptyString(value))
            return;
        const newId = this.generateId(value, idPrefix);
        if (!StringUtil.isEmptyString(newId) && this.id !== newId) {
            this.id = newId;
            if (this._designer.selectedComponents?.includes(this))
                this._designer.redisplayProp("id", this.id);
        }
    }

    override get id(): string {
        return this.generateId() ?? super.id;
    }

    override set id(value: string) {
        super.id = value;
    }

    getId(includePrefix = true): string {
        return includePrefix ? this.id : StringUtil.stringAfter(this.id, "-", false, this.id);
    }

    private generateId(value: string = this.text, idPrefix: string = this.sourceComponent?.id): string {
        if (value == null)
            return null;
        return ComponentUtil.createPrefixedId(idPrefix, StringUtil.replaceAll(value ?? "", " ", "_").toLowerCase());
    }

    override getPropertyDefinitions() {
        return ContextMenuLabelPropDefinitions.getDefinitions();
    }

    applyDefaultOverrideProps() {
        Object.values(this.getPropertyDefinitions()).forEach(value => {
            const overrideValue = ContextMenuLabelPropDefinitions.overrideDefaultValue(value);
            if (overrideValue != null) {
                this[value.name] = overrideValue;
                this.addPropertyDefaultValueOverride(value.name, overrideValue);
            }
        });

        const imageName = this.imageName ?? this.imageProps?.name;

        this.imageProps = {
            ...this.imageProps,
            name: imageName ?? "", // force the imageElement to be added added, ensuring the text is aligned properly
            height: 16,
            width: 16,
            marginLeft: 4,
            marginRight: 12
        };
    }

    override get serializationName() {
        return "contextmenulabel";
    }

    override get properName(): string {
        return "ContextMenuLabel";
    }
}

ComponentTypes.registerComponentType("contextmenulabel", Label.prototype.constructor);
