import { Alignment, DynamicLoader, GeneralSettings, LogManager, StringUtil } from "@mcleod/core";
import { PanelProps } from "../components/panel/PanelProps";
import { DomEventListener, DomMouseEvent } from "../events/DomEvent";
import { MouseEvent } from "../events/MouseEvent";
import { ComponentCreator, ComponentFactory } from "../page/ComponentFactory";
import { TooltipOptions } from "../page/Tooltip";
import { Component } from "./Component";

const log = LogManager.getLogger("components/ComponentTooltipHandler");

export class ComponentTooltipHandler {
    private static _preOverlayMouseOverComponent: Component;
    private readonly _component: Component;
    private _displayTooltipEventListener: DomEventListener;
    private _dismissTooltipEventListener: DomEventListener;
    private _tooltipMouseMoveListener: DomEventListener;
    private _lastMouseOverEvent: DomMouseEvent;
    private _mouseOver: boolean;
    private _hideTooltipOnClick: boolean;
    private _tooltipDisplayTimeout: any;
    private _tooltipHideTimeout: any;
    public tooltipPosition: Alignment;
    public tooltipInstance: Component;

    constructor(component: Component) {
        this._component = component;
    }

    get hideTooltipOnClick(): boolean {
        return this._hideTooltipOnClick == null ? true : this._hideTooltipOnClick;
    }

    set hideTooltipOnClick(value: boolean) {
        this._hideTooltipOnClick = value;
    }

    get isMouseOver(): boolean {
        return this._mouseOver === true;
    }

    private get hasRequiredObjects(): boolean {
        return this._component.tooltip != null || this._component.tooltipCallback != null || this._component.disabledTooltip != null;
    }

    manageTooltipDisplayEvent() {
        if (this._component._designer != null && this._component["_allowTooltipInDesigner"] !== true)
            return;

        const isListeningForEvent = this._displayTooltipEventListener != null;
        if (isListeningForEvent === this.hasRequiredObjects) {
            // Both true? We have at least one required object and are currently listening for events.
            // Both false? We do not have any required objects nor are we currently listening for events.
            return;
        }
        if (this.hasRequiredObjects /* and not currently listening... */) {
            this._displayTooltipEventListener = (event: DomMouseEvent) => this._doOnDisplayTooltipEvent(event);
            this._component._element.addEventListener("mouseenter", this._displayTooltipEventListener);
        } else /* remove listeners, since we don't have required objects */ {
            this._component._element.removeEventListener("mouseenter", this._displayTooltipEventListener);
            delete this._displayTooltipEventListener;
            this._clearTooltipShowingEvents();
        }
    }

    private _doOnDisplayTooltipEvent(event: DomMouseEvent) {
        log.debug(this, "Fired doOnDisplayTooltipEvent for component %o, event %o", this._component, event);
        // Closing an overlay means that _displayTooltipEventListener can fire for a component if the mouse was over that component when the overlay closed,
        //  even if the mouse didn't just enter the boundary of the component.  when that happens, we do not want to show a tooltip.
        if (this._component === ComponentTooltipHandler.getPreOverlayMouseOverComponent()) {
            log.debug(this, "Ignoring display tooltip event; mouse was over this field before an overlay was displayed, and still is.")
            return;
        }
        if (GeneralSettings.isRunningAutomation && !event.ctrlKey) {
            log.debug(this, "Ignoring display tooltip event; automation is running and ctrl key is not pressed")
            return;
        }
        this._mouseOver = true;
        this._clearTooltipShowingEvents();
        this._setupTooltipShowingEvents();
        this._displayTooltipOnDelay();
    }

    private _setupTooltipShowingEvents() {
        this._tooltipMouseMoveListener = (event: DomMouseEvent) => this._trackMouseMovementEvent(event)
        this._dismissTooltipEventListener = (event: DomMouseEvent) => this._doOnDismissTooltipEvent(event);
        this._component._element.addEventListener("mouseleave", this._dismissTooltipEventListener);
        this._component._element.addEventListener("mousemove", this._tooltipMouseMoveListener);
    }

    private _clearTooltipShowingEvents() {
        if (this._dismissTooltipEventListener != null)
            this._component._element.removeEventListener("mouseleave", this._dismissTooltipEventListener);
        if (this._tooltipMouseMoveListener != null)
            this._component._element.removeEventListener("mousemove", this._tooltipMouseMoveListener);
        delete this._dismissTooltipEventListener;
        delete this._tooltipMouseMoveListener;
    }

    private _trackMouseMovementEvent(event: DomMouseEvent) {
        log.debug(this, "Fired mousemove for component %o, event %o", this._component, event);
        this._lastMouseOverEvent = event;
    }

    private _doOnDismissTooltipEvent(event: DomMouseEvent) {
        log.debug(this, "Fired mouseleave for component %o, event %o", this._component, event);
        this._mouseOver = false;
        this._clearTooltipShowingEvents();
        if (this._tooltipDisplayTimeout != null)
            clearTimeout(this._tooltipDisplayTimeout);
        if (this.tooltipInstance != null && !this.tooltipInstance._element.contains(event.relatedTarget as Node)) {
            this._tooltipHideTimeout = setTimeout(() => this.hideTooltip(), 500);
            log.debug(this, "Set tooltip hide timeout: %o for component id: %o", this._tooltipHideTimeout, this._component.id);
        }
    }

    public showTooltip(text: ComponentCreator, options?: Partial<TooltipOptions>, props?: Partial<PanelProps>) {
        if (!StringUtil.isEmptyString(text || this._component.tooltip))
            return DynamicLoader.getModuleByName("components/page/Tooltip")
                .showTooltip(this._component, text || this._component.tooltip, options, props);
    }

    public hideTooltip() {
        this._mouseOver = false;
        DynamicLoader.getModuleByName("components/page/Tooltip").hideTooltip(this.tooltipInstance);
        this.tooltipInstance = null;
    }

    private _displayTooltipOnDelay() {
        this._tooltipDisplayTimeout = setTimeout(() => {
            if (this._mouseOver) {
                this.clearTooltipHideTimeout();
                this._displayTooltip(new MouseEvent(this._component, this._lastMouseOverEvent));
            }
        }, 500);
    }

    private async _displayTooltip(originatingEvent: MouseEvent) {
        if (this._component.suppressTooltip == true || !this.hasRequiredObjects)
            return;
        let tooltip: Component;
        if (this._component.tooltip != null)
            tooltip = ComponentFactory.createCommon(this._component.tooltip, null, this._component);
        if (this._component.disabledTooltip != null && this._component.enabled === false) {
            const disabledTooltip = ComponentFactory.createCommon(this._component.disabledTooltip, null, this._component);
            tooltip = this.appendTooltipComponents(tooltip, disabledTooltip);
        }
        if (this._component.tooltipCallback == null) {
            this.tooltipInstance = this.showTooltip(tooltip, {
                position: this.tooltipPosition,
                originatingEvent: originatingEvent
            });
        } else {
            // Have to call .tooltipCallback from ._component, since
            // Component is the class that mixes-in the QuickInfo class. Otherwise, QuickInfoComponent would
            // fail due to references of this in the callback would refer to `ComponentTooltipProvider`.
            this.tooltipInstance = await this._component.tooltipCallback(tooltip, originatingEvent);
        }
    }

    private appendTooltipComponents(...comps: Component[]): Component {
        if (comps == null || comps.length === 0)
            return undefined;
        const nonNullComps = [];
        for (const comp of comps)
            if (comp != null)
                nonNullComps.push(comp);
        comps = nonNullComps;
        if (comps.length === 1)
            return comps[0];
        const panelClass = DynamicLoader.getClassForPath("components/components/panel/Panel");
        const result = new panelClass();
        for (let i = 0; i < comps.length - 1; i++) {
            result.add(comps[i]);
            result.add(new panelClass({ marginBottom: 8 }));
        }
        result.add(comps[comps.length - 1]);
        return result;
    }

    public clearTooltipHideTimeout() {
        if (this._tooltipHideTimeout != null) {
            log.debug(this, "Clearing tooltip hide timeout: %o for component id: %o", this._tooltipHideTimeout, this._component.id);
            clearTimeout(this._tooltipHideTimeout);
            this._tooltipHideTimeout = null;
        }
    }

    public static getPreOverlayMouseOverComponent(): Component {
        return this._preOverlayMouseOverComponent;
    }

    public static setPreOverlayMouseOverComponent(comp: Component) {
        // If the supplied value is not null, only assign it if the mouse is currently
        // over that component. Always allow the value to be set to null.
        if (comp != null) {
            if (comp.isMouseOver)
                this._preOverlayMouseOverComponent = comp;
        } else {
            this._preOverlayMouseOverComponent = comp;
        }
    }
}
