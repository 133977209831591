import { getTheme, getThemeColor, Styles } from "@mcleod/core";

export const SwitchStyles = Styles.make("swch", () => {
    return {
        noCaption: {
            display: "flex",
            alignItems: "center"
        },
        root: {
            display: "inline-flex"
        },
        track: {
            width: "44px",
            height: "20px",
            borderRadius: "16px",
            marginTop: "2px",
            outline: "none"
        },
        thumb: {
            width: "27px",
            height: "27px",
            marginTop: "-3px",
            marginLeft: "-3px",
            borderRadius: "50%",
            transition: "transform .2s",
            backgroundColor: getThemeColor("subtle.darkest") + "2",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        thumbDisabled: {
            width: "27px",
            height: "27px",
            marginTop: "-3px",
            marginLeft: "-3px",
            borderRadius: "50%",
            transition: "transform .2s",
            backgroundColor: getThemeColor("subtle.darkest") + "2",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        switchContainer: {
            display: "flex",
        },
        thumbInner: {
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: getThemeColor("primary.reverse"),
        },
        thumbInnerDisabled: {
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: getThemeColor("primary.reverse"),
        },
        checked: {
            transform: "translateX(24px)"
        },
        neutral: {
            transform: "translateX(12px)"
        },
        svg: {
            color: getTheme().palette.primary.reverse,
            width: "24px",
            height: "24px",
            padding: "3px"
        }
    }
});
