import {
    Alignment, Api, ArrayUtil, Collection, Color, DOMUtil, FieldUpdateEvent, getThemeColor, HorizontalAlignment,
    isRightAlignedDisplayType, Keys, LeftOrRightAlignment, Model, ModelRow, Mutex, ObjectUtil, SortDirection, SortUtil,
    StringUtil, UserSettings, VerticalAlignment
} from "@mcleod/core";
import {
    Button, ChangeEvent, Container, CrudDecoratorCloseEvent, CrudDecoratorCloseListener, DomEvent, Image, KeyHandler,
    Label, Panel, PanelProps, ScreenStack, serializeComponents, TableAddRowOptions, TableAddRowResult,
    TableRowAdditionalToolsDisplayEvent, TableRowAdditionalToolsDisplayListener, TableRowModeChangeEvent,
    TableRowModeChangeListener, Textbox, ValidationResult
} from "../..";
import { CloneComponent } from "../../base/CloneComponent";
import { Component } from "../../base/Component";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";
import { DesignerInterface } from "../../base/DesignerInterface";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { PermissionsDefinition } from "../../base/PermissionsDefinition";
import { SelectionMode, SelectionType } from "../../base/SelectionMode";
import { DataSource, DataSourceAction, DataSourceMode } from "../../databinding/DataSource";
import { ClickEvent } from "../../events/ClickEvent";
import { Event } from "../../events/Event";
import { KeyEvent } from "../../events/KeyEvent";
import {
    TableAction, TableContentsChangedEvent, TableContentsChangedListener
} from "../../events/TableContentsChangedEvent";
import { TableFilterChangedEvent, TableFilterChangedListener } from "../../events/TableFilterChangedEvent";
import { TableRowBeforeSaveEvent, TableRowBeforeSaveListener } from "../../events/TableRowBeforeSaveEvent";
import { TableRowCreationEvent, TableRowCreationListener } from "../../events/TableRowCreationEvent";
import { TableRowDisplayEvent, TableRowDisplayListener } from "../../events/TableRowDisplayEvent";
import { TableRowExpansionEvent, TableRowExpansionListener } from "../../events/TableRowExpansionEvent";
import { TableRowMoveEvent, TableRowMoveListener } from "../../events/TableRowMoveEvent";
import { TableSelectionEvent, TableSelectionListener } from "../../events/TableSelectionEvent";
import { ComponentCreator, ComponentFactory } from "../../page/ComponentFactory";
import { Overlay } from "../../page/Overlay";
import { EditRowDecorator } from "../../page/decorators/EditRowDecorator";
import { ComponentDeserializer, DeserializeProps } from "../../serializer/ComponentDeserializer";
import { serializeProps } from "../../serializer/ComponentSerializer";
import { ButtonVariant } from "../button/ButtonVariant";
import { DropTargetPanel } from "../panel/DropTargetPanel";
import { Tab } from "../tabset/Tab";
import { ClearButtonVisible } from "../textbox/ClearButtonVisible";
import { TextboxVariant } from "../textbox/TextboxVariant";
import { AddType } from "./AddType";
import { ComponentSearcher } from "./ComponentSearcher";
import { FieldSortInfo } from "./FieldSortInfo";
import { RowModeControlType } from "./RowModeControlType";
import { SortFieldInfo } from "./SortFieldInfo";
import { SortSelector } from "./SortSelector";
import { TableCell } from "./TableCell";
import { TableColumn } from "./TableColumn";
import { TableConfig } from "./TableConfig";
import { TablePropDefinitions, TableProps, TableRowEditResolveMode } from "./TableProps";
import { DragSession, TableRow } from "./TableRow";
import { TableRowMode } from "./TableRowMode";
import { TableRowProps } from "./TableRowProps";
import { TableRowStringSearcher } from "./TableRowStringSearcher";
import { TableStyles } from "./TableStyles";
import { TableToolsPanel } from "./TableToolsPanel";
import { HeadingTableCell } from "./HeadingTableCell";
import { AdditionalTableRowToolsDesignerPanel } from "./AdditionalTableRowToolsDesignerPanel";

const defaultExpandBackground = "background4";
const defaultExpandColor = "primary.light";

const _rowExpandListenerDef: ListenerListDef = { listName: "_rowExpandListeners" };
const _rowCollapseListenerDef: ListenerListDef = { listName: "_rowCollapseListeners" };
const _rowCreateListenerDef: ListenerListDef = { listName: "_rowCreateListeners" };
const _rowBeforeSaveListenerDef: ListenerListDef = { listName: "_rowBeforeSaveListeners" };
const _contentsChangedListenerDef: ListenerListDef = { listName: "_contentsChangedListenerDef" };
const _filterChangedListenerDef: ListenerListDef = { listName: "_filterChangedListenerDef" };
const _afterTableRowMoveListenerDef: ListenerListDef = { listName: "_afterTableRowMoveListenerDef" };
const _beforeTableRowMoveListenerDef: ListenerListDef = { listName: "_beforeTableRowMoveListenerDef" };
const _afterCrudCoseListenerDef: ListenerListDef = { listName: "_afterCrudCoseListenerDef" };
const _rowDisplayListenerDef: ListenerListDef = { listName: "_rowDisplayListeners" };
const _rowModeChangeListenerDef: ListenerListDef = { listName: "_rowModeChangeListeners" };
const _rowAdditionalToolsListenersDef: ListenerListDef = { listName: "_rowAdditionalToolsListeners" };
const _selectionListenerDef: ListenerListDef = { listName: "_selectionListeners" };

export enum SearchFilterVisible {
    BOTH = "both",
    SEARCH_ONLY = "searchOnly",
    FILTER_ONLY = "filterOnly",
    NEITHER = "neither"
}

const log = DesignableObjectLogManager.getLogger("components.table.Table");

export class Table extends Component implements TableProps {
    private _allowAddDisabledByServer: boolean;
    private _allowAdvancedSearch: boolean;
    private _allowConfig: boolean;
    private _allowDelete: boolean;
    private _allowDeleteDisabledByServer: boolean;
    private _allowDetail: boolean;
    private _allowDetailForRow: (row: TableRow) => boolean;
    private _allowEdit: boolean;
    private _allowEditDisabledByServer: boolean;
    private _allowExport: boolean;
    private _allowPin: boolean;
    private _allowShare: boolean;
    public _columns: TableColumn[];
    public rowSpacing: number;
    private _rows: TableRow[];
    private _allRows: TableRow[];
    private _selectedIndexes: number[];
    public virtualized: boolean;
    private _table: HTMLTableElement;
    protected _tbody: HTMLElement;
    private _thead: HTMLTableSectionElement;
    private lastScrollLeft: number;
    private headingRow: TableRow;
    private _observer: IntersectionObserver;
    private _columnHeadingsVisible: boolean;
    private _headerVisible: boolean;
    private _searchFilterVisible: SearchFilterVisible;
    private _expanderHeaderSpacer: HTMLElement;
    private _readingFromJson: boolean;
    private _selectionMode: SelectionMode;
    private _fixedRowHeight: number;
    public preRenderRowCount: number;
    private _data: any[];
    private _filteredRows: TableRow[];
    private _filterValue: string;
    private _addlSearcherCallback: () => ComponentSearcher[];
    private _heading: Panel;
    private _search: Textbox;
    private _tableRowStringSearcher: TableRowStringSearcher;
    private _filterCTA: Image;
    private _rowCountLabel: Label;
    private _filter: Textbox;
    private _filterClearButtonVisible: ClearButtonVisible;
    private _busy: boolean;
    private _busyWhenDataSourceBusy: boolean;
    private _busyImage: Image;
    private _buttonAddColumn: Button;
    private _panelDesignerExpansionDropTarget: Panel;
    private _expandComponent: any;
    private _expanderComponent: Component;
    private _additionalRowToolsDef: any;
    private _additionalRowToolsPanel: AdditionalTableRowToolsDesignerPanel;
    private _emptyCaption: string;
    private _emptyComponent: Component;
    private _noRecordsMatch: boolean;
    private _sortInfo: FieldSortInfo[];
    private _addType: AddType;
    private quickAddRowMutex = new Mutex();
    private _quickAddRow: TableRow;
    private _quickAddData: ModelRow | any;
    private _rowModeControlType: RowModeControlType;
    private _rowProps: Partial<TableRowProps>;
    private _filterTimeoutHandle: number;
    private _dbSearchTimeoutHandle: number;
    private _allowDbSearch: boolean = false;
    private _columnSortOverlay: Overlay;
    public expanderAlignment: Alignment;
    public rowAlign: VerticalAlignment;
    public rowBorderBottomWidth: number;
    public rowBorderBottomColor: Color;
    public rowBorderTopWidth: number;
    public rowBorderTopColor: Color;
    public _generalLayout: string;
    public _detailLayout: string;
    public _editLayout: string;
    public _addLayout: string;
    public _searchLayout: string;
    public editLayoutWidth: number;
    public exportName: string;
    private _resizeObserver: ResizeObserver;
    private _displayToolsCell: boolean;
    private _toolsCellAlignment: LeftOrRightAlignment;
    public toolsPanel: TableToolsPanel = new TableToolsPanel(this);
    private largestToolsCellWidth: number = 0;
    public possibleConfigs: Collection<string> = {};
    private _configInUse: TableConfig;
    private _baseConfig: TableConfig;
    private _sequenceField: string;
    private _outsideClickListener: (event) => void;
    private _insideClickEvent: PointerEvent;
    private _rowEditResolveMode: TableRowEditResolveMode;
    private _afterDbSearchListener = (event) => {
        if (event.getAction() === DataSourceAction.SEARCH && event.isAfter())
            this._doAfterDbSearch();
    };
    private _persistChangesImmediately: boolean;
    public onEditLayoutLoaded: (rowDecorator: EditRowDecorator, tableRow: TableRow) => void;
    public doOnRowDelete: (row: TableRow) => void;
    public onValidate: (table: Table) => ValidationResult[];
    private _lastSelectedRow: TableRow;
    private _nonStandardRows: TableRow[];
    public _printableToggleEnabled: boolean;
    private _oddRowColor: Color;
    private _evenRowColor: Color;
    private _ignoreRowColorSetting: boolean;
    private _customFilter: (data:TableRow) => boolean;
    private _expandRowsPromise: Promise<boolean>;

    constructor(props?: Partial<TableProps>) {
        super("div", props);
        this._element.classList.add(TableStyles.base);
        this._columns = [];
        this._rows = [];
        this._allRows = []; //this array contains all the table's rows, even the ones that aren't displayed
        this._selectedIndexes = [];
        this.rowSpacing = 4;
        this.virtualized = true; // props == null || props.virtualized === undefined ? true : props.virtualized;
        this._table = document.createElement("table");
        this._table.className = TableStyles.tableBase;
        this._tbody = document.createElement("tbody");
        this._tbody.className = TableStyles.tbodyBase;
        this._tbody.addEventListener("scroll", event => this.bodyScrolled(event));
        this._tbody.tabIndex = 0;
        this._thead = document.createElement("thead");
        this._thead.className = TableStyles.theadBase + " " + TableStyles.hideScroll;
        const headingRowProps: Partial<TableRowProps> = {
            className: TableStyles.theadRowBase,
            virtualized: false,
            allowEdit: false,
            allowDelete: false
        };
        this.headingRow = new TableRow(this, headingRowProps);
        this._thead.appendChild(this.headingRow._element);
        this._thead.addEventListener("scroll", event => this.headScrolled(event));
        this._table.appendChild(this._thead);
        this._table.appendChild(this._tbody);
        this.addHeading();
        this._element.appendChild(this._table);
        if (this.virtualized === true)
            this._observer = new IntersectionObserver((entries) => this.handleIntersection(entries), { root: this._tbody });
        this._columnHeadingsVisible = true;
        this._headerVisible = true;
        this._addType = AddType.NOT_ALLOWED;
        this._rowModeControlType = RowModeControlType.AUTO;
        this._rowProps = null;
        this.setProps(props);
        this.ensureEmptyComponentCreated();
        this.addKeyDownListener((event: KeyEvent) => this.sendKey(event));
        this.syncQuickAddRow();
        this._resizeObserver = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => {
                if (Array.isArray(entries) && entries.length > 0)
                    this.tableResized();
            });
        });
        this._resizeObserver.observe(this._tbody);
    }

    public override async ensureLoaded() {
        await this.toolsPanel?.ensureLoaded();
    }

    activateValidateOnOutsideClick(insideClickEvent: PointerEvent) {
        this._insideClickEvent = insideClickEvent;
        if (this._outsideClickListener == null && this.allowEdit && this.rowModeControlType === RowModeControlType.AUTO) {
            log.debug(this, "Activating outside click listener");
            this._outsideClickListener = (event) => this._validateOnOutsideClick(event);
            document.addEventListener("click", this._outsideClickListener, true);
        }
    }

    private _deactivateValidateOnOutsideClick() {
        log.debug(this, "Deactivating outside click listener");
        document.removeEventListener("click", this._outsideClickListener, true);
        this._outsideClickListener = null;
        this._insideClickEvent = null;
    }

    private _validateOnOutsideClick(event) {
        if (this._insideClickEvent === event ||
            this._tbody.contains(event.target) ||
            this.quickAddRow?._element === event.target || this.quickAddRow?._element.contains(event.target) || this.clickedInContainedOverlay())
            return;
        this.completeEditedRows();
        this._deactivateValidateOnOutsideClick();
    }

    private clickedInContainedOverlay(): boolean {
        let sourceElement = ScreenStack.getNewestOverlay()?.sourceComponent;
        if (sourceElement instanceof Component)
            sourceElement = sourceElement._element;
        return DOMUtil.isOrContains(this._tbody, sourceElement);
    }

    /**
     * Callback that defines things to do when the table is resized
     */
    private tableResized() {
        this.recalculateSpecialRowPadding();
    }

    setProps(props: Partial<TableProps>) {
        super.setProps(props);
    }

    public get filterTextbox(): Textbox {
        return this._filter;
    }

    get id(): string {
        return super.id;
    }

    set id(value: string) {
        const oldId = this.id;
        const oldExpansionId = this.expandComponent?.id;
        super.id = value;
        if (this.expandComponent != null &&
            (StringUtil.isEmptyString(this.expandComponent.id) === true || oldExpansionId === (oldId + "-expand"))) {
            this.expandComponent.id = this.getExpandComponentId();
        }
        this.applyDefaultConfig();
    }

    get selectionMode(): SelectionMode {
        return this._selectionMode || SelectionMode.NONE;
    }

    set selectionMode(value: SelectionMode) {
        this._selectionMode = value;
    }

    get selectedIndexes(): number[] {
        return this._selectedIndexes;
    }

    set selectedIndexes(value: number[]) {
        this._setSelectedIndexesInternal(value, null);
    }

    get rowProps(): Partial<TableRowProps> {
        return this._rowProps;
    }

    set rowProps(value: Partial<TableRowProps>) {
        const initialRowProps = this._rowProps;
        this._rowProps = value;
        if (this._designer != null && this._allRows.length > 0 && initialRowProps == null) {
            this._allRows[0].setProps({ ...this._rowProps });
        }
    }

    get tableHeadingRow(): TableRow {
        return this.headingRow;
    }

    private _setSelectedIndexesInternal(value: number[], domEvent: DomEvent) {
        const oldIndexes = this.selectedIndexes;
        if (value == null)
            value = [];

        if (ArrayUtil.equals(value, oldIndexes)) {
            return;
        }

        for (const index of this.selectedIndexes) {
            const previouslySelectedRow = this.rows[index];
            // The previously selected row may have been removed already
            if (previouslySelectedRow != null)
                previouslySelectedRow.selected = false;
        }
        this._selectedIndexes = value;
        for (const index of value)
            this.rows[index].selected = true;
        this.toolsPanel.selectionChanged();
        const event = new TableSelectionEvent(this, oldIndexes, this.getRowsByIndexes(value), this.selectedIndexes, this.getRowsByIndexes(value), domEvent);
        this.fireListeners(_selectionListenerDef, event);
    }

    getRowsByIndexes(indexes: number[]): TableRow[] {
        const result: TableRow[] = [];
        for (const index of indexes)
            result.push(this.rows[index]);
        return result;
    }

    getRowByIndex(index: number): TableRow {
        if (index < 0 || index >= this.rows.length)
            return null;
        return this.rows[index];
    }

    /**
     * The rows array contains the list of rows that are currently displayed.  I wish it was called displayedRows instead.
     */
    get rows() {
        return this._rows;
    }

    set rows(value: TableRow[]) {
        this._rows = value;
    }

    /**
     * Gets all TableRows whether they are filtered out / displayed or not.
     */
    get allRows() {
        return this._allRows;
    }

    get hasSelection(): boolean {
        return ArrayUtil.isEmptyArray(this.selectedIndexes) === false;
    }

    get selectedIndex() {
        if (this.selectedIndexes.length !== 1)
            return -1;
        return this.selectedIndexes[0];
    }

    set selectedIndex(value) {
        if (this.filteredRows == null || value >= this.filteredRows.length)
            this.selectedIndexes = [];
        else
            this.selectedIndexes = [value];
    }

    get selectedAllRowsIndex(): number {
        const selectedRow = this.selectedRow;
        if (selectedRow == null)
            return -1;
        return this._allRows.indexOf(selectedRow);
    }

    get selectedRows(): TableRow[] {
        const result = [];
        for (const index of this.selectedIndexes)
            result.push(this.rows[index]);
        return result;
    }

    set selectedRows(value: TableRow[]) {
        this._setSelectedRowsInternal(value, null);
    }

    get heading(): Panel {
        return this._heading;
    }

    private _setSelectedRowsInternal(value: TableRow[], event: DomEvent) {
        if (value == null)
            value = [];
        const indexes = [];
        for (const row of value)
            if (this.indexOf(row) > -1)
                indexes.push(this.indexOf(row));
        this._setSelectedIndexesInternal(indexes, event);
    }

    get selectedRow(): TableRow {
        const index = this.selectedIndex;
        if (index < 0)
            return null;
        return this.rows[index];
    }

    set selectedRow(value: TableRow) {
        const index = this.indexOf(value);
        if (index < 0)
            this.selectedIndexes = [];
        else
            this.selectedIndexes = [index];
    }

    get columnHeadingsVisible() {
        return this._columnHeadingsVisible;
    }

    set columnHeadingsVisible(value) {
        if (value === this._columnHeadingsVisible)
            return;
        this._columnHeadingsVisible = value;
        if (value)
            this._table.insertBefore(this._thead, this._tbody);
        else
            this._table.removeChild(this._thead);
    }

    get columns() {
        return this._columns;
    }

    set columns(value: TableColumn[]) {
        this._columns = [];
        if (value != null) {
            for (let x = 0; x < value.length; x++) {
                const column = value[x];
                this.addColumn(column, x === 0, x === value.length - 1);
                if (this.owner != null && column.id != null)
                    this.owner[column.id] = column;
            }
        }
    }

    scrollToSelection() {
        if (this.selectedRows.length > 0)
            this.selectedRows[0].scrollIntoView();
    }

    sendKey(event: KeyEvent | KeyboardEvent): boolean {
        if (event.ctrlKey || event.altKey || this.selectionMode === SelectionMode.NONE)
            return false;
        if (event.key === Keys.ARROW_UP || event.key === Keys.ARROW_DOWN || event.key === Keys.ARROW_LEFT || event.key === Keys.ARROW_RIGHT) {
            if (this.selectedRows.length !== 1) {
                if (this.rows.length > 0) {
                    this.selectedRows = [this.rows[0]];
                    this.scrollToSelection();
                }
            }
            else {
                let index = this.rows.indexOf(this.selectedRows[0]);
                if (index < 0)
                    index = 0;
                if (event.key === Keys.ARROW_DOWN) {
                    if (index < this.rows.length - 1) {
                        this.selectedRows = [this.rows[index + 1]];
                        this.scrollToSelection();
                    }
                }
                else if (event.key === Keys.ARROW_UP) {
                    if (index > 0) {
                        this.selectedRows = [this.rows[index - 1]];
                        this.scrollToSelection();
                    }
                }
                else if (event.key === Keys.ARROW_RIGHT && this.selectedRows[0].expandable)
                    this.selectedRows[0].expanded = true;
                else if (event.key === Keys.ARROW_LEFT && this.selectedRows[0].expandable)
                    this.selectedRows[0].expanded = false;
            }
            event.preventDefault();
            return true;
        }
        return false;
    }

    handleIntersection(entries) {
        for (let i = 0; i < entries.length; i++) {
            const target = entries[i].target;
            if (entries[i].isIntersecting) {
                target.__row.populateDOMIfNeeded();
                this._observer.unobserve(target);
            }
        }
    }

    addColumn(column: TableColumn, isFirstColumn: boolean, isLastColumn: boolean) {
        column.index = this.columns.length;
        this.columns.push(column);
        //if the column's heading cell has already been created within the column, use that
        //otherwise, create the column's heading cell from the provided definition object
        let headingCell: HeadingTableCell = column.headingCell;
        if (headingCell == null) {
            if (column.headingDef instanceof HeadingTableCell)
                headingCell = column.headingDef;
            else
                headingCell = new HeadingTableCell({ ...column.headingDef });
        }
        if (StringUtil.isEmptyString(headingCell.id) || headingCell.id === "undefined")
            headingCell.id = "columnHeader" + column.index;
        this.headingRow.addCell(headingCell);
        if (isFirstColumn === true && (this._expanderHeaderSpacer == null || this.expanderAlignment !== Alignment.LEFT))
            headingCell.applyAutoFirstColumnPadding();
        else if (isLastColumn === true && (this._expanderHeaderSpacer == null || this.expanderAlignment !== Alignment.RIGHT))
            headingCell.applyAutoLastColumnPadding();
        if (this._expanderHeaderSpacer == null || this.expanderAlignment === Alignment.LEFT)
            this.headingRow._element.appendChild(headingCell._element);
        else
            this.headingRow._element.insertBefore(headingCell._element, this._expanderHeaderSpacer);
        column.headingCell = headingCell;
        if (this._designer != null && !this._readingFromJson) {
            this.rows[0].clear();
            this.rows[0].populateDOMIfNeeded();
            this._designer.selectComponent(headingCell);
        }
        if (column instanceof TableColumn) // even though this method accepts a TableColumn
            this.setupColumnSorting(column);
        this.matchDefaultColumnAlignment(column);
        return column;
    }

    private matchDefaultColumnAlignment(column: TableColumn) {
        const cellComps = column.cellDef?.def?.components;
        if (cellComps?.length === 1 && column.headingCell.align == null) {
            const comp = cellComps[0];
            if (comp.align == HorizontalAlignment.RIGHT)
                column.headingCell.align = HorizontalAlignment.RIGHT;
            else if (comp.field != null) {
                this.dataSource?.getMetadata().then(metadata => {
                    const field = metadata?.getFieldFromOutput(comp.field);
                    if (comp.align === HorizontalAlignment.RIGHT || isRightAlignedDisplayType(field?.displayType)) {
                        column.headingCell.align = HorizontalAlignment.RIGHT;
                    }
                });
            }
        }
    }

    private async setupColumnSorting(column: TableColumn) {
        let sortSelector: SortSelector;
        const fields: SortFieldInfo[] = await column.getSortFields();
        if (this._designer == null) {
            if (fields.length === 1) {
                sortSelector = new SortSelector(fields[0], { text: column.headingCell.caption });
                sortSelector.addClickListener((event: ClickEvent) => this.sortFromClick(event));
            }
            else if (fields.length > 1) {
                sortSelector = new SortSelector(null, { text: column.headingCell.caption });
                const sortPanel = this.createSortPanel(sortSelector, fields);
                sortSelector.addClickListener(() => {
                    Overlay.alignToAnchor(sortPanel, sortSelector);
                    this.updateSortDisplay();
                    this._columnSortOverlay = Overlay.showInOverlay(sortPanel);
                });
            }
            else if (StringUtil.isEmptyString(column.headingCell.caption) === false)
                sortSelector = new SortSelector(null, { text: column.headingCell.caption });
        }
        else if (StringUtil.isEmptyString(column.headingCell.caption) === false)
            sortSelector = new SortSelector(fields[0], { text: column.headingCell.caption });
        column.headingCell.forEveryTopLevelChildComponentReverse(component => {
            if (component instanceof SortSelector)
                column.headingCell.remove(component);
        });
        if (sortSelector != null)
            column.headingCell.insert(sortSelector, 0);
    }

    private createSortPanel(sortSelector: SortSelector, fields: SortFieldInfo[]): Panel {
        const sortPanel = new Panel({ id: "sortPanel", minWidth: 140 });
        for (const field of fields) {
            let fieldCaption = field.caption;
            if (fieldCaption == null) {
                const metadata = this.dataSource?.getMetadataFromCache();
                const outputField = metadata?.getFieldFromOutput(field.field);
                fieldCaption = outputField?.caption;
            }
            if (fieldCaption == null)
                fieldCaption = field.field;
            const fieldLabel = new SortSelector(field, { id: field.field, text: fieldCaption, allowSelect: false, fontBold: true, fontSize: "small", marginTop: 5 }, sortSelector);
            fieldLabel.addClickListener(event => {
                Overlay.hideOverlay(this._columnSortOverlay);
                this.sortFromClick(event, sortSelector);
            });
            sortPanel.add(fieldLabel);
        }
        return sortPanel;
    }

    public resetColumnSorting() {
        for (const column of this.columns) {
            this.setupColumnSorting(column);
        }
    }

    private forEverySortSelector(callback: (selector: SortSelector) => void) {
        for (const column of this.columns) {
            for (const headingComp of column.headingCell.components) {
                if (headingComp instanceof SortSelector)
                    callback(headingComp);
            }
        }
    }

    get displayToolsCell(): boolean {
        return this._displayToolsCell ?? this.getPropertyDefinitions().displayToolsCell.defaultValue;
    }

    set displayToolsCell(value: boolean) {
        this._displayToolsCell = value;
    }

    get toolsCellAlignment(): LeftOrRightAlignment {
        return this._toolsCellAlignment || Alignment.RIGHT;
    }

    set toolsCellAlignment(value: LeftOrRightAlignment) {
        this._toolsCellAlignment = value;
    }

    /**
     * TableRow notifies the Table of the width of its tools cell so that Table can adjust the spacer width so the
     * column headings line up with the column content.
     * @param width
     */
    toolsCellAdded(width: number): void {
        // two use cases this doesn't handle
        // when rows are removed, we don't reset this width.  I guess removing one or more rows should cause the Table to poll all its TableRows and figure out the max width
        // we don't allow for TableRows to have differing widths in their tool cells
        if (width > this.largestToolsCellWidth) {
            this.largestToolsCellWidth = width;
            this.recalculateSpecialRowPadding();
        }
    }

    private recalculateSpecialRowPadding() {
        const scrollbarWidth = DOMUtil.isScrollbarVisible(this._tbody) ? DOMUtil.getScrollbarWidth() : 0;
        if (this.quickAddRow != null)
            this.quickAddRow.paddingRight = scrollbarWidth;
        this.headingRow.paddingRight = this.largestToolsCellWidth + scrollbarWidth;
    }

    isFiltered(): boolean {
        return !StringUtil.isEmptyString(this._filterValue);
    }

    isSorted(): boolean {
        return ArrayUtil.isEmptyArray(this._sortInfo) !== true;
    }

    public applySort(fieldName: string, direction: SortDirection = "asc", addingToSort: boolean = false) {
        if (addingToSort === true) {
            if (this.fieldInCurrentSort(fieldName) === true) {
                const message = "Not adding field %s to sort for table %s; the field is already included in the " +
                    "existing sort";
                log.debug(this, message, fieldName, this.id);
                return;
            }
        }
        const selector = this.findSortSelectorsForField(fieldName)?.selector;
        if (selector == null) {
            log.debug(this, "Not adding field %s to sort for table %s; the field has no sort selector", fieldName, this.id);
            return;
        }
        this.sortByField(addingToSort, selector, null, direction);
    }

    private fieldInCurrentSort(fieldName: string) {
        if (this._sortInfo != null) {
            for (const fieldSortInfo of this._sortInfo) {
                if (fieldSortInfo.fieldName === fieldName) {
                    return true;
                }
            }
        }
        return false;
    }

    private addSort(newSort: FieldSortInfo) {
        if (this._sortInfo == null)
            this._sortInfo = [];
        this._sortInfo.push(newSort);
        this.updateSortOrders();
    }

    private removeSort(sortToRemove: FieldSortInfo) {
        sortToRemove.selector.update(null, null, false);
        if (this._sortInfo == null)
            return null;
        ArrayUtil.removeFromArray(this._sortInfo, sortToRemove);
        if (this._sortInfo.length == 0)
            this._sortInfo = null;
        this.updateSortOrders();
    }

    public clearSort() {
        if (this._sortInfo == null)
            return;
        for (let x = this._sortInfo.length - 1; x >= 0; x--) {
            const fieldSortInfo = this._sortInfo[x];
            this.removeSort(fieldSortInfo);
        }
    }

    private getFieldSortInfo(selector: SortSelector): FieldSortInfo {
        if (this._sortInfo == null)
            return null;
        for (const fieldSortInfo of this._sortInfo) {
            if (fieldSortInfo.selector === selector)
                return fieldSortInfo;
        }
        return null;
    }

    private updateSortOrders() {
        if (this._sortInfo == null)
            return;
        const displayOrder = this._sortInfo.length > 1;
        let count = 0;
        for (const fieldSortInfo of this._sortInfo) {
            fieldSortInfo.order = ++count;
            fieldSortInfo.displayOrder = displayOrder;
            fieldSortInfo.selector.update(fieldSortInfo.sort, fieldSortInfo.order, fieldSortInfo.displayOrder);
        }
    }

    private advanceSortToNextState(fieldSortInfo: FieldSortInfo, sortDescendingByDefault: boolean) {
        if (fieldSortInfo.sort === null)
            fieldSortInfo.sort = sortDescendingByDefault ? "desc" : "asc";
        else if (fieldSortInfo.sort === "asc")
            fieldSortInfo.sort = sortDescendingByDefault ? null : "desc";
        else if (fieldSortInfo.sort === "desc")
            fieldSortInfo.sort = sortDescendingByDefault ? "asc" : null;
    }

    private updateSortDisplay() {
        if (this.isSorted() !== true)
            return;
        for (const fieldSortInfo of this._sortInfo) {
            fieldSortInfo.selector.update(fieldSortInfo.sort, fieldSortInfo.order, fieldSortInfo.displayOrder);
        }
    }

    private sortFromClick(event: ClickEvent, parentSelector?: SortSelector) {
        const addingToSort = event.domEvent?.ctrlKey === true || event.domEvent?.altKey === true;
        const sortLabel = event.target as SortSelector;
        this.sortByField(addingToSort, sortLabel, parentSelector);
    }

    private sortByField(addingToSort: boolean, selector: SortSelector, parentSelector?: SortSelector, overrideSortDirection?: SortDirection) {
        this._clearDisplayedRows();

        //if clicking on a selector that isn't currently in the sort, we want to add it to the sort
        // -> if the user used ctrl/alt key, add to the existing sort
        // -> else, clear existing sort selectors before adding the new one (so that it's the only one left)
        //if clicking on a selector that is currently in the sort, we want to update or remove it
        // -> advance the selector to the next sort state
        // -> if the result is that the selector is 'unsorted', remove that field from the sort sequence and update remaining sort fields
        //finally, actually sort the table's filtered rows using the sort info that results from the above

        const sortFieldInfo = selector.sortFieldInfo;
        let fieldSortInfo = this.getFieldSortInfo(selector);
        if (fieldSortInfo == null) {
            if (addingToSort !== true)
                this.clearSort();
            fieldSortInfo = {
                fieldName: parentSelector?.fieldName != null ? parentSelector.fieldName : selector.fieldName,
                selector: selector,
                sort: null,
                order: 1,
                sortNullsAtEnd: sortFieldInfo.sortNullsAtEnd,
                displayOrder: this._sortInfo?.length > 0
            };
            if (overrideSortDirection == null)
                this.advanceSortToNextState(fieldSortInfo, sortFieldInfo.sortDescendingByDefault);
            else
                fieldSortInfo.sort = overrideSortDirection;
            this.addSort(fieldSortInfo);
        }
        else {
            fieldSortInfo.sortNullsAtEnd = sortFieldInfo.sortNullsAtEnd;
            if (overrideSortDirection == null)
                this.advanceSortToNextState(fieldSortInfo, sortFieldInfo.sortDescendingByDefault);
            else
                fieldSortInfo.sort = overrideSortDirection;
            if (fieldSortInfo.sort == null) {
                this.removeSort(fieldSortInfo);
            }
        }
        this.updateSortDisplay();
        for (const row of this._sortRows([...this.filteredRows]))
            this.displayRow(row);
        this._syncRowsDraggable();
        this.applyLastScrollLeft();
    }

    private _sortTableRows(a: TableRow, b: TableRow): number {
        log.debug(this, () => ["sorting  a: %o  b: %o  sortInfo: %o", a, b, this._sortInfo]);
        for (const fieldSortInfo of this._sortInfo) {
            const valueA = SortUtil.getSortTestValue(a.data, fieldSortInfo.fieldName);
            const valueB = SortUtil.getSortTestValue(b.data, fieldSortInfo.fieldName);
            const compareResult = SortUtil.compareTwoValues(valueA, valueB, fieldSortInfo.sort,
                fieldSortInfo.sortNullsAtEnd);
            if (compareResult !== 0)
                return compareResult;
        }
        return 0;
    }

    clearColumns() {
        this.columns = [];
        this.headingRow.clear();
        this.rebuildExpanderHeader();
        for (const row of this._allRows) {
            if (row.populatedDOM) {
                row.clear();
                row.addVirtualizedPlaceholder();
                this._observer.observe(row._element);
            }
        }
    }

    clear() {
        this.clearRows();
        this.clearColumns();
    }

    public override isEmpty(): boolean {
        return ArrayUtil.isEmptyArray(this._allRows);
    }

    getColumnIndex(identifier: number | TableColumn | string): number {
        if (typeof identifier === "number")
            return identifier;
        if (identifier instanceof TableColumn)
            return this.columns.indexOf(identifier);
        // If a string is provided, attempt to match it against the cell ID (from the layout json definition), or the
        // ID of the headingCell, or the headingCell's caption.
        for (let x = 0; x < this.columns.length; x++) {
            const column = this.columns[x];
            if (column?.cellDef?.def?.id === identifier ||
                column?.headingCell?.id === identifier ||
                column?.headingCell?.caption === identifier)
                return x;
        }
        return -1;
    }

    /**
     * Retrieves a column based on a variable input.
     *
     * @param columnName The column's name will be used to find the column.
     */
    getColumnByCaption(columnName: string) {
        const index = this.getColumnIndex(columnName);
        if(index < 0) {
            log.debug("Unable to find column index for " + columnName);
            return;
        }
        return this._columns[index];
    }

    /**
     * Set a column's visibility based on variable inputs.
     *
     * @param col The column that's visibility will be set.
     * @param isVisible Whether or not the column is visible.
     */
    setColumnVisibility(col: TableColumn, isVisible: boolean) {
        if(!col) {
            log.debug("Unable to set column visibility to NULL column.");
            return;
        }
        col.cellDef.cellProps.visible = isVisible;
        col.headingCell.visible = isVisible;
    }

    /**
     * Allows for the removal of a table column based on a variable input.
     *
     * @param identifier The column's index number, or the TableColumn object, or a string.  If a string is provided,
     * it will be used to find a column where the string value is either A) the cell ID (from the layout json
     * definition) or B) the ID of the column's headingCell or C) the caption of the column's headingCell.
     */
    removeColumn(identifier: number | TableColumn | string) {
        const index = this.getColumnIndex(identifier);
        if (index >= 0) {
            const column = this.columns[index];
            this.columns.splice(index, 1);
            this.headingRow.cells.splice(index, 1);
            this.headingRow._element.removeChild(column.headingCell._element);
            for (const row of this._allRows) {
                row.removeColumn(index);
            }
            const nsRows = this.getNonStandardRows();
            for (const row of nsRows) {
                row.removeColumn(index);
            }
            this.resetColumnIndexes();
            this.resetFirstLastColumnPadding(nsRows);
            return column;
        }
    }

    private resetColumnIndexes() {
        let count = -1;
        for (const column of this.columns) {
            column.index = ++count;
        }
    }

    indexOf(row: TableRow) {
        return this.rows.indexOf(row);
    }

    selectRow(row: TableRow, selectionType: SelectionType, event: DomEvent) {
        if (this.selectionMode == null || this.selectionMode === SelectionMode.NONE)
            return;
        if (window.getSelection().type === "Caret")
            window.getSelection().removeAllRanges(); // this keeps shift-clicking rows from selecting all the HTMLElements between the two points the user clicked
        if (row == null) {
            this._setSelectedRowsInternal([], event);
            return;
        }
        if (this.selectionMode === SelectionMode.SINGLE)
            selectionType = SelectionType.SINGLE;
        if (selectionType === SelectionType.SINGLE)
            this.handleSingleSelection(row, event);
        else if (selectionType === SelectionType.DISTINCT)
            this.handleDistinctSelection(row, event);
        else if (selectionType === SelectionType.RANGE)
            this.handleRangeSelection(row, event);
    }

    private handleSingleSelection(row: TableRow, event: DomEvent | ClickEvent) {
        const sel = this.selectedRows;
        if (sel?.length === 1 && sel[0] === row) {
            if (!(event instanceof ClickEvent) || event.hasModifiers({ ctrlKey: true })) {
                this._setSelectedRowsInternal([], event as DomEvent);
                this._lastSelectedRow = null;
            }
        }
        else {
            this._setSelectedRowsInternal([row], event as DomEvent);
            this._lastSelectedRow = row;
        }
    }

    private handleDistinctSelection(row: TableRow, event: DomEvent) {
        const newSel = this.selectedRows;
        const index = newSel.indexOf(row);
        if (index < 0) {
            newSel.push(row);
            this._lastSelectedRow = row;
        }
        else
            newSel.splice(index, 1);
        this._setSelectedRowsInternal([...newSel], event);
    }

    private handleRangeSelection(row: TableRow, event: DomEvent) {
        const sel = this.selectedRows;
        let newSel;
        if (sel.length === 0)
            newSel = [row];
        else {
            const thisSelIndex = this.rows.indexOf(row);
            const lastSelIndex = this.rows.indexOf(sel[sel.length - 1]);
            const minSelIndex = Math.min(thisSelIndex, lastSelIndex);
            const maxSelIndex = Math.max(thisSelIndex, lastSelIndex);
            newSel = [];
            for (let index = minSelIndex; index <= maxSelIndex; index++)
                newSel.push(this.rows[index]);
        }
        this._setSelectedRowsInternal(newSel, event);
    }

    /**
     * Removes a row from the table.  Note that this method DOES NOT delete the record from the DataSource/database
     * (when the table is bound in such a fashion).
     *
     * @param row The parameter can specify either of the following:
     * - The index of the row to be removed.  Note that this is the index of the row within the displayed data (which
     * may be filtered and/or sorted, and thus not include all of the rows that the table contains).  It IS NOT possible
     * to provide the parameter as the index number to remove a row that is not currently displayed.
     * - The ModelRow data that the row to be removed contains/matches.  The matching TableRow will be found (one that
     * either contains the ModelRow as its data, or one that contains a ModelRow with the same key data), and that
     * TaleRow will be removed.  It IS possible to provide the parameter as the  ModelRow data to remove a row that is
     * not currently displayed.
     */
    removeRow(row: number | ModelRow) {
        const rows = this.filteredRows;
        const rowIndex = typeof row === "number" ? row : this.getIndexFromData(row, rows);

        // If the row being removed is currently selected, then we need to clear that selection.  Do so by getting the
        // index of the entry in the selectedIndexes array, and holding on to that so we can clear the selection after
        // the row is removed.
        const rowBeingRemoved = rows[rowIndex];
        let indexSelectedIndex = -1;
        for (let x=0 ; x<this.selectedRows.length ; x++) {
            if (this.selectedRows[x] === rowBeingRemoved) {
                indexSelectedIndex = x;
                break;
            }
        }
        this.internalRemoveRow(rowIndex, this.filteredRows);

        // If the table is filtered, we also need to remove the row from the allRows array
        if (rows !== this._allRows) {
            let allRowsIndex = -1;
            if (rowBeingRemoved != null) {
                // If the row being removed was also being displayed, find its allRows index using the row object.
                // This is just a little better than the subsequent else if, which does a deeper comparison.
                allRowsIndex = this._allRows.indexOf(rowBeingRemoved);
            }
            else if (typeof row !== "number")
                allRowsIndex = this.getIndexFromData(row, this.allRows);
            this.internalRemoveRow(allRowsIndex, this._allRows);
        }
        this.dataSource?.notifyHasChangedComponents();

        // Now update the selected indexes by removing the entry in the selectedIndexes array that we found above.
        // Also, where necessary, decrement each value in the selectedIndexes array so that additional row selections
        // remain intact.  Or, if no rows exist in the table, or if none are selected, assign an empty array to
        // selectedIndexes, just to ensure that the lack of row selection is applied.
        let updatedSelectedIndexes: number[];
        if (rows.length === 0 || this.hasSelection !== true)
            updatedSelectedIndexes = [];
        else {
            updatedSelectedIndexes = [ ...this.selectedIndexes ];
            if (indexSelectedIndex >= 0)
                updatedSelectedIndexes.splice(indexSelectedIndex, 1);
            for (let x=0 ; x < updatedSelectedIndexes.length ; x++) {
                const sel = updatedSelectedIndexes[x];
                if (rowIndex < sel || sel >= rows.length)
                    updatedSelectedIndexes[x] = sel - 1;
            }
        }
        this.selectedIndexes = updatedSelectedIndexes;
    }

    /**
     * Within the provided TableRow array, find the first row whose data matches the provided ModelRow.  The match could
     * be based on the same ModelRow object being found in one of the TableRows, or one of the TableRows containing a
     * ModelRow with the same key data.
     *
     * @param tableRowData The ModelRow to use when searching.
     * @param rows The array of TableRows to search.
     * @returns The index of the matching row in the provided array.
     */
    getIndexFromData(tableRowData: ModelRow, rows: TableRow[]) {
        const keyData = tableRowData.getKeyData();
        for (let i = 0; i < rows.length; i++) {
            const rowInArray = rows[i];
            if (tableRowData === rowInArray?.data) {
                log.debug("Found same row data in TableRow array: %o", rowInArray);
                return i;
            }
            if (rowInArray.data instanceof ModelRow && ObjectUtil.deepEqual(keyData, rowInArray.data.getKeyData())) {
                log.debug("Found row in TableRow array with matching key data.  Row: %o  Key Data: %o", rowInArray,
                    keyData);
                return i;
            }
        }
        return -1;
    }

    private internalRemoveRow(rowIndex: number, rows: TableRow[]) {
        if (rowIndex >= 0) {
            const rowBeingRemoved = rows[rowIndex];
            rows.splice(rowIndex, 1);
            this.hideDisplayedRow(rowBeingRemoved);
            this._handleEmptyComponent();
            this.resetIndexAndSequence(rows);
        }
        else
            log.debug("Not removing row from array; provided index < 0");
    }

    addRow(data, rowProps?: Partial<TableRowProps>, options?: Partial<TableAddRowOptions>): TableAddRowResult {
        const row = new TableRow(this);
        row.setDesigner(this.getDesigner());
        row.owner = this.owner;
        const props: Partial<TableRowProps> = {
            ...this._rowProps,
            data: data,
            expanded: data.expanded,
            virtualized: this.virtualized,
            placeholderHeight: this._fixedRowHeight,
            ...rowProps
        };
        if (this.rowModeControlType === RowModeControlType.ALWAYS_EDIT) {
            if (props.mode == null || props.mode === TableRowMode.NONE)
                props.mode = TableRowMode.UPDATE;
        }
        row.setProps(props);
        this._allRows.push(row);
        row.index = this._allRows.length - 1;
        this.applyRowProps(row);

        if (options?.addToData === true) {
            if (this._sequenceField != null && data instanceof ModelRow)
                data.set(this._sequenceField, this._data.length + 1, this);
            if (this.dataSource != null)
                this.dataSource.addRow(data, null, false);
            else
                this.data.push(data);
            this.dataSource?.notifyHasChangedComponents(true);
            this.fireContentsChangedListener(new TableContentsChangedEvent(this, TableAction.ADD));
        }
        if (options?.display === true) {
            if (this.rowPassesFilter(row) === true) {
                const insertPos = this._getRowInsertPos(row);
                this.displayRow(row, insertPos);
                this._filteredRows?.splice(insertPos, 0, row);
                this._setRowCountLabel();
            }
        }
        this._syncRowsDraggable();
        let saveSuccessful: boolean;
        const shouldSave = options?.addToData === true && (options?.save === true || (options?.save == null && this.persistChangesImmediately === true));
        if (shouldSave === true)
            saveSuccessful = row.saveChanges();
        return { row: row, saveSuccessful: saveSuccessful };
    }

    handleDataDisplay(event: FieldUpdateEvent, data: ModelRow, allData: ModelRow[], rowIndex: number) {
        const tableRow = this.findTableRow(event.row);
        tableRow?.displayDataForField(event, event.row, allData, rowIndex);
    }

    public findTableRow(row: ModelRow): TableRow {
        for (const tableRow of this.rows) {
            if (tableRow.data === row)
                return tableRow;
        }
        return null;
    }

    private _getRowInsertPos(rowToInsert: TableRow): number {
        const rows = [...this.filteredRows, rowToInsert];
        this._sortRows(rows);
        return rows.indexOf(rowToInsert);
    }

    private _sortRows(rows: TableRow[]): TableRow[] {
        if (rows != null && this.isSorted()) {
            rows.sort((a, b) => this._sortTableRows(a, b));
        }
        return rows;
    }

    displayRow(row: TableRow, insertPos: number = -1) {
        let insertedIndex = insertPos;
        if (insertPos === -1) {
            this.rows.push(row);
            this._tbody.appendChild(row._element);
            insertedIndex = this.rows.length - 1;
        }
        else {
            const existingRowAtInsertPos = this.rows[insertPos];
            this.rows.splice(insertPos, 0, row);
            //if the row that is above where the new row will be inserted is expanded, make sure we insert the new row below the expansion
            if (existingRowAtInsertPos != null)
                this._tbody.insertBefore(row._element, existingRowAtInsertPos._element);
            else
                this._tbody.appendChild(row._element);
        }

        this.handleEvenOddRowColors(insertedIndex, row);

        const heightUnset = this._fixedRowHeight == null || this.rows.length < this.preRenderRowCount || row.mode == TableRowMode.ADD;
        // Checking offsetParent can take time...be sure to only call it if the height is unset.
        if (heightUnset && row._element.offsetParent != null) {
            row.populateDOMIfNeeded();
            const height = row._element.clientHeight;
            if (this._fixedRowHeight == null && height > 0) {
                this._fixedRowHeight = height;
                for (const row of this._allRows)
                    row.placeholderHeight = height;
            }
        }
        if (this.virtualized)
            this._observer.observe(row._element);
        else
            row.populateDOMIfNeeded();
        this._handleEmptyComponent();
        return row;
    }

    public get evenRowColor(): Color {
        if (this._designer != null)
            return undefined;
        return this._evenRowColor ?? UserSettings.get().table_even_row_color;
    }

    public set evenRowColor(value: Color) {
        this._evenRowColor = value;
    }

    public get oddRowColor(): Color {
        if (this._designer != null)
            return undefined;
        return this._oddRowColor ?? UserSettings.get().table_odd_row_color;
    }

    public set oddRowColor(value: Color) {
        this._oddRowColor = value;
    }

    public get ignoreRowColorSetting(): boolean {
        return this._ignoreRowColorSetting;
    }

    public set ignoreRowColorSetting(value: boolean) {
        this._ignoreRowColorSetting = value;
    }

    private handleEvenOddRowColors(index: number, row: TableRow) {
        if (this._designer != null)
            return;
        //index for determining if row is 'even' or 'odd' is not the row's index, but where it is visually on the page
        //example: the first row in the table (index = 0) is treated as an odd row, since it's the first visible row
        //accomplish this by adding 1 to the index
        if (!this.ignoreRowColorSetting) {
            const colorToUse = ((++index % 2) === 0) ? this.evenRowColor : this.oddRowColor;
            this.applyRowBackgroundColor(colorToUse, row);
        }
    }

    private applyRowBackgroundColor(color: Color, row: TableRow) {
        row.initDefaultState();
        if (color != null)
            row.backgroundColor = color;
        else
            row.backgroundColor = (row.defaultState.originalProps as Partial<TableRowProps>).backgroundColor;
    }

    async setRowsExpanded(expand: boolean, rowFilter?: (row: TableRow) => boolean, doAfterRowExpansion?: () => void) {
        const tableRows = this.rows?.filter(row => row.expanded !== expand && (!rowFilter || rowFilter(row)));
        if (tableRows.length === 0)
            return;
        const expandPromise = (this._expandRowsPromise || Promise.resolve()).then(async () => {
            try {
                for (const row of tableRows) {
                    await row.setExpandedAsync(expand);
                }
                if (doAfterRowExpansion != null) {
                    doAfterRowExpansion();
                } else if (expand) {
                    ArrayUtil.getLastElement(tableRows)?.scrollIntoView();
                }
                return true;
            } finally {
                this._expandRowsPromise = null;
            }
        });
        this._expandRowsPromise = expandPromise;
        return expandPromise;
    }

    async _createExpansionComponent(row: TableRow): Promise<Component> {
        let component: Component = null;
        const componentSource = row["expandComponent"] || this.expandComponent; //I think row.expandComponent is only used in PropertiesTable, hopefully it can be removed someday
        if (typeof componentSource === "function") {
            component = componentSource(this, row, componentSource, row.expanded);
            await component.ensureLoaded();
        } else if (componentSource instanceof Component) {
            component = CloneComponent.clone({ component: componentSource, id: this.getExpandComponentId(componentSource.id, row.index), appendComponentId: this._designer == null });
            await component.ensureLoaded();
            if (component instanceof Container) {
                component.setPropOnChildren("boundRow", row.data);
            }
        } else {
            component = await new ComponentDeserializer({
                owner: null,
                def: componentSource.def,
                designer: this._designer,
                defaultPropValues: {boundRow: row.data}
            }).deserializeSingleComponent();
        }
        this.fireListeners(_rowExpandListenerDef, new TableRowExpansionEvent(row, component, this, row.expanded));
        this.createExpansionRowElement(row, component);
        this.insertExpansionRowElement(row)
        if (this.data != null) {
            const rowData = row.data;
            component.displayData(rowData, this.data, row.index);
        }
        if (this._expandRowsPromise == null)
            row.scrollIntoView();
        return component;
    }

    private getExpandComponentId(currentId?: string, rowIndex?: number): string {
        let result = currentId;
        if (StringUtil.isEmptyString(currentId) === true)
            result = this.id + "-expand";
        if (rowIndex != null)
            result += "-" + rowIndex;
        return result;
    }

    private createExpansionRowElement(row: TableRow, expansionComponent: Component) {
        const expansionRow = document.createElement("tr");
        expansionRow.style.display = "block";
        expansionRow.style.alignItems = "flex-start";
        const expansionCell = document.createElement("td");
        expansionCell.style.padding = "0px";
        expansionRow.style.borderBottom = "3px solid " + getThemeColor("primary");
        expansionRow.appendChild(expansionCell);
        expansionCell.appendChild(expansionComponent._element);
        expansionCell.style.display = "block";
        row._setExpansionElement(expansionRow);
    }

    private insertExpansionRowElement(row: TableRow) {
        row.setBorderBottom(true);
        if (row._element.nextSibling == null)
            this._tbody.appendChild(row.getExpansionElement());
        else
            this._tbody.insertBefore(row.getExpansionElement(), row._element.nextSibling);
    }

    _removeExpansionRowElement(row: TableRow, fireEvent: boolean = true) {
        const expansionElement = row.getExpansionElement();
        if (expansionElement == null || this._tbody.contains(expansionElement) === false)
            return;
        row.setBorderBottom(false);
        this._tbody.removeChild(expansionElement);
        row._setExpansionElement(null);
        row._setExpansionComponent(null);
        if (fireEvent)
            this.fireListeners(_rowCollapseListenerDef, new TableRowExpansionEvent(row, this.expandComponent, this, row.expanded));
    }

    _serializeProp(key: string, value: string): string {
        //control serialization of even/odd row colors here..this allows normal getters to not render
        //row colors in the designer (so a user's default row colors won't be serialized into the layout)
        if ("evenRowColor" === key)
            return this._evenRowColor;
        if ("oddRowColor" === key)
            return this._oddRowColor;
        return value;
    }

    _serializeNonProps(dataSources) {
        let result = "";
        result += this.serializeHeadingRow(dataSources);
        result += this.serializeRowDef();
        result += this.serializeExpandComponent(dataSources);
        result += this.serializeTools();
        result = StringUtil.removeTrailingString(result, ",\n");
        result += "\n}\n";
        return result;
    }

    private serializeHeadingRow(dataSources): string {
        let result = "";
        if (this.headingRow != null && this.headingRow.cells.length > 0) {
            result += "\"columns\": [\n";
            for (let i = 0; i < this.headingRow.cells.length; i++) {
                result += "{\n";
                result += "\"heading\":\n" + serializeComponents(this.headingRow.cells[i], dataSources);
                result += ",\n";
                result += "\"cell\": " + serializeComponents(this.rows[0].cells[i], dataSources) + "\n";
                result += "}";
                if (i < this.columns.length - 1)
                    result += ",";
                result += "\n";
            }
            result += "],\n";
        }
        return result;
    }

    private serializeRowDef(): string {
        let result = "";
        if (this._allRows.length > 0) {
            const firstRow = this._allRows[0];
            if (firstRow != null) {
                const serialized = serializeProps(firstRow);
                if (serialized.length > 0) {
                    result += "\"rowProps\": {\n";
                    result += serialized;
                    result = StringUtil.removeTrailingString(result, ",\n");
                    result += "\n},\n";
                }
            }
        }
        return result;
    }

    private serializeExpandComponent(dataSources): string {
        let result = "";
        if (this.expandComponent instanceof Container && !this.expandComponent.isEmpty()) {
            let def = serializeComponents(this.expandComponent, dataSources);
            const parsed = JSON.parse(def);
            if (parsed.backgroundColor === defaultExpandBackground)
                delete parsed.backgroundColor;
            if (parsed.borderColor === defaultExpandColor) {
                delete parsed.borderWidth;
                delete parsed.borderColor;
            }
            def = JSON.stringify(parsed);
            result += "\"expandComponent\": " + def + ",\n";
        }
        return result;
    }

    private serializeTools(): string {
        let result = "";
        result += this.serializeToolsPanel(this.toolsPanel.leftTools.components, "leftTools");
        result += this.serializeToolsPanel(this.toolsPanel.rightTools.components, "rightTools");
        result += this.serializeToolsPanel(this._additionalRowToolsPanel.components, "additionalRowTools");
        return StringUtil.removeTrailingString(result, ",\n");
    }

    private serializeToolsPanel(components: Component[], name: string): string {
        let result = "";
        if (components?.length > 0)
            result = "\"" + name + "\": " + serializeComponents(components, null) + ",\n";
        return result;
    }

    public get rowEditResolveMode(): TableRowEditResolveMode {
        return this._rowEditResolveMode == null ? TableRowEditResolveMode.DIRECT_FROM_EDITOR : this._rowEditResolveMode;
    }

    public set rowEditResolveMode(value: TableRowEditResolveMode) {
        this._rowEditResolveMode = value;
    }

    override async _deserializeSpecialProps(props: DeserializeProps): Promise<string[]> {
        const compSpecial = await super._deserializeSpecialProps(props);
        const compDef = props.def;
        if (compDef.rowProps != null)
            this.rowProps = { ...compDef.rowProps };
        await this.deserializeTools(true, {...props, def: compDef.leftTools});
        await this.deserializeTools(false, {...props, def: compDef.rightTools});

        this.additionalRowToolsDef = compDef.additionalRowTools;

        if (compDef.columns != null) {
            const value = compDef.columns;
            this._columns = [];
            this._readingFromJson = true;
            for (let i = 0; i < value.length; i++) {
                const col = new TableColumn();
                if (typeof value[i].heading !== "string") {
                    const headingProps = { ...props.defaultPropValues, table: this, row: this.headingRow };
                    // We provide the custom componentCreationCallback below only so we can call the TableHeadingCell
                    // constructor when deserializing the heading cell.  The more straightforward way to do this would
                    // have been to change all existing layouts to include the 'headingcell' serialization name, but I
                    // wanted to avoid creating that many conflicts in the early-adopter era.  Maybe someday we make
                    // that change, at which time we would no longer need the componentCreationCallback here.
                    const deserializeProps: DeserializeProps = {
                        ...props,
                        def: value[i].heading,
                        defaultPropValues: headingProps,
                        componentCreationCallback: (type: string, props: any) => {
                            if ("cell" !== type) {
                                // We shouldn't end up here because HeaderTableCell def.components should not be defined
                                // and HeadingTableCell overrides _deserializeSpecialProps
                                return null;
                            }
                            return ComponentTypes.createComponentOfType( "headingcell",props)
                        }
                    }
                    col.headingDef = await new ComponentDeserializer(deserializeProps).deserializeSingleComponent();
                }
                else
                    col.headingDef = value[i].heading;
                if (typeof value[i].cell !== "string") {
                    const cellProps = { ...props.defaultPropValues, table: this };
                    col.cellDef = { def: value[i].cell, owner: props.owner, cellProps: cellProps, dataSources: props.dataSources };
                }
                else
                    col.cell = value[i].cell;
                this.addColumn(col, i === 0, i === value.length - 1);
            }
            if (this._designer != null) {
                await this.rows[0].populateDOM();
            }
            this._readingFromJson = false;
        }
        if (compDef.expandComponent != null)
            this.expandComponent = { def: compDef.expandComponent, dataSources: props.dataSources };

        if (this._designer == null) {
            this._heading?.addMountListener(() => {
                const layout = this.getRootLayout();
                if (this == layout?.getFirstFocasableChild())
                    this.focusSearchFilter();
            })
        }

        return [...compSpecial, "columns", "expandComponent", "additionalRowTools"];
    }

    private async deserializeTools(isLeft: boolean, props: DeserializeProps) {
        const tools = await new ComponentDeserializer({...props}).deserialize();
        if (tools != null)
            for (const component of tools)
                this.addTool(component, isLeft);
    }

    doAfterDeserialize() {
        super.doAfterDeserialize();
        log.debug("Syncing add actions after deserialization");
        this.syncAddActions();
    }

    rebuildExpanderHeader() {
        if (this.expandComponent != null && this._designer == null && (this._expanderHeaderSpacer == null || !this.headingRow._element.contains(this._expanderHeaderSpacer))) {
            this.createExpanderHeaderSpacer();
            this.headingRow._element.appendChild(this._expanderHeaderSpacer);
        }
    }

    createExpanderHeaderSpacer() {
        if (this._expanderHeaderSpacer != null)
            return;
        const spacer = document.createElement("td");
        spacer.id = "expanderHeader";
        spacer.style.width = "40px";
        spacer.style.position = "sticky";
        spacer.style.right = "0px";
        spacer.style.backgroundColor = "inherit";
        this._expanderHeaderSpacer = spacer;
    }

    addHeading() {
        this._heading = new Panel({ marginBottom: 8, verticalAlign: VerticalAlignment.CENTER });
        this._createSearch();
        this._createFilter();
        this._rowCountLabel = new Label({ text: "No Results", marginLeft: 8, fontSize: 16, color: "#9E9E9E", rowBreak: false, minWidth: 125 });
        this._heading.add(this._rowCountLabel);
        this._heading.add(this.toolsPanel);
        this._heading.parent = this as any; // "this" isn't a Container, so the "as any" is necessary.  Right now, just trying to get a parent hierarchy for the toolsPanel
        this._element.appendChild(this._heading._element);
        this.searchFilterVisible = SearchFilterVisible.FILTER_ONLY;
    }

    private _createSearch() {
        this._search = new Textbox({
            placeholder: "Search",
            fontSize: 16,
            captionVisible: false,
            rowBreak: false,
            variant: TextboxVariant.UNDERLINED
        });
        this._search.imagePre = new Image({ name: "magnifyingGlass", color: "#9E9E9E", paddingLeft: 4, height: 28, width: 28 });

        this._filterCTA = new Image({ name: "funnel", color: "primary", height: 28, width: 28 });
        this._filterCTA.addClickListener(event => this._toggleFilterVisible());
        this._search.addKeyUpListener(event => this._invokeDbSearch(event));
    }

    private _createFilter() {
        this._filter = new Textbox({
            placeholder: "Filter",
            fontSize: 16,
            captionVisible: false,
            rowBreak: false,
            variant: TextboxVariant.NO_LINES,
            marginBottom: 0,
            clearButtonVisible: this._filterClearButtonVisible
        });
        this._filter.imagePre = new Image({ name: "funnel", color: "#9E9E9E", paddingLeft: 4, height: 28, width: 28 });
        this._filter.addChangeListener(event => this._handleFilterInput(event));
    }

    getFilterImage(): Image {
        return this._filter?.imagePre;
    }

    public get filterClearButtonVisible(): ClearButtonVisible {
        return this._filterClearButtonVisible;
    }

    public set filterClearButtonVisible(value: ClearButtonVisible) {
        this._filterClearButtonVisible = value;
        if (this._filter != null)
            this._filter.clearButtonVisible = value;
    }

    private _handleFilterInput(event: ChangeEvent) {
        this.filterValue = event.newValue;
    }

    private _invokeDbSearch(event: KeyEvent) {
        if (event.domEvent != null && event.key === Keys.ENTER && this._search.text?.length > 0) {
            if (this._dbSearchTimeoutHandle != null) {
                window.clearTimeout(this._dbSearchTimeoutHandle);
            }
            this._dbSearchTimeoutHandle = window.setTimeout(() => this._searchFromServer(this._search.text), 300);
        }
    }

    private _searchFromServer(searchText: string) {
        this._filterValue = null;
        const dataSource = this.dataSource;
        if (dataSource == null) {
            return;
        }

        if (searchText.length > 0) {
            dataSource.search({ quick_search: searchText }).then(response => { return; });
        }
        else if (this.dataSource.data.length > 0) {
            dataSource.clear();
            dataSource.displayDataInBoundComponents();
            this._setRowCountLabel();
        }
    }

    private _doAfterDbSearch() {
        if (this._allRows.length > 0) {
            this._search.imagePost = this._filterCTA;
            if (this.searchFilterVisible === SearchFilterVisible.BOTH)
                this._search.imagePost.color = "#9E9E9E";
            else
                this._search.imagePost.color = "primary";
        }
        else {
            this._search.imagePost = null;
            this.searchFilterVisible = SearchFilterVisible.SEARCH_ONLY;
        }
        this._rowCountLabel.visible = true;
    }

    get searchFilterVisible(): SearchFilterVisible {
        return this._searchFilterVisible;
    }

    set searchFilterVisible(value: SearchFilterVisible) {
        if (this._searchFilterVisible === value) {
            return;
        }
        this._searchFilterVisible = value;
        this._syncSearchFilter();
    }

    private _toggleFilterVisible() {
        if (this.searchFilterVisible === SearchFilterVisible.BOTH) {
            this._search.imagePost.color = "primary";
            this.searchFilterVisible = SearchFilterVisible.SEARCH_ONLY;
        }
        else {
            this._search.imagePost.color = "#9E9E9E";
            this.searchFilterVisible = SearchFilterVisible.BOTH;
        }
    }

    addTool(tool: ComponentCreator, addToLeftSection: boolean = true) {
        this.toolsPanel.addTool(tool, addToLeftSection);
    }

    removeTool(tool: Component) {
        this.toolsPanel.removeTool(tool);
    }

    get filterValue(): string {
        return this._filterValue;
    }

    set filterValue(value: string) {
        this._filterValue = value != null ? value.trim() : value;
        if (this._filterTimeoutHandle != null)
            window.clearTimeout(this._filterTimeoutHandle);
        this._filterTimeoutHandle = window.setTimeout(() => {
            this.applyFilters();
        }, 300);
    }

    public setFilter(value: string) {
        this._filter.text = value;
    }

    resetFilter() {
        this.filterValue = null;
        this._filter.text = "";
        this._setRowCountLabel();
        if (this.isSorted())
            this._allRows.sort((a, b) => this._sortTableRows(a, b));
        for (const row of this._allRows)
            this.displayRow(row);
        this.applyLastScrollLeft();
    }

    get _designer(): DesignerInterface {
        return super._designer;
    }

    set _designer(value: DesignerInterface) {
        super._designer = value;
        this.headingRow._designer = value;
        this.toolsPanel.leftTools._designer = value;
        this.toolsPanel.rightTools._designer = value;
        this._search._designer = value;
        this._filter._designer = value;
        this._filterCTA._designer = value;
        if (value != null) {
            value.addDesignerContainerProperties(this, 100, 200, null, false);
            if (value.allowsDrop) {
                this._buttonAddColumn = new Button({
                    imageName: "add",
                    color: "primary",
                    variant: ButtonVariant.round,
                    fillHeight: true,
                    height: "unset",
                    tooltip: "Add a new column to this table",
                    margin: 0,
                    padding: 3,
                });
                this._buttonAddColumn.addDragOverListener((event: Event) => event.preventDefault());
                this._buttonAddColumn.addDropListener((event: Event) => this._designerDropAddColumn());
                this._buttonAddColumn.addClickListener((event: Event) => this.addDesignerColumn());
                this._thead.appendChild(this._buttonAddColumn._element);
            }
            else {
                const buttonElement = this._buttonAddColumn?._element;
                if (buttonElement != null && this._thead.contains(buttonElement) === true)
                    this._thead.removeChild(buttonElement);
                this._buttonAddColumn = null;
            }
            this.recalculateSpecialRowPadding();
            this._addDesignerRow(this._designer);
            this.syncDesignerExpandArea(null);
            this.syncDesignerRowAdditionalToolsPanel();
        }
    }

    addDesignerColumn() {
        return this._designer.addTableColumn(this);
    }

    _designerDropAddColumn() {
        const col = this.addDesignerColumn();
        const colCell = this.rows[0].cells[col.index];
        this._designer.componentDropped(colCell);
    }

    protected override _initialDropInDesigner(): void {
        this.addDesignerColumn();
    }

    focus() {

    }

    focusSearch() {
        this._search.focus();
    }

    focusSearchFilter() {
        if (this._heading.components.includes(this._search))
            this._search.focus();
        else if (this._heading.components.includes(this._filter))
            this._filter.focus();
    }

    getSpecialDesignerDropTarget(dropTarget: DropTargetPanel) {
        if (this._panelDesignerExpansionDropTarget != null && dropTarget._element === this._panelDesignerExpansionDropTarget._element && this.expandComponent == null) {
            this._designer.modified();
            const exp = new Panel({ _designer: this._designer, id: this.getExpandComponentId(), backgroundColor: defaultExpandBackground, borderWidth: 1, borderColor: defaultExpandColor, fillRow: true });
            this._element.replaceChild(exp._element, this._panelDesignerExpansionDropTarget._element);
            this.expandComponent = exp;
            return this.expandComponent;
        }
    }

    _handleSpecialSwitch(component, by): boolean {
        if (component instanceof TableCell) {
            for (let i = 0; i < this.columns.length; i++) {
                if (component === this.columns[i].headingCell) {
                    const switchIndex = i + by;
                    if (switchIndex >= 0 && switchIndex <= this.columns.length) {
                        this.switchColumns(i, switchIndex);
                        return true;
                    }
                }
            }
        }
        return false;
    }

    addNonStandardRow(row: TableRow) {
        if (this._nonStandardRows == null)
            this._nonStandardRows = [];
        ArrayUtil.addNoDuplicates(this._nonStandardRows, row);
    }

    private getNonStandardRows(): TableRow[] {
        const result = (this._nonStandardRows != null) ? [...this._nonStandardRows] : [];
        if (this.quickAddRow != null)
            result.push(this.quickAddRow);
        return result;
    }

    moveColumn(oldIndex: number, newIndex: number) {
        if (oldIndex === newIndex || oldIndex == null || newIndex == null)
            return;
        ArrayUtil.moveArrayElement(this.columns, oldIndex, newIndex);
        ArrayUtil.moveArrayElement(this.headingRow.cells, oldIndex, newIndex);
        if (newIndex < oldIndex)
            DOMUtil.moveDOMElementBefore(this.headingRow._element.childNodes[oldIndex], this.headingRow._element.childNodes[newIndex]);
        else
            DOMUtil.moveDOMElementAfter(this.headingRow._element.childNodes[oldIndex], this.headingRow._element.childNodes[newIndex]);
        for (const row of this._allRows) {
            row.moveColumn(oldIndex, newIndex);
        }
        const nsRows = this.getNonStandardRows();
        for (const row of nsRows) {
            row.moveColumn(oldIndex, newIndex);
        }
        this.resetColumnIndexes();
        this.resetFirstLastColumnPadding(nsRows);
    }

    switchColumns(index1: number, index2: number) {
        if (index1 === index2 || index1 == null || index2 == null)
            return;
        ArrayUtil.switchArrayElements(this.columns, index1, index2);
        ArrayUtil.switchArrayElements(this.headingRow.cells, index1, index2);
        DOMUtil.switchDOMElements(this.headingRow._element.childNodes[index1], this.headingRow._element.childNodes[index2]);
        for (const row of this._allRows) {
            row.switchColumns(index1, index2);
        }
        const nsRows = this.getNonStandardRows();
        for (const row of nsRows) {
            row.switchColumns(index1, index2);
        }
        this.resetColumnIndexes();
        this.resetFirstLastColumnPadding(nsRows);
    }

    /**
     * Resets default padding for all cells in all rows.  This is used when columns have been moved/swapped or removed.
     *
     * @param nonStandardRows Specifies non-standard table rows that also need adjusting.  This is typically only
     *                        provided when the rows have already been determined (to avoid duplicate work).
     */
    private resetFirstLastColumnPadding(nonStandardRows: TableRow[] = this.getNonStandardRows()) {
        this.headingRow.resetFirstLastColumnPadding();
        for (const row of this.allRows) {
            row.resetFirstLastColumnPadding();
        }
        for (const row of nonStandardRows) {
            row.resetFirstLastColumnPadding();
        }
    }

    public findTableRowFromAdditionalTool(button: Button) {
        return this.rows.find(row => row.additionalToolButtons?.includes(button));
    }

    async deserializeAdditionalRowTools(): Promise<Button[]> {
        if (this.additionalRowToolsDef == null)
            return [];

        const deserializeProps: DeserializeProps = { owner: this.owner, def: this.additionalRowToolsDef, designer: this._designer};
        const comps = await new ComponentDeserializer(deserializeProps).deserialize();
        const result = comps.filter(comp => comp instanceof Button) as Button[];
        this.syncDesignerRowAdditionalToolsPanel(result);
        return result;
    }

    async syncDesignerRowAdditionalToolsPanel(buttons?: Button[]) {
        if (this._designer == null)
            return;
        if (this._additionalRowToolsPanel == null) {
            this._additionalRowToolsPanel = new AdditionalTableRowToolsDesignerPanel(this);
            this._tbody.appendChild(this._additionalRowToolsPanel.createWrapperPanel()._element);
        }

        if (buttons != null && this._additionalRowToolsPanel.isEmpty())
            this._additionalRowToolsPanel.components = buttons;
    }

    async syncDesignerExpandArea(oldValue) {
        if (this._designer == null)
            return;
        if (this.expandComponent == null && this._panelDesignerExpansionDropTarget == null) {
            if (this._designer.allowsDrop) {
                this._panelDesignerExpansionDropTarget = new DropTargetPanel({
                    enclosingComponent: this,
                    _designer: this._designer,
                    backgroundColor: defaultExpandBackground,
                    borderWidth: 1,
                    borderColor: defaultExpandColor,
                    align: HorizontalAlignment.CENTER
                });
                this._panelDesignerExpansionDropTarget.add(new Label({ text: "Drag a component here to start designing the expansion area of this Table's rows", color: "subtle.darker" }));
                if (oldValue != null && this._element.contains(oldValue._element))
                    this._element.removeChild(oldValue._element);
                this._element.appendChild(this._panelDesignerExpansionDropTarget._element);
            }
        }
        else {
            if (this._panelDesignerExpansionDropTarget != null) {
                if (this._element.contains(this._panelDesignerExpansionDropTarget._element))
                    this._element.removeChild(this._panelDesignerExpansionDropTarget._element);
                this._panelDesignerExpansionDropTarget = null;
            }
            if (!(this.expandComponent instanceof Component)) {
                const deserializeProps: DeserializeProps = {owner: this, def: this.expandComponent.def, designer: this._designer,dataSources: this.expandComponent.dataSources};
                this.expandComponent = await new ComponentDeserializer(deserializeProps).deserializeSingleComponent();
                if (this.expandComponent.backgroundColor === undefined)
                    this.expandComponent.backgroundColor = defaultExpandBackground;
            }
            this._element.appendChild(this.expandComponent._element);
        }
    }

    _addDesignerRow(designer) {
        if (this._allRows.length === 0)
            this.addRow({}, { _designer: designer, ...this._rowProps }, { display: true }); // add an empty row so we can drop components in the table's cells
    }

    clearRows() {
        this._clearDisplayedRows();
        this._allRows = [];
    }

    private _clearDisplayedRows() {
        for (const row of this.rows) {
            this._removeExpansionRowElement(row, false);
            this._observer.unobserve(row._element);
        }
        this.selectedIndexes = [];
        this.rows = [];
        this._tbody.innerHTML = "";
        this._handleEmptyComponent();
    }

    private hideDisplayedRow(row: TableRow) {
        if (row == null)
            return;
        row.expanded = false;
        const rowsIndex = this.rows.indexOf(row);
        if (rowsIndex >= 0) {
            this.rows.splice(rowsIndex, 1);
            this._tbody.removeChild(row._element);
            //remove row from filtered rows so that it matches rows (which it always should),
            //and so that the row count label is updated correctly below
            if (this._filteredRows != null) {
                const filteredRowsIndex = this._filteredRows.indexOf(row);
                if (filteredRowsIndex >= 0)
                    this._filteredRows.splice(rowsIndex, 1);
            }
        }
        this._setRowCountLabel();
    }

    override displayComponentData(rowData: ModelRow, allData: ModelRow[], rowIndex: number) {
        this._clearDisplayedRows();
        this._allRows = [];
        if (this.field == null)
            this.data = allData;
        else if (rowData != null) {
            this.data = rowData[this.field];
        }
        log.debug(this, "displayData", this, allData);
        if (this.data == null) {
            this._setRowCountLabel();
            return;
        }
        const filtered = this._sortRows([...this.filteredRows]);
        for (const row of filtered)
            this.displayRow(row);
        this._setRowCountLabel();
        this.applyLastScrollLeft();
    }

    shouldDisplayData(data: ModelRow, allData: ModelRow[], rowIndex: number): boolean {
        return this.dataSource == null || allData == this.dataSource.data
    }

    /**
     * This causes the table to redisplay a given row.  When the underlying data in the table is updated, this method will cause
     * that data to be reflected in the UI.  This has no effect is the Table is virtualized and the user hasn't scrolled to the specified
     * row yet.  When the user does scroll to that row, the most current data will be displayed.
     * @param index The index of the row that needs to be redisplayed.
     */
    redisplaySingleRow(index: number, data: ModelRow, isNewRow: boolean, makeVisible: boolean): void {
        if (isNewRow) {
            // this._data.splice(index, 1, data);
            if (this.data == null)
                this.data = this.dataSource?.data || [];
            const addRowResult = this.addRow(data, null, { display: true, addToData: true, save: false });
            const row = addRowResult.row;
            this._setRowCountLabel();
            if (makeVisible == true) {
                row.scrollIntoView();
                setTimeout(() => {
                    row.ripple("primary.lightest", { speed: 300 }).then(() => row.ripple("primary.light", { speed: 500 }));
                }, 500);
            }
        }
        else {
            this._data[index] = data;
            const row = this._allRows[index];
            row.data = this._data[index];

            // If the row has already been populated with its components then we need to refresh its displayed data.
            // - Fire the row display listener so that any logic based on the row's data is evaluated.
            // - Do this even if the row currently isn't displayed to the user (it could be filtered out), but could
            //   become visible to the user again later.  The event logic could also update something the user can see,
            //   such as a count that is visible outside of the table.
            if (row.populatedDOM === true) {
                row.displayComponentValues();
                const displayEvent = new TableRowDisplayEvent(row, this);
                this.fireRowDisplayListeners(displayEvent);
            }

            if (this.rows.includes(row)) {
                // If the row is currently being displayed, decide if it should still be displayed (based on the current
                // table filter).
                if (this.rowPassesFilter(row) !== true) {
                    log.debug(this, "Hiding previously displayed row due to redisplay + filtering: %o", row);
                    this.hideDisplayedRow(row);
                }
                else
                    log.debug(this, "Row being redisplayed will remain in the table: %o", row);
            }
            else {
                // If the row is not currently being displayed, decide if it should be displayed (based on the current
                // table filter).
                if (this.rowPassesFilter(row) === true) {
                    log.debug(this, "Previously hidden row being displayed due to redisplay (now passes filtering): %o", row);
                    const insertPos = this._getRowInsertPos(row);
                    this.displayRow(row, insertPos);
                    this._filteredRows?.splice(insertPos, 0, row);
                    this._setRowCountLabel();
                }
                else
                    log.debug(this, "Previously hidden row will remain hidden: %o", row);
            }
        }
    }

    get data(): ModelRow[] {
        return this._data;
    }

    set data(value: ModelRow[]) {
        this._data = value;
        this._allRows = [];
        this._filteredRows = null;
        const rowMode = this._getRowModeFromDataSource();
        if (this._data != null) {
            for (const dataRow of this._data) {
                this.addRow(dataRow, { mode: rowMode });
            }
            this.initializeSortFromData();
        }
    }

    /**
     * This method only updates current sort info, but doesn't actually invoke sorting.
     * It assumes that the data is already sorted, and that we only need to make the table match that order.
     */
    private initializeSortFromData() {
        //if user is using a custom config/sort, don't use the sort from the data being displayed
        if (this.configInUse === this.baseConfig) {
            this.clearSort();
            if (ArrayUtil.isEmptyArray(this.dataSource?.orderBy) === false) {
                for (const orderByInfo of this.dataSource.orderBy) {
                    const selector = this.findSortSelectorsForField(orderByInfo.field)?.selector;
                    if (selector != null)
                        this.addSort({ fieldName: orderByInfo.field, sort: orderByInfo.sort, selector: selector });
                }
            }
        }
        //still apply the data-provided sort to the base config, if it doesn't already specify a sort
        //(this method is likely how it will be specified, we just don't need to populate it more than once)
        if (this.baseConfig?.hasOrderByInfo() === false) {
            if (ArrayUtil.isEmptyArray(this.dataSource?.orderBy) === false) {
                for (const orderByInfo of this.dataSource.orderBy) {
                    this.baseConfig.addOrderByInfo({ field: orderByInfo.field, sort: orderByInfo.sort });
                }
            }
        }
    }

    private findSortSelectorsForField(fieldName: string): any {
        if (StringUtil.isEmptyString(fieldName))
            return null;
        for (const column of this.columns) {
            for (const component of column.headingCell?.components) {
                if (component instanceof SortSelector) {
                    if (component.fieldName === fieldName)
                        return { selector: component };
                    const child = component.getChildSelector(fieldName);
                    if (child != null)
                        return { selector: child, parentSelector: component };
                }
            }
        }
        return null;
    }

    private _getRowModeFromDataSource(): TableRowMode {
        if (this.dataSource == null)
            return TableRowMode.NONE;
        switch (this.dataSource.mode) {
            case DataSourceMode.SEARCH:
                return TableRowMode.SEARCH;
            default:
                return TableRowMode.NONE;
        }
    }

    get expandComponent() {
        return this._expandComponent;
    }

    set expandComponent(value) {
        const oldValue = this._expandComponent;
        this._expandComponent = value;
        if (value != null)
            this._expandComponent.parent = this;
        this.syncDesignerExpandArea(oldValue).then(() => this.rebuildExpanderHeader());
    }

    get additionalRowToolsDef() {
        return this._additionalRowToolsDef;
    }

    set additionalRowToolsDef(value) {
        this._additionalRowToolsDef = value;
    }

    get addType(): AddType {
        return this._addType ?? this.getPropertyDefinitions().addType.defaultValue;
    }

    set addType(value: AddType) {
        if (this._addType === value)
            return;
        this._addType = value;
        this.syncAddActions();
    }

    private syncAddActions() {
        if (this.isDeserializing() === true) {
            log.debug("Not syncing add actions, table is still being deserialized");
            return;
        }
        this.toolsPanel.syncTools();
        this.syncQuickAddRow();
    }

    get quickAddData(): ModelRow<unknown> | any {
        return this._quickAddData;
    }

    saveQuickAddData() {
        if (this.quickAddRow.validateSimple() !== true)
            return;
        const quickAddData = this.quickAddData;
        const quickAddRow = this.quickAddRow;
        this.shrinkAllRows();
        this.removeQuickAddRow();
        this.addQuickAddRow(false);
        this.addRow(quickAddData, { mode: TableRowMode.NONE }, { display: true, addToData: true, save: false }); //save should have already occurred
        if (this.filterValue != null && !this.tableRowStringSearcher.rowContains(quickAddRow.data, this.filterValue))
            this.resetFilter();
    }

    private syncQuickAddRow() {
        if (this.isQuickAddAllowed() !== true)
            this.removeQuickAddRow();
        else
            this.addQuickAddRow();
    }

    public async resetQuickAddRow() {
        this.removeQuickAddRow();
        this.syncQuickAddRow();
    }

    private async addQuickAddRow(updateTableContent: boolean = true): Promise<void> {
        const release = await this.quickAddRowMutex.acquire();
        try {
            await this.addQuickAddRowInternal(updateTableContent);
        }
        finally {
            release();
        }
    }
    
    private async addQuickAddRowInternal(updateTableContent: boolean): Promise<void> {
        if (this.quickAddRow != null)
            return;
        this._quickAddData = await this._createNewRowData();
        await this.dataSource?.getMetadata();
        this._quickAddRow = new TableRow(this);
        this.quickAddRow.setDesigner(this.getDesigner());
        this.quickAddRow.owner = this.owner;
        const props: Partial<TableRowProps> = {
            ...this._rowProps,
            id: this.id + "QuickAddTableRow",
            data: this.quickAddData,
            index: 0,
            expanded: false,
            virtualized: false,
            placeholderHeight: this._fixedRowHeight,
            allowEdit: true,
            allowDelete: false
        };
        this.quickAddRow.setProps(props);
        this.quickAddRow.mode = TableRowMode.QUICK_ADD;
        this.quickAddRow._element.tabIndex = 0;
        this.applyRowProps(this.quickAddRow);
        await this.quickAddRow.populateDOM();
        this.quickAddRow.forEveryChildComponent((component: Component) => {
            component.applyDefaultDataValue();
            component.displayData(component.boundRow, null, null);
        })
        this._insertBelowTableHeader(this.quickAddRow._element);
        if (updateTableContent === true)
            this._handleEmptyComponent(); //called to make sure we don't show the empty component when quick add is in use
    }

    async removeQuickAddRow(): Promise<void> {
        const release = await this.quickAddRowMutex.acquire();
        try {
            this.removeQuickAddRowInternal(); // Now synchronous
        } finally {
            release();
        }
    }

    private removeQuickAddRowInternal() {
        if (this.quickAddRow == null && this.quickAddData == null)
            return;
        if (this.quickAddRow?._element != null && this._table.contains(this.quickAddRow._element)) {
            this._table.removeChild(this.quickAddRow?._element);
        }
        this._quickAddRow = null;
        this._quickAddData = null;
        this._handleEmptyComponent(); //called to make sure we show the empty component when quick add is not in use
    }

    _insertBelowTableHeader(element: HTMLElement) {
        this._table.insertBefore(element, this._thead.nextSibling);
    }

    async _createNewRowData(): Promise<ModelRow | any> {
        if (this.dataSource != null)
            return await this.dataSource.createBlankRow();
        return {};
    }

    deleteRow(index: number) {
        if (this._outsideClickListener != null && this.rows[index]?.mode === TableRowMode.UPDATE)
            this._deactivateValidateOnOutsideClick();
        if (this.doOnRowDelete)
            this.doOnRowDelete(this.rows[index]);
        this.dataSource.deleteTableRow(index, !this.persistChangesImmediately, this.field);
        this.resetSequence(this._allRows); //don't need to reset row indexes here because DataSource.deleteTableRow() redisplayed data in the table
        this._syncRowsDraggable();
    }

    isNormalAddAllowed(): boolean {
        return this.isAnyAddAllowed() &&
            this.addType === AddType.NORMAL &&
            this.getLayoutNameForMode(DataSourceMode.ADD) != null;
    }

    private isQuickAddAllowed(): boolean {
        return this.isAnyAddAllowed() &&
            this.addType === AddType.QUICK &&
            this._designer == null;
    }

    private isAnyAddAllowed(): boolean {
        return this.allowAddDisabledByServer !== true &&
            this.addType !== AddType.NOT_ALLOWED &&
            this.dataSource?.mode !== DataSourceMode.SEARCH;
    }

    get allowAddDisabledByServer(): boolean {
        return this._allowAddDisabledByServer ?? this.getPropertyDefinitions().allowAddDisabledByServer.defaultValue;
    }

    private set allowAddDisabledByServer(value: boolean) {
        this._allowAddDisabledByServer = value;
        this.syncAddActions();
    }

    public get allowAdvancedSearch(): boolean {
        return this._allowAdvancedSearch ?? this.getPropertyDefinitions().allowAdvancedSearch.defaultValue;
    }

    public set allowAdvancedSearch(value: boolean) {
        this._allowAdvancedSearch = value;
        this.toolsPanel.syncTools();
    }

    public get allowConfig(): boolean {
        return this._allowConfig ?? this.getPropertyDefinitions().allowConfig.defaultValue;
    }

    public set allowConfig(value: boolean) {
        this._allowConfig = value;
        this.toolsPanel.syncTools();
    }

    public get allowDelete(): boolean {
        return (this._allowDelete && this._allowDeleteDisabledByServer !== true) ?? this.getPropertyDefinitions().allowDelete.defaultValue;
    }

    public set allowDelete(value: boolean) {
        this._allowDelete = value;
        this.toolsPanel.syncTools();
    }

    public get allowDeleteDisabledByServer(): boolean {
        return this._allowDeleteDisabledByServer;
    }

    private set allowDeleteDisabledByServer(value: boolean) {
        this._allowDeleteDisabledByServer = value;
        this.toolsPanel.syncTools();
    }

    public get allowDetail(): boolean {
        return this._allowDetail ?? this.getPropertyDefinitions().allowDetail.defaultValue;
    }

    public set allowDetail(value: boolean) {
        if (value !== true && this.rowProps != null)
            delete this.rowProps.onDblClick;
        this._allowDetail = value;
        if (value === true) {
            if (this.rowProps == null)
                this.rowProps = {};
            this.rowProps.onDblClick = (event: ClickEvent) => this.toolsPanel.handleRowDblClick(event);
        }
        this.toolsPanel.syncTools();
    }

    public get allowDetailForRow(): (row: TableRow) => boolean {
        return this._allowDetailForRow;
    }

    public set allowDetailForRow(value: (row: TableRow) => boolean) {
        this._allowDetailForRow = value;
    }

    get allowEdit() {
        return (this._allowEdit ?? this.getPropertyDefinitions().allowEdit.defaultValue) &&
            this._allowEditDisabledByServer !== true;
    }

    set allowEdit(value: boolean) {
        this._allowEdit = value;
        this.toolsPanel.syncTools();
    }

    get allowEditDisabledByServer(): boolean {
        return this._allowEditDisabledByServer;
    }

    private set allowEditDisabledByServer(value: boolean) {
        this._allowEditDisabledByServer = value;
        this.toolsPanel.syncTools();
    }

    public get allowExport(): boolean {
        return this._allowExport ?? this.getPropertyDefinitions().allowExport.defaultValue;
    }

    public set allowExport(value: boolean) {
        this._allowExport = value;
        this.toolsPanel.syncTools();
    }

    public get allowPin(): boolean {
        return this._allowPin ?? this.getPropertyDefinitions().allowPin.defaultValue;
    }

    public set allowPin(value: boolean) {
        this._allowPin = value;
        this.toolsPanel.syncTools();
    }

    public get allowShare(): boolean {
        return this._allowShare ?? this.getPropertyDefinitions().allowShare.defaultValue;
    }

    public set allowShare(value: boolean) {
        this._allowShare = value;
        this.toolsPanel.syncTools();
    }

    get rowModeControlType() {
        return this._rowModeControlType;
    }

    set rowModeControlType(value: RowModeControlType) {
        this._rowModeControlType = value;
    }

    get persistChangesImmediately(): boolean {
        return this._persistChangesImmediately != null ? this._persistChangesImmediately : false;
    }

    set persistChangesImmediately(value: boolean) {
        this._persistChangesImmediately = value;
        if (value && this.dataSource != null)
            this.dataSource.preventChangeNotifications = true;
    }

    get sequenceField(): string {
        return this._sequenceField;
    }

    set sequenceField(value: string) {
        //this shouldn't be changed after the Table's properties are initially loaded
        this._sequenceField = value;
    }

    get busy(): boolean {
        if (this._busy == null)
            return false;
        return this._busy;
    }

    set busy(value: boolean) {
        this._busy = value;
        if (this.dataSource?.mode !== DataSourceMode.UPDATE) {
            if (value && this._busyImage == null)
                this._busyImage = new Image({ name: "spinner", color: "primary.light", width: "100%", height: 48, marginTop: 16, marginBottom: 16, rotate: true });
            this._handleEmptyComponent();
        }
        if (value !== true)
            this._handleEmptyComponent();
    }

    get busyWhenDataSourceBusy(): boolean {
        return this._busyWhenDataSourceBusy == null ? this.getPropertyDefinitions().busyWhenDataSourceBusy.defaultValue : this._busyWhenDataSourceBusy;
    }

    set busyWhenDataSourceBusy(value: boolean) {
        this._busyWhenDataSourceBusy = value;
    }

    get emptyCaption() {
        return this._emptyCaption == null ? this.getPropertyDefinitions().emptyCaption.defaultValue : this._emptyCaption;
    }

    set emptyCaption(value: string) {
        this._emptyCaption = value;
        this.createEmptyComponentFromCaption();
    }

    private ensureEmptyComponentCreated() {
        if (this.emptyComponent == null)
            this.createEmptyComponentFromCaption();
    }

    private createEmptyComponentFromCaption() {
        const newEmptyComponent = new Panel({ fillHeight: true });
        const emptyLabel = ComponentFactory.createCommon(this.emptyCaption, {
            fillRow: true,
            fontSize: "large",
            color: "subtle.light",
            align: HorizontalAlignment.CENTER,
            paddingTop: 12,
            paddingBottom: 12
        });
        newEmptyComponent.add(emptyLabel);
        this.emptyComponent = newEmptyComponent;
    }

    get emptyComponent(): Component {
        return this._emptyComponent;
    }

    set emptyComponent(value: Component) {
        if (this.emptyComponent === value)
            return;
        const oldEmptyComponent = this._emptyComponent;
        this._emptyComponent = value;
        if (oldEmptyComponent != null && this._table.contains(oldEmptyComponent._element))
            this._table.replaceChild(this._emptyComponent._element, oldEmptyComponent._element);
        else
            this._handleEmptyComponent();
    }

    _handleEmptyComponent() {
        this._noRecordsMatch = false;
        if (this.busy) {
            if (this._busyImage != null)
                this.setTableContent(this._busyImage._element);
        } else {
            const hasRows = this.rows.length > 0;
            if ((this.quickAddRow?._element == null || this._table.contains(this.quickAddRow._element) === false) &&
                !hasRows && this._emptyComponent != null) {
                this.setTableContent(this._emptyComponent._element);
                this._noRecordsMatch = true;
            }
            else
                this.setTableContent(this._tbody);
        }
    }

    /**
     * The table can have one of the following.
     *    - its normal body
     *    - a label saying there aren't any records
     *    - a busy indicator
     *
     * This method removes all but the desired element.
     *
     * @param element
     */
    private setTableContent(element: HTMLElement) {
        if (!this._table.contains(element)) {
            if (this._table.contains(this._tbody))
                this._table.replaceChild(element, this._tbody);
            else if (this._emptyComponent != null && this._table.contains(this._emptyComponent._element))
                this._table.replaceChild(element, this._emptyComponent._element);
            else if (this._busyImage != null && this._table.contains(this._busyImage._element))
                this._table.replaceChild(element, this._busyImage._element);
            else
                log.error("Unexpected condition in setTableContent");
        }
    }

    set expanderComponent(value) {
        this._expanderComponent = value;
        this.rebuildExpanderHeader();
    }

    get expanderComponent() {
        return this._expanderComponent;
    }

    get noRecordsMatch() {
        return this._noRecordsMatch;
    }

    private applyRowProps(tableRow: TableRow) { // it'd be nice to allow fully setting the row props instead of just a select few
        if (this.rowBorderBottomWidth !== 1)
            tableRow.borderBottomWidth = this.rowBorderBottomWidth;
        if (this.rowBorderBottomColor != null)
            tableRow.borderBottomColor = this.rowBorderBottomColor;
        if (this.rowBorderTopWidth !== 1)
            tableRow.borderTopWidth = this.rowBorderTopWidth;
        if (this.rowBorderTopColor != null)
            tableRow.borderTopColor = this.rowBorderTopColor;
    }

    _applyCellProps(cell: TableCell) {
        if (this.rowAlign == null || this.rowAlign === VerticalAlignment.TOP)
            cell.verticalAlign = null;
        else
            cell.verticalAlign = this.rowAlign;
        if (this.rowSpacing == null || this.rowSpacing === 4) {
            cell.paddingTop = null;
            cell.paddingBottom = null;
        }
        else {
            cell.paddingTop = this.rowSpacing;
            cell.paddingBottom = this.rowSpacing * 2;
        }
    }

    set headerVisible(value) {
        if (value === this._headerVisible)
            return;
        this._headerVisible = value;
        if (value)
            this._element.insertBefore(this._heading._element, this._table);
        else
            this._element.removeChild(this._heading._element);

    }
    get headerVisible() { return this._headerVisible; }

    getComponentsForDiagnostic(): Component[] {
        const result = [this._heading, this.headingRow];
        if (this.quickAddRow != null) {
            result.push(this.quickAddRow);
        }
        result.push(...this.rows);
        return result;
    }

    get rowCount(): number {
        return this._allRows.length;
    }

    get displayedRowCount(): number {
        return this.rows.length;
    }

    private _setRowCountLabel() {
        if (this._rowCountLabel != null) {
            const count = this.filteredRows?.length;
            if (count === 0 || this._designer != null)
                this._rowCountLabel.text = "No Results";
            else if (count === 1)
                this._rowCountLabel.text = "1 Result";
            else
                this._rowCountLabel.text = count + " Results";
        }
    }

    addRowExpandListener(value: TableRowExpansionListener) {
        this.addEventListener(_rowExpandListenerDef, value);
    }

    removeRowExpandListener(value: TableRowExpansionListener) {
        this.removeEventListener(_rowExpandListenerDef, value);
    }

    addRowCollapseListener(value: TableRowExpansionListener) {
        this.addEventListener(_rowCollapseListenerDef, value);
    }

    removeRowCollapseListener(value: TableRowExpansionListener) {
        this.removeEventListener(_rowCollapseListenerDef, value);
    }

    addRowCreateListener(value: TableRowCreationListener) {
        this.addEventListener(_rowCreateListenerDef, value);
    }

    removeRowCreateListener(value: TableRowCreationListener) {
        this.removeEventListener(_rowCreateListenerDef, value);
    }

    fireRowCreationListeners(creationEvent: TableRowCreationEvent) {
        this.fireListeners(_rowCreateListenerDef, creationEvent);
    }

    addRowBeforeSaveListener(value: TableRowBeforeSaveListener) {
        this.addEventListener(_rowBeforeSaveListenerDef, value);
    }

    removeRowBeforeSaveListener(value: TableRowBeforeSaveListener) {
        this.removeEventListener(_rowBeforeSaveListenerDef, value);
    }

    fireRowBeforeSaveListeners(beforeRowSaveEvent: TableRowBeforeSaveEvent) {
        this.fireListeners(_rowBeforeSaveListenerDef, beforeRowSaveEvent);
    }

    addContentsChangedListener(value: TableContentsChangedListener) {
        this.addEventListener(_contentsChangedListenerDef, value);
    }

    removeContentsChangedListener(value: TableContentsChangedListener) {
        this.removeEventListener(_contentsChangedListenerDef, value);
    }

    fireContentsChangedListener(contentsChangedEvent: TableContentsChangedEvent) {
        this.fireListeners(_contentsChangedListenerDef, contentsChangedEvent);
    }

    addFilterChangedListener(value: TableFilterChangedListener) {
        this.addEventListener(_filterChangedListenerDef, value);
    }

    removeFilterChangedListener(value: TableFilterChangedListener) {
        this.removeEventListener(_filterChangedListenerDef, value);
    }

    fireFilterChangedListener(filterChangedEvent: TableFilterChangedEvent) {
        this.fireListeners(_filterChangedListenerDef, filterChangedEvent);
    }

    addBeforeTableRowMoveListener(value: TableRowMoveListener) {
        this.addEventListener(_beforeTableRowMoveListenerDef, value);
    }

    removeBeforeTableRowMoveListener(value: TableRowMoveListener) {
        this.removeEventListener(_beforeTableRowMoveListenerDef, value);
    }

    fireBeforeTableRowMoveListener(tableRowMoveEvent: TableRowMoveEvent) {
        this.fireListeners(_beforeTableRowMoveListenerDef, tableRowMoveEvent);
    }

    addAfterTableRowMoveListener(value: TableRowMoveListener) {
        this.addEventListener(_afterTableRowMoveListenerDef, value);
    }

    removeAfterTableRowMoveListener(value: TableRowMoveListener) {
        this.removeEventListener(_afterTableRowMoveListenerDef, value);
    }

    fireAfterTableRowMoveListener(tableRowMoveEvent: TableRowMoveEvent) {
        this.fireListeners(_afterTableRowMoveListenerDef, tableRowMoveEvent);
    }

    addAfterCrudCloseListener(value: CrudDecoratorCloseListener) {
        this.addEventListener(_afterCrudCoseListenerDef, value);
    }

    removeAfterCrudCloseListener(value: CrudDecoratorCloseListener) {
        this.removeEventListener(_afterCrudCoseListenerDef, value);
    }

    fireAfterCrudCloseListener(crudCloseEvent: CrudDecoratorCloseEvent) {
        this.fireListeners(_afterCrudCoseListenerDef, crudCloseEvent);
    }

    addRowDisplayListener(value: TableRowDisplayListener) {
        this.addEventListener(_rowDisplayListenerDef, value);
    }

    removeRowDisplayListener(value: TableRowDisplayListener) {
        this.removeEventListener(_rowDisplayListenerDef, value);
    }

    fireRowDisplayListeners(displayEvent: TableRowDisplayEvent) {
        this.fireListeners(_rowDisplayListenerDef, displayEvent);
    }

    addRowModeChangeListener(value: TableRowModeChangeListener) {
        this.addEventListener(_rowModeChangeListenerDef, value);
    }

    removeRowModeChangeListener(value: TableRowModeChangeListener) {
        this.removeEventListener(_rowModeChangeListenerDef, value);
    }

    fireRowModeChangeListeners(modeChangeEvent: TableRowModeChangeEvent) {
        this.fireListeners(_rowModeChangeListenerDef, modeChangeEvent);
    }

    addSelectionListener(value: TableSelectionListener) {
        this.addEventListener(_selectionListenerDef, value);
    }

    removeSelectionListener(value: TableSelectionListener) {
        this.removeEventListener(_selectionListenerDef, value);
    }

    addAdditionalRowToolsDisplayListener(value: TableRowAdditionalToolsDisplayListener) {
        this.addEventListener(_rowAdditionalToolsListenersDef, value);
    }

    removeAdditionalRowToolsDisplayListener(value: TableRowAdditionalToolsDisplayListener) {
        this.removeEventListener(_rowAdditionalToolsListenersDef, value);
    }

    fireAdditionalRowToolsDisplayListeners(eventOrRow: TableRowAdditionalToolsDisplayEvent | TableRow) {
        if (eventOrRow instanceof TableRow)
            eventOrRow = new TableRowAdditionalToolsDisplayEvent(eventOrRow, this);
        if (!ArrayUtil.isEmptyArray(eventOrRow.getTableRow()?.additionalToolButtons))
            return this.fireListeners(_rowAdditionalToolsListenersDef, eventOrRow);
    }

    override getPropertyDefinitions() {
        return TablePropDefinitions.getDefinitions();
    }

    async getFields(): Promise<any[]> {
        return this.columns?.map(async column => await column.getFields());
    }

    private get tableRowStringSearcher(): TableRowStringSearcher {
        if (this._tableRowStringSearcher == null)
            this._tableRowStringSearcher = new TableRowStringSearcher(this);
        return this._tableRowStringSearcher;
    }

    /**
     * The filteredRows array represents the current set of rows that match the filter.  The actual object returned could be:
     *  -> the _allRows array, when no valid filter is present, or there are no rows in the table
     *  -> the _filteredRows array, when a valid filter is present
     *
     * In theory the result of this method should match _rows after the act of evaluating the filter is complete.
     * We keep _filteredRows around afterward so that we can use its presence to tell if the filter has already been evaluated
     * (_filteredRows gets nulled out again when the user changes the filter or when the data in the overall table is reset)
     */
    get filteredRows(): TableRow[] {
        if (this._filteredRows == null) {
            if (this._customFilter == null && (this.filterValue == null || this.filterValue.length === 0) || this._allRows.length === 0)
                return this._allRows;
            const filterValue = this.filterValue?.toLowerCase();
            const result = [];
            for (const row of this._allRows) {
                if (this.rowPassesFilter(row, filterValue) === true)
                    result.push(row);
            }
            this._filteredRows = result;
        }
        return this._filteredRows;
    }

    set customFilter(filter:(data:TableRow) => boolean) {
        this._customFilter = filter;
        this.applyFilters();
    }

    clearCustomFilter() {
        this._customFilter = null;
        this.applyFilters();
    }

    public applyFilters() {
        this._filteredRows = null;
        this._applyFiltering();
        this._syncRowsDraggable();
    }

    private rowPassesFilter(row: TableRow, providedFilterValue?: string): boolean {
        let filterValue: string;
        if (StringUtil.isEmptyString(providedFilterValue) !== true)
            filterValue = providedFilterValue; //assumed to already be in lower case
        else
            filterValue = this.filterValue?.toLowerCase();

        let result = true;
        if (this._customFilter != null && !this._customFilter(row))
            result = false;
        if (StringUtil.isEmptyString(filterValue) === false &&
            !this.tableRowStringSearcher.rowContains(row.data, filterValue)) {
            result = false;
        }
        return result;
    }

    /**
     * This method allows access to the Table's addlSearcherCallback function.  It is intended to be used primarily by
     * TableRowStringSearcher, which compares the Table's input filter value against data in each TableRow.
     */
    get addlSearcherCallback(): () => ComponentSearcher[] {
        return this._addlSearcherCallback;
    }

    /**
     * The addlSearcherCallback method provides a way for a Table to make non-standard fields (those bound to a field
     * within a table cell in the layout's definition file) searchable via the Table's filter field.
     *
     * For example, say a component is added in code and is not present in the table's definition in the layout file.
     * By default, the filter logic would have no way to match any value presented in that component.  But by specifying the
     * addlSearcherCallback, such a component could be made searchable.
     *
     * The panelBrkStatus component in BrokerageMovementTable.ts is such an example: it is defined as a Panel in the layout,
     * and is not bound to a field.  The Table object in BrokerageMovementTable can define the callback method so that the
     * brokerage status code and brokerage status description values are included in the Table's filter.
     *
     * The callback method should return an array of ComponentSearcher objects.  A ComponentSearcher is an object whose searcher function
     * takes the relevant ModelRow as a parameter and returns a ComponentSearchResult object that can be used in filter comparisons.
     *
     * So in the previous Brokerage Planning example, 2 ComponentSearchers are returned:
     *
     *     tableSearcherCreationCallback(): ComponentSearcher[] {
     *       return [
     *         new ComponentSearcher("brokerage_status"),
     *         new ComponentSearcher("brokerage_status_descr")
     *       ];
     *     }
     *
     * The simplest version of ComponentSearcher takes the single fieldName parameter described above.  Other parameters, which include a
     * fieldName alias and a custom searcher fuction, are available.
     */
    set addlSearcherCallback(value: () => ComponentSearcher[]) {
        this._addlSearcherCallback = value;
    }

    _applyFiltering() {
        this._clearDisplayedRows();
        this._setRowCountLabel();
        let filteredRows = this.filteredRows;
        if (filteredRows == null) {
            return;
        }
        filteredRows = this._sortRows([...filteredRows]);
        this.resetIndexAndSequence(filteredRows);
        for (const row of filteredRows)
            this.displayRow(row);
        this.applyLastScrollLeft();
        this.fireFilterChangedListener(new TableFilterChangedEvent(this, this.filterValue));
    }

    getSearchValues(): string[] {
        const result = [];
        for (const row of this.rows) {
            result.push(...row.getSearchValues());
        }
        return result;
    }

    getParentDataSource(): DataSource {
        let result = null;
        if (this.parent instanceof Tab) {
            result = this.parent.dataSource;
        }
        return result;
    }

    dataSourceModeChanged(mode: DataSourceMode): void {
        super.dataSourceModeChanged(mode);
        if (mode === DataSourceMode.SEARCH)
            this.searchFilterVisible = SearchFilterVisible.NEITHER;
        if (mode === DataSourceMode.NONE) {
            if (this.allowDbSearch === true)
                this.searchFilterVisible = SearchFilterVisible.SEARCH_ONLY;
            else
                this.searchFilterVisible = SearchFilterVisible.FILTER_ONLY;
        }
        this.syncAddActions();
        if (mode === DataSourceMode.SEARCH)
            this.clearRows();
    }

    completeEditedRows(): boolean {
        let result = true;
        for (const row of this.rows) {
            if (row.mode === TableRowMode.ADD || row.mode === TableRowMode.UPDATE) {
                row.shrinkAllComponents();
                result = result && row.saveChanges();
            }
        }
        if (this.quickAddRow != null)
            this.quickAddRow.selected = false;
        return result;
    }

    get allowDbSearch(): boolean {
        return this._allowDbSearch;
    }

    set allowDbSearch(value: boolean) {
        this._allowDbSearch = value;
        if (value === true)
            this.searchFilterVisible = SearchFilterVisible.SEARCH_ONLY;
        else
            this.searchFilterVisible = SearchFilterVisible.FILTER_ONLY;
        this._setSearchTooltip();
    }

    private _setSearchTooltip() {
        if (this.allowDbSearch !== true) {
            this._search.tooltip = null;
            return;
        }
        if (this._search.tooltip != null)
            return;
        const dsUrl = this.dataSource?.url;
        if (dsUrl == null)
            return;
        const filter = { model_url: dsUrl };
        Api.search("common/quick-find-fields", filter).then(response => {
            this._search.tooltip = this._finalizeSearchTooltip(response.data[0].fields);
        });
    }

    private _finalizeSearchTooltip(fields: string[]): Panel {
        const tooltipPanel = new Panel({ maxHeight: 500, scrollY: true });
        const fieldListLabel = new Label({ rowBreak: true, fillRow: true });
        tooltipPanel.add(fieldListLabel);
        if (fields == null || fields.length === 0)
            fieldListLabel.text = "Search using any field.";
        else {
            fieldListLabel.text = "Search using the following fields:";
            for (const field of fields) {
                const singleFieldLabel = new Label({ text: "  - " + field, rowBreak: true, fillRow: true });
                tooltipPanel.add(singleFieldLabel);
            }
        }
        const advSearchLabel = new Label({
            fillRow: true,
            text: "You may also use the advanced search option to search across multiple fields.",
            imageColor: "primaryReverse",
            imageName: "magnifyingGlassPage",
            imageHeight: 35,
            imageWidth: 35,
            marginTop: 10
        });
        tooltipPanel.add(advSearchLabel);
        return tooltipPanel;
    }

    override setDesigner(value) {
        super.setDesigner(value);
        if (value != null)
            this.searchFilterVisible = SearchFilterVisible.BOTH;
    }

    private _syncSearchFilter() {
        if (this._filter == null || this._search == null)
            return;
        if (this._searchFilterVisible === SearchFilterVisible.BOTH) {
            if (!this._heading.components.includes(this._search))
                this._heading.insert(this._search, 0);
            if (!this._heading.components.includes(this._rowCountLabel))
                this._heading.insert(this._rowCountLabel, 1);
            this._heading.remove(this._filter);
            this._filter.variant = TextboxVariant.NO_LINES;
            this._heading.add(this._filter);
            return;
        }
        if (this._searchFilterVisible === SearchFilterVisible.NEITHER) {
            this._heading.remove(this._search);
            this._heading.remove(this._rowCountLabel);
            this._heading.remove(this._filter);
            this._filter.text = null;
            this._filterValue = null;
            return;
        }
        if (this._searchFilterVisible === SearchFilterVisible.SEARCH_ONLY) {
            if (!this._heading.components.includes(this._search)) {
                this._heading.insert(this._search, 0);
                this.dataSource?.addAfterExecutionListener(this._afterDbSearchListener);
            }
            if (!this._heading.components.includes(this._rowCountLabel))
                this._heading.insert(this._rowCountLabel, 1);
            this._filter.text = null;
            this._filterValue = null;
            this._heading.remove(this._filter);
            this._filter.variant = TextboxVariant.NO_LINES;
            this._rowCountLabel.visible = this.dataSource?.lastSearch != null;
            return;
        }
        if (this._searchFilterVisible === SearchFilterVisible.FILTER_ONLY) {
            this._heading.remove(this._search);
            this.dataSource?.removeAfterExecutionListener(this._afterDbSearchListener);
            this._heading.remove(this._filter);
            this._filter.variant = TextboxVariant.UNDERLINED;
            this._heading.insert(this._filter, 0);
            if (!this._heading.components.includes(this._rowCountLabel))
                this._heading.insert(this._rowCountLabel, 1);
        }
    }

    public buildConfig(): TableConfig {
        const result = new TableConfig();
        result.tableId = this.id;
        result.layoutPath = this.getRootLayout()?.layoutName;
        for (const column of this.columns) {
            result.addColumn({ headingCellId: column._headingCell.id });
        }
        if (this._sortInfo != null) {
            for (const fieldSortInfo of this._sortInfo) {
                result.addOrderByInfo({ field: fieldSortInfo.fieldName, sort: fieldSortInfo.sort });
            }
        }
        return result;
    }

    get configInUse(): TableConfig {
        return this._configInUse;
    }

    set configInUse(value: TableConfig) {
        if (value == null || this.configInUse?.id === value.id)
            return;
        //store default configuration so we can revert back to it if necessary
        if (this._baseConfig == null)
            this._baseConfig = this.buildConfig();
        //remove columns but hold them as variables
        //add columns back based on order from config
        //each addColumn() call in turn calls setupColumnSorting() for that column
        //add sort similar to how we call it in _initializeSortFromData()
        log.debug(this, "Applying table configuration %o", value);
        this._configInUse = value;
        const currentColumns = [...this.columns];
        let missingColumns = [...this.columns];
        this.clearColumns();
        let index = -1;
        for (const columnDescriptor of value.columnDescriptors) {
            index++;
            for (const currentColumn of currentColumns) {
                if (columnDescriptor.headingCellId === currentColumn.headingCell.id) {
                    log.debug(this, "Adding column %o", columnDescriptor);
                    this.addColumn(currentColumn, index === 0, index === currentColumns.length);
                    missingColumns = missingColumns.filter(column => columnDescriptor.headingCellId != column.headingCell.id);
                    break;
                }
            }
        }

        missingColumns.forEach((column, index) => {
            log.debug(this, "Adding column that was not defined in the table configuration %o", column);
            this.addColumn(column, this.columns.length === 0, index === missingColumns.length - 1);
        });
        this.resetFirstLastColumnPadding();
        this.clearSort();
        for (const orderByInfo of value.orderByInfo) {
            const selectors = this.findSortSelectorsForField(orderByInfo.field);
            if (selectors != null) {
                log.debug(this, "Applying order by %o to selectors %o", orderByInfo, selectors);
                this.sortByField(true, selectors.selector, selectors.parentSelector, orderByInfo.sort);
            }
            else {
                const message = "Sort Selector not found for order by %o, the field may no longer be in the layout";
                log.debug(this, message, orderByInfo);
            }
        }
    }

    /**
     * This method can be used to apply the user's default table configuration.  Normally the default configuration is
     * applied after the containing layout has been deserialized (and it's more efficient to do it then).  This method
     * exists to provide us the ability to reset back to the default configuration, which is helpful when the id of an
     * already-deserialized table changes (think Brokerage Planning).
     */
    public applyDefaultConfig(layoutName?: string) {
        if (this.isDeserializing() === true) {
            log.debug(this, "Not applying default table configuration; the table is still being deserialized");
            return;
        }
        const layoutPath = StringUtil.isEmptyString(layoutName) === false ? layoutName :
            this.getParentLayout()?.layoutName;
        if (StringUtil.isEmptyString(layoutPath) === true) {
            log.debug(this, "Not applying default table configuration; the parent layout is not known");
            return;
        }
        const defaultConfigRow = UserSettings.getSingleton().getDefaultTableConfigRow(layoutPath, this.id);
        log.debug(this, "Found default table configuration %o", defaultConfigRow);
        if (defaultConfigRow != null)
            this.configInUse = new TableConfig(defaultConfigRow);
    }

    get baseConfig(): TableConfig {
        return this._baseConfig;
    }

    public useBaseConfig() {
        this.configInUse = this.baseConfig;
    }

    validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        let result: ValidationResult[] = null;
        for (const row of this.rows) {
            const thisResult: ValidationResult[] = row.validate(checkRequired, showErrors);
            if (thisResult !== null) {
                if (result == null)
                    result = thisResult;
                else
                    result = result.concat(thisResult);
            }
        }

        if (this.onValidate != null) {
            const onValidateResult = this.onValidate(this);
            if (result == null)
                result = onValidateResult;
            else
                result = result.concat(onValidateResult);
        }

        //if all rows are valid, quit editing them
        if (result != null) {
            let allRowsValidationResult = true;
            for (const rowValidationResult of result) {
                if (rowValidationResult.isValid !== true) {
                    allRowsValidationResult = false;
                    break;
                }
            }
            if (allRowsValidationResult === true) {
                this.completeEditedRows();
            }
        }
        else //a null ValidationResult[] object means everything was valid
            this.completeEditedRows();
        return result;
    }

    override get serializationName() {
        return "table";
    }

    override get properName(): string {
        return "Table";
    }

    private _syncRowsDraggable() {
        const value = this.rowsAreDraggable();
        for (const row of this._allRows) {
            row.draggable = value;
        }
    }

    rowsAreDraggable(): boolean {
        return StringUtil.isEmptyString(this._sequenceField) !== true && this._allRows.length > 1 && this.rowsDisplayedInSequence() === true;
    }

    private rowsDisplayedInSequence(): boolean {
        //if the table has been filtered, we probably aren't looking at all of the possible rows, so assume rows are not all present and in sequence
        if (this.isFiltered() === true)
            return false;
        //if the rows are sorted, see how they are sorted.  if they are sorted only by the sequence field (in ascending order), then they are in sequence
        if (this.isSorted() === true) {
            for (const si of this._sortInfo) {
                if (si.fieldName !== this._sequenceField || si.sort !== "asc")
                    return false;
            }
        }
        return true;
    }

    /**
     * Moves a TableRow from one index to another
     * This method assumes:
     *    - no filter criteria is present
     *    - the table has not been sorted by the user (via the column headers)
     *
     * @param row: the TableRow object to move
     * @param newIndex: the index to which the TableRow should be moved
     * @param oldIndex: the index from which the TableRow originated
     * @param dragSession: the DragSession created related to the TableRow drag event
     */
    moveRow(row: TableRow, newIndex: number, dragSession?: DragSession) {
        const oldIndex = row.index;
        const beforeMoveEvent = new TableRowMoveEvent(this, row, newIndex, oldIndex, true, dragSession);
        this.fireBeforeTableRowMoveListener(beforeMoveEvent);
        // Clear top values that were set during dragging
        this.clearMoveTopValues();
        if (beforeMoveEvent.defaultPrevented === true) {
            log.debug(this, "Move of row cancelled: row: %o, oldIndex: %o, newIndex: %o", row, oldIndex, newIndex);
            return;
        }
        if (row.index === newIndex || newIndex < 0 || newIndex > this._rows.length - 1)
            return;
        ArrayUtil.moveArrayElement(this._data, row.index, newIndex);
        ArrayUtil.moveArrayElement(this._rows, row.index, newIndex);
        ArrayUtil.moveArrayElement(this._allRows, row.index, newIndex);
        this.resetIndexAndSequence(this._allRows);
        this._moveRowInDOM(row, newIndex);
        this.fireAfterTableRowMoveListener(new TableRowMoveEvent(this, row, newIndex, oldIndex, false, dragSession));
    }

    private clearMoveTopValues() {
        log.debug(this, "Removing top values applied during row dragging");
        this.rows.forEach(row => row._element.style.top = "");
    }

    private resetSequence(rows: TableRow[]) {
        this.resetIndexAndSequence(rows, false, true);
    }

    /**
     * Sets each TableRow's sequenceField and index values
     * The sequenceField starts its count at 1, while the index starts at zero
     *
     * @param rows: the TableRow[] containing rows to resequence
     */
    private resetIndexAndSequence(rows: TableRow[], resetIndex: boolean = true, resetSequence: boolean = true) {
        const needToResetSequence = resetSequence === true && StringUtil.isEmptyString(this.sequenceField) !== true;
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (resetIndex === true)
                row.index = i;
            if (needToResetSequence === true && row.data instanceof ModelRow)
                row.data.set(this.sequenceField, i + 1, this);
        }
    }

    /**
     * Moves a TableRow's element within the tbody's children
     *
     * @param row: the TableRow to move
     * @param newIndex: the row's new index
     */
    private _moveRowInDOM(row: TableRow, newIndex: number) {
        this._tbody.removeChild(row._element);
        this._tbody.insertBefore(row._element, this._tbody.children[newIndex]);
    }

    /**
     * Sets the onmousemove event on the _tbody element
     *
     * @param fn: the listener method (can be null)
     */
    setTableBodyOnMouseMove(fn: (event) => void) {
        this._tbody.onmousemove = fn;
    }

    /**
     * Defines if the component can grow to the height of the container that contains it.
     * Table's implementation of this method sets the table's maxHeight to the provided value.
     * This allows the table to fill up the container, but still get a scroll bar when more records are present than can be viewed in that height.
     *
     * @param height: the height to use to reset the table's maxHeight (provided as a number)
     */
    public override growToContainerHeight(height: number) {
        this.maxHeight = height;
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "rowExpand": { ..._rowExpandListenerDef },
            "rowCreate": { ..._rowCreateListenerDef },
            "contentsChanged": { ..._contentsChangedListenerDef },
            "filterChanged": { ..._filterChangedListenerDef },
            "afterTableRowMove": { ..._afterTableRowMoveListenerDef },
            "beforeTableRowMove": { ..._beforeTableRowMoveListenerDef },
            "afterCrudClose": { ..._afterCrudCoseListenerDef },
            "rowBeforeSave": { ..._rowBeforeSaveListenerDef },
            "rowDisplay": { ..._rowDisplayListenerDef },
            "rowModeChange": { ..._rowModeChangeListenerDef },
            "selection": { ..._selectionListenerDef }
        };
    }

    private getLayoutNameForMode(mode: DataSourceMode) {
        switch (mode) {
            case DataSourceMode.SEARCH: return this.searchLayout;
            case DataSourceMode.ADD: return this.addLayout;
            case DataSourceMode.UPDATE: return this.editLayout;
            default: return this.generalLayout;
        }
    }

    public get generalLayout(): string {
        return this._generalLayout;
    }

    public set generalLayout(value: string) {
        this._generalLayout = value;
        this.toolsPanel.syncTools();
    }
    public get detailLayout(): string {
        return this._detailLayout || this._generalLayout;
    }

    public set detailLayout(value: string) {
        this._detailLayout = value;
        this.toolsPanel.syncTools();
    }

    public get addLayout(): string {
        return this._addLayout || this._generalLayout;
    }

    public set addLayout(value: string) {
        this._addLayout = value;
        this.toolsPanel.syncTools();
    }

    public get editLayout(): string {
        return this._editLayout || this._generalLayout;
    }

    public set editLayout(value: string) {
        this._editLayout = value;
        this.toolsPanel.syncTools();
    }

    public get searchLayout(): string {
        return this._searchLayout || this._generalLayout;
    }

    public set searchLayout(value: string) {
        this._searchLayout = value;
        this.toolsPanel.syncTools();
    }

    public override get dataSource(): DataSource<ModelRow<any>> {
        return super.dataSource;
    }

    public set dataSource(value: DataSource<ModelRow<any>>) {
        super.dataSource = value;
        if (this.persistChangesImmediately)
            value.preventChangeNotifications = true;
    }

    public get quickAddRow(): TableRow {
        return this._quickAddRow;
    }

    /**
     * Table's override of updateBoundData is only intended to retrieve search values from the first row in the table
     * when in search mode
     *
     * @param row
     * @param mode
     * @param fillingLinkedValues
     * @returns void
     */
    public override updateBoundData(row: ModelRow, mode: DataSourceMode, fillingLinkedValues?: boolean) {
        if (mode !== DataSourceMode.SEARCH || fillingLinkedValues !== true)
            return;
        const firstRowInTable = this.rows[0]?.data;
        if (firstRowInTable == null || !(firstRowInTable instanceof ModelRow))
            return;
        this.rows[0].updateDataFromComponents();
        for (const field of Object.keys(firstRowInTable.data)) {
            const value = firstRowInTable.get(field);
            if (value != null)
                row.set(field, value);
        }
        //row.setValues(this.rows[0]?.data?.data);
    }

    public getBodyHeight(): number {
        return DOMUtil.getElementHeight(this._tbody);
    }

    protected bodyScrolled(event: any) {
        const left = this._tbody.scrollLeft;
        if (this.lastScrollLeft !== left) {
            // we need to somehow adjust for the scrollbar in tbody, but I need more math to figure out how
            //      const scrollOffset = DOMUtil.isScrollbarVisible(this._tbody) ? DOMUtil.getScrollbarWidth() : 0;
            this._thead.scrollLeft = left;
            this.lastScrollLeft = left;
        }
    }

    private headScrolled(event: any) {
        const left = this._thead.scrollLeft;
        if (this.lastScrollLeft !== left) {
            this._tbody.scrollLeft = left;
            this.lastScrollLeft = left;
        }
    }

    applyLastScrollLeft() {
        const left = this.lastScrollLeft ?? 0;
        if (this._thead.scrollLeft !== left) {
            this._thead.scrollLeft = left;
        }
        if (this._tbody.scrollLeft !== left) {
            this._tbody.scrollLeft = left;
        }
    }

    getPermissionsTypes(): PermissionsDefinition[] {
        const result = super.getPermissionsTypes();
        if (this.isAnyAddAllowed())
            result.push({
                permsType: "tableAdd",
                description: "Add security",
                availableToAllDescription: "Everyone can add rows to this table",
                availableToNoneDescription: "No one can add rows to this table"
            });
        if (this.allowEdit)
            result.push({
                permsType: "tableEdit",
                description: "Edit security",
                availableToAllDescription: "Everyone can edit rows in this table",
                availableToNoneDescription: "No one can edit rows in this table"
            });
        if (this.allowDelete)
            result.push({
                permsType: "tableDelete",
                description: "Delete security",
                availableToAllDescription: "Everyone can delete rows from this table",
                availableToNoneDescription: "No one can delete rows from this table",
            });
        return result;
    }

    public async resolveRowEdit(originalRow: ModelRow, updatedRow: ModelRow) {
        switch (this.rowEditResolveMode) {
            case TableRowEditResolveMode.NONE: updatedRow = null; break;
            case TableRowEditResolveMode.CUSTOM: updatedRow = await this.doOnResolveRowEdit(updatedRow); break;
            case TableRowEditResolveMode.LOOKUP_KEY: updatedRow = await this.lookupRow(updatedRow.getKeyData());
        }
        if (updatedRow != null)
            this.dataSource.replaceRow(originalRow, updatedRow);
    }

    private async lookupRow(keyData: Collection<unknown>) {
        const metadata = await this.dataSource.getMetadata();
        const searchParam = {};
        for (const field of metadata.keyFields)
            searchParam[field] = keyData[field];
        searchParam["_field_list"] = { layoutName: this.owner?.layoutName };
        const results = await Model.search(this.dataSource.url, searchParam);
        return results.getSingleModelRow();
    }

    /**
     * Reassign this method to perform custom mapping of updated rows back to the Table's rows.  For example,
     * a Table might have a flattened view of orders and stops.  The layout used to edit it might have a completely
     * different view of the order/stop relationship (one-to-many, or may not even contain the stop join).  This method
     * allows the app-level code to determine how to create a ModelRow that's appropriate for the Table, given the
     * ModelRow that is edited.
     * @param updatedRow
     * @returns
     */
    public async doOnResolveRowEdit(updatedRow: ModelRow): Promise<ModelRow> {
        return updatedRow;
    }

    public get printableToggleEnabled(): boolean {
        return this._printableToggleEnabled == null ? true : this._printableToggleEnabled;
    }

    public set printableToggleEnabled(value: boolean) {
        this._printableToggleEnabled = value;
    }

    getKeyHandlers(): KeyHandler[] {
        /*
        let needsListener = false;
        if (this.addType === AddType.QUICK)
          needsListener = true;
        else {
          //assume for now that all rows will have the same components/key handlers,
          //so just check the first row for key handlers
          //hmm...the table rows may not be loaded yet when we first check this...
          const firstRow = ArrayUtil.getFirstElement(this.rows);
          if (firstRow != null) {
            firstRow.discoverKeyHandlers();
            if (ArrayUtil.isEmptyArray(firstRow.keyHandlers) !== true)
              needsListener = true;
          }
        }
        if (needsListener === true)
          return [{ key: "ALL", listener: (event) => this._handleKeys(event), element: this._table, scope: this._table }];
         */
        return [{ key: "ALL", listener: (event) => this._handleKeys(event), element: this._element, scope: this._element }];
    }

    private _handleKeys(event: KeyEvent) {
        //set the event's shouldAutomaticallyStopPropagation flag back to false; it should only be flipped to true if
        //at least one TableRow's key handler takes action on the event
        log.debug(this, "Attempting to handle key event %o within Table %o", event, this.id);
        event.shouldAutomaticallyStopPropagation = false;
        const activeElement = document.activeElement;
        //handle the key if we are within the quick add row or ir the quick add row is selected
        if (this.quickAddRow != null && DOMUtil.isOrContains(this.quickAddRow._element, activeElement)) {
            log.debug(this, "The quick info row will handle the key");
            this.quickAddRow.handleKey(event);
            return;
        }
        //if the focus is within a component in a particular table row handle the key for that row
        //note that when a row is selected and focus is not in a particular component, the active element is the table body element
        //or when a particular component has focus, its element will be the active element
        if (activeElement !== this._tbody) {
            for (const row of this.rows) {
                if (DOMUtil.isOrContains(row._element, activeElement)) {
                    log.debug(this, "Row %o will handle the key", row);
                    row.handleKey(event);
                    return;
                }
                const expansion = row.getExpansionComponent();
                if (expansion != null && expansion instanceof Panel && DOMUtil.isOrContains(expansion._element, activeElement)) {
                    log.debug(this, "The expansion area of row %o will handle the key", row);
                    expansion.fillKeyHandlerGroup();
                    expansion.getKeyHandlerGroup().handleKey(event);
                    return;
                }
            }
            this.toolsPanelHandleKey(event);
        }
        //if the active element is the table body itself, then rows may or may not be selected
        //if any rows are selected then handle the key for each selected row
        else {
            let keyHandledByRow = false;
            for (const row of this.rows) {
                if (row.selected) {
                    log.debug(this, "Selected row %o will attempt to handle the key", row);
                    const thisRowHandledKey = row.handleKey(event);
                    keyHandledByRow = keyHandledByRow || thisRowHandledKey;
                }
            }

            //if we still haven't handled the key, see if the header tools panel can handle it
            if (keyHandledByRow !== true)
                this.toolsPanelHandleKey(event);
        }
    }

    toolsPanelHandleKey(event: KeyEvent) {
        log.debug(this, "Giving the table tools panel an attempt to handle the key");
        this.toolsPanel.fillKeyHandlerGroup();
        const keyHandledByTools = this.toolsPanel.getKeyHandlerGroup().handleKey(event);
        if (keyHandledByTools === true)
            log.debug(this, "The table tools panel handled the key");
    }

    override _getBorderPropTarget() {
        return this._table;
    }

    public override doBeforeComponentEnlarge(rowsToIgnore: TableRow[]) {
        const currHeight = DOMUtil.getElementHeight(this._element);
        if (rowsToIgnore.includes(this.quickAddRow) !== true && this.quickAddRow?._element != null && this._table.contains(this.quickAddRow._element))
            this._table.removeChild(this.quickAddRow?._element);
        for (const row of this.rows) {
            if (row.expanded === true)
                this._tbody.removeChild(row.getExpansionElement());
            this._tbody.removeChild(row._element);
        }
        for (const row of rowsToIgnore) {
            this._tbody.appendChild(row._element);
            if (row.expanded === true)
                this._tbody.appendChild(row.getExpansionElement());
        }

        //disable things that would affect the dataset that the table is displaying (so that the dataset cannot change
        //while the user is only viewing one row from that dataset)
        // - search/filter fields, the advanced search button, really all the tools in the table header
        // - sort controls
        const tempTableProps: Partial<TableProps> = { minHeight: Math.max(200, currHeight) };
        const tempHeadingProps: Partial<PanelProps> = { height: DOMUtil.getElementHeight(this._heading._element) };
        this.applyTempState(tempTableProps);
        const tableHeadingState = this._heading.applyTempState(tempHeadingProps);
        tableHeadingState.addObject("components", [...this._heading.components]);
        this._heading.removeAll();
        this.forEverySortSelector((sortSelector: SortSelector) => sortSelector.enabled = false);
    }

    public override doAfterComponentsShrink() {
        for (let x = this._tbody.children.length - 1; x >= 0; x--) {
            this._tbody.removeChild(this._tbody.children[x]);
        }
        if (this.quickAddRow?._element != null && this._table.contains(this.quickAddRow._element) !== true)
            this._table.insertBefore(this.quickAddRow._element, this._tbody);
        for (const row of this.rows) {
            if (row.expanded === true)
                this._tbody.appendChild(row.getExpansionElement());
            this._tbody.appendChild(row._element);
        }
        //re-enable the things that affect the dataset that the table is displaying
        // - search/filter fields, the advanced search button, really all the tools in the table header
        // - sort controls
        this.revertTempState();
        const tableHeadingState = this._heading.revertTempState();
        this._heading.add(...tableHeadingState.getObject("components"));
        this.forEverySortSelector((sortSelector: SortSelector) => sortSelector.enabled = true);
    }

    private shrinkAllRows() {
        if (this.quickAddRow != null)
            this.quickAddRow.enlarged = false;
        for (const row of this.rows) {
            row.enlarged = false;
        }
    }

    public override discoverIncludedComponents(): Component[] {
        const cells = this.rows?.[0]?.cells ?? [];
        const headingCells = cells.map(cell => cell.col?.headingCell);
        const result =  [...cells, ...headingCells, this.expandComponent, this.toolsPanel.leftTools, this.toolsPanel.rightTools, this._additionalRowToolsPanel];
        return result.filter(c => c != null);
    }

    public get backgroundColor(): Color {
        return super.backgroundColor;
    }

    public set backgroundColor(value: Color) {
        super.backgroundColor = value;
        if (this.headingRow.backgroundColor == null)
            this.headingRow.backgroundColor = value;
    }

    public enableAllRowComponents(value: boolean) {
        this._allRows.forEach(row => row.enableAllComponents(value));
        this.getNonStandardRows().forEach(row => row.enableAllComponents(value));
    }
}

ComponentTypes.registerComponentType("table", Table.prototype.constructor);
