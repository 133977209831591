import { Alignment, ArrayUtil, DOMUtil } from "@mcleod/core";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Component } from "../../base/Component";
import { Image } from "../image/Image";
import { ManualModeEllipsisPopup } from "./ManualModeEllipsisPopup";
import { MouseEvent } from "../../events/MouseEvent";
import { Overlay } from "../../page/Overlay";
import { OverlayProps } from "../../page/OverlayProps";
import { Panel } from "../panel/Panel";
import { RowModeControlType } from "./RowModeControlType";
import { TableCell } from "./TableCell";
import { TableCellProps } from "./TableCellProps";
import { TableRow } from "./TableRow";
import { TableRowMode } from "./TableRowMode";
import { TableRowToolsViewer } from "./TableRowToolsViewer";
import { KeyHandler } from "../../events/KeyHandler";

export class TableRowToolsCell extends TableCell {
    private _toolsPanel: Panel;
    private deleteButton: Button;
    private slideoutButton: Button;
    private quickAddButton: Button;
    private dragHandlerImage: Image;
    private ellipsisButton: Button;
    private toolsViewer: TableRowToolsViewer;
    private manualModeEllipsisButton: Button;
    private manualModeEllipsisPopup: ManualModeEllipsisPopup;
    private manualSaveButton: Button;
    private manualCancelButton: Button;
    private overlay: Overlay;

    constructor(tableRow: TableRow, props?: Partial<TableCellProps>) {
        if (props == null)
            props = {};
        if (props.id == null)
            props.id = tableRow.table.id + "-" + tableRow.index + "-tools";
        super(props);
    }

    public get toolsPanel(): Panel {
        return this._toolsPanel;
    }

    fill() {
        this.hideToolsViewer();
        this.createToolsPanel();
        this.add(this.toolsPanel);
        let cellWidth: number;
        const tableRowMode = this.row.mode;
        if (this.table.rowModeControlType === RowModeControlType.MANUAL) {
            // Older style with standard edit/save/delete buttons
            this.fillManualModeTypeTools();
            cellWidth = 100;
        }
        else {
            // Display tools in hover over ellipsis button
            let components: Component[];
            if (tableRowMode === TableRowMode.QUICK_ADD)
                components = this.gatherQuickAddTools();
            else if (this.table.rowModeControlType === RowModeControlType.AUTO ||
                     this.table.rowModeControlType === RowModeControlType.ALWAYS_EDIT) {
                components = this.gatherAutoAlwaysEditTypeTools();
            }
            this.toolsPanel.removeAll();
            if (ArrayUtil.isEmptyArray(components) === false) {
                this.createEllipsisButton();
                this.toolsPanel.add(this.ellipsisButton);
                this.createToolsViewer(components);
            }
            cellWidth = 38;
        }
        this.width = cellWidth;
        this.table.toolsCellAdded(cellWidth);
        this.row.fillKeyHandlerGroup(true);
    }

    private createToolsPanel() {
        if (this.toolsPanel == null) {
            this._toolsPanel = Panel.createNoMarginNoPaddingPanel({
                id: "tableRowToolsInternal",
                rowBreakDefault: false
            });
        }
    }

    private createEllipsisButton() {
        if (this.ellipsisButton == null) {
            this.ellipsisButton = new Button({
                variant: ButtonVariant.round,
                rowBreak: false,
                borderWidth: 0,
                color: "subtle.darker",
                imageName: "ellipsis",
                imageRotation: 90
            });
            this.ellipsisButton.addMouseEnterListener(() => this.showToolsViewer());
        }
    }

    private createToolsViewer(tools: Component[]) {
        this.toolsViewer = new TableRowToolsViewer(tools, this.ellipsisButton, () => this.hideToolsViewer());
    }

    private showToolsViewer() {
        this.table.fireAdditionalRowToolsDisplayListeners(this.row)
        this.toolsViewer.syncTools();
        const domRect = this.toolsPanel._element.getBoundingClientRect();
        let overlayLeft = domRect.right;
        this.toolsViewer.doWhileDimensionsComputed(() => {
            const toolsViewerWidth = DOMUtil.getElementWidth(this.toolsViewer._element);
            const toolsViewerMarginRight = DOMUtil.getComputedStyleAsNumber("marginRight", this.toolsViewer._element);
            overlayLeft = overlayLeft - toolsViewerWidth + toolsViewerMarginRight;
        });
        const overlayProps: Partial<OverlayProps> = {
            style: {
                position: "absolute",
                left: DOMUtil.getSizeSpecifier(overlayLeft),
                top: DOMUtil.getSizeSpecifier(domRect.top)
            }
        };
        this.showOverlay(this.toolsViewer, overlayProps);
    }

    hideToolsViewer() {
        this.hideOverlay();
        this.toolsViewer?.reset();
    }

    private gatherQuickAddTools(): Component[] {
        const result: Component[] = [];
        if (this.table.editLayout != null) {
            this.createSlideoutButton();
            result.push(this.slideoutButton);
        }
        this.createQuickAddButton();
        result.push(this.quickAddButton);
        return result;
    }

    private createQuickAddButton() {
        if (this.quickAddButton == null) {
            this.quickAddButton = new Button({
                themeKey: "tableRow.quickAddButton",
                rowBreak: false,
                id: "quickAddButton",
                focusable: true
            });
            this.quickAddButton.addClickListener(() => {
                this.hideToolsViewer();
                this.row.persistQuickAddRow();
            });
        }
    }

    private gatherAutoAlwaysEditTypeTools(): Component[] {
        const result: Component[] = [];
        result.push(...this.row.additionalToolButtons);
        const tableRowMode = this.row.mode;
        if (tableRowMode !== TableRowMode.SEARCH && this.table.sequenceField != null) {
            if (this.row.draggable === true)
                result.push(this.createDragHandlerImage());
        }
        if (this.row.allowEdit === true && this.table.editLayout != null) {
            this.createSlideoutButton()
            result.push(this.slideoutButton);
        }
        if (tableRowMode !== TableRowMode.SEARCH && this.row.allowDelete) {
            this.createDeleteButton();
            result.push(this.deleteButton);
        }
        return result;
    }

    private createSlideoutButton() {
        if (this.slideoutButton == null) {
            this.slideoutButton = new Button({
                themeKey: "tableRow.slideoutButton",
                rowBreak: false,
                focusable: true
            });
            this.slideoutButton.addClickListener(() => {
                this.hideToolsViewer();
                this.row.showEditLayout()
            });
        }
    }

    /**
     * This convenience method provides access to the slideout (edit) button, in case it needs to be moved or otherwise
     * adjusted.  This method should not be needed often...if you find yourself here ask why you need to alter/move
     * the button.
     */
    public getSlideoutButton(): Button {
        return this.slideoutButton;
    }

    /**
     * Creates the drag handler image for the row.  This button, when present, is shown within the tools cell.
     */
    private createDragHandlerImage(): Image {
        if (this.dragHandlerImage == null) {
            this.dragHandlerImage = new Image({
                themeKey: "tableRow.dragHandler",
                rowBreak: false
            });
            this.dragHandlerImage.addMouseDownListener((event: MouseEvent) => {
                this.hideToolsViewer();
                this.dragHandlerImage.revertTempState();
                this.row._dragHandlerMouseDownListener(event);
            });
        }
        return this.dragHandlerImage;
    }

    /**
     * This convenience method provides access to the drag handler image, in case it needs to be moved or otherwise
     * adjusted.  This method should not be needed often...if you find yourself here ask why you need to alter/move
     * the image.
     */
    public getDragHandlerImage(): Image {
        return this.dragHandlerImage;
    }

    public hideDragHandle() {
        if (this.dragHandlerImage != null)
            this.dragHandlerImage.visible = false;
    }

    public showDragHandle() {
        if (this.dragHandlerImage != null)
            this.dragHandlerImage.visible = true;
    }

    private createDeleteButton() {
        if (this.deleteButton == null) {
            this.deleteButton = new Button({ themeKey: "tableRow.deleteButton", rowBreak: false, focusable: true });
            this.deleteButton.addClickListener(() => {
                this.row.deleteRow();
            });
        }
    }

    /**
     * This convenience method provides access to the delete button, in case it needs to be moved or otherwise
     * adjusted.  This method should not be needed often...if you find yourself here ask why you need to alter/move
     * the button.
     */
    public getDeleteButton(): Button {
        return this.deleteButton;
    }

    private fillManualModeTypeTools() {
        const tableRowMode = this.row.mode;
        if (tableRowMode === TableRowMode.ADD || tableRowMode === TableRowMode.UPDATE) {
            this.toolsPanel.remove(this.manualModeEllipsisButton);
            this.createManualSaveButton();
            if (this.toolsPanel.contains(this.manualSaveButton) === false)
                this.toolsPanel.add(this.manualSaveButton);
            this.createManualCancelButton();
            if (this.toolsPanel.contains(this.manualCancelButton) === false)
                this.toolsPanel.add(this.manualCancelButton);
        }
        else if (tableRowMode === TableRowMode.NONE) {
            this.toolsPanel.remove(this.manualSaveButton);
            this.toolsPanel.remove(this.manualCancelButton);
            this.createManualModeEllipsisButton();
            if (this.toolsPanel.contains(this.manualModeEllipsisButton) === false)
                this.toolsPanel.add(this.manualModeEllipsisButton);
        }
    }

    private createManualSaveButton() {
        if (this.manualSaveButton == null) {
            this.manualSaveButton = new Button({
                margin: 0,
                width: 78,
                focusable: false,
                caption: "Save",
                marginBottom: 2,
                color: "primary"
            });
            this.manualSaveButton.addClickListener(() => {
                this.row.saveChanges();
            });
        }
    }

    private createManualCancelButton() {
        if (this.manualCancelButton == null) {
            this.manualCancelButton = new Button({
                margin: 0,
                width: 78,
                focusable: false,
                caption: "Cancel"
            });
            this.manualCancelButton.addClickListener(() => {
                this.row.cancelEdit();
            });
        }
    }

    private createManualModeEllipsisButton() {
        if (this.manualModeEllipsisButton == null) {
            this.manualModeEllipsisButton = new Button({
                variant: ButtonVariant.round,
                focusable: false,
                margin: 2,
                rowBreak: false,
                color: "subtle.darker",
                imageName: "ellipsis"
            });
            this.manualModeEllipsisButton.addClickListener(() => {
                this.toggleManualModeEllipsisPopup();
            });
        }
    }

    private toggleManualModeEllipsisPopup() {
        if (this.manualModeEllipsisPopup == null) {
            this.manualModeEllipsisPopup = new ManualModeEllipsisPopup(this.row);
            Overlay.alignToAnchor(this.manualModeEllipsisPopup, this.manualModeEllipsisButton, Alignment.BOTTOM);
            const overlayProps: Partial<OverlayProps> = { onClose: () => this.manualModeEllipsisPopup = null };
            this.showOverlay(this.manualModeEllipsisPopup, overlayProps);
        }
        else
            this.hideOverlay();
    }

    private showOverlay(popup: Component, overlayProps: Partial<OverlayProps> = {}) {
        this.overlay = Overlay.showInOverlay(popup, overlayProps);
    }

    hideOverlay() {
        if (this.overlay != null)
            Overlay.hideOverlay(this.overlay);
    }

    override getKeyHandlers(): KeyHandler[] {
        const result = this.keyHandlers != null ? [...this.keyHandlers] : [];
        if (this.toolsPanel != null)
            result.push(...this.toolsPanel.getChildrenKeyHandlersRecursive());
        return result;
    }

    public static create(tableRow: TableRow, props?: Partial<TableCellProps>): TableRowToolsCell {
        return new TableRowToolsCell(tableRow, props);
    }
}
