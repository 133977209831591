import { StringUtil } from "@mcleod/core";
import { Component, DesignerInterface, DesignerStyles, HorizontalSpacer, Layout } from "@mcleod/components";
import { LayoutDesignerTab } from "./LayoutDesignerTab";
import { McLeodTailor } from "./custom/McLeodTailor";

enum Error {
    ID_NULL = "Component id is not defined",
    DUPLICATE = "Duplicate component id",
    RESERVED_PROPERTY = "Reserved property name"
}

enum Warning {
    INVALID_FORMAT = "Auto-generated id"
}

export interface ComponentIdValidationResult {
    component: Component,
    error: Error,
    warning: Warning;
}

export class ComponentIdValidator {
    private layout: Layout;
    private designer: DesignerInterface;
    private reservedProperties: Set<string>;

    constructor(designerTab: LayoutDesignerTab) {
        this.designer = designerTab.designer;
        this.layout = designerTab.designerPanel;
    }

    validateComponentId(id: string): IdValidationResult {
        const resultMap = new Map<string, ComponentIdValidationResult[]>();
        this.forEveryComponent(this.layout, (child: Component) => {
            if (child.id == id)
                this.addOrInsertIntoMap(child, resultMap);
        });
        return new IdValidationResult(this.designer, resultMap);
    }

    validateActiveTab(): IdValidationResult {
        const resultMap = new Map<string, ComponentIdValidationResult[]>();
        this.forEveryComponent(this.layout, component => this.addOrInsertIntoMap(component, resultMap));
        return new IdValidationResult(this.designer, resultMap);
    }

    private addOrInsertIntoMap(component: Component, componentMap: Map<string, ComponentIdValidationResult[]>) {
        if (this.shouldValidate(component)) {
            const isNullId = this.isNullId(component.id);
            const compsWithId = componentMap.get(component.id);
            if (compsWithId == null) {
                componentMap.set(component.id, [this.createValidationResult(component, false)]);
            }
            else {
                if (!isNullId && compsWithId.length == 1)
                    compsWithId[0].error = Error.DUPLICATE;
                compsWithId.push(this.createValidationResult(component, !isNullId));
            }
        }
    }

    private createValidationResult(component: Component, isDuplicate: boolean): ComponentIdValidationResult {
        let error = null;
        let warning = null;
        if (this.isNullId(component.id)) {
            error = Error.ID_NULL;
        } else if (this.getReservedProperties().has(component.id)) {
            error = Error.RESERVED_PROPERTY;
        } else if (isDuplicate) {
            error = Error.DUPLICATE;
        } else if (this.includeWarning(component) && component.hasDefaultDesignerId()) {
            warning = Warning.INVALID_FORMAT;
        }

        return { component, error, warning }
    }

    private getReservedProperties(): Set<string> {
        if (this.reservedProperties) {
            return this.reservedProperties;
        }

        this.reservedProperties = new Set<string>();
        let current = Layout.prototype;

        while (current && current !== Object.prototype) {
            Object.getOwnPropertyNames(current).forEach(prop => {
                this.reservedProperties.add(prop);
            });
            current = Object.getPrototypeOf(current);
        }

        return this.reservedProperties;
    }


    private includeWarning(component: Component) {
        return !(component instanceof HorizontalSpacer);
    }

    shouldValidate(comp: Component) {
        return comp.deserialized === true && comp != this.layout;
    }

    isNullId(id: string) {
        return StringUtil.isEmptyString(id) || "null" === id || "undefined" === id;
    }

    private forEveryComponent(component: Component, callback: (component: Component) => void) {
        if (component == null)
            return;
        callback(component);
        if (component instanceof Layout && component.isNested === true)
            return;
        component.discoverIncludedComponents()?.forEach(child => {
            this.forEveryComponent(child, callback);
        });
    }

    updateUI(result?: IdValidationResult, showWarnings?: boolean) {
        if (result == null) {
            this.removeAddedStylesAndTooltips();
        } else {
            result.errors?.forEach(compResult => {
                compResult.component.setClassIncluded(DesignerStyles.designerComponentIdError, true);
                this.setTooltipCallback(compResult);
            })

            if (showWarnings !== false) {
                result.warnings?.forEach(compResult => {
                    compResult.component.setClassIncluded(DesignerStyles.designerComponentIdWarning, true);
                    this.setTooltipCallback(compResult);
                })
            }
        }
    }

    private removeAddedStylesAndTooltips() {
        this.forEveryComponent(this.layout, component => {
            component["_allowTooltipInDesigner"] = false;
            component.tooltipCallback = null;
            component.setClassIncluded(DesignerStyles.designerComponentIdError, false);
            component.setClassIncluded(DesignerStyles.designerComponentIdWarning, false);
        })
    }

    private setTooltipCallback(result: ComponentIdValidationResult) {
        if (result.error || result.warning) {
            const component = result.component;
            component["_allowTooltipInDesigner"] = true;
            let tooltip = (result.error ?? result.warning).toString();
            if (result.error !== Error.ID_NULL)
                tooltip += ": " + component.id;
            component.tooltipCallback = () => {
                return component.showTooltip(tooltip,
                    { pointerColor: result.error ? "error" : "warning" },
                    { themeKey: "quickInfo", borderLeftColor: result.error ? "error" : "warning" });
            };
        }
    }
}

export class IdValidationResult {
    private _errors: ComponentIdValidationResult[] = [];
    private _warnings: ComponentIdValidationResult[] = [];
    private designer: DesignerInterface;

    constructor(designer: DesignerInterface, resultMap: Map<string, ComponentIdValidationResult[]>) {
        this.designer = designer;
        for (const resultsById of resultMap.values()) {
            resultsById.forEach(compResult => this.populateErrorsAndWarnings(compResult))
        }
    }

    get errors(): ComponentIdValidationResult[] { return this._errors; }
    get warnings(): ComponentIdValidationResult[] { return this._warnings; }

    getUniqueErrors(): Set<Error> { return new Set([...this.errors.map(compResult => compResult.error)]) }

    hasErrors(): boolean { return this.errors.length > 0; }
    hasWarnings(): boolean { return this.warnings.length > 0; }

    private populateErrorsAndWarnings(compResult: ComponentIdValidationResult) {
        const component = compResult.component;
        component.setClassIncluded(DesignerStyles.designerComponentIdError, false);
        component.setClassIncluded(DesignerStyles.designerComponentIdWarning, false);
        component["_allowTooltipInDesigner"] = false;
        component.tooltipCallback = null;

        if (compResult.error && this.includeErrorOrWarning(compResult.component))
            this.errors.push(compResult);
        else if (compResult.warning && this.includeErrorOrWarning(compResult.component))
            this.warnings.push(compResult);
    }

    private includeErrorOrWarning(component: Component) {
        if (this.designer instanceof McLeodTailor)
            return component.isCustom;
        return true;
    }
}
