import { HorizontalAlignment, VerticalAlignment } from "@mcleod/core";
import { Label } from "../..";
import { LabelProps } from "./LabelProps";
import { LabelShapeDefinitions } from "./LabelShapeDefinitions";
import { LabelShapeType } from "./LabelShapeType";

const commonLabelProps: Partial<LabelProps> = {
    align: HorizontalAlignment.CENTER,
    verticalAlign: VerticalAlignment.CENTER,
    fontSize: "small",
    minWidth: 24,
    fontBold: true,
    nullDisplayValue: "hide"
};

export interface LabelShapeProps {
    labelProps: Partial<LabelProps>,
    styleProps: Partial<CSSStyleDeclaration>
}

export class LabelShape {
    public readonly labelProps: Partial<LabelProps>;
    public readonly styleProps: Partial<CSSStyleDeclaration>;

    constructor(props: LabelShapeProps) {
        this.labelProps = { ...commonLabelProps, ...props.labelProps };
        this.styleProps = props.styleProps;
    }

    applyProps(label: Label) {
        this.updateLabelProps(label, true);
    }

    removeProps(label: Label) {
        this.updateLabelProps(label, false);
    }

    syncPropIfNeeded(label: Label, key: string) {
        if (this.labelProps[key] != null) {
            this.updateSingleLabelProp(label, key, this.labelProps[key], true);
        }
    }

    private updateLabelProps(label: Label, apply: boolean) {
        Object.entries(this.labelProps).forEach(([key, value]) => {
            this.updateSingleLabelProp(label, key, value, apply);
        });

        this.updateStyleProps(label, apply);
    }

    private updateSingleLabelProp(label: Label, key: string, value: unknown, apply: boolean) {
        if (apply) {
            label[key] = value;
        } else if (label[key] === value) {
            label[key] = null;
        }
        label._designer?.redisplayProp(key, apply ? value : null);
    }

    private updateStyleProps(label: Label, apply: boolean) {
        Object.entries(this.styleProps).forEach(([key, value]) => {
            if (apply) {
                label._element.style[key] = value;
            } else if (label._element.style[key] === value) {
                label._element.style[key] = null;
            }
        });
    }

    public static get(shapeType: LabelShapeType): LabelShape {
        if(shapeType == null)
            return null;

        const props = LabelShapeDefinitions[shapeType];
        if (!props) {
            throw new Error(`No shape definition found for type: ${shapeType}`);
        }

        return new LabelShape({...props});
    }
}
