import { Button, ButtonVariant, Component, ContextMenu, CrudDecorator, DataSourceMode, DesignableObject, DesignerStyles, Event, KeyHandler, Layout, PermDefinitionConstants, ResourceFileProps, TabCloseEvent, Table, TableColumn } from "@mcleod/components";
import { Collection, Keys } from "@mcleod/core";
import { AbstractLayoutDesigner, DesignerProps, UIDesignerKeyHandlers } from "@mcleod/designer";
import { RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { PanelPermsGrant } from "./PanelPermsGrant";
import { PermissionsDesignerTab } from "./PermissionsDesignerTab";
import { PermsContainer } from "./PermsContainer";


export class PermissionsDesigner extends AbstractLayoutDesigner<PermissionsDesignerTab> implements PermsContainer {
    panelGrants: PanelPermsGrant;
    crudDecorator: CrudDecorator;
    tabsetTools = [];
    constructor(props?: Partial<DesignerProps>) {
        super({
            ...props,
            wrap: false,
            endpointPath:"layout",
            localStorageManager: null,
            title: "Permissions Editor"
        })
    }

    override async onLoad(): Promise<void> {
        this.panelGrants = Layout.getLayout("common/permissions/PanelPermsGrant") as PanelPermsGrant;
        this.panelGrants.permsContainer = this;
        await this.panelGrants.ensureLoaded();
        await super.onLoad();
        this.tabset.style.overflow = "auto";
        this.add(this.tabset, this.panelGrants);

        this.tabset.addBeforeTabCloseListener((event: TabCloseEvent) => {
            if (this.tabset.getComponentCount() === 1 && this.crudDecorator == null)
                event.preventDefault();
        })
    }

    override afterTabClosed() {
        if (this.tabset.getComponentCount() === 0)
            this.crudDecorator?.slideOut();
    }

    private get permsByItem(): Collection<RowPermsSecureItem>  {
        return this.getActiveTab().permsByItem;
    }

    selectComponent(object: DesignableObject): void {
        if (object === this.firstSelected)
            return;
        this.handleComponentSelection(object, false);
        const comp = this.firstSelected;
        if (comp === this.getActiveLayout()) {
            const perms = Object.values(this.permsByItem).filter(row => row.isEntireScreen());
            this.panelGrants.setItemBeingEdited(this.getActiveTab().path, RowPermsSecureItem.ENTIRE_SCREEN, [PermDefinitionConstants.ENTIRE_SCREEN], perms);
        } else if (comp == null || comp.id == null) {
            this.panelGrants.setItemBeingEdited(null, null, null, undefined);
        }
        else {
            const perms = [];
            for (const row of Object.values(this.permsByItem))
                if (row.get("item_name") === comp.id)
                    perms.push(row);
            this.panelGrants.setItemBeingEdited(this.getActiveTab().path, comp.id, comp.getPermissionsTypes(), perms);

        }
    }

    setPropsForDeserialization(componentType: string, props: any) {
        if (componentType === "layout")
            return { ...props, applyFieldLevelPermissions: false, _designer: this };
        return props ?? {};
    }

    override createTab(props: ResourceFileProps): PermissionsDesignerTab {
        return new PermissionsDesignerTab(this, props);
    }

    override addNewTab(): PermissionsDesignerTab {
        return null;
    }

    override addDesignerContainerProperties(container: Component, minWidth: number, minHeight: number, width?: number): void {
        if (container?._element == null)
            return;
        container.setClassIncluded(DesignerStyles.designerContainer);
        if (container.width === undefined) {
            if (container.minWidth === undefined)
                container.element.style.minWidth = minWidth + "px";
            if (width != null)
                container.element.style.width = width + "px";
        }
        if (container.minHeight === undefined)
            container.element.style.minHeight = minHeight + "px";
    }

    permsChanged(row: RowPermsSecureItem, deleted: boolean) {
        if (row != null) {
            this.firstSelected?.setClassIncluded(DesignerStyles.permed, !deleted);
            this.firstSelected?.setClassIncluded(DesignerStyles.permedSelected, !deleted);
            this.firstSelected?.setClassIncluded(DesignerStyles.designerSelected, deleted);
            const key = row.get("item_name") + ":" + row.get("security_type");
            if (deleted)
                delete this.permsByItem[key];
            else
                this.permsByItem[key] = row;
            Layout.clearCachedLayout(this.getActiveTab().path);
        }
    }

    override async doAfterTabDefintionLoaded(tab: PermissionsDesignerTab, def: any) {
        try {
            await this.deserializeLayout(tab);
            tab.addPermedClassToComponents();
        } catch (error) {
            tab.addErrorLabel(error.toString());
        }
    }

    override getKeyHandlers(): KeyHandler[] {
        const result = [];
        result.push({
            key: Keys.P, modifiers: { ctrlKey: true }, listener: () => {
                if (this.selectedComponents != null && this.selectedComponents.length === 1 && !(this.firstSelected.parent instanceof PermissionsDesignerTab))
                    this.selectComponent(this.firstSelected.parent);
            }
        });

        result.push(...UIDesignerKeyHandlers.getDesignerSelectSiblingKeyListeners(this));

        return result
     }

     doAfterTabAdded(tab: PermissionsDesignerTab): void {
         if (this.crudDecorator == null && this.tabset.getComponentCount() === 1) {
             tab.closeable = false;
         }
     }

    get allowsDrop(): boolean {  return false; }

    redisplayProp(propName: string, propValue: any): void {}

    addDragAndDropListeners(component: Component): void {}

    filterProps(props: any, selectedComponent: Component): void {}

    disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void { }

    addTableColumn(table: Table): TableColumn { return null; }

    canModifyProp(propName: string, component: Component): boolean { return false; }

    componentDropped(comp: Component) {}

    applyChangeToSelectedComponents(data: any, newValue: any): void {}

    displayDataSourceTools(): void {}

    addEventHandlerFunction(component: DesignableObject, eventPropName: string): void {}

    doAfterPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean) {}
    doBeforePropChanged(component: Component, propName: string) {}


    public static async slideUp(path: string, doAfterSlideOut: () => void): Promise<CrudDecorator> {
        const layout = new PermissionsDesigner();
        const crudPanel = new CrudDecorator({
            layout: layout,
            mode: DataSourceMode.NONE,
            headerProps: { visible: false },
            pseudoNavigateUrl: window.location.pathname,
            doAfterSlideOut
        });
        layout.open = path;
        layout.crudDecorator = crudPanel;

        layout.addLayoutLoadListener(() => {
            const closeButton = new Button({
                imageName: "x",
                variant: ButtonVariant.round,
                imageHeight: 28,
                imageWidth: 28,
                cancel: true,
                padding: 0,
                color: "primary.reverse",
                onClick: () => crudPanel.slideOut(),
                style: { position: "absolute",  top: "0px", right: "0px" }
            });

            layout.panelGrants.panelHeading._element.appendChild(closeButton._element);
        })

        await crudPanel.slideIn(
            { speed: 200 }, true,
            { closeOnClickOff: false, greyedBackground: true },
            { paddingLeft: 24, paddingRight: 24, paddingTop: 0, paddingBottom: 0 }
        );
        return crudPanel;
    }

    showComponentContextMenu(component: Component, event: Event): void {
        // The resize listener prevents the Overlay from covering the panelGrants.
        // this allows users to still interact with panelGrants while the Overlay is open.

        const updateOverlayWidth = () => {
            const newWidth = this.getActiveTab()?.bounds?.right;
            if (overlay?.overlayDiv && newWidth)
                overlay.overlayDiv.style.width = newWidth + "px";
        };

        const resizeListener = () => updateOverlayWidth();
        this.panelGrants.addResizeListener(resizeListener);

        const menu = new ContextMenu({sourceComponent: component, designer: this});
        this.getActiveTab().addPermedClassToComponents(menu.menuLabels);
        menu.addUnmountListener(() => {
            this.panelGrants.removeResizeListener(resizeListener);
            this.selectComponent(component);
        });

        const overlay = menu.showInOverlay(event);
        if (overlay != null) {
            updateOverlayWidth();
            this.selectComponent(menu.menuLabels?.[0]);
        }
    }
}
