import { DataSource, Label, Layout, LayoutHeader, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutUserGroupList extends Layout {
    labelGroupMembership: Label;
    labelUsersInGroup: Label;
    labelUsersNotInGroup: Label;
    layoutheaderUserGroups: LayoutHeader;
    panelGroups: Panel;
    panelMembership: Panel;
    panelUsersInGroup: Panel;
    panelUsersNotInGroup: Panel;
    sourceUserGroup: DataSource;
    sourceUsersInGroup: DataSource;
    sourceUsersNotInGroup: DataSource;
    tableGroups: Table;
    tableUsersInGroup: Table;
    tableUsersNotInGroup: Table;
}
