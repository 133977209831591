import { AbstractObjectRegistry } from "@mcleod/core";
import { ModelDataChangeFilter } from "./ModelDataChangeFilter";

export class ModelDataChangeFilterRegistry extends AbstractObjectRegistry<ModelDataChangeFilter> {
    private static registry: ModelDataChangeFilterRegistry;

    public static get(): ModelDataChangeFilterRegistry {
        this.initialize();
        return this.registry;
    }

    private static initialize() {
        if (this.registry == null)
            this.registry = new ModelDataChangeFilterRegistry();
    }
}
