import { ClickEvent, SlideoutDecorator, Toast,  ChangeEvent,  DataSourceExecutionEvent, Snackbar } from "@mcleod/components";
import { AutogenLayoutUserList } from "./autogen/AutogenLayoutUserList";
import { CommonDialogs } from "@mcleod/common";
import { Api, StringUtil } from "@mcleod/core";

export class UserList extends AutogenLayoutUserList {
    private getUserTypeFilter(): string {
        let result: string = "";
        if (this.switchShowEnd.checked) {
            result += "U|";
        }
        if (this.switchShowMcLeod.checked) {
            result += "MCLEOD|";
        }
        if (this.switchShowService.checked) {
            result += "SERVICE|";
        }
        if (result.endsWith("|")) {
            result = result.substring(0, result.length - 1);
        }
        if (result.length === 0) {
            result = "X";
        }
        return result;
    }

    private getFilter(): any {
        return { user_type: this.getUserTypeFilter() };
    }

    /** This is an event handler for multiple switches */
    filterChanged(event: ChangeEvent) {
        this.sourceUsers.search(this.getFilter());
    }

    /** This is an event handler for the beforeExecution event of sourceUsers. */
    sourceUsersBeforeExecution(event: DataSourceExecutionEvent) {
        event.filter = this.getFilter();
    }

    /** This is an event handler for the onClick event of buttonAddServiceUser. */
    buttonAddServiceUserOnClick(event: ClickEvent) {
        CommonDialogs.showInputDialog("Enter the id to give to this new service user (usually 'service-' followed by the scac)", 
            "Create Service User", { text: "service-"}).then(async(userId) => {
            if (StringUtil.isEmptyString(userId)) {
                return;
            }
            this.buttonAddServiceUser.busy = true;
            try {
                const response = await Api.post("auth/new-service-user", { id: userId });
                this.sourceUsers.search(this.getFilter());
                Snackbar.showSnackbar("Service user has been created.");
            } finally {
                this.buttonAddServiceUser.busy = false;
            }
        });
    }
}
