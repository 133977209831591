export enum DisplayType {
    STRING = "string",
    INTEGER = "int",
    DECIMAL = "decimal",
    FLOAT = "float",
    DATE = "date",
    DATETIME = "datetime",
    DATERANGE = "daterange",
    TIME = "time",
    CURRENCY = "currency",
    WEIGHT = "weight",
    DISTANCE = "distance",
    LENGTH = "length",
    TEMPERATURE = "temperature",
    PERCENTAGE = "percentage",
    LOCATION = "location",
    EMAIL = "email",
    PHONE = "phone",
    CITY = "city",
    STATE = "state",
    LINK = "link",
    COLOR = "color"
}

const RIGHT_ALIGNED_DISPLAY_TYPES = [DisplayType.CURRENCY, DisplayType.FLOAT, DisplayType.INTEGER, DisplayType.LENGTH, DisplayType.TEMPERATURE, DisplayType.WEIGHT, DisplayType.DECIMAL, DisplayType.PERCENTAGE];

const DATE_DISPLAY_TYPES = [DisplayType.DATE, DisplayType.DATETIME, DisplayType.TIME];

export function isRightAlignedDisplayType(displayType: DisplayType) {
    return RIGHT_ALIGNED_DISPLAY_TYPES.includes(displayType);
}

export function displaysDateOrTime(displayType: DisplayType, includeDateRange = true) {
    return DATE_DISPLAY_TYPES.includes(displayType) || (includeDateRange && displayType === DisplayType.DATERANGE);
}

export function isDisplayTypeNumeric(type) {
    return (type === DisplayType.INTEGER
        || type === DisplayType.DECIMAL
        || type === DisplayType.FLOAT
        || type === DisplayType.CURRENCY
        || type === DisplayType.WEIGHT
        || type === DisplayType.DISTANCE
        || type === DisplayType.PERCENTAGE
        || type === DisplayType.LENGTH
        || type === DisplayType.TEMPERATURE);
}
