import { Button, DataSource, Layout, Switch, Table } from "@mcleod/components";

export abstract class AutogenLayoutUserList extends Layout {
    buttonAddServiceUser: Button;
    sourceUsers: DataSource;
    switchShowEnd: Switch;
    switchShowMcLeod: Switch;
    switchShowService: Switch;
    tableUsers: Table;
}
