import { AbstractSettings, AbstractSettingsDef, SettingsRegistry } from "@mcleod/core";

export interface TenantCompanySettingsDef extends AbstractSettingsDef {
    //unauth
    browser_title: string;
    header_bg_color: string;
    header_logo: string; // base64
    login_additional_text: string;
    login_welcome_message: string;
    text_color: string;
}

export class TenantCompanySettings extends AbstractSettings {
    constructor() {
        super("company_settings");
    }

    public static getSingleton(): TenantCompanySettings {
        return SettingsRegistry.get().getObject("company_settings") as TenantCompanySettings;
    }

    public static get(): TenantCompanySettingsDef {
        return TenantCompanySettings.getSingleton().values as TenantCompanySettingsDef;
    }

    public override get values(): TenantCompanySettingsDef {
        return super.values as TenantCompanySettingsDef;
    }

    protected getValuesInternal(): TenantCompanySettingsDef {
        return this._values as TenantCompanySettingsDef;
    }
}
