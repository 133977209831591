import { ServerFilter } from "@mcleod/core";
import { ModelDataChangeFilter } from "./ModelDataChangeFilter";
import { ModelDataChangeFilterData } from "./ModelDataChangeFilterData";
import { MODEL_DATA_CHANGE_DISCARD_RESULT, ModelDataChangeSingleFilterResult } from "./ModelDataChangeFilterResult";

export class ModelDataChangeServerFilter extends ModelDataChangeFilter {
    override get type(): string {
        return "SERVER";
    }

    override evaluate(data: ModelDataChangeFilterData): ModelDataChangeSingleFilterResult {
        // Return 'discard' here, so that we can throw the message away (and not treat it as a delete).
        return ServerFilter.evaluate(data.values) ? true : MODEL_DATA_CHANGE_DISCARD_RESULT;
    }
}
