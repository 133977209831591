import { DOMUtil } from "@mcleod/core";
import { ComponentTypes } from "../../base/ComponentTypes";
import { MainPageAccessor } from "../../base/MainPageAccessor";
import { Chart, ChartType } from "./Chart";
import { ChartPropDefinitions } from "./ChartProps";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";

const log = DesignableObjectLogManager.getLogger("components/chart/GaugeChart");
/**
 * A GaugeChart is a ChartJS doughnut chart where the:
 *     -> arc of the doughnut has a certain start position, specified by the ChartJS 'rotation' option (the degrees at which the arc starts),
 *     -> length of the arc of the doughnut can be set via the ChartJS 'circumference' option (the sweep angle in degrees)
 *     -> the cutout (the hole in the doughnut) can be a certain size, set as a percentage of the whole circle, which makes the arc of the doughnut thinner/thicker
 */
export class GaugeChart extends Chart {
    public startDegrees: number; //the start angle, in degrees, of where the gauge should begin
    public lengthDegrees: number; //the length, in degrees, of the gauge (how far around the circle it goes)
    public thickness: number; //specifies how thick the gauge line should be.  this is indicated as a percentage number.
    private _centerText: string;

    constructor(props?: Partial<ChartPropDefinitions>) {
        super({ id: "gaugeChartCanvas", ...props });
    }

    public override get chartType(): ChartType {
        return "doughnut";
    }

    public override set chartType(value: ChartType) {
        super.chartType = value;
    }

    public override get showGridLines(): boolean {
        return false;
    }

    public override set showGridLines(value: boolean) {
        if (value === true)
            throw Error("Grid lines are not displayed on a gauge component");
        super.showGridLines = value;
    }

    public get centerText(): string {
        return this._centerText;
    }

    public set centerText(value: string) {
        this._centerText = value;
        // this.renderCenterText();
    }

    public get scaleProps(): any {
        return { x: { display: false }, y: { display: false } };
    }

    public override renderChart() {
        super.renderChart();
        //this.renderCenterText();
    }

    private renderCenterText() {
        const canvas = this._element as HTMLCanvasElement;
        const ctx = canvas.getContext("2d");
        ctx.font = "30px Comic Sans MS";
        ctx.fillStyle = "red";
        ctx.textAlign = "center";
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        if (DOMUtil.isOrContains(MainPageAccessor.mainPage._element, this._element) !== true) {
            // canvas.style.position = "absolute";
            // canvas.style.left = DOMUtil.getSizeSpecifier(-9999);
            // document.body.appendChild(canvas);
            // canvasWidth = DOMUtil.getElementWidth(canvas);
            // canvasHeight = DOMUtil.getElementHeight(canvas);
            // document.body.removeChild(canvas);
            // canvas.style.position = '';
            // canvas.style.left = '';
        }
        ctx.fillText(this.centerText, canvasWidth / 2, canvasHeight / 2);
    }

    override get chartOptions(): any {
        const options: any = super.chartOptions;
        options.rotation = this.startDegrees;
        options.circumference = this.lengthDegrees;
        options.cutout = (100 - this.thickness) + "%";
        this.setAnimationOptions(options);
        return options;
    }

    private setAnimationOptions(options: any) {
        if (this.animate === true) {
            options.animation = {
                onComplete: (animation) => this.draw(animation) //https://www.chartjs.org/docs/latest/configuration/animations.html
            }
        }
        else
            options.animation = false;
    }

    //the onComplete callback that calls this method isn't being called when we expect...this needs more investigation
    private draw(animationContext) {
        if (animationContext.initial)
            log.debug(this, "Initial animation finished");
        else {
            log.debug(this, "animation finished");
            this.renderCenterText();
        }
    }

    override get serializationName() {
        return "gaugechart";
    }

    override get properName(): string {
        return "Gauge Chart";
    }
}

ComponentTypes.registerComponentType("gaugechart", GaugeChart.prototype.constructor);


/* delete this later...this is a basic set of ChartJS options that produces a doughnut chart with a certain length arc (used for reference)
var options = {
  type: 'doughnut',
  data: {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [{
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
    }]
  },
  options: {
    rotation: 270, // start angle in degrees
    circumference: 180, // sweep angle in degrees
  }
}
*/
