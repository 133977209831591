import {
    Component, ComponentCreator, ComponentFactory, DataSourceMode, Layout, LayoutProps, Panel, PanelProps,
    Textbox, TextboxProps, TooltipOptions
} from "@mcleod/components";
import { DisplayType, HorizontalAlignment, ModelRow } from "@mcleod/core";
import { KeyValuePair } from "./KeyValuePair";
import { PanelKeyValuePairs } from "./PanelKeyValuePairs";

export class CommonTooltips {
    /**
     * This method displays a toolitp that contains key/value pairs.
     *
     * @param heading The content to display above the key value pairs
     * @param keyValuePairs
     * @returns The Panel that contains the resulting tooltip content.
     */
    public static createKeyValueTooltip(keyValuePairs: KeyValuePair[], header?: ComponentCreator, footer?: ComponentCreator): Panel {
        const panel = new Panel({ fillRow: true, margin: 0, padding: 0 });
        if (header != null) {
            const component = ComponentFactory.createCommon(header);
            panel.add(component);
        }
        const keyValuePanel = new PanelKeyValuePairs({ margin: 0, padding: 0 }, keyValuePairs);
        panel.add(keyValuePanel);
        if (footer != null) {
            const component = ComponentFactory.createCommon(footer);
            panel.add(component);
        }
        return panel;
    }

    public static setTooltipFromLayoutCallback(component: Component, idOrData: string | ModelRow<any> | ModelRow<any>[],
        layoutName: string, layoutProps?: Partial<LayoutProps>, options?: Partial<TooltipOptions>,
        searchFilter?: any, tooltipPanelProps?: Partial<PanelProps>) {
        if (idOrData == null && searchFilter == null) return;
        component.tooltipCallback = (baseTooltip, originatingEvent) => {
            const layout = Layout.getLayout(layoutName, layoutProps);
            layout.addLayoutLoadListener(async event => {
                if (idOrData instanceof ModelRow) {
                    layout.mainDataSource.data = [idOrData];
                    layout.mainDataSource.rowIndex = 0;
                }
                else if (Array.isArray(idOrData)) {
                    layout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, idOrData);
                }
                else {
                    if (searchFilter == null)
                        searchFilter = { id: idOrData };
                    await layout.mainDataSource.search(searchFilter);
                }
                component.tooltipInstance = component.showTooltip(layout, options,
                    { themeKey: "quickInfo", color: null, ...tooltipPanelProps });
            });
            return null;
        };
    }

    public static setTooltipCallback(component: Component, tooltip: ComponentCreator, options?: Partial<TooltipOptions>, checkEmpty: boolean = true) {
        if (component == null || (checkEmpty && component.isEmpty()))
            return;
        component.tooltipCallback = (baseTooltip, originatingEvent) => {
            return component.showTooltip(tooltip, options, { themeKey: "quickInfo", color: null });
        };
    }

    public static setPanelTooltipCallback(component: Component, textboxProps: () => Partial<TextboxProps>[], options?: Partial<TooltipOptions>, isBold: boolean = true, checkEmpty: boolean = false) {
        CommonTooltips.setTooltipCallback(component, () => {
            const panel = new Panel({ padding: 0, margin: 0 });
            textboxProps?.().forEach(props => {
                const textbox = new Textbox({ ...props, printable: true, fontBold: isBold });
                if (textbox.displayType === DisplayType.CURRENCY)
                    textbox.printableLabel.align = HorizontalAlignment.LEFT;
                panel.add(textbox);
            });
            component.tooltip = panel;
            return component.tooltip as Panel;
        }, options, checkEmpty);
    }

    public static setTooltipCallbackFromPanel(component: Component, panel: Panel, options?: Partial<TooltipOptions>, tooltipPanelProps?: Partial<PanelProps>) {
        if (component == null)
            return;
        component.tooltipCallback = (baseTooltip, originatingEvent) => {
            return component.showTooltip(panel, options, { themeKey: "quickInfo", color: null, ...tooltipPanelProps });
        };
    }
}
