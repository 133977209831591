import { DesignerInterface, PanelProps, PropType } from "@mcleod/components";
import { ColorSelector } from "./ColorSelector";
import { HelpLinkEditor } from "./HelpLinkEditor";
import { ImageSelector } from "./ImageSelector";
import { MultiLineEditor } from "./MultiLineEditor";
import { PanelOpenFieldList } from "./PanelOpenFieldList";
import { PanelOpenLayout } from "./PanelOpenLayout";
import { PanelOpenModel } from "./PanelOpenModel";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";
import { StringArrayEditor } from "./StringArrayEditor";
import { TailorExtensionEditor } from "./TailorExtensionEditor";
import { DropdownItemEditor } from "./DropdownItemEditor";
import { ActionPermissionSelector } from "./ActionPermissionSelector";
import { ContextMenuEditor } from "./ContextMenuEditor";
import { TableExtraSortFieldsEditor } from "./TableExtraSortFieldsEditor";

export class SpecialtyEditorDef {
    private _propType: PropType;
    private _textboxEnabled: boolean;
    private _buttonImage: string;

    constructor(propType: PropType, textboxEnabled: boolean = false, buttonImage: string = "ellipsis") {
        this._propType = propType;
        this._textboxEnabled = textboxEnabled;
        this._buttonImage = buttonImage;
    }

    public get propType(): PropType {
        return this._propType;
    }

    public get textboxEnabled(): boolean {
        return this._textboxEnabled;
    }

    public get buttonImage(): string {
        return this._buttonImage;
    }

    construct(textboxText: string, editingDesigner: DesignerInterface, panelProps?: Partial<PanelProps>):
        SpecialtyPropertyEditor {
        switch (this.propType) {
            case PropType.helpLink:
                return new HelpLinkEditor(textboxText, panelProps);
            case PropType.layout:
                return new PanelOpenLayout(textboxText, panelProps);
            case PropType.fieldList:
                return new PanelOpenFieldList(textboxText, panelProps);
            case PropType.endpoint:
                return new PanelOpenModel(textboxText, panelProps);
            case PropType.tailorExtension:
                return new TailorExtensionEditor(textboxText, panelProps);
            case PropType.image:
                return new ImageSelector(panelProps);
            case PropType.color:
                return new ColorSelector(panelProps);
            case PropType.stringArray:
                return new StringArrayEditor(textboxText, panelProps);
            case PropType.multilineString:
                return new MultiLineEditor(textboxText, panelProps);
            case PropType.dropdownItem:
                return new DropdownItemEditor(textboxText, editingDesigner, panelProps);
            case PropType.actionPermission:
                return new ActionPermissionSelector(textboxText, panelProps);
            case PropType.contextMenu:
                return new ContextMenuEditor(editingDesigner, panelProps);
            case PropType.tableExtraSortFields: {
                const fieldsInTableDataSource =
                    TableExtraSortFieldsEditor.gatherFieldsForTableDataSource(editingDesigner);
                return new TableExtraSortFieldsEditor(textboxText, panelProps, fieldsInTableDataSource);
            }
            default:
                throw new Error("Unexpected editor type");
        }
    }
}
