import { Component, DataSource, DesignableObject, DesignerInterface, DesignerStyles, Panel, PropType } from "@mcleod/components";
import { LogManager, StringUtil } from "@mcleod/core";
import { PropertiesTable } from "..";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { ActionChangeComponentProperty } from "./actions/ActionChangeComponentProperty";
import { PropertiesTablePanel } from "./PropertiesTablePanel";
import { ContextMenuDesigner } from "./ContextMenuDesigner";

const log = LogManager.getLogger("designer.ui.UIDesignerUtil");

export class UIDesignerUtil {

    public static designerHandleComponentSelection(selectedComponents: Component[], component: Component, add: boolean, propsPanel: PropertiesTablePanel) {
        const tableProps = propsPanel?.tableProps;
        if (add && selectedComponents.indexOf(component) >= 0) {
            selectedComponents.splice(selectedComponents.indexOf(component), 1);
            UIDesignerUtil.setSelectedClassIncluded(component, false);
            tableProps?.displayProperties(selectedComponents);
            propsPanel?.syncHeaderText(component)
            return selectedComponents;
        }

        for (let i = 0; !add && i < selectedComponents.length; i++)
            UIDesignerUtil.setSelectedClassIncluded(selectedComponents[i], false);
        if (component == null)
            selectedComponents.length = 0;
        else {
            if (add)
                selectedComponents.push(component);
            else {
                selectedComponents.length = 0;
                selectedComponents.push(component);
            }
            if (UIDesignerUtil.markAsSelected(component) === true)
                UIDesignerUtil.setSelectedClassIncluded(component);
        }

        tableProps?.displayProperties(selectedComponents);
        propsPanel?.syncHeaderText(component)
        return selectedComponents;
    }

    static setSelectedClassIncluded(component: Component, included: boolean = true) {
        const selectedClass = component._element.classList.contains(DesignerStyles.permed) ? DesignerStyles.permedSelected : DesignerStyles.designerSelected;
        component.setClassIncluded(selectedClass, included);
    }

    static markAsSelected(component: DesignableObject): boolean {
        return !(component instanceof Panel && component.id === "designerTabHeading");
    }

    static designerApplyChangeToSelectedComponents(selectedComponents, activeTab, data, newValue, tableProps: PropertiesTable) {
        if (data.prop.type === PropType.number) {
            newValue = (newValue == null || newValue.length === 0) ? null : parseInt(newValue);
        } else if (data.prop.type === PropType.string) {
            newValue = StringUtil.isEmptyString(newValue) ? null : newValue;
        }

        for (let i = 0; selectedComponents != null && i < selectedComponents.length; i++) {
            const designableObject = selectedComponents[i].designerDataSource ?? selectedComponents[i];
            activeTab.designer.doBeforePropChanged(designableObject, data.prop_name);

            if (designableObject instanceof DataSource) {
                if (data.prop_name === "parentDataSource")
                    designableObject.parentDataSource = activeTab.dataSources[newValue];
                else {
                    const currValue = designableObject[data.prop_name];
                    if (newValue !== currValue)
                        designableObject[data.prop_name] = newValue;
                }
            } else {
                if (data.prop_name === "dataSource" || data.prop_name === "mainDataSource") {
                    // need to come up with a better way to reference other components on the screen
                    designableObject[data.prop_name] = activeTab.dataSources[newValue];
                } else {
                    designableObject[data.prop_name] = newValue;
                }
                designableObject.propChangedInDesigner(data.prop_name, data.value, designableObject[data.prop_name]);
            }
        }
        tableProps.syncRowCaptionStyle(data.prop_name);

        data.prop.affectsProps?.forEach(affect => {
            tableProps.redisplayProp(affect, selectedComponents[0][affect])
            tableProps.syncRowCaptionStyle(affect);
        });
    }

    static async designerCheckForPropChange(designer: DesignerInterface, propertyName: string, newValue: any,
        oldValue: any) {
        for (const selectedComponent of designer.selectedComponents) {
            if (oldValue != newValue) {
                if (propertyName === "dataSource") {
                    newValue = UIDesignerUtil.getDataSourceFromActiveTab(designer, newValue);
                    oldValue = UIDesignerUtil.getDataSourceFromActiveTab(designer, oldValue);
                }
                designer.executeAction(new ActionChangeComponentProperty(selectedComponent, propertyName, newValue, oldValue))
            }
        }
    }

    private static getDataSourceFromActiveTab(designer: DesignerInterface, value: string | DataSource): DataSource {
        if (value instanceof DataSource)
            return value;
        return designer.getActiveTab().dataSources[value];
    }

    public static getPropDisplayValue(designer: DesignerInterface, component: DesignableObject, propName: string, value: any) {
        if (value != null) {
            const propItems = UIDesignerUtil.resolvePropertyItems(designer, component, propName)
            if (propItems) {
                const caption = propItems.find(item => item.value === value)?.caption;
                if (caption)
                    return caption;
            }
        }
        return value ?? "not set";
    }

    private static resolvePropertyItems(designer: DesignerInterface, component: DesignableObject, propName: string): any[] {
        let items = component.getPropertyDefinitions()[propName]?.dropdownProps?.items;
        if (typeof items === "function")
            items = items(designer);
        return items
    }

    public static doAfterPropChanged(designer: AbstractUIDesigner | ContextMenuDesigner, component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean, propsSeen: string[] = []) {
        log.debug("Invoked doAfterPropChanged for property: %o", propName);
        redisplayProp = redisplayProp && component === designer.firstSelected;
        const affectsProps: string[] = component.getPropertyDefinitions()[propName]?.affectsProps;

        if (propsSeen.includes(propName)) {
            log.debug("Property %o already seen in doAfterPropChanged", propName);
            return;
        }
        propsSeen.push(propName);

        // sync the tableProps.row data and captions if component is the first selected
        if (component === designer.firstSelected) {
            const data = designer.tableProps.getRowData(propName);
            if (data)
                data.value = newValue;

            if (redisplayProp === true)
                designer.redisplayProp(propName, newValue);

            designer.tableProps.syncRowCaptionStyle(propName);
        }

        designer.syncPropChanged(component, propName, oldValue, newValue, redisplayProp);
        log.debug("Property %o affects other properties: %o", propName, affectsProps);
        affectsProps?.forEach(affect => designer.doAfterPropChanged(component, affect, null, component[affect], true, propsSeen));
    }

}
