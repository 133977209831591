import { DOMUtil } from "@mcleod/core";
import { Component } from "../../base/Component";
import { DesignerInterface } from "../../base/DesignerInterface";
import { ContextMenuCreationEvent } from "../../events/ContextMenuCreationEvent";
import { Event } from "../../events/Event";
import { Overlay } from "../../page/Overlay";
import { OverlayProps } from "../../page/OverlayProps";
import { List, ListItemType } from "../list/List";
import { ListItem } from "../list/ListItem";
import { Panel } from "../panel/Panel";
import { ContextMenuItemCreator } from "./ContextMenuItemCreator";
import { ContextMenuLabel } from "./ContextMenuLabel";
import { ContextMenuItemsProvider, ContextMenuLabelProps } from "./ContextMenuLabelProps";

export interface ContextMenuProps {
    sourceComponent: Component;
    itemProvider?: ContextMenuItemsProvider;
    designer?: DesignerInterface;
}

export class ContextMenu extends List implements ContextMenuProps {
    private overlay: Overlay;
    private _sourceComponent: Component;
    private shouldDisplay = true;
    private wasDisplayed = false;

    private hideOverlay = () => {
        if (this.overlay != null) {
            Overlay.hideOverlay(this.overlay);
            this.overlay = null;
            this.removeListeners();
        }
    };

    constructor(props: ContextMenuProps) {
        super({
            _designer: props?.designer,
            backgroundColor: "#F9F9F9",
            borderShadow: true,
            borderRadius: 4,
            borderWidth: 1,
            minWidth: 200,
            maxWidth: 325,
            borderColor: "subtle.light",
            padding: 0
        });
        this.items = ContextMenuItemCreator.createListItems(props);
        this.selectedColor = "default";
        this.selectedBackgroundColor = "primary.lightest";
        this.shouldDisplay = this.hasVisibleContextMenuLabels();
        this.sourceComponent = props.sourceComponent;
    }

    get sourceComponent(): Component {
        return this._sourceComponent;
    }

    set sourceComponent(value: Component) {
        if (value !== this._sourceComponent) {
            this._sourceComponent = value;
            this.fireCreationListeners();
        }
    }

    fireCreationListeners() {
       if (this.sourceComponent == null)
            return;
        const creationEvent = new ContextMenuCreationEvent(this.sourceComponent, this);
        this.sourceComponent.fireContextMenuCreationListeners(creationEvent);
        this.shouldDisplay = !creationEvent.defaultPrevented && this.hasVisibleContextMenuLabels();
    }

    get menuLabels(): ContextMenuLabel[] {
        return this.getItemsRenderedComponents().filter((item: any): item is ContextMenuLabel => item instanceof ContextMenuLabel);
    }

    getVisibleMenuLabels(): ContextMenuLabel[] {
        return this.menuLabels.filter(item => item.visible);
    }

    hasVisibleContextMenuLabels(): boolean {
        return this.menuLabels.some(item => item.visible);
    }

    showInOverlay(event: Event, overlayProps?: Partial<OverlayProps>): Overlay {
        if (this.shouldDisplay) {
            if (this.wasDisplayed) {
                this.selectedIndexes = [];
                this._allItems.forEach(item => this.addListenersToItem(item));
            }
            if (Event.resolveDomEvent(event)?.type === "contextmenu")
                event.consume();
            this.hidePopups();
            this.doBeforeDisplay();
            this.addMountListener(() => this.addListeners());
            this.addUnmountListener(() => this.removeListeners());
            this.style.visibility = "hidden";
            const resolvedOverlayProps = this.resolveOverlayProps(event, overlayProps);
            this.overlay = Overlay.showInOverlay(this, resolvedOverlayProps);
            this.repositionIfOffScreen(
                DOMUtil.convertStyleAttrToNumber(resolvedOverlayProps?.style?.top),
                DOMUtil.convertStyleAttrToNumber(resolvedOverlayProps?.style?.left));

            this.style.visibility = "visible";
            this.wasDisplayed = true;
        }
        return this.overlay;
    }

    private doBeforeDisplay() {
        this.menuLabels.forEach(label => label.applyDefaultOverrideProps());
    }

    private resolveOverlayProps(event: Event, overlayProps: Partial<OverlayProps>): Partial<OverlayProps> {
        const resolvedOverlayProps = {
            sourceComponent: this.sourceComponent,
            ...overlayProps,
            closeHandler: () => {
                this.hideOverlay();
                return Promise.resolve();
            }
        };

        if (this._designer != null) {
            resolvedOverlayProps.centered = true;
            resolvedOverlayProps.greyedBackground = true;
        } else if (event?.domEvent instanceof MouseEvent) {
            resolvedOverlayProps.style = {
                position: "absolute",
                top:  DOMUtil.getSizeSpecifier(event.domEvent.clientY),
                left: DOMUtil.getSizeSpecifier(event.domEvent.clientX),
                ...overlayProps?.style
            };
        }
        return resolvedOverlayProps;
    }

    private addListeners(): void {
        this.addSelectionListener(this.hideOverlay);
        window.addEventListener("blur", this.hideOverlay);
        window.addEventListener("resize", this.hideOverlay);
        if (this._designer && this.sourceComponent) {
            this.sourceComponent.addUnmountListener(this.hideOverlay);
        }
    }

    private removeListeners(): void {
        this.removeSelectionListener(this.hideOverlay);
        window.removeEventListener("blur", this.hideOverlay);
        window.removeEventListener("resize", this.hideOverlay);
    }

    private hidePopups() {
        this.sourceComponent?.hideTooltip();
        if (this.sourceComponent instanceof Panel) {
            this.sourceComponent.dismissAllPopups();
        }
    }

    // reposition the context menu if it is off screen. Initially I was going to use the OnScreen class, but it didn't work as expected
    // and involved creating a tempory anchor element based on the mouse click coordinates.
    private repositionIfOffScreen(top: number, left :number) {
        const menuElement = this.overlay?.getOverlayContent()?._element;
        if (this._designer != null || menuElement == null) {
            return;
        }
        const menuRect = menuElement.getBoundingClientRect();

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const padding = 5;

        const possiblePositions = [
            { top: top + padding, left: left + padding },
            { top: top + padding, left: left - menuRect.width - padding },
            { top: top - menuRect.height - padding, left: left + padding },
            { top: top - menuRect.height - padding, left: left - menuRect.width - padding }
        ];

        let chosenPosition = null;

        for (const position of possiblePositions) {
            const { top, left } = position;

            const fitsVertically = top >= 0 && top + menuRect.height <= viewportHeight;
            const fitsHorizontally = left >= 0 && left + menuRect.width <= viewportWidth;

            if (fitsVertically && fitsHorizontally) {
                chosenPosition = { top, left };
                break;
            }
        }

        // if no position fits, use the default with clamping to the viewport
        if (!chosenPosition) {
            chosenPosition = {
                top: Math.min(top + padding, viewportHeight - menuRect.height - padding),
                left: Math.min(left + padding, viewportWidth - menuRect.width - padding)
            };
        }

        menuElement.style.top = `${chosenPosition.top}px`;
        menuElement.style.left = `${chosenPosition.left}px`;
    }

    get items(): ListItem[] {
        return super.items;
    }

    set items(itemInputArray: ListItemType[]) {
        this._allItems = [];
        if (itemInputArray != null) {
            for (const itemInput of itemInputArray) {
                const item = this.resolveSingleItem(itemInput);
                this._allItems.push(item);
                if (item.renderedComponent instanceof ContextMenuLabel) {
                    const separator = item.renderedComponent.separatorListItem;
                    if (separator != null)
                        this._allItems.push(separator);
                }
            }
        }
        this.displayItems();
    }

    updateMenuLabels(
        itemPropsMap: Record<string, Partial<ContextMenuLabelProps> | (() => Partial<ContextMenuLabelProps>)>,
        includeIdPrefix = false
    ) {
        this.menuLabels.forEach(label => {
            const props = itemPropsMap[label.getId(includeIdPrefix)];

            const resolvedProps = typeof props === "function" ? props() : props;

            if (resolvedProps) {
                label.setProps(resolvedProps);
            }
        });
    }
}
