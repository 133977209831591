import { LogicResponsibilityFiltering } from "@mcleod/core";
import { ModelDataChangeFilter } from "./ModelDataChangeFilter";
import { ModelDataChangeFilterData } from "./ModelDataChangeFilterData";
import { ModelDataChangeSingleFilterResult } from "./ModelDataChangeFilterResult";

export class ModelDataChangeResponsibilityFilter extends ModelDataChangeFilter {
    override get type(): string {
        return "RESPONSIBILITY";
    }

    override evaluate(data: ModelDataChangeFilterData): ModelDataChangeSingleFilterResult {
        return LogicResponsibilityFiltering.userHasResponsibility(data.values);
    }
}
