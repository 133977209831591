import { Collection, DOMUtil, HorizontalAlignment, ModelRow, Navigation, VerticalAlignment } from "@mcleod/core";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { CrudDecoratorCloseEvent, CrudDecoratorCloseListener } from "../../events/CrudDecoratorCloseEvent";
import { DataHeaderCloseAction } from "./DataHeaderCloseAction";
import { DataHeaderPropDefinitions, DataHeaderProps } from "./DataHeaderProps";
import { DataSource, DataSourceMode } from "../../databinding/DataSource";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";
import { Label } from "../label/Label";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Panel } from "../panel/Panel";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { refreshKeyListeners } from "../../events/GlobalKeyListener";
import { ClickEvent, ContextMenu, HorizontalSpacer, SaveButton, Toast, ToastOptions } from "../.."; // Direct import causes circular reference
import { ScreenStack } from "../panel/ScreenStack";
import { SearchButton } from "../../page/SearchButton";
import { LayoutHeader } from "../layoutheader/LayoutHeader";

const log = DesignableObjectLogManager.getLogger("components/DataHeader");
const _afterCloseListenerDef: ListenerListDef = { listName: "_afterCloseListenerDef" };
const _beforeCloseListenerDef: ListenerListDef = { listName: "_beforeCloseListenerDef" };

export class DataHeader extends LayoutHeader implements DataHeaderProps {
    private _allowRowDelete: (row: ModelRow) => boolean;
    private panelTools: Panel;
    private saveButton: SaveButton;
    private deleteButton: Button;
    private closeButton: Button;
    private searchButton: SearchButton;
    private _showSave: boolean;
    private _showClose: boolean;
    private _showSaveAndClose: boolean;
    private _showExecute: boolean;
    private _showDelete: boolean;
    public onExecute: (row: ModelRow) => void;
    private promptToSave: boolean;
    public navigateOnClose: (closeAction: DataHeaderCloseAction) => Promise<void>;
    public afterClosePath: string;
    private _ellipsisButton: Button;

    protected override initComponents() {
        this.closeButton = this.createCloseButton();
        this.panelTools = new Panel({
            align: HorizontalAlignment.RIGHT,
            verticalAlign: VerticalAlignment.CENTER,
            paddingRight: 0
        });

        this.add(
            this.panelTitle,
            this.addlPanelLeft,
            new HorizontalSpacer(),
            this.addlPanelRight,
            this.panelTools,
            this.addlPanelBottom
        );
    }

    public override get addlPanelRight(): Panel {
        const panel = super.addlPanelRight;
        panel.rowBreak = false;
        return panel;
    }

    public override get title(): string {
        return super.title || this.getLayoutTitle(this.dataSource?.mode);
    }

    public override set title(value: string) {
        super.title = value;
    }

    protected override get designerTitleFiller(): string {
        return "[Dynamic Title]";
    }

    public get dataSource(): DataSource {
        return super.dataSource;
    }

    public set dataSource(value: DataSource) {
        super.dataSource = value;
        if (this.saveButton != null)
            this.saveButton.dataSource = value;
    }

    public override syncTitle(mode?: DataSourceMode) {
        mode = mode || this?.layout?.mainDataSource?.mode;
        super.syncTitle(mode);
        this.syncTools(mode);
    }

    private syncTools(mode: DataSourceMode) {
        this.panelTools.removeAll();
        if (this.showExecute !== false) {
            if (mode === DataSourceMode.SEARCH)
                this.configureToolsForSearch();
            else
                this.configureToolsForAddOrUpdate();
        }
        if (this.showClose === true)
            this.panelTools.add(this.closeButton);
        refreshKeyListeners(this);
    }

    private configureToolsForSearch() {
        if (this.saveButton != null && this.saveButton.primaryButton != null)
            this.saveButton.primaryButton.default = true;
        this.buildAddlComponents();
        this.searchButton = new SearchButton({
            default: true,
            backgroundColor: "primary",
            color: "primary.reverse",
            width: 100,
            marginRight: 0,
            rowBreak: false
        });
        this.searchButton.addClickListener(() => {
            const values = this.layout.mainDataSource.getSearchFilter(true);
            if (this.layout.mainDataSource.validate(true)) {
                if (this.onExecute == null)
                    this.dataSource.search(values);
                else
                    this.onExecute(values);
                this.internalNavigateOnClose(DataHeaderCloseAction.EXECUTE);
            }
        });
        this.panelTools.add(this.searchButton);
        this.syncContextMenu();
    }

    private configureToolsForAddOrUpdate() {
        if (this.searchButton != null)
            this.searchButton.default = false;
        this.buildAddlComponents();
        this.buildSaveButton();
        this.syncContextMenu();
        this.buildDeleteButton();
        this.updateComponentsForDesigner();
    }

    private buildSaveButton() {
        this.saveButton?.removeFromHasChangedComponents();
        const additionalSaveOptions: Label[] = this.saveButton?.addlActions;
        this.saveButton = new SaveButton({
            id: "dhSaveCloseButton",
            backgroundColor: "primary",
            color: "primary.reverse",
            minWidth: 128,
            borderWidth: 0,
            allowSave: this.showSave,
            allowSaveAndClose: this.showSaveAndClose,
            rowBreak: false,
            dataSource: this.dataSource,
        });
        this.setSaveButtonAddlActions(additionalSaveOptions);
        this.saveButton.primaryButton.default = true;
        if (this.onExecute != null)
            this.saveButton.onSave = (postedRow: ModelRow) => this.onExecute(postedRow);
        this.saveButton.onClose = () => {
            this.internalNavigateOnClose(DataHeaderCloseAction.EXECUTE);
        };
        this.panelTools.add(this.saveButton);
    }

    private buildDeleteButton() {
        if (this.showDelete === true &&
            this.dataSource?.mode === DataSourceMode.UPDATE &&
            this.internalAllowRowDelete() === true) {
            this.deleteButton = new Button({
                id: "dhDeleteButton",
                imageProps: {
                    color: "error",
                    name: "delete",
                    height: 32,
                    width: 32
                },
                variant: ButtonVariant.round,
                borderWidth: 0,
                rowBreak: false
            });
            this.deleteButton.addClickListener(() => this.deleteRecord());
            this.panelTools.add(this.deleteButton);
        }
    }

    private internalAllowRowDelete(): boolean {
        const row = this.dataSource?.activeRow;
        if (row == null)
            return false;
        if (this._allowRowDelete != null)
            return this._allowRowDelete(row);
        return true;
    }

    /**
     * Set this variable within a layout's doAfterDataHeaderSetup() to allow each row that is loaded to be tested to see
     * if it can be deleted.
     */
    public set allowRowDelete(value: (row: ModelRow) => boolean) {
        this._allowRowDelete = value;
    }

    private async deleteRecord() {
        const message = "Are you sure you want to delete this record?";
        const confirmation = await ReflectiveDialogs.showDestructive(message, "Delete Confirmation");
        if (confirmation === true) {
            await this.dataSource.activeRow?.delete();
            const toastOptions: Partial<ToastOptions> = { targetPanel: ScreenStack.getPreviousToastTarget() };
            Toast.showSuccessToast("Record Deleted", "The record was successfully deleted.", "delete", toastOptions);
            this.internalNavigateOnClose(DataHeaderCloseAction.DELETE);
        }
    }

    private createCloseButton(): Button {
        return new Button({
            id: "dhCloseButton",
            cancel: true,
            rowBreak: false,
            borderWidth: 0,
            padding: 2,
            variant: ButtonVariant.round,
            imageProps: { name: "x", height: 32, width: 32, color: "primary" },
            onClick: () => this.close()
        });
    }

    private async close() {
        if (this.promptToSave === true &&
            (this.initialDataSourceMode === DataSourceMode.ADD || this.initialDataSourceMode === DataSourceMode.UPDATE) &&
            this.dataSource.hasChanged(true)) {
                const message = "Unsaved changes are present.  Do you wish to leave without saving changes?";
                const dialogProps = {
                    yesButtonCaption: "Leave",
                    noButtonCaption: "Stay"
                };
                const leave = await ReflectiveDialogs.showYesNo(message, "Leave Without Saving?", dialogProps);
                if (leave !== true)
                    return;
        }
        this.internalNavigateOnClose(DataHeaderCloseAction.CANCEL)
    }

    public promptToSaveChanges() {
        this.promptToSave = true;
    }

    private async internalNavigateOnClose(closeAction: DataHeaderCloseAction) {
        const beforeCloseEvent = new CrudDecoratorCloseEvent(this, this.initialDataSourceMode, closeAction, true);
        this.fireBeforeCloseListener(beforeCloseEvent);
        if (beforeCloseEvent.defaultPrevented === true) {
            log.debug(this, "Close of DataHeader cancelled: %o", this);
            return;
        }
        if (this.navigateOnClose != null)
            await this.navigateOnClose(closeAction);
        else {
            const destinationPath = this.afterClosePath ?? "";
            await Navigation.navigateTo(destinationPath);
        }
        const afterCloseEvent = new CrudDecoratorCloseEvent(this, this.initialDataSourceMode, closeAction, false);
        this.fireAfterCloseListener(afterCloseEvent);
    }

    public get showClose(): boolean {
        return this._showClose == null ? DataHeaderPropDefinitions.getDefinitions().showClose.defaultValue : this._showClose;
    }

    public set showClose(value: boolean) {
        this._showClose = value;
        this.syncTools(this.layout?.mainDataSource?.mode);
    }

    public get showSave(): boolean {
        return this._showSave == null ? DataHeaderPropDefinitions.getDefinitions().showSave.defaultValue : this._showSave;
    }

    public set showSave(value: boolean) {
        this._showSave = value;
        this.syncTools(this.layout?.mainDataSource?.mode);
    }

    public get showSaveAndClose(): boolean {
        return this._showSaveAndClose == null ? DataHeaderPropDefinitions.getDefinitions().showSaveAndClose.defaultValue : this._showSaveAndClose;
    }

    public set showSaveAndClose(value: boolean) {
        this._showSaveAndClose = value;
        this.syncTools(this.layout?.mainDataSource?.mode);
    }

    public get showExecute(): boolean {
        return this._showExecute == null ? DataHeaderPropDefinitions.getDefinitions().showExecute.defaultValue : this._showExecute;
    }

    public set showExecute(value: boolean) {
        this._showExecute = value;
        this.syncTools(this.layout?.mainDataSource?.mode);
    }

    public get showDelete(): boolean {
        return this._showDelete ?? DataHeaderPropDefinitions.getDefinitions().showDelete.defaultValue;
    }

    public set showDelete(value: boolean) {
        this._showDelete = value;
        this.syncTools(this.layout?.mainDataSource?.mode);
    }

    private syncContextMenu() {
        if (this._designer != null && this._designer.showComponentContextMenu == null)
            return;

        const contextMenu = new ContextMenu({ sourceComponent: this });
        if (!contextMenu.hasVisibleContextMenuLabels())
            return;

        this._ellipsisButton = new Button({
            focusable: false,
            padding: 6,
            height: DOMUtil.getElementHeight(this.saveButton?._element),
            borderWidth: 0,
            rowBreak: false,
            color: "primary",
            imageProps: {
                name: "ellipsis",
                height: 18,
                width: 18
            }
        });
        this.panelTools.add(this._ellipsisButton);

        this._ellipsisButton.addClickListener((event: ClickEvent) => {
            if (this._designer != null) {
                this._designer.showComponentContextMenu?.(this, event);
            } else {
                contextMenu.showInOverlay(event)
            }
        });
    }

    protected override determinePropertyDefaultValue(prop: ComponentPropDefinition) {
        if (prop.name === "title")
            return this.getLayoutTitle(DataSourceMode.NONE);
        return super.determinePropertyDefaultValue(prop);
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return DataHeaderPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "dataheader";
    }

    override get properName(): string {
        return "Data Header";
    }

    setSaveButtonAddlActions(labels: Label[]) {
        if (this.saveButton != null) {
            log.debug(this, "Adding additional actions to save button: %o", labels);
            this.saveButton.addlActions = labels;
        } else {
            log.debug(this, "Not adding additional actions to save button, as button is configured to not be present %o", labels);
        }
    }

    protected override updateComponentsForDesigner() {
        super.updateComponentsForDesigner();

        const interactionEnabled = this._designer == null;
        if (this.saveButton != null)
            this.saveButton._interactionEnabled = interactionEnabled;
        if (this.closeButton != null)
            this.closeButton._interactionEnabled = interactionEnabled;
        if (this.deleteButton != null)
            this.deleteButton._interactionEnabled = interactionEnabled;
    }

    set doBeforeClose(value: CrudDecoratorCloseListener) {
        this.addBeforeCloseListener(value);
    }

    addBeforeCloseListener(value: CrudDecoratorCloseListener) {
        this.addEventListener(_beforeCloseListenerDef, value);
    }

    removeBeforeCloseListener(value: CrudDecoratorCloseListener) {
        this.removeEventListener(_beforeCloseListenerDef, value);
    }

    fireBeforeCloseListener(closeEvent: CrudDecoratorCloseEvent) {
        this.fireListeners(_beforeCloseListenerDef, closeEvent);
    }

    set doAfterClose(value: CrudDecoratorCloseListener) {
        this.addAfterCloseListener(value);
    }

    addAfterCloseListener(value: CrudDecoratorCloseListener) {
        this.addEventListener(_afterCloseListenerDef, value);
    }

    removeAfterCloseListener(value: CrudDecoratorCloseListener) {
        this.removeEventListener(_afterCloseListenerDef, value);
    }

    fireAfterCloseListener(closeEvent: CrudDecoratorCloseEvent) {
        this.fireListeners(_afterCloseListenerDef, closeEvent);
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "afterClose": { ..._afterCloseListenerDef },
            "beforeClose": { ..._beforeCloseListenerDef },
        };
    }
}

ComponentTypes.registerComponentType("dataheader", DataHeader.prototype.constructor);
