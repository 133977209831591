
import { LabelShapeProps } from "./LabelShape";
import { LabelShapeType } from "./LabelShapeType";


export const LabelShapeDefinitions: Record<LabelShapeType, LabelShapeProps> = {
    [LabelShapeType.RIBBON]: {
        labelProps: {
            paddingRight: 10,
            backgroundColor: "warning",
            color: "warning.reverse",
        },
        styleProps: {
            clipPath: `polygon(100% 100%, 0% 100%, 0% 0%, 100% 0%, calc(100% - 6px) 50%, 100% 100%)`,
        },
    },
    [LabelShapeType.STARBURST]: {
        labelProps: {
            backgroundColor: "success",
            color: "success.reverse",
            padding: 4,
            minHeight: 24,
            maxWidth: 100,
            maxHeight: 100,
        },
        styleProps: {
            aspectRatio: "1 / 1",
            clipPath: `polygon(100% 50%, 89.94% 62.98%, 90.45% 79.39%, 74.69% 83.98%, 65.45% 97.55%, 50% 92%, 34.55% 97.55%, 25.31% 83.98%, 9.55% 79.39%, 10.06% 62.98%, 0% 50%, 10.06% 37.02%, 9.55% 20.61%, 25.31% 16.02%, 34.55% 2.45%, 50% 8%, 65.45% 2.45%, 74.69% 16.02%, 90.45% 20.61%, 89.94% 37.02%)`,
        },
    },
};
