import {
    Button, ButtonVariant, ClearButtonVisible, Component, CrudDecorator, DecoratorRouter, KeyEvent, KeyHandler,
    KeyModifiers, Label, LabelProps, Layout, Overlay, Panel, PanelProps, showTooltip, SlideoutDecorator, Textbox, Tree,
    TreeNode, TreeNodeProps
} from "@mcleod/components";
import {
    Alignment, Api, ArrayUtil, AuthToken, GeneralSettings, HorizontalAlignment, Keys, LogManager, MenuItem, Navigation,
    ObjectUtil, ShiftedNumbers, StringUtil
} from "@mcleod/core";
import { CommonDialogs } from "./CommonDialogs";
import { RowUserMenuFavorite } from "./models/ModelUserMenuFavorite";
import { PageHeader } from "./pageheader/PageHeader";

interface MenuTreeNodeProps extends TreeNodeProps {
    navItem: any;
}

class MenuTreeNode extends TreeNode {
    navItem: MenuItem;

    constructor(props: Partial<MenuTreeNodeProps>) {
        super(props);
    }
}

const log = LogManager.getLogger("common/MainMenu");

export class MainMenu extends Panel {
    //used for the toolbox portion of the menu
    panelToolbox: Panel;
    panelToolboxImages: Panel;
    //used for the favorites portion of the menu
    panelFavorites: Panel;
    favoritesEmptyLabel: Label;
    treeFavoritesMenu: Tree<MenuTreeNode>;
    //used for the regular menu (including the filter)
    textSearchMenu: Textbox;
    menuData: MenuItem;
    treeMenu: Tree<MenuTreeNode>;
    //other
    buttonHome: Button;
    overlay: Overlay;

    constructor(props?: Partial<PanelProps>) {
        super({ backgroundColor: "background2", color: "subtle.darker", ...props });
        this.add(new Label({
            text: "Main Menu",
            allowSelect: false,
            fontSize: "xlarge",
            fontBold: true,
            color: "primary",
            fillRow: true,
            rowBreak: false
        }));
        this.setupHomeButton();
        this.add(this.buttonHome);
        const settings = GeneralSettings.get();
        if (settings == null || settings.menu == null)
            this.add(new Label({ text: "The menu hasn't been loaded.", color: "subtle.light" }));
        else {
            this.menuData = settings.menu;
            this.panelFavorites = this.createFavorites();
            this.panelToolbox = this.createToolbox();
            this.createFilteredMenu();
            if (this.panelToolbox != null)
                this.add(this.panelToolbox);
            this.add(this.panelFavorites);
            this.add(this.textSearchMenu);
            this.add(this.treeMenu);
            this.populateMenuTree();
        }
    }

    private setupHomeButton() {
        this.buttonHome = new Button({
            imageName: "home",
            variant: ButtonVariant.round,
            color: "primary",
            tooltip: "Return to your home page"
        });
        this.buttonHome.addClickListener(event => {
            Navigation.navigateTo("");
            Overlay.hideOverlay(this.overlay);
        });
    }

    private createFilteredMenu() {
        this.textSearchMenu = new Textbox({
            placeholder: "Search for items on the menu",
            color: "background2-reverse",
            captionVisible: false,
            clearButtonVisible: ClearButtonVisible.WHEN_FOCUSED,
            fillRow: true,
            paddingLeft: 0,
            paddingRight: 0
        });
        this.textSearchMenu.addFocusListener(event => this.unselectMenus(this.treeFavoritesMenu));
        this.textSearchMenu.addChangeListener(event => this.populateMenuTree());
        this.textSearchMenu.addKeyDownListener(event => this.handleSharedKeys(event)); //in theory we shouldn't need this, but the ALL key handler below doesn't hear left/right arrow keys?
        this.treeMenu = new Tree({ fillRow: true, fillHeight: true });
        this.treeMenu.addChangeListener(event => this.unselectMenus(this.treeFavoritesMenu));
    }

    clearSearch() {
        if (this.textSearchMenu != null) {
            this.textSearchMenu.text = "";
        }
    }

    show(anchor: Component) {
        this.setProps({ style: { transition: "", opacity: "0" }, height: 0, padding: 0 });
        this.clearSearch();
        Overlay.alignToAnchor(this, anchor); // really just to get the CSS since we are positioning manually
        this.setProps({ left: 0, top: 60, style: { transition: "height 250ms, opacity 500ms" } })
        this.overlay = Overlay.showInOverlay(this);
        this.focus();
        setTimeout(() => this.setProps({ height: "90vh", padding: 8, style: { opacity: "1" } }), 0);
    }

    override focus() {
        if (this.textSearchMenu != null)
            this.textSearchMenu.focus();
    }

    private populateMenuTree() {
        const filter = this.textSearchMenu.text.toLowerCase();
        const root = new TreeNode({ tree: this.treeMenu });
        let filtered = this.menuData.items;
        if (filter?.length > 0) {
            filtered = ObjectUtil.deepCopy(filtered);
            filtered = ObjectUtil.filterRecursiveArrayContainingString<MenuItem>(filtered, "items", "caption", filter);
        }
        for (const item of filtered)
            this.addTreeNode(root, item);
        root.removeChildrenMatching(node => node.expandedImageName != null && node.getChildCount() === 0);
        if (filter.length > 0)
            root.expandAll();
        this.treeMenu.getRootNode().setChildren(root.getChildren());
        this.treeMenu.selectedNode = null;
        const soleLeaf = this.getSoleLeaf(root);
        if (soleLeaf != null)
            this.treeMenu.selectedNode = soleLeaf;
    }

    private getSoleLeaf(node) {
        if (node.getChildCount() > 1 || (node.isRoot() && node.getChildCount() === 0))
            return null;
        else if (node.getChildCount() === 0)
            return node;
        else
            return this.getSoleLeaf(node.getChild(0));
    }

    private addTreeNode(parent: TreeNode, item: MenuItem) {
        if (item.items == null)
            parent.addChild(new MenuTreeNode({ component: this.createMenuItemPanel(item), navItem: item }));
        else {
            const node = new TreeNode({
                text: item.caption,
                expandedImageName: "minusInBox",
                collapsedImageName: "addInBox",
                allowSelect: false
            });
            parent.addChild(node);
            for (const child of item.items)
                this.addTreeNode(node, child);
        }
    }

    private populateUnfilteredOneLevelMenuTree(menuTree: Tree<MenuTreeNode>, nodes: MenuTreeNode[]) {
        const root = new TreeNode({ tree: menuTree });
        for (const node of nodes) {
            root.addChild(node);
        }
        menuTree.getRootNode().setChildren(root.getChildren());
        menuTree.selectedNode = null;
    }

    private createMenuItemPanel(item: MenuItem) {
        const button = new Button({
            imageName: "pushPin",
            variant: ButtonVariant.round,
            color: "subtle.light",
            tooltip: "Pin this page to your favorites"
        });
        button.addClickListener(event => this.addFavorite(event.target, item));
        return this.createBaseMenuItemPanel(this.createLauncher(item), button);
    }

    private createLauncher(item: MenuItem, openPageInSlideUp: boolean = false): Label {
        const result = new Label({
            text: item.caption,
            allowSelect: false,
            rowBreak: false,
            fillRow: true,
            minHeight: 34,
            contextMenuItems: () => [
                { text: "Open in New Tab", onClick: () => this.openPage(item, true, true)},
                { text: "Open in New Window", onClick: () => this.openPage(item, true, false)}
            ]
        });
        this.addLauncherClickListener(result, item, openPageInSlideUp);
        return result;
    }

    private createImageOnlyLauncher(item: MenuItem, openPageInSlideUp: boolean = false): Button {
        const result = new Button({
            imageName: item.image,
            tooltip: item.caption,
            rowBreak: false,
            fillRow: false,
            imageHeight: 22,
            imageWidth: 22,
            padding: 0,
            margin: 0,
            marginTop: 14,
            marginRight: 14,
            color: "subtle",
            borderWidth: 0
        });
        result.addFocusListener(event => {
            this.unselectMenus(this.treeFavoritesMenu, this.treeMenu);
            event.target.color = "primary";
        });
        result.addBlurListener(event => event.target.color = "subtle");
        if (item.caption === "MPact") {
            result.addClickListener(() => {
                Api.post("lme/dispatch/open-mpact-url").then((result) => {
                    const apiData = result.data[0];
                    if (apiData) {
                        window.open(apiData.encrypted_url, "_blank");
                    }
                });
            })
        } else if (item.path.startsWith("https")) {
            result.addClickListener(() => window.open(item.path, "", "popup"))
        } else {
            this.addLauncherClickListener(result, item, openPageInSlideUp);
        }
        return result;
    }

    private addLauncherClickListener(result: Component, item: MenuItem, openPageInSlideUp: boolean) {
        result.addClickListener(event => {
            if (openPageInSlideUp !== true)
                this.openPage(item, event.ctrlKey);
            else
                this.openPageInSlideUp(item);
        });
    }

    /**
     * Finds the specified id within the user's current list of favorite items
     *
     * @param {string} id
     * @param {string} matchField field in the favorite to match against the id
     * @returns {number} the index of the id in the user's favorites, or -1 if the id isn't in the user's favorites
     */

    private getIndexOfFavorite(id: string, matchField: string) {
        const faves = GeneralSettings.get().menu_favorites;
        if (faves != null)
            for (let i = 0; i < faves.length; i++)
                if (faves[i][matchField] === id)
                    return i;
        return -1;
    }

    /**
     *
     * @param {component} anchor The component to anchor the "congratulations on your new favorite!" tooltip to
     * @param {object} menuItem The user-menu-favorite item that you want to all
     */
    private async addFavorite(anchor: Component, menuItem: MenuItem) {
        const favoriteItem = new RowUserMenuFavorite({
            path: menuItem.path
            // don't set these for now.  That will cause the favorite item's caption
            // and image follow the layout from the menu item
            //      caption: item.caption,
            //      image: item.image,
        });
        if (favoriteItem.get("path") == "url")
            favoriteItem.set({ caption: menuItem.caption, params: menuItem.params });

        if (this.getIndexOfFavorite(menuItem.path, "path") >= 0) {
            showTooltip(anchor, "This page is already in your favorites!", { timeout: 2500 });
            return;
        }
        await favoriteItem.post();
        favoriteItem.set("caption", menuItem.caption);
        favoriteItem.set("image", menuItem.image);
        showTooltip(anchor, "This page has been added to your favorites!", { timeout: 2500 });
        let favesFromSettings = GeneralSettings.get().menu_favorites;
        if (favesFromSettings == null) {
            favesFromSettings = [];
            GeneralSettings.get().menu_favorites = favesFromSettings;
        }
        if (favesFromSettings.length === 0 && this.getTreeFavoritesSize() === 2)
            this.treeFavoritesMenu.getRootNode().removeChild(1);
        favesFromSettings.push(favoriteItem.data);
        const menuItemPanel = this.createFavorite(favoriteItem);
        const previousFavoriteCount = this.getTreeFavoritesSize();
        this.treeFavoritesMenu.getRootNode().addChild(new MenuTreeNode({
            component: menuItemPanel,
            navItem: favoriteItem.data
        }));
        if (previousFavoriteCount === 0) {
            this.panelFavorites.remove(this.favoritesEmptyLabel);
            this.panelFavorites.add(this.treeFavoritesMenu);
        }
    }

    private createFavorites(): Panel {
        this.treeFavoritesMenu = new Tree({ fillRow: true, fillHeight: true, borderWidth: 0 });
        this.treeFavoritesMenu.addChangeListener(event => this.unselectMenus(this.treeMenu));
        this.treeFavoritesMenu.adHocKeyHandler = (event: KeyEvent) => this.handleFavoritesMenuArrowKeys(event);
        const result = new Panel({ id: "panelMenuFavorites", fillRow: true, marginBottom: 8 });
        result.add(new Label({ text: "Favorites", ...this.getMenuSectionHeaderLabelProps() }));
        const favesFromSettings = GeneralSettings.get().menu_favorites;
        if (favesFromSettings == null || favesFromSettings.length === 0) {
            this.initializeFavoritesEmptyLabel();
            result.add(this.favoritesEmptyLabel);
        } else {
            const menuNodes: MenuTreeNode[] = [];
            for (const item of favesFromSettings) {
                log.debug("Loading menu item for favorite", item);
                const menuItem = this.findMenuItemForPath(item.path, this.menuData);
                if (menuItem != null) {
                    log.debug("Found menu item", menuItem);
                    menuNodes.push(new MenuTreeNode({
                        component: this.createFavorite(new RowUserMenuFavorite().setValues({
                            ...menuItem,
                            id: item.id
                        })), navItem: menuItem
                    }));
                }
            }
            this.populateUnfilteredOneLevelMenuTree(this.treeFavoritesMenu, menuNodes);
            result.add(this.treeFavoritesMenu);
        }
        return result;
    }

    private handleFavoritesMenuArrowKeys(event: KeyEvent) {
        if (event.isKey(Keys.ARROW_UP, {}) &&
            this.getTreeFavoritesSize() > 0 &&
            this.treeFavoritesMenu.getRootNode().getChild(0) === this.treeFavoritesMenu.selectedNode) {
            this.selectFirstToolboxItem();
            event.preventDefault();
            event.stopPropagation();
        } else if (event.isKey(Keys.ARROW_DOWN, {}) &&
            this.getTreeFavoritesSize() > 0 &&
            this.treeFavoritesMenu.getRootNode().getChild(this.getTreeFavoritesSize() - 1) === this.treeFavoritesMenu.selectedNode) {
            this.textSearchMenu.focus();
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private getTreeFavoritesSize(): number {
        return this.treeFavoritesMenu.getRootNode().getChildCount();
    }

    private initializeFavoritesEmptyLabel() {
        if (this.favoritesEmptyLabel == null)
            this.favoritesEmptyLabel = new Label({
                text: "Use the pushpins next to the menu items to add your favorites.",
                fillRow: true,
                align: HorizontalAlignment.CENTER,
                color: "subtle.light"
            });
    }

    private createToolbox(): Panel {
        const toolboxItemsFromSettings = GeneralSettings.get().menu_toolbox_items;
        if (ArrayUtil.isEmptyArray(toolboxItemsFromSettings) === true) {
            log.debug("No menu toolbox items are available for this user.")
            return null;
        }
        const result = new Panel({ id: "panelMenuToolbox", fillRow: true, marginBottom: 8 });
        result.add(new Label({ text: "Toolbox", ...this.getMenuSectionHeaderLabelProps() }));
        this.panelToolboxImages = new Panel({ id: "panelMenuToolboxImageRow", fillRow: false, marginTop: 4 });
        this.panelToolboxImages.addKeyDownListener((event: KeyEvent) => this.handleToolboxImageKey(event));
        result.add(this.panelToolboxImages);
        for (const toolboxItem of toolboxItemsFromSettings) {
            log.debug("Loading menu item for toolbox item", toolboxItem);
            let menuItem = this.findMenuItemForPath(toolboxItem.path, this.menuData);
            if (menuItem != null) {
                log.debug("Found menu item", menuItem);
                if (toolboxItem.image != null && menuItem?.image == null) {
                    log.debug("Using image name from settings menu item");
                    menuItem = { ...menuItem, image: toolboxItem.image };
                }
                this.panelToolboxImages.add(this.createToolboxMenuPanel(menuItem));
            } else if (toolboxItem.path.startsWith("https")) {
                this.panelToolboxImages.add(this.createToolboxMenuPanel(toolboxItem));
            }
        }
        return result;
    }

    private handleToolboxImageKey(event: KeyEvent) {
        if (event.isKey(Keys.ARROW_DOWN, {})) {
            if (this.getTreeFavoritesSize() > 0)
                this.selectFirstFavorite();
            else
                this.textSearchMenu.focus();
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        if (event.isKey(Keys.ARROW_LEFT, {}) || event.isKey(Keys.ARROW_RIGHT, {})) {
            let index = 0;
            for (let x = 0; x < this.panelToolboxImages.components.length; x++) {
                if (this.panelToolboxImages.components[x].hasFocus()) {
                    index = x;
                    break;
                }
            }
            if (event.isKey(Keys.ARROW_LEFT, {}))
                index--;
            else if (event.isKey(Keys.ARROW_RIGHT, {}))
                index++;
            if (index < 0)
                index = 0;
            if (index > this.panelToolboxImages.components.length - 1)
                index = this.panelToolboxImages.components.length - 1;
            this.panelToolboxImages.components[index].focus();
        }
    }

    private getMenuSectionHeaderLabelProps(): Partial<LabelProps> {
        return {
            fontSize: "large",
            color: "success",
            borderBottomWidth: 1,
            borderBottomColor: "strokeSecondary",
            fillRow: true
        };
    }

    private findMenuItemForPath(searchPath: string, searchMenuItem: MenuItem): MenuItem {
        if (searchMenuItem.path === searchPath)
            return searchMenuItem;
        if (searchMenuItem.items != null) {
            for (const item of searchMenuItem.items) {
                const sub = this.findMenuItemForPath(searchPath, item);
                if (sub != null)
                    return sub;
            }
        }
        return undefined;
    }

    private createFavorite(toolbarItem: RowUserMenuFavorite): Panel {
        const button = new Button({ imageName: "delete", variant: ButtonVariant.round, color: "subtle.light" });
        button.addClickListener(async () => {
            if (await CommonDialogs.showYesNo("Are you sure you want to remove this favorite?", "Confirm Removal")) {
                await toolbarItem.delete();
                const index = this.getIndexOfFavorite(toolbarItem.get("id"), "id");
                if (index >= 0) {
                    GeneralSettings.get().menu_favorites.splice(index, 1);
                    const previousFavoriteCount = this.getTreeFavoritesSize();
                    this.treeFavoritesMenu.getRootNode().removeChild(index);
                    if (previousFavoriteCount == 1) {
                        this.panelFavorites.remove(this.treeFavoritesMenu);
                        this.initializeFavoritesEmptyLabel();
                        this.panelFavorites.add(this.favoritesEmptyLabel);
                    }
                }
                PageHeader.instance.displayMenu();
            }
        });
        return this.createBaseMenuItemPanel(this.createLauncher(toolbarItem.data), button);
    }

    private createToolboxMenuPanel(toolboxItem: MenuItem): Button {
        if (toolboxItem.path.startsWith('https')) {
            return this.createImageOnlyLauncher(toolboxItem, false);
        }
        return this.createImageOnlyLauncher(toolboxItem, true);
    }

    private createBaseMenuItemPanel(...components: Component[]) {
        const result = new Panel({
            fillRow: true,
            padding: 0,
            components: components
        });
        return result;
    }

    private async openPage(navItem: MenuItem, newTab: boolean, windowDecorators: boolean = false) {
        if (navItem == null) return;
        if (navItem.path == "url") {
            const window = await Navigation.navigateTo(this.replaceKeywords(navItem.params), { newTab: true });
            if (navItem.windowProperties != null) {
                window["mcleodProps"] = navItem.windowProperties;
            }
            if (navItem.messageProperties) {
                window.postMessage({ mcleodProps: navItem.messageProperties });
            }
        } else if (navItem.caption === "MPact") {
            this.openMpactInBrowser();
        } else {
            Navigation.navigateTo(navItem.path || navItem[""], { newTab: newTab, windowDecorators: windowDecorators });
        }
        Overlay.hideOverlay(this.overlay);
    }

    private replaceKeywords(input: string): string {
        const result = StringUtil.replaceAll(input, "{authToken}", AuthToken.get());
        return result;
    }

    private async openPageInSlideUp(navItem: MenuItem) {
        Overlay.hideOverlay(this.overlay);
        const layout = Layout.getLayout(navItem.path, { displayingFromToolbox: true });
        layout.addLayoutLoadListener(event => {
            const decorated: Component = DecoratorRouter.decoratePage(layout, null);
            if (decorated instanceof Layout) {
                new SlideoutDecorator({
                    layout: decorated,
                    title: navItem.caption,
                    transitionOptions: { direction: Alignment.BOTTOM },
                    displayOverlay: true,
                    overlayProps: { closeOnClickOff: false, coverPageHeader: false },
                    fillHorizontalSpace: true,
                    fillVerticalSpace: true
                });
            } else if (decorated instanceof CrudDecorator) {
                decorated.headerProps = { showClose: true };
                decorated.slideIn({ speed: 200 }, null, null, { paddingAll: 8 });
            } else
                throw new Error("This item cannot be opened from the menu as a slide up panel.")
        });
    }

    selectFirstFavorite() {
        if (this.getTreeFavoritesSize() > 0)
            this.selectFirstNode(this.treeFavoritesMenu);
    }

    selectFirstToolboxItem() {
        this.panelToolboxImages?.components[0]?.focus();
    }

    selectLastToolboxItem() {
        this.panelToolboxImages?.components[this.panelToolboxImages.components.length - 1]?.focus();
    }

    private selectFirstNode(menu: Tree<MenuTreeNode>) {
        if (menu == null)
            return;
        menu.focus();
        if (menu.getRootNode().getChildCount() > 0)
            menu.selectedNode = menu.getRootNode().getChild(0) as MenuTreeNode;
    }

    override getKeyHandlers(): KeyHandler[] {
        let result = [];
        if (this.keyHandlers != null)
            result = [...this.keyHandlers];
        result.push({
            key: "ALL",
            listener: (event) => this.handleSharedKeys(event),
            element: null,
            scope: this._element
        });
        return result;
    }

    private handleSharedKeys(event: KeyEvent) {
        //set shouldAutomaticallyStopPropagation to 'false'...we can't assume that we are going to logically handle the key via the 'ALL' listener
        //for example, if the user is typing in the filter textbox, we don't want to consume the key (so their typing has an effect)
        //or if the user uses the key to refresh the page, that should work as well
        event.shouldAutomaticallyStopPropagation = false;
        if (this.showToolbox(event.domEvent) === true) {
            event.preventDefault();
            event.shouldAutomaticallyStopPropagation = true;
        } else if (KeyModifiers.hasModifiers({ ctrlKey: false, shiftKey: true, altKey: false }, event) === true &&
            ShiftedNumbers[event.key] != null &&
            this.showFavorite(event.domEvent, ShiftedNumbers[event.key]) === true) {
            event.preventDefault();
            event.shouldAutomaticallyStopPropagation = true;
        } else if (event.isKey(Keys.ESCAPE, {})) {
            Overlay.hideOverlay(this.overlay);
            event.preventDefault();
            event.shouldAutomaticallyStopPropagation = true;
        } else
            this.processTreeKey(event);
    }

    private getNumToolboxItems(): number {
        return ArrayUtil.getLength(GeneralSettings.get().menu_toolbox_items);
    }

    private getNumFavorites(): number {
        return ArrayUtil.getLength(GeneralSettings.get().menu_favorites);
    }

    private convertHotKeyStringToNumber(zeroThroughNineKey: string): number {
        if (zeroThroughNineKey < "0" || zeroThroughNineKey > "9")
            return -1;
        return zeroThroughNineKey !== "0" ? parseInt(zeroThroughNineKey) : 10;
    }

    showToolbox(event: KeyboardEvent): boolean {
        const keyAsNumber = this.convertHotKeyStringToNumber(event.key);
        if (keyAsNumber >= 1 && keyAsNumber <= this.getNumToolboxItems()) {
            const item = GeneralSettings.get().menu_toolbox_items[keyAsNumber - 1] as MenuItem;
            if (item.caption === "MPact")
                this.openMpactInBrowser();
            else if (item.path.startsWith("https"))
                window.open(item.path, "", "popup");
            else
                this.openPageInSlideUp(item);
            return true;
        }
        return false;
    }

    showFavorite(event: KeyboardEvent, keyValue: string): boolean {
        const keyAsNumber = this.convertHotKeyStringToNumber(keyValue);
        if (keyAsNumber >= 1 && keyAsNumber <= this.getNumFavorites()) {
            const item = GeneralSettings.get().menu_favorites[keyAsNumber - 1];
            this.openPage(item, event.ctrlKey);
            return true;
        }
        return false;
    }

    private processTreeKey(event: KeyEvent) {
        const activeMenuTree = this.getActiveMenuTree();
        if (activeMenuTree == null)
            return;
        if (event.key === Keys.ENTER) {
            if (activeMenuTree.selectedNode?.navItem != null)
                this.openPage(activeMenuTree.selectedNode.navItem, event.ctrlKey);
            event.preventDefault();
            event.shouldAutomaticallyStopPropagation = true;
        } else
            activeMenuTree.handleKey(event);
    }

    private getActiveMenuTree(): Tree<MenuTreeNode> {
        //see which menu is active (has a selected node), and open the page for that node
        if (this.treeMenu.selectedNode != null || this.textSearchMenu.hasFocus())
            return this.treeMenu;
        else if (this.treeFavoritesMenu.selectedNode != null)
            return this.treeFavoritesMenu;
        return null;
    }

    private unselectMenus(...menus: Tree<MenuTreeNode>[]) {
        for (const menu of menus) {
            if (menu != null) {
                menu._element.blur();
                menu.selectedNode = null;
            }
        }
    }

    private openMpactInBrowser() {
        Api.post("lme/dispatch/open-mpact-url").then((result) => {
            const apiData = result.data[0];
            if (apiData) {
                window.open(apiData.encrypted_url, "_blank");
            }
        });
    }
}
