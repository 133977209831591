import { Layout, LayoutHeader, Panel, Switch, Textbox, Tree } from "@mcleod/components";

export abstract class AutogenLayoutPermissionsMain extends Layout {
    layoutPermsGrant: Layout;
    layoutheaderPermsManager: LayoutHeader;
    panel1: Panel;
    panel3: Panel;
    panelMain: Panel;
    switchPermsSet: Switch;
    textSearchMenu: Textbox;
    treeMenu: Tree;
}
