import { Button, Label, Layout, LayoutHeader, Panel } from "@mcleod/components";

export abstract class AutogenLayoutReportPreview extends Layout {
    buttonDownloadExcel: Button;
    buttonDownloadPdf: Button;
    buttonIsBusy: Button;
    labelExport: Label;
    labelPreview: Label;
    layoutheaderReportPreview: LayoutHeader;
    panelPdf: Panel;
    panelPdfPreview: Panel;
}
