import { Alignment, ArrayUtil, StringUtil } from "@mcleod/core";
import { ChangeListener } from "../../events/ChangeEvent";
import { Checkbox } from "../checkbox/Checkbox";
import { CheckboxProps } from "../checkbox/CheckboxProps";
import { Component } from "../../base/Component";
import { ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { DataSource } from "../../databinding/DataSource";
import { DesignerInterface } from "../../base/DesignerInterface";
import { ExcludableTextboxPropDefinitions, ExcludableTextboxProps } from "./ExcludableTextboxProps";
import { Label } from "../label/Label";
import { LookupModelSelectionListener } from "../../events/LookupModelSelectionEvent";
import { Panel } from "../panel/Panel";
import { PropsAccessLabelCreator } from "../../PropsAccessLabelCreator";
import { serializeComponents } from "../../serializer/ComponentSerializer";
import { Textbox } from "../textbox/Textbox";
import { TextboxProps } from "../textbox/TextboxProps";

export class ExcludableTextbox extends Panel implements ExcludableTextboxProps {
    private _checkbox: Checkbox;
    private checkboxPropsAccessLabel: Label;
    private _excludeAlign: Alignment;
    private _textbox: Textbox;
    private textboxPropsAccessLabel: Label;
    private changeListenerRef: ChangeListener = () => this.doOnTextboxChange();
    // private dropdownSelectListenerRef: DropdownSelectionListener = () => this.doOnDropdownSelection();
    private lookupModelSelectListenerRef: LookupModelSelectionListener = () => this.doOnLookupModelSelection();

    constructor(props: Partial<ExcludableTextboxProps>, textbox: Textbox, checkbox: Checkbox) {
        super({ ...props });
        this.setInnerComponentProps(this, { padding: 0 });
        this.textbox = textbox;
        this.checkbox = checkbox;
        this.createInnerComponentsInDesigner();
        this.syncExcludeAlign();
    }

    public get checkbox(): Checkbox {
        return this._checkbox;
    }

    public set checkbox(value: Checkbox) {
        if (value == null) {
            this.remove(this.checkbox);
            return;
        }
        try {
            const oldCheckbox = this.checkbox;
            if (oldCheckbox === value)
                return;
            this._checkbox = value;
            this.remove(oldCheckbox);
            if (this.checkbox != null && this.contains(this.checkbox) !== true)
                this.add(this.checkbox);
            this.syncExcludeAlign();
            const checkboxProps: Partial<CheckboxProps> = {
                id: this.getCheckboxId(),
                dataSource: this.dataSource,
                field: this.field + "_exclude",
                searchOnly: this.searchOnly,
                enabled: false,
                _designer: this._designer
            };
            this.setInnerComponentProps(this.checkbox, checkboxProps);
            if (StringUtil.isEmptyString(this.checkbox.caption) === true)
                this.checkbox.caption = "Exclude";
            this.checkbox.addPropertyDefaultValueOverride("caption", "Exclude");
            this.checkbox.deletableInDesigner = false;
            this.checkbox.alternateDesignerTarget = this;
        }
        finally {
            this.syncPropsAccessLabels();
        }
    }

    public get excludeAlign(): Alignment {
        return this._excludeAlign ?? this.getPropertyDefinitions().excludeAlign.defaultValue;
    }

    public set excludeAlign(value: Alignment) {
        if (this.excludeAlign === value)
            return;
        this._excludeAlign = value;
        this.syncExcludeAlign();
    }

    private syncExcludeAlign() {
        const textboxProps: Partial<TextboxProps> = {};
        const checkboxProps: Partial<CheckboxProps> = {};
        switch (this.excludeAlign) {
            case Alignment.RIGHT:
                textboxProps.rowBreak = false;
                checkboxProps.marginTop = 24;
                checkboxProps.marginLeft = null;
                checkboxProps.rowBreak = true;
                break;
            case Alignment.BOTTOM:
            default:
                textboxProps.rowBreak = true;
                checkboxProps.marginTop = null;
                checkboxProps.marginLeft = -6;
                checkboxProps.rowBreak = false;
                break;
        }
        this.setInnerComponentProps(this.textbox, textboxProps);
        this.setInnerComponentProps(this.checkbox, checkboxProps);
    }

    public get textbox(): Textbox {
        return this._textbox;
    }

    public set textbox(value: Textbox) {
        if (value == null) {
            this.remove(this.textbox);
            return;
        }
        try {
            const oldTextbox = this.textbox;
            if (oldTextbox === value)
                return;
            this._textbox = value;
            this.remove(oldTextbox);
            if (this.textbox != null && this.contains(this.textbox) !== true)
                this.insert(this.textbox, 0);
            this.syncExcludeAlign();
            this.syncListeners();
            const textboxProps: Partial<TextboxProps> = {
                id: this.getTextboxId(),
                dataSource: this.dataSource,
                field: this.field,
                searchOnly: this.searchOnly,
                // Can't set inner textbox's required values using public required* property getters.
                // Doing so would result in inner textbox's _required value being set, which throws off its logic.
                required: this["_required"],                         // UGH
                requiredDuringAdd: this["_requiredDuringAdd"],       // UGH
                requiredDuringSearch: this["_requiredDuringSearch"], // UGH
                requiredDuringUpdate: this["_requiredDuringUpdate"], // UGH
                marginTop: 8,
                fillRow: true,
                _designer: this._designer
            };
            this.setInnerComponentProps(this.textbox, textboxProps);
            this.textbox.deletableInDesigner = false;
            this.textbox.alternateDesignerTarget = this;
        }
        finally {
            this.syncPropsAccessLabels();
        }
    }

    private syncListeners() {
        this.textbox.removeLookupModelSelectionListener(this.lookupModelSelectListenerRef);
        // this.textbox.removeAfterDropdownSelectionListener(this.dropdownSelectListenerRef);
        this.textbox.removeChangeListener(this.changeListenerRef);

        if (this.textbox.hasLookupModel() === true)
            this.textbox.addLookupModelSelectionListener(this.lookupModelSelectListenerRef);
        // else if (this.textbox.items != null)
        //     this.textbox.addAfterDropdownSelectionListener(this.dropdownSelectListenerRef);
        else
            this.textbox.addChangeListener(this.changeListenerRef);
    }

    private doOnTextboxChange() {
        this.updateCheckboxAvailability();
    }

    // Would like to use the dropdown selection listener, but it currently doesn't fire for searchOnly components
    // private doOnDropdownSelection() {
    //     this.updateCheckboxAvailability();
    // }

    private doOnLookupModelSelection() {
        this.updateCheckboxAvailability();
    }

    private updateCheckboxAvailability() {
        if (this.textbox == null || this.checkbox == null)
            return;
        const textboxEmptyOrWildCard = this.textbox.isEmpty() === true || this.textbox.text.trim() === "*";
        if (textboxEmptyOrWildCard)
            this.checkbox.checked = false;
        this.checkbox.enabled = !textboxEmptyOrWildCard;
    }

    override _applyEnabled(value: boolean): void {
        this.textbox.enabled = value;
        this.checkbox.enabled = value;
        if (value) {
            this._element.removeAttribute("disabled");
        }
        else {
            this._element.setAttribute("disabled", "true");
        }
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return ExcludableTextboxPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "excludabletextbox";
    }

    override get properName(): string {
        return "Excludable Textbox";
    }

    allowDropInDesigner(_component: Component): boolean {
        return false;
    }

    override get _designer(): DesignerInterface {
        return super._designer;
    }

    override set _designer(value: DesignerInterface) {
        super._designer = value;

        // Handle when the designer is being set after inner components are created
        if (this.checkbox != null)
            this.checkbox._designer = value;
        if (this.textbox != null)
            this.textbox._designer = value;

        // Handle creating the inner Textbox/Checkbox when adding a new ExcludableTextbox in the Designer
        if (value != null)
            this.createInnerComponentsInDesigner();

        this.syncPropsAccessLabels();
    }

    private createInnerComponentsInDesigner() {
        // If we are adding a new ExcludableTextbox to a layout in the designer, and the component doesn't already
        // contain the inner components, create them.
        if (this._designer != null && !this.isDeserializing() && !this.isCloning()) {
            if (this.textbox == null) {
                this.textbox = new Textbox({
                    id: this.getTextboxId(),
                    _designer: this._designer
                });
            }
            if (this.checkbox == null) {
                this.checkbox = new Checkbox({
                    id: this.getCheckboxId(),
                    caption: "Exclude",
                    _designer: this._designer
                });
            }
        }
    }

    private syncPropsAccessLabels() {
        this.remove(this.textboxPropsAccessLabel);
        this.remove(this.checkboxPropsAccessLabel);
        if (this._designer == null)
            return;

        if (this.textbox != null) {
            this.textboxPropsAccessLabel = PropsAccessLabelCreator.create({
                id: this.getTextboxPropsLabelId(),
                text: "Textbox Props",
                _designer: this.textbox._designer,
                marginLeft: 15,
                rowBreak: false,
                tooltip: "Click to access the properties for the textbox",
            });
            this.textboxPropsAccessLabel.alternateDesignerTarget = this.textbox;
            this.add(this.textboxPropsAccessLabel);
        }

        if (this.checkbox != null) {
            this.checkboxPropsAccessLabel = PropsAccessLabelCreator.create({
                id: this.getCheckboxPropsLabelId(),
                text: "Checkbox Props",
                _designer: this.checkbox._designer,
                marginLeft: 15,
                rowBreak: false,
                tooltip: "Click to access the properties for the exclude checkbox"
            });
            this.checkboxPropsAccessLabel.alternateDesignerTarget = this.checkbox;
            this.add(this.checkboxPropsAccessLabel);
        }
    }

    override doAfterDeserialize() {
        this.syncInnerComponents();
    }

    override doAfterClone() {
        this.syncInnerComponents();
    }

    override getChildrenForCloning(): Component[] {
        const result = super.getChildrenForCloning();
        ArrayUtil.removeFromArray(result, this.checkboxPropsAccessLabel);
        ArrayUtil.removeFromArray(result, this.textboxPropsAccessLabel);
        return result;
    }

    /**
     * Identify the inner textbox and checkbox components, and set the checkbox's availability
     */
    private syncInnerComponents() {
        this.components.forEach((component: Component) => {
            if (this.checkbox == null && component instanceof Checkbox)
                this.checkbox = component;
            else if (this.textbox == null && component instanceof Textbox)
                this.textbox = component;
        });
        this.updateCheckboxAvailability();
    }

    /**
     * Override to avoid serializing textbox and checkbox props access labels
     */
    _serializeNonProps(): string {
        const filteredComponents = this.components?.filter((component: Component) => {
            return component !== this.textboxPropsAccessLabel && component !== this.checkboxPropsAccessLabel;
        });
        if (ArrayUtil.isEmptyArray(filteredComponents) === false)
            return "\"components\": " + serializeComponents(filteredComponents, null,) + ",\n";
        return "";
    }

    override get id(): string {
        return super.id;
    }

    override set id(value: string) {
        super.id = value;
        if (this.checkbox != null) {
            const checkboxId = this.getCheckboxId();
            this.setInnerComponentProps(this.checkbox, { id: checkboxId });
        }
        if (this.textbox != null) {
            const textboxId = this.getTextboxId();
            this.setInnerComponentProps(this.textbox, { id: textboxId });
        }
        if (this.checkboxPropsAccessLabel != null)
            this.checkboxPropsAccessLabel.id = this.getCheckboxPropsLabelId();
        if (this.textboxPropsAccessLabel != null)
            this.textboxPropsAccessLabel.id = this.getTextboxPropsLabelId();
    }

    override get dataSource(): DataSource {
        return super.dataSource;
    }

    override set dataSource(value: DataSource) {
        super.dataSource = value;
        const props: Partial<ComponentProps> = { dataSource: value };
        this.setInnerComponentProps(this.checkbox, props);
        this.setInnerComponentProps(this.textbox, props);
    }

    override get field(): string {
        return super.field;
    }

    override set field(value: string) {
        super.field = value;
        this.setInnerComponentProps(this.checkbox, { field: value + "_exclude" });
        this.setInnerComponentProps(this.textbox, { field: value });
    }

    override get searchOnly(): boolean {
        return super.searchOnly;
    }

    override set searchOnly(value: boolean) {
        super.searchOnly = value;
        const props: Partial<ComponentProps> = { searchOnly: value };
        this.setInnerComponentProps(this.checkbox, props);
        this.setInnerComponentProps(this.textbox, props);
    }

    override get required(): boolean {
        return super.required;
    }

    override set required(value: boolean) {
        super.required = value;
        const props: Partial<ComponentProps> = { required: value };
        if (this.searchOnly === true)
            props.requiredDuringSearch = value;
        this.setInnerComponentProps(this.textbox, props);
    }

    override get requiredDuringAdd(): boolean {
        return super.requiredDuringAdd;
    }

    override set requiredDuringAdd(value: boolean) {
        super.requiredDuringAdd = value;
        const props: Partial<ComponentProps> = { requiredDuringAdd: value };
        this.setInnerComponentProps(this.textbox, props);
    }

    override get requiredDuringSearch(): boolean {
        return super.requiredDuringSearch;
    }

    override set requiredDuringSearch(value: boolean) {
        super.requiredDuringSearch = value;
        const props: Partial<ComponentProps> = { requiredDuringSearch: value };
        this.setInnerComponentProps(this.textbox, props);
    }

    override get requiredDuringUpdate(): boolean {
        return super.requiredDuringUpdate;
    }

    override set requiredDuringUpdate(value: boolean) {
        super.requiredDuringUpdate = value;
        const props: Partial<ComponentProps> = { requiredDuringUpdate: value };
        this.setInnerComponentProps(this.textbox, props);
    }

    private setInnerComponentProps(component: Component, props: Partial<ComponentProps>) {
        if (component != null) {
            component.setProps(props);
            component.addPropertyDefaultValueOverrides(props);
        }
    }

    private getCheckboxId(): string {
        return this.getInnerComponentId(this.checkbox);
    }

    private getTextboxId(): string {
        return this.getInnerComponentId(this.textbox);
    }

    private getCheckboxPropsLabelId(): string {
        return this.getPropsAccessLabelId(this.checkbox);
    }

    private getTextboxPropsLabelId(): string {
        return this.getPropsAccessLabelId(this.textbox);
    }

    private getInnerComponentId(component: Component): string {
        return this.id + StringUtil.capitalize(component?.serializationName);
    }

    private getPropsAccessLabelId(component: Component): string {
        return this.getInnerComponentId(component) + "Props";
    }
}
ComponentTypes.registerComponentType("excludabletextbox", ExcludableTextbox.prototype.constructor);
