import { Navigation } from ".";

export class ExternalLink {
    private _text: string;
    private _url: string;
    private _relatedElement: HTMLElement|(() => HTMLElement);

    constructor(text: string, url: string, relatedElement?: HTMLElement|(() => HTMLElement)) {
        this._text = text;
        this._url = url;
        this._relatedElement = relatedElement;
    }

    public get text(): string {
        return this._text;
    }

    protected get url(): string {
        return this._url;
    }

    protected set url(value: string) {
        this._url = value;
    }

    public get relatedElement(): HTMLElement {
        if (typeof this._relatedElement === "function")
            this._relatedElement = this._relatedElement();
        return this._relatedElement;
    }

    protected urlIsValid(): boolean {
        return this.url?.startsWith("https://") === true || this.url?.startsWith("http://") === true;
    }

    //override this method to alter the url before it is used
    //this could involve getting a finalized url from the server
    protected async resolveURL(): Promise<void> {
    }

    public async open(): Promise<boolean> {
        await this.resolveURL();
        if (this.urlIsValid() === true) {
            Navigation.navigateTo(this.url, { newTab: true });
            return true;
        }
        return false;
    }

    public equals(otherExternalLink: ExternalLink): boolean {
        if (otherExternalLink == null)
            return false;
        return this.text === otherExternalLink.text && this.url === otherExternalLink.url;
    }

    /**
     * Implement toJSON so that we only serialize the parts of the link that need to be saved in a layout file.
     * @returns any
     */
    public toJSON(): any {
        const result: any = {
            text: this.text,
            url: this.url
        };
        return result;
    }
}
