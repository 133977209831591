import { PanelPropDefinitions, PanelProps } from "../panel/PanelProps";
import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ObjectUtil } from "@mcleod/core";
import { Layout } from "../layout/Layout";

export interface LayoutHeaderProps extends PanelProps {
    title: string;
    layout: Layout;
}

let layoutHeaderPropDefs: ComponentPropDefinitions;

export class LayoutHeaderPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (layoutHeaderPropDefs == null) {
            layoutHeaderPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                title: {
                    type: PropType.string,
                    description: "This specifies the text that will appear in the header of the layout. " +
                        "If it is left blank, the title of the layout that this component is on will be used."
                }
            };
            layoutHeaderPropDefs.fillRow.defaultValue = true;
            layoutHeaderPropDefs.marginBottom.defaultValue = 12;
            layoutHeaderPropDefs.padding.defaultValue = 0;
            ComponentPropDefinitionUtil.populateComponentPropNames(layoutHeaderPropDefs);
        }
        return layoutHeaderPropDefs;
    }

    public static cloneDefs(): ComponentPropDefinitions {
        return ObjectUtil.deepCopy(LayoutHeaderPropDefinitions.getDefinitions());
    }
}
