/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTenantUser.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "auth/tenant-user";

export class AutogenModelTenantUser<RowType extends ModelRow> extends Endpoint<RowType, RowTenantUserFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowTenantUser extends ModelRow<RowTenantUserFields> {
	constructor(values?: Partial<RowTenantUserFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowTenantUserFields {
	"account.id": string;
	"account.license_key": string;
	"account.name": string;
	"account.scac": string;
	"auth_user.default_on_prem_user_id": string;
	"auth_user.email_address": string;
	"auth_user.id": string;
	"auth_user.idp_id": string;
	"auth_user.login_id": string;
	"auth_user.name": string;
	"auth_user.password": string;
	"auth_user.token_key": number;
	"auth_user.user_type": string;
	login_index: number;
	on_prem_user_id: string;
	"tenant.account_id": string;
	"tenant.allow_mcleod": string;
	"tenant.allow_sso_request_access": string;
	"tenant.home_url": string;
	"tenant.id": string;
	"tenant.name": string;
	"tenant.service_token_valid_minutes": number;
	"tenant.user_token_valid_minutes": number;
	tenant_id: string;
	user_id: string;
}

