export enum PropType {
    bool = "bool",
    color = "color",
    dropdownItem = "dropdownItem",
    endpoint = "endpoint",
    event = "event",
    fieldList = "fieldList",
    helpLink = "helpLink",
    image = "image",
    layout = "layout",
    multilineString = "multilineString",
    number = "number",
    string = "string",
    stringArray = "stringArray",
    tailorExtension = "tailorExtension",
    actionPermission = "actionPermission",
    contextMenu = "contextMenu",
    tableExtraSortFields = "tableExtraSortFields"
}
