export const props = { viewBox: "0 0 41 30" };
export const innerHTML = `
    <svg width="41" height="35" viewBox="0 0 24 24" fill="none">
    <path d="M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H11.8027C11.5341 19.5357 11.326 19.0322 11.189 18.5H4C3.72386 18.5 3.5 18.2761 3.5 18V6C3.5 5.72386 3.72386 5.5 4 5.5H20C20.2761 5.5 20.5 5.72386 20.5 6V12.126C21.0889 12.5497 21.5978 13.0774 22 13.6822V6C22 4.89543 21.1046 4 20 4H4Z" fill="#B60909"/>
    <path d="M17 11H13V12.5H13.0313C14.089 11.5665 15.4783 11 17 11Z" fill="#B60909"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.594 14.3939C11.7695 13.6231 11.674 12.7776 11.2251 12L8.33831 7L5.45155 12C4.16855 14.2222 5.77231 17 8.33831 17C9.63622 17 10.688 16.2893 11.2444 15.2993C11.3368 14.9861 11.4541 14.6835 11.594 14.3939ZM8.33831 10.75L7.07535 12.9375C6.51404 13.9097 7.21568 15.125 8.33831 15.125C9.46093 15.125 10.1626 13.9097 9.60126 12.9375L8.33831 10.75Z" fill="#B60909"/>
    <path d="M19 8H13V9.5H19V8Z" fill="#B60909"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 17C22 19.7614 19.7614 22 17 22C14.2386 22 12 19.7614 12 17C12 14.2386 14.2386 12 17 12C19.7614 12 22 14.2386 22 17ZM20.5 17C20.5 18.933 18.933 20.5 17 20.5C16.2405 20.5 15.5374 20.2581 14.9637 19.847L19.847 14.9637C20.2581 15.5374 20.5 16.2405 20.5 17ZM13.9577 18.7317L18.7317 13.9577C18.2211 13.6664 17.63 13.5 17 13.5C15.067 13.5 13.5 15.067 13.5 17C13.5 17.63 13.6664 18.2211 13.9577 18.7317Z" fill="#B60909"/>
    </svg>
`;
