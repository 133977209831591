import { ScrollOptions } from "./DOMDefinitions";
import { HeightWidth } from "./HeightWidth";
import { LogManager } from "./logging/LogManager";

const log = LogManager.getLogger("core.DOMUtil");

export interface ElementDimensionOptions {
    includePadding?: boolean;
    includeMargin?: boolean;
    includeBorder?: boolean;
}

export class DOMUtil {

    public static copyClasses(source: Element, target: Element, filter?: (className: string) => boolean, removeAfterCopy: boolean = false): void {
        if (!source || !target)
            return;

        Array.from(source.classList).forEach((className) => {
            if (!filter || filter(className)) {
                target.classList.add(className);
                if (removeAfterCopy)
                    source.classList.remove(className);
            }
        });
    }

    public static moveDOMElementBefore(element: Node, insertBeforeElement: Node): void {
        const currentParent = element.parentNode;
        currentParent?.removeChild(element);
        insertBeforeElement.parentNode.insertBefore(element, insertBeforeElement);
    }

    public static moveDOMElementAfter(element: Node, insertAfterElement: Node): void {
        const currentParent = element.parentNode;
        currentParent?.removeChild(element);
        insertAfterElement.parentNode.insertBefore(element, insertAfterElement.nextSibling);
    }

    public static switchDOMElements(nodeA: Node, nodeB: Node): void {
        const parentA = nodeA.parentNode;
        const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
        nodeB.parentNode.insertBefore(nodeA, nodeB);
        parentA.insertBefore(nodeB, siblingA);
    }

    public static replaceElement(element: Node, elementToReplace: Node): void {
        elementToReplace?.parentNode?.replaceChild(element, elementToReplace);
    }


    public static isScrollbarVisible(element: HTMLElement): boolean {
        return element.scrollHeight > element.clientHeight;
    }

    public static removeChild(parent: Element, child: Element) {
        if (child != null && parent?.contains(child))
            parent.removeChild(child);
    }

    public static isOrContains(outerElement: Element, innerElement: Element): boolean {
        if (outerElement == null || innerElement == null)
            return false;
        return outerElement === innerElement || outerElement.contains(innerElement);
    }

    /**
     * This will iterate the specified element's container hierarchy and return the first parent
     * element that has a vertical scrollbar that is visible.
     * @param element
     * @returns
     */
    public static findScrollableParent(element: HTMLElement): HTMLElement {
        const parent = element.parentElement;
        if (parent == null || parent === document.body)
            return null;
        if (DOMUtil.isScrollbarVisible(parent))
            return parent;
        return DOMUtil.findScrollableParent(parent);
    }

    public static scrollElementIntoView(element: HTMLElement, options?: Partial<ScrollOptions>, parent?: HTMLElement) {
        const p = parent == null ? DOMUtil.findScrollableParent(element) : parent;
        if (p == null)
            return;
        const percent = DOMUtil.getPercentVerticallyVisible(element, p);
        if (percent < 1) {
            log.debug("Scrolling Tabset - parent scroll height before scroll %o", parent?.scrollHeight)
            log.debug("Scrolling Tabset - parent client height before scroll %o", parent?.clientHeight)
            const behavior = options?.behavior ?? "auto";
            const block: ScrollLogicalPosition = options?.block == null ? "start" : options.block;
            (element).scrollIntoView({ behavior: behavior, block: block });
        }
    }

    public static getSizeSpecifier(value: number | string): string {
        if (value == null)
            return "";
        if (typeof value === "number")
            return value + "px";
        else if (typeof value === "string" && value.length > 0) {
            const lastChar = value[value.length - 1];
            if (lastChar >= "0" && lastChar <= "9")
                return value + "px";
        }
        return value;
    }

    public static convertStyleAttrToNumber(styleValue: string): number {
        if (styleValue == null)
            return 0;
        const digits = styleValue.match(/\-*\d+\.*\d*/);
        if (digits == null)
            return 0;
        let resultAsString = "";
        for (const digit of digits)
            resultAsString += digit;
        return Number(resultAsString);
    }

    public static getStyleAttrAsNumber(styleValue: string | number): number {
        if (styleValue == null)
            return NaN;
        if (typeof styleValue === "string")
            return DOMUtil.convertStyleAttrToNumber(styleValue);
        return styleValue;
    }

    /**
     * Gets a style value from an element or its first ancestor that has that style value specified.
     *
     * @param styleKey
     * The id of the style attribute for which the value is needed.  Example: backgroundColor
     * @param element
     * The element from which the search for the style value will begin.
     * @param defaultValue
     * The value to return if neither the element or any of its ancestors specifies a value for the provided style key.  This is an optional parameter.  When not specified, null can be returned from this method.
     */
    public static getStyleValueFromElementOrAncestor(styleKey: string, element: HTMLElement, defaultValue?: any): any {
        let testElement = element;
        while (testElement != null) {
            const parentStyleValue = testElement.style[styleKey];
            if (parentStyleValue != null && parentStyleValue != "")
                return parentStyleValue;
            testElement = testElement.parentElement;
        }
        return defaultValue;
    }

    private static getElementDimensionOptions(options?: ElementDimensionOptions): ElementDimensionOptions {
        return {
            includePadding: true,
            includeMargin: false,
            includeBorder: true,
            ...options
        };
    }

    public static getElementHeightString(element: Element): string {
        return document.defaultView.getComputedStyle(element).height;
    }

    /**
     * Calculates the height of an element
     * @param element
     * The element for which the height is needed.
     * @param options
     * Options for calculating the height. If not specified, the height will include padding and border, but not margin.
     * @returns
     * The height of the element.
     */
    public static getElementHeight(element: Element, options?: ElementDimensionOptions): number {
        if (element == null)
            return 0;
        const computedStyle = document.defaultView.getComputedStyle(element);
        return this.getElementHeightFromStyle(computedStyle, options);
    }

    private static getElementHeightFromStyle(style: CSSStyleDeclaration, options?: ElementDimensionOptions): number {
        let result = DOMUtil.convertStyleAttrToNumber(style.height);

        const { includePadding, includeMargin, includeBorder } = this.getElementDimensionOptions(options);

        if (!includePadding) {
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingTop);
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingBottom);
        }
        if (includeMargin) {
            result += DOMUtil.convertStyleAttrToNumber(style.marginTop);
            result += DOMUtil.convertStyleAttrToNumber(style.marginBottom);
        }
        if (!includeBorder && style.boxSizing === "border-box") {
            result -= DOMUtil.convertStyleAttrToNumber(style.borderTopWidth);
            result -= DOMUtil.convertStyleAttrToNumber(style.borderBottomWidth);
        }
        return result;
    }

    public static getElementWidthString(element: Element): string {
        return document.defaultView.getComputedStyle(element).width;
    }

    /**
     * Calculates the width of an element
     * @param element
     * The element for which the width is needed.
     * @param options
     * Options for calculating the width. If not specified, the width will include padding and border, but not margin.
     * @returns
     * The width of the element.
     */
    public static getElementWidth(element: Element, options?: ElementDimensionOptions): number {
        if (element == null)
            return 0;
        const computedStyle = document.defaultView.getComputedStyle(element);
        return this.getElementWidthFromStyle(computedStyle, options);
    }

    private static getElementWidthFromStyle(style: CSSStyleDeclaration, options?: ElementDimensionOptions): number {
        let result = DOMUtil.convertStyleAttrToNumber(style.width);

        const { includePadding, includeMargin, includeBorder } = this.getElementDimensionOptions(options);

        if (!includePadding) {
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingLeft);
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingRight);
        }
        if (includeMargin) {
            result += DOMUtil.convertStyleAttrToNumber(style.marginLeft);
            result += DOMUtil.convertStyleAttrToNumber(style.marginRight);
        }
        if (!includeBorder && style.boxSizing === "border-box") {
            result -= DOMUtil.convertStyleAttrToNumber(style.borderLeftWidth);
            result -= DOMUtil.convertStyleAttrToNumber(style.borderRightWidth);
        }
        return result;
    }

    /**
     * Calculates the available width of a parent element by subtracting the total width
     * of its child elements from the parent's total width.
     *
     * @param parentElement - The parent element for which the available width is calculated.
     * @param includeChildCallback - Optional callback to determine whether a child element should be included in the calculation.
     * @returns The calculated available width of the parent element
     */
    public static getAvailableWidth(parentElement: Element, includeChildCallback?: (child: Element) => boolean): number {
        return this.getAvailableHeightOrWidth(parentElement, "width", includeChildCallback);
    }

    /**
     * Calculates the available height of a parent element by subtracting the total height
     * of its child elements from the parent's total height.
     *
     * @param parentElement - The parent element for which the available height is calculated.
     * @param includeChildCallback - Optional callback to determine whether a child element should be included in the calculation.
     * @returns The calculated available height of the parent element.
     */
    public static getAvailableHeight(parentElement: Element, includeChildCallback?: (child: Element) => boolean): number {
        return this.getAvailableHeightOrWidth(parentElement, "height", includeChildCallback);
    }

    private static getAvailableHeightOrWidth(parentElement: Element, dimension: "height" | "width", includeChildCallback?: (child: Element) => boolean): number {
        if (!parentElement)
            return 0;

        const parentOptions: ElementDimensionOptions = { includePadding: true, includeBorder: false };
        const childOptions: ElementDimensionOptions = { includeMargin: true, includePadding: false };

        const totalSpace = dimension === "height"
            ? this.getElementHeight(parentElement, parentOptions)
            : this.getElementWidth(parentElement, parentOptions);

        if (totalSpace === 0)
            return 0;

        let usedSpace = 0;
        for (let x = 0; x < parentElement.children.length; x++) {
            const child = parentElement.children[x];
            if (includeChildCallback && !includeChildCallback(child))
                continue;
            const childDimension = dimension === "height" ? this.getElementHeight(child, childOptions) : this.getElementWidth(child, childOptions);
            usedSpace += childDimension;
        }

        return Math.max(0, totalSpace - usedSpace);
    }

    /**
     * Calculates the width of an element as a percentage of a given basis.
     *
     * @param {HTMLElement} element - The element to calculate the width for.
     * @param {number} percentageBasis - The basis for percentage calculation, default is window.innerWidth.
     * @param {boolean} round - Whether to round the result to two decimal places, default is true.
     * @returns {number} The percentage relative to the percentageBasis.
     */
    public static getElementWidthPercentage(element: HTMLElement, percentageBasis: number = window.innerWidth, round: boolean = true): number {
        return DOMUtil.calculateDimensionPercentage(() => this.getElementWidth(element), percentageBasis, round);
    }

     /**
     * Calculates the height and width of an element as percentages of the given basis.
     *
     * @param {DOMRect} rect - The bounding rectangle of the element.
     * @param {boolean} round - Whether to round the result to two decimal places, default is true.
     * @returns {{ height: string, width: string }} The height and width as percentages.
     */
    public static getHeightWidthPercentage(rect: DOMRect, round: boolean = true): { height: string; width: string } {
        const height = this.calculateDimensionPercentage(() => rect?.height ?? 0, window.innerHeight, round);
        const width = this.calculateDimensionPercentage(() => rect?.width ?? 0, window.innerWidth, round);
        return { height: `${height}%`, width: `${width}%` };
    }

    private static calculateDimensionPercentage(heightOrWidthFunc: () => number, percentageBasis: number , round: boolean = true): number {
        const elementDimension = heightOrWidthFunc();
        let percentage = (elementDimension / percentageBasis) * 100;

        if (round) {
            percentage = Math.round(percentage * 100) / 100;
        }

        return percentage;
    }

    public static getElementHeightWidth(element: Element, options?: ElementDimensionOptions): HeightWidth {
        const computedStyle = document.defaultView.getComputedStyle(element);
        const height = this.getElementHeightFromStyle(computedStyle, options);
        const width = this.getElementWidthFromStyle(computedStyle, options);
        return { height: height, width: width };
    }

    public static getComputedStyle(stylekey: string, element: Element): string {
        return document.defaultView.getComputedStyle(element)[stylekey];
    }

    public static getComputedStyleAsNumber(stylekey: string, element: Element): number {
        return this.getStyleAttrAsNumber(this.getComputedStyle(stylekey, element));
    }

    public static getComputedSize(stylekey: string, element: Element): number {
        const value = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle(stylekey, element));
        return (value != null && value >= 0) ? value : 0;
    }

    /**
     * Determines how visible the component is within some other component.  Only the vertical height is examined.
     *
     * @param element
     * The element for which the visibility percent is needed.
     * @param outerElement
     * The element that defines the bounds from which the percentage should be calculated.
     */
    public static getPercentVerticallyVisible(element: HTMLElement, outerElement: HTMLElement): number {
        const elementRect = element.getBoundingClientRect();
        const elementRectTop = elementRect.top;
        const elementRectHeight = elementRect.height;
        const elementRectBottom = elementRectTop + elementRectHeight;
        const contentRect = outerElement.getBoundingClientRect();
        const contentRectTop = contentRect.top;
        const contentRectHeight = contentRect.height;
        const contentRectBottom = contentRectTop + contentRectHeight;

        if (elementRectHeight === 0 || contentRectHeight === 0) //elements probably haven't rendered yet
            return 0;
        if (elementRectBottom < contentRectTop) //element is above the visible area
            return 0;
        if (elementRectTop > contentRectBottom) //element is below the visible area
            return 0;
        if (elementRectTop >= contentRectTop && elementRectBottom <= contentRectBottom) //element is completely visible
            return 1;
        if (elementRectTop >= contentRectTop) { //element is partially visible, runs off the bottom of the visible area
            const visibleHeight = contentRectBottom - elementRectTop;
            return visibleHeight / elementRectHeight;
        }
        if (elementRectBottom <= contentRectBottom) { //element is partially visible, runs off the top of the visible area
            const visibleHeight = elementRectBottom - contentRectTop;
            return visibleHeight / elementRectHeight;
        }

        //element is partially visible, runs off the top of the visible area AND the bottom of the visible area
        return contentRectHeight / elementRectHeight;
    }

    public static getScrollbarWidth() {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        document.body.appendChild(outer);
        const inner = document.createElement('div');
        outer.appendChild(inner);
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
        outer.parentNode.removeChild(outer);
        return scrollbarWidth;
    }

    public static convertSizeStyleToPixels(styleSize: string | number, percentageBasis: number, valueIfUnset?: number): number {
        if (typeof styleSize === "number")
            return styleSize;
        if (styleSize == null || styleSize.length === 0)
            return valueIfUnset;
        if (styleSize.endsWith("%")) {
            const percent = parseInt(styleSize.substring(0, styleSize.length - 1));
            return percent / 100 * percentageBasis;
        }
        if (styleSize.endsWith("px"))
            styleSize = styleSize.substring(0, styleSize.length - 2);
        return parseInt(styleSize);
    }

    public static setStyles(element: HTMLElement, style: Partial<CSSStyleDeclaration>) {
        Object.assign(element, style);
    }

    public static getDraggedFiles(event: DragEvent): File[] {
        const result: File[] = [];
        if (event.dataTransfer.items != null) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                const item = event.dataTransfer.items[i];
                if (item.kind === 'file')
                    result.push(item.getAsFile());
            }
        } else
            for (let i = 0; i < event.dataTransfer.files.length; i++)
                result.push(event.dataTransfer.files[i]);
        return result;
    }

    public static isActiveElement(element: HTMLElement) {
        return document.activeElement === element;
    }
}
