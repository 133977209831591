import { Alignment, CurrencySettings, CurrencyUtil, DisplayType, getApiMetadataFromCache } from "@mcleod/core";
import { Layout } from "../components/layout/Layout";
import { MouseEvent } from "../events/MouseEvent";
import { ComponentCreator, ComponentFactory } from "../page/ComponentFactory";
import { Component } from "./Component";
import { getRelevantModelRow } from "./ComponentDataLink";
import { BorderType, Container, Label, Panel, PanelProps, Table, TableRow, Textbox, TooltipOptions } from "..";

export class QuickInfo extends Component {
    private _quickInfoLayout: string;

    getMixinClassName() {
        return "QuickInfo";
    }

    get quickInfoLayout(): string {
        if (this._quickInfoLayout == null && this._boundField?.lookupModel != null)
            return getApiMetadataFromCache(this._boundField.lookupModel)?.quickInfoLayout;
        return this._quickInfoLayout;
    }

    set quickInfoLayout(value: string) {
        this._quickInfoLayout = value;
        this._syncHoverCallback();
    }

    protected shouldDisplayCurrencyTooltip(): boolean {
        return this["displayType"] === DisplayType.CURRENCY
        && CurrencySettings.getSingleton()?.multiCurrencyEnabled()
        && !this.isPrintableLabel();
    }

    private isPrintableLabel() {
        return this._element?.parentElement?.["_mcleodComponent"]?.printable === true;
    }

    protected _syncHoverCallback() {
        this.tooltipCallback = null;
        if (this.quickInfoLayout != null) {
            this.tooltipCallback = this._quickInfoTooltipCallback;
        } else if (this.shouldDisplayCurrencyTooltip()) {
            this.tooltipCallback = this._currencyTooltipCallback;
        }
    }

    protected async _quickInfoTooltipCallback(baseTooltip: Component, originatingEvent: MouseEvent): Promise<Component> {
        const keyValue = this._getKeyValue();
        if (keyValue == null)
            return null;
        const layout = this.getQuickInfoLayout();
        if (layout == null)
            return null;
        layout.onLoad = () => {
            if (layout.mainDataSource != null)
                layout.mainDataSource.search({ search: keyValue }).then(r => {
                    this.tooltipInstance = this._internalShowTooltip(layout, originatingEvent);
                });
        };
        //seems odd to return null here, but we know that the value is really going to be set within the
        //onLoad() above, after the data has been queried and the tooltip has been displayed
        return null;
    }

    protected _internalShowTooltip(tooltip: ComponentCreator, originatingEvent: MouseEvent): Component {
        return this.showTooltip(tooltip, this.getTooltipOptions(), this.getTooltipPanelProps());
    }

    protected _currencyTooltipCallback(baseTooltip: Component, originatingEvent: MouseEvent) {
        return this._internalShowTooltip(this.createCurrencyQuickInfoPanel(), originatingEvent);
    }

    public getTooltipOptions(): Partial<TooltipOptions> {
        return {
            position: this.tooltipPosition == null ? Alignment.BOTTOM : this.tooltipPosition,
            pointerColor: position => position === Alignment.RIGHT ? "warning.dark" : "background5"
        };
    }

    public getTooltipPanelProps(): Partial<PanelProps> {
        return {
            themeKey: "quickInfo",
            color: null
        };
    }

    override showTooltip(text: ComponentCreator, options?: Partial<TooltipOptions>, props?: Partial<PanelProps>) {
        if (this.shouldDisplayCurrencyTooltip() && this.isCurrencyQuickInfoPanel(text) == false) {
            return super.showTooltip(this.createCurrencyQuickInfoPanel(text), this.getTooltipOptions(), this.getTooltipPanelProps());
        } else {
            if (CurrencySettings.getSingleton()?.multiCurrencyEnabled() && text instanceof Component) {
                this.removeCurrencyFieldCallbacks(text);
            }
            return super.showTooltip(text, options, props);
        }
    }

    private getQuickInfoLayout(): Layout {
        if (this.quickInfoLayout != null)
            return Layout.getLayout(this.quickInfoLayout);
        return null;
    }

    private _getKeyValue(): any {
        const row = getRelevantModelRow(this);
        const value = row?.get(this.field);
        return value;
    }

    protected getQuickInfoLayoutDefaultValue(): string {
        if (this._boundField?.lookupModel != null)
            return getApiMetadataFromCache(this._boundField.lookupModel)?.quickInfoLayout;
    }

    private isCurrencyQuickInfoPanel(tooltip: ComponentCreator): boolean {
        return tooltip instanceof Panel && tooltip.id === "currencyTooltipPanel";
    }

    private createCurrencyQuickInfoPanel(tooltip?: ComponentCreator): Panel {
        let panel = Panel.createNoMarginNoPaddingPanel({id: "currencyTooltipPanel"});
        let compTooltip;
        if (tooltip) {
            compTooltip = ComponentFactory.createCommon(tooltip, null, this);
        }
        let addExchangeLayout = true;
        if (compTooltip != null) {
            this.removeCurrencyFieldCallbacks(compTooltip);
            const wrapper = new Panel({rowBreak: false, borderRightWidth: 1, borderType: BorderType.DOTTED,  borderRightColor: "subtle.light",marginRight: 10});
            wrapper.add(compTooltip);
            panel.add(wrapper);
            wrapper.forEveryChildComponent((child) => {
                if (addExchangeLayout == true && child instanceof Layout && child.layoutName == "lme/general/multicurrency/ExchangeRateQuickInfo") {
                    addExchangeLayout = false;
                }
            })
        }
        if (addExchangeLayout) {
            const layout = Layout.getLayout("lme/general/multicurrency/ExchangeRateQuickInfo", {width: 380});
            const currentCurrency = CurrencyUtil.getCurrencyObjectFromDataValue(this.getDataValue());
            layout.addLayoutLoadListener(() => {
                layout.mainDataSource?.search({ currency_for_exchange: currentCurrency });
            });
            panel.add(layout);
        }
        else {
            panel = Panel.createNoMarginNoPaddingPanel({id: "currencyTooltipPanel"});
            panel.add(compTooltip);
        }
        return panel;
    }

    private removeCurrencyFieldCallbacks(compTooltip: Component) {
        if (compTooltip instanceof Container) {
            compTooltip.components.forEach(component => {
                this.removeCurrencyFieldCallbacks(component);
            })
        }
        else if (compTooltip instanceof Table) {
            const rows: TableRow[] = compTooltip.rows;
            rows.forEach(row => {
                row.cells.forEach(cell => {
                    this.removeCurrencyFieldCallbacks(cell);
                })
            });
        }
        else if (compTooltip instanceof Textbox || compTooltip instanceof Label &&
            compTooltip.tooltipCallback == this._currencyTooltipCallback) {
            compTooltip.tooltipCallback = compTooltip.quickInfoLayout ? this._quickInfoTooltipCallback : null;
        }
        return;
    }
}
