import { Styles } from "@mcleod/core";

Styles.createClass("@keyframes rippleEffect", "to { transform: scale(4); opacity: 0; }", true);

export const ComponentStyles = Styles.make("cmp", {
    base: { padding: "4px", position: "relative" },
    permsEditor: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        paddingRight: "8px"
    },
    hidden: { display: "none !important" },
    hiddenNoCollapse: { visibility: "hidden" },
    borderShadow: { boxShadow: "0px 5px 5px 0px rgb(0 0 0 / 20%)" },
    ripple: {
        position: "absolute",
        transform: "scale(0)",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%"
    },
    rippleWrapper: {
        position: "absolute",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    fill: {
        flex: "1",
        maxWidth: "100%"
    },
    "unanimated": {
        transition: "none !important",
        transitionDuration: `0ms !important`,
        animation: "none !important"
    }
});
