import { Button } from "../button/Button";
import { ButtonProps } from "../button/ButtonProps";
import { Panel } from "../panel/Panel";
import { TableRow } from "./TableRow";

export class ManualModeEllipsisPopup extends Panel {
    constructor(row: TableRow) {
        super({ width: 75 });
        const sharedProps: Partial<ButtonProps> = {
            focusable: false,
            color: "black",
            margin: 0,
            borderWidth: 0,
            fillRow: true
        };
        if (row.allowEdit === true) {
            const editButton = new Button({ caption: "Edit", ...sharedProps });
            editButton.addClickListener(() => {
                row.editRow();
                row.displayComponentValues(); // clears out the default nullDisplayValue for printable textboxes
            });
            this.add(editButton);
        }
        if (row.allowDelete === true) {
            const deleteButton = new Button({ caption: "Delete", ...sharedProps });
            deleteButton.addClickListener(() => {
                row.deleteRow();
            });
            this.add(deleteButton);
        }
    }
}
