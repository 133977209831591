import { HorizontalAlignment } from "@mcleod/core";
import { Button, Component, Label } from "../..";
import { Panel } from "../panel/Panel";
import { Table } from "./Table";

export class AdditionalTableRowToolsDesignerPanel extends Panel {
    private table: Table;
    constructor(table: Table) {
        super({
            padding:0,
            _designer: table._designer,
            fillRow: true,
            fillHeight: true,
            rowBreakDefault: false
        });
        this.table = table;
        this.alternateDesignerTarget = this.table;
    }

    override add(...components: Component[]): Component {
        const buttons = components?.filter(c => c.serializationName === "button") as Button[];
        buttons.forEach(button => this.table.rows[0]?.applyDefaultToolProps(button));
        return super.add(...buttons);
    }

    createWrapperPanel(): Panel {
        return new Panel({
            fillRow: true,
            borderWidth: 1,
            borderColor: "subtle.lighter",
            height: 50,
            components: [
                new Label({
                    text: "Additional TableRow tools",
                    color: "subtle.darker",
                    fillRow: true, fillHeight: true,
                    align: HorizontalAlignment.RIGHT,
                    rowBreak:false, allowSelect: false}),
                this],
        });
    }

    override allowDropInDesigner(component: Component): boolean {
        return component.serializationName === "button";
    }
}
