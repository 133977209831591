import { GeneralSettings } from "./settings/GeneralSettings";
import { LogManager } from "./logging/LogManager";

const waitAttribute = "automation-wait";
const log = LogManager.getLogger("core.AutomationUtil");

export class AutomationUtil {

    private static loadPromises: Set<Promise<void>> = new Set();

    public static addLoadPromise(loadPromise: Promise<any>) {
        if (GeneralSettings.isRunningAutomation && loadPromise) {
            const rootElement = document.getElementById("root");
            AutomationUtil.loadPromises.add(loadPromise);
            AutomationUtil.syncLoadPromises(rootElement, "Promise added");

            loadPromise.finally(() => {
                AutomationUtil.loadPromises.delete(loadPromise);
                AutomationUtil.syncLoadPromises(rootElement, "Promise resolved/rejected");
            });
        }
    }

    private static syncLoadPromises(rootElement: HTMLElement | null, action: string) {
        if (!rootElement) {
            log.debug("Root element not found. Unable to set automation-wait attribute.");
            return;
        }

        const promiseCount = AutomationUtil.loadPromises.size;

        if (promiseCount > 0) {
            if (!rootElement.hasAttribute(waitAttribute)) {
                rootElement.setAttribute(waitAttribute, "");
                log.debug(`Attribute "${waitAttribute}" added.`);
            }
        } else {
            if (rootElement.hasAttribute(waitAttribute)) {
                rootElement.removeAttribute(waitAttribute);
                log.debug(`Attribute "${waitAttribute}" removed.`);
            }
        }

        if (log.isDebugEnabled()) {
            log.debug("syncLoadPromises", {
                action,
                promiseCount,
                waitAttribute: rootElement.hasAttribute(waitAttribute) ? "present" : "removed",
            });
        }
    }
}
