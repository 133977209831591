import { getThemeColor, Styles } from "@mcleod/core";

export class DesignerStyleConstants {
    static readonly designerStylePrefix = "dsg";

    public static isDesignerStyle(className: string): boolean {
        return className?.startsWith(this.designerStylePrefix);
    }
}

export const DesignerStyles = Styles.make(DesignerStyleConstants.designerStylePrefix, {
    designerSelected: {
        border: "2px dashed #444 !important"
    },
    designerContainer: {
        border: "1px dotted #999"
    },
    designerComponentIdError: {
        boxShadow: "inset 0px 0px 2px 2px " + getThemeColor("error")
    },
    designerComponentIdWarning: {
        boxShadow: "inset 0px 0px 2px 2px " + getThemeColor("warning")
    },
    permed: {
        border: `1px solid ${getThemeColor("error.light")} !important`,
        borderColor: `${getThemeColor("error.light")} !important`
    },
    permedSelected: {
        border: `2px dashed ${getThemeColor("error.light")} !important`
    },
    customComponent: {
        boxShadow: "inset 0px 0px 2px 2px #ffbb58"
    },
    modifiedBaseComponent: {
        boxShadow: "inset 0px 0px 2px 2px #ad12b0"
    },
    hasContextMenu: {
        position: "relative",
        border: "1px dotted #999"
    },
    "hasContextMenu::after": {
        content: '""',
        position: "absolute",
        top: "0",
        right: "0",
        height: "100%",
        width: "4px",
        background: "#40e3e3",
        pointerEvents: "none"
    },
});
