import { DataSource, Layout, LayoutHeader, SearchButton, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutPermissionsSearch extends Layout {
    buttonSearch: SearchButton;
    layoutheaderPermSearch: LayoutHeader;
    sourceUserPermsSearch: DataSource;
    switchUserOrGroup: Switch;
    tablePerms: Table;
    textboxGroup: Textbox;
    textboxUser: Textbox;
}
