import { Collection } from "../Collection";
import { RegistryObject } from "./RegistryObject";

export interface ObjectRegistryEntry<T extends RegistryObject> {
    type: string;
    object: T;
}

export abstract class AbstractObjectRegistry<T extends RegistryObject> {
    private registeredObjects: Collection<ObjectRegistryEntry<T>> = {};

    public register(...objects: T[]) {
        if (objects != null) {
            for (const object of objects) {
                this.registeredObjects[object.type] = { type: object.type, object };
            }
        }
    }

    public isRegistered(type: string): boolean {
        return this.registeredObjects[type] != null;
    }

    public getObject(type: string): T {
        return this.registeredObjects[type]?.object;
    }
}
