import { DataSource, Layout, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAccount extends Layout {
    labelTenants: Tab;
    sourceAccounts: DataSource;
    sourceTenants: DataSource;
    tableTenants: Table;
    tabsetMain: Tabset;
    textboxName: Textbox;
    textboxScac: Textbox;
}
