import {
    Alignment, AuthToken, CompanySettings, GeneralSettings, getThemeColor, UserSettings, VerticalAlignment
} from "@mcleod/core";
import { Button, ButtonVariant, Image, ImageType, Panel, PanelProps } from "@mcleod/components";
import { MainMenu } from "../MainMenu";

export abstract class PageHeader extends Panel {
    private _panelLogo: Panel;
    private _imageLogo: Image;
    private _buttonMenu: Button;
    private _menu: MainMenu;
    private static _instance: PageHeader;
    private static _pageHeaderZIndexResetTime: number = new Date().getTime();

    protected constructor(props?: Partial<PanelProps>) {
        super({
            id: "pageHeader",
            padding: 0,
            fillRow: true,
            verticalAlign: VerticalAlignment.CENTER,
            ...props
        });
        PageHeader._instance = this;

        this.initComponents();
        this.syncSettings();
    }

    public static get instance(): PageHeader {
        return this._instance;
    }

    protected abstract initComponents(): void;

    protected get panelLogo(): Panel {
        if (this._panelLogo == null) {
            this._panelLogo = new Panel({
                id: "headerLogoContainer",
                padding: 0,
                height: 60,
                fillRow: true,
                rowBreak: false
            });

            const logo = this.companyLogo;
            if (logo != null) {
                this._imageLogo = new Image({
                    id: "imageLogo",
                    imageType: ImageType.IMG,
                    imageBytes: logo,
                    height: this.logoImageHeight,
                    width: "auto",
                });
            } else {
                this._imageLogo = new Image({
                    id: "imageLogo",
                    width: 1,
                    height: 1,
                    visible: false
                });
            }
            this._panelLogo.add(this._imageLogo);
        }
        return this._panelLogo;
    }

    protected abstract get logoImageHeight(): number

    protected get buttonMenu() {
        if (this._buttonMenu == null) {
            this._buttonMenu = new Button({
                id: "buttonMenu",
                imageName: "hamburger",
                variant: ButtonVariant.round,
                imageAlign: Alignment.CENTER,
                paddingLeft: 14,
                paddingRight: 14,
                imageWidth: 28,
                imageHeight: 28,
                rowBreak: false,
                visible: false
            });
            this._buttonMenu.addClickListener(() => this.displayMenu());
        }
        return this._buttonMenu;
    }

    protected syncSettings() {
        const settings = this.companySettings;
        if (settings != null) {
            this.color = settings.text_color || getThemeColor("primary.reverse");
            this.backgroundColor = settings.header_bg_color || getThemeColor("primary");
        } else {
            this.removeAll();
        }

        GeneralSettings.getSingleton().addSettingsChangeListener(() => {
            this.syncMenuButtonVisibility();
        });
        this.syncMenuButtonVisibility();
    }

    get companySettings() {
        return CompanySettings.get();
    }

    get companyLogo() {
        return this.companySettings?.header_logo
    }

    get menu(): MainMenu {
        if (this._menu == null) {
            this._menu = new MainMenu({
                width: 300,
                height: 0,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
                padding: 0
            });
        }
        return this._menu;
    }

    public displayMenu() {
        this.menu.show(this.buttonMenu);
    }

    private syncMenuButtonVisibility() {
        this.buttonMenu.visible = this.isMenuButtonVisible;
    }

    public get isMenuButtonVisible(): boolean {
        return AuthToken.isAuthenticated() && UserSettings.get() != null;
    }

    public overridePageHeaderZIndex(millis: number) {
        PageHeader._pageHeaderZIndexResetTime = new Date().getTime() + millis + 1000;
        this.style.zIndex = "5000";
    }

    public resetPageHeaderZIndex() {
        if (PageHeader._pageHeaderZIndexResetTime <= new Date().getTime())
            this.zIndex = this.zIndex;
    }
}
