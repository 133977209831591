import { ArrayUtil } from "@mcleod/core";
import {
    Checkbox, Component, DesignerInterface, DialogProps, HeadingTableCell, PanelProps, TableColumn
} from "@mcleod/components";
import { AbstractValueDisplayValueEditor } from "./AbstractValueDisplayValueEditor";

export class TableExtraSortFieldsEditor extends AbstractValueDisplayValueEditor {
    constructor(currentValueJson: string, props?: Partial<PanelProps>, valueItems?: string[]) {
        super(currentValueJson, props, valueItems);
    }

    override get valueCaption(): string {
        return "Field";
    }

    override get valueName(): string {
        return "field";
    }

    override get displayValueCaption(): string {
        return "Caption";
    }

    override get displayValueName(): string {
        return "caption";
    }

    override get emptyCaption(): string {
        return "Extra sort fields, to be presented in the selected column, can be defined in this table";
    }

    protected override createTable() {
        super.createTable();
        const sortDescendingByDefaultColumn = new TableColumn({
            id: "columnSortDescendingByDefault",
            index: 2,
            headingDef: {
                caption: "Sort Descending By Default"
            },
            cell: this.createSortDescendingByDefaultCheckbox.bind(this)
        });

        const sortNullsAtEndColumn = new TableColumn({
            id: "columnSortNullsAtEnd",
            index: 3,
            headingDef: {
                caption: "Sort Nulls At End"
            },
            cell: this.createSortNullsAtEndCheckbox.bind(this)
        });
        this.table.addColumn(sortDescendingByDefaultColumn, false, false);
        this.table.addColumn(sortNullsAtEndColumn, false, true);
        this.table.tableHeadingRow.resetFirstLastColumnPadding();
    }

    private createSortDescendingByDefaultCheckbox(): Checkbox {
        return new Checkbox({
            caption: "",
            field: "sortDescendingByDefault",
            id: "checkboxSortDescendingByDefault",
            valueChecked: true,
            valueUnchecked: false
        });
    }

    private createSortNullsAtEndCheckbox(): Checkbox {
        return new Checkbox({
            caption: "",
            field: "sortNullsAtEnd",
            id: "checkboxSortNullsAtEnd",
            valueChecked: true,
            valueUnchecked: false
        });
    }

    override getResultValue(): any {
        const result: any[] = [];
        for (const row of this.tableRows) {
            result.push({
                field: row.data.get("field", null),
                caption: row.data.get("caption", null),
                sortDescendingByDefault: row.data.get("sortDescendingByDefault", null),
                sortNullsAtEnd: row.data.get("sortNullsAtEnd", null),
            });
        }
        if (ArrayUtil.isEmptyArray(result) === false)
            return JSON.stringify(result);
        return null;
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Extra Sort Fields",
            height: 600,
            width: 800
        }
    }

    static gatherFieldsForTableDataSource(editingDesigner: DesignerInterface): string[] {
        const selectedHeadingCells = editingDesigner.selectedComponents?.filter((component: Component) => {
            return component instanceof HeadingTableCell;
        });
        return (ArrayUtil.isEmptyArray(selectedHeadingCells) === false) ?
            selectedHeadingCells[0].gatherFieldsForRelatedDataSource(false) as string[] : null;
    }
}
