/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelUsers.ts                         *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/users";

export class AutogenModelUsers<RowType extends ModelRow> extends Endpoint<RowType, RowUsersFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowUsers extends ModelRow<RowUsersFields> {
	constructor(values?: Partial<RowUsersFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowUsersFields {
	acct_lock_date: Date;
	active_date: Date;
	admin_user: string;
	agent: string;
	agree_fieldmarshal: Date;
	agree_tailor: Date;
	agree_tailor_ext: Date;
	all_contacts: string;
	allow_new_devices: string;
	alternative_user: string;
	ap_division_id: string;
	appt_sched_id: string;
	auth_subject: string;
	auto_edit_type: string;
	avail_tract_path: string;
	avail_tract_sample: string;
	available: string;
	bridge_customer_access: string;
	brokerage_divider: number;
	brokerage_planning: string;
	brokerage_planning2: string;
	brokerage_planning3: string;
	brokerage_planning4: string;
	brokerage_planning_contnr: string;
	call_list_profile: string;
	carrier_call_list_id: string;
	carrier_mgr_profil: string;
	carrier_mgr_profil2: string;
	carrier_mgr_profil3: string;
	carrier_mgr_profil4: string;
	cell_phone: string;
	close_window: string;
	commission_payee_id: string;
	company_id: string;
	company_name: string;
	conf_cntr_template: number;
	conf_template: number;
	confirm_record: string;
	contact_id: string;
	continuous_radius: number;
	crm_vs_profile: string;
	csm_profile_id: string;
	csm_profile_id2: string;
	csm_profile_id3: string;
	csm_profile_id4: string;
	currency_reporting: string;
	currency_transaction: string;
	daily_advance_limits: string;
	daily_brok_profile: string;
	daily_snap_profile: string;
	date_format: string;
	def_move_type: string;
	default_search: number;
	default_wirecode: string;
	delivered_load_day: number;
	department_id: string;
	detention_profile_id: string;
	detention_profile_id2: string;
	detention_profile_id3: string;
	detention_profile_id4: string;
	disp_new_order_screen: string;
	distance_calc_vendor: string;
	dock_enable_wfmd_mode_swtch: string;
	dock_login_id: string;
	dragndrop_profile_id: string;
	driver_manager_profile: string;
	driver_manager_profile2: string;
	driver_manager_profile3: string;
	driver_manager_profile4: string;
	driver_manager_vendor: string;
	driver_managers: string;
	drivers_wo_manager: string;
	ds_cap_profile: string;
	ds_mv_eta_profile: string;
	ds_mv_eval_profile: string;
	dsp_override_user: string;
	email_address: string;
	email_bcc: string;
	email_client: string;
	email_host: string;
	email_imap_host: string;
	email_imap_password: string;
	email_imap_sent_folder: string;
	email_imap_ssl: string;
	email_imap_username: string;
	email_override_company: string;
	email_password: string;
	email_port: number;
	email_protocol: string;
	email_use_sent_folder: string;
	email_username: string;
	employee_type: string;
	enable_alerts: string;
	extension: string;
	external_password: string;
	external_user_id: string;
	failed_logins: number;
	fax: string;
	fileimport_profile: string;
	findnear_vendor: string;
	fleetmgmt_prof_id: string;
	fm_account_id: string;
	fm_can_view_metadata: string;
	fm_profile_id: string;
	fm_webhook_url: string;
	ftl_revenue_code_id: string;
	google_map_layer: string;
	google_map_type: string;
	grid_even_row_color: number;
	grid_insert_sort: string;
	grid_odd_row_color: number;
	id: string;
	img_category: string;
	inactive_date: Date;
	is_active: string;
	is_clearer: string;
	is_closer: string;
	is_dock_supervisor: string;
	is_dock_worker: string;
	is_email_confirmed: string;
	is_mcleod: string;
	issue_atm_cash: string;
	lock_status: string;
	log_sql: string;
	login_id: string;
	ltl_carrier_brk_status: string;
	ltl_mapping_vendor: string;
	ltl_quote_template: number;
	manifest_profile: string;
	margin_percentage: number;
	max_payment_code_amt: number;
	max_payment_code_amt_c: string;
	max_payment_code_amt_d: Date;
	max_payment_code_amt_n: number;
	max_payment_code_amt_r: number;
	mc_position_vendor: string;
	menu_tree_view: string;
	mfa_device_id: string;
	mfa_last_prompted: Date;
	mfa_secret: string;
	mfa_status: string;
	mobcomm_profile_id: string;
	mobile_pw_reset_token: string;
	mobile_pw_token_expire_ms: number;
	move_dates: string;
	movement_map_vendor: string;
	name: string;
	notification: string;
	notify_alerts: string;
	notify_frequency: string;
	notify_method: string;
	notify_occurance: string;
	operations_user_id: string;
	order_mode: string;
	order_planning2: string;
	order_planning3: string;
	order_planning4: string;
	order_planning_vendor: string;
	order_tran_request: string;
	orderhist_profile: string;
	outbound_notification: string;
	override_vendor_division: string;
	payee_id: string;
	pbw_brokerage_planning: string;
	phone: string;
	planner: string;
	planning: string;
	planning_container: string;
	planning_divider: number;
	portal_admin_id: string;
	primary_agency: string;
	prompt_cont_moves: string;
	quote_comp_act_create: string;
	quote_comp_act_id: string;
	quote_pend_act_create: string;
	quote_pend_act_id: string;
	quote_template: number;
	rapid_alert_expand: string;
	rapid_alert_sound: string;
	receive_alerts: string;
	recruiting_profile: string;
	request_location_vendor: string;
	revenue_code_id: string;
	route_detail_vendor: string;
	save_screen_sizes: string;
	search_history_limit: number;
	sec_ans1: string;
	sec_ans2: string;
	sec_question1: number;
	sec_question2: number;
	seg_alloc_code: string;
	sel_text_on_focus: string;
	show_active: string;
	show_google_route: string;
	show_inactive: string;
	single_point_vendor: string;
	support_inactivate_date: Date;
	system_tray: string;
	table_even_row_color: string;
	table_odd_row_color: string;
	telephony_access_token: string;
	telephony_access_token_exp: Date;
	telephony_number: string;
	telephony_password: string;
	telephony_refresh_token: string;
	telephony_refresh_token_exp: Date;
	terminal_id: string;
	time_format: string;
	token_key: number;
	toolbar_multi_rows: string;
	toolbar_setting: string;
	trailer_loan_profile_id: string;
	trailer_wash_loc: string;
	trailer_wash_user: string;
	trimble_map_traffic: string;
	tw_seg_code: string;
	tw_ticket_template: number;
	type_agents: string;
	type_carrier: string;
	type_company_drs: string;
	type_number: string;
	type_owner_oper: string;
	user_group: string;
	user_type_desc: string;
	vendor_user_id: string;
	view_movement_vendor: string;
	waterfall_default_rt_guide: string;
	waterfall_include_rates: string;
	waterfall_include_rt_guides: string;
	waterfall_prevent_tendering: string;
	web_acct_status: string;
	web_agent: string;
	web_created_on: Date;
	web_enabled_on: Date;
	web_last_login: Date;
	web_locked_on: Date;
	web_map: string;
	web_password: string;
	web_user_id: string;
	web_user_type: string;
	window_location: string;
	windows_login: string;
	wire_trans_per_day: number;
	zone_mapping_vendor: string;
}

