import {
    Button, ButtonVariant, DomEventListener, Image, KeyModifiers, Label, LabelProps, Panel, PanelProps, ScreenStack
} from "@mcleod/components";
import {
    Alignment, ArrayUtil, AuthToken, GeneralSettings, Keys, Navigation, ShiftedNumbers, StringUtil, UserSettings, VerticalAlignment
} from "@mcleod/core";
import { CommonDialogs } from "../CommonDialogs"
import { DiagnosticDialog } from "../DiagnosticDialog";
import { HelpPanel } from "../HelpPanel";
import { Login } from "../Login";
import { PageHeader } from "./PageHeader";
import { PageHeaderProvider } from "./PageHeaderProvider";
import { Router } from "../router/Router";
import { SupportEmailProvider } from "../SupportEmailProvider";

export class WebPageHeader extends PageHeader {
    keyListener: DomEventListener;
    filler: Panel;
    panelHelp: HelpPanel;
    panelNotifications: Panel;
    panelUser: Panel;
    panelSupportEmail: Panel;
    labelEnvironmentType: Label;

    private constructor(props?: PanelProps) {
        super(props);
        this.keyListener = (event) => this.handleKey(event as KeyboardEvent);
        document.addEventListener("keydown", this.keyListener);
    }

    static get instance(): WebPageHeader {
        if (PageHeader.instance == null)
            new WebPageHeader(); // The parent will set its own ._instance value.
        return PageHeader.instance as WebPageHeader;
    }

    protected override initComponents() {
        this.filler = new Panel({
            fillRow: true,
            rowBreak: false
        });
        this.panelHelp = new HelpPanel();
        this.panelNotifications = new Panel({
            id: "notificationContainer",
            rowBreak: false
        });
        this.panelUser = new Panel({
            id: "userContainer",
            fillHeight: true,
            verticalAlign: VerticalAlignment.CENTER
        });
        this.panelSupportEmail = new Panel({
            id: "supportEmailPanel",
            verticalAlign: VerticalAlignment.CENTER,
            rowBreak: false,
            padding: 0
        });

        this.add(
            this.buttonMenu,
            this.panelLogo,
            this.filler,
            this.panelHelp,
            this.panelNotifications,
            this.panelUser,
            this.panelSupportEmail
        );
    }

    override get logoImageHeight(): number {
        return 60;
    }

    protected override syncSettings() {
        const environmentMessage = GeneralSettings.get()?.environment_message;
        if (environmentMessage != null) {
            this.labelEnvironmentType = new Label({
                text: environmentMessage,
                fontBold: true,
                fontSize: "xxlarge",
                marginLeft: 64,
                fillHeight: true,
                verticalAlign: VerticalAlignment.CENTER
            });
            this.filler.add(this.labelEnvironmentType)
        }

        super.syncSettings();

        GeneralSettings.getSingleton().addSettingsChangeListener(() => {
            if (!UserSettings.isUserTypeLME()) {
                this.panelHelp.configurePortalHelp();
            }
            this.syncUserInfoPanel();
            this.syncSupportEmailLinkPanel();
        });
        this.syncUserInfoPanel();
        this.syncSupportEmailLinkPanel();
    }

    public syncUserInfoPanel() {
        this.panelUser.removeAll();
        const userSettings = UserSettings.get();
        if (AuthToken.isAuthenticated() && userSettings != null) {
            const panelInfo = new Panel({
                fillRow: true,
                rowBreak: false,
                padding: 0,
                verticalAlign: VerticalAlignment.CENTER,
                marginRight: 8
            });
            this.panelUser.add(panelInfo);
            const panelDropdown = new Panel({
                verticalAlign: VerticalAlignment.CENTER,
                fillHeight: true,
                padding: 0
            });
            this.panelUser.add(panelDropdown);
            const dropdown = this.createUserDropdown();
            panelDropdown.add(new Button({
                imageName: "chevron",
                imageWidth: 36,
                imageHeight: 36,
                variant: ButtonVariant.round,
                marginLeft: 0,
                marginRight: 14,
                dropdownItems: dropdown,
                dropdownProps: { align: Alignment.RIGHT }
            }));
            panelInfo.add(new Label({
                fontSize: "large",
                fillHeight: true,
                text: userSettings.user_name
            }));

            if (!StringUtil.isEmptyString(userSettings.user_company_name))
                panelInfo.add(new Label({
                    fontSize: "small",
                    fillHeight: true,
                    text: userSettings.user_company_name
                }));
        } else {
            this.panelUser.add(new Label({
                text: "Sign in",
                link: Router.loginRoute,
                fillHeight: true,
                marginRight: 8
            }));
        }
    }

    private syncSupportEmailLinkPanel() {
        this.panelSupportEmail.removeAll();
        if (SupportEmailProvider.get()?.shouldDisplayPageHeaderSupportEmailLink() === true) {
            this.panelSupportEmail.add(new Image({
                name: "mail",
                onClick: () => SupportEmailProvider.get()?.doOnSupportEmailClick(),
                fillHeight: true,
                marginRight: 8,
                tooltip: "Let us know how we can help!"
            }));
            if (this.indexOf(this.panelSupportEmail) < 0)
                this.insert(this.panelSupportEmail, this.indexOf(this.panelUser));
        } else {
            this.remove(this.panelSupportEmail);
        }
    }

    private createUserDropdown() {
        const allProps = { paddingRight: 28, paddingLeft: 8, paddingBottom: 8, paddingTop: 8, fontSize: "large", color: "subtle.darker" };
        const labelSignOut = new Label({ text: "Sign out", imageName: "exit", ...allProps, borderTopWidth: 1, borderTopColor: "strokeSecondary" });
        labelSignOut.addClickListener(() => {
            Login.logout();
        });
        const result = [];
        if (UserSettings.get().user_settings_page_access) {
            const labelSettings = new Label({ text: "Settings", imageName: "settings", ...allProps });
            labelSettings.addClickListener(() => {
                Navigation.navigateTo("common/UserSettings");
            });
            result.push(labelSettings);
        }
        const addlDropdownItems: Label[] = this.getAdditionalUserDropdownItems(allProps);
        if (ArrayUtil.isEmptyArray(addlDropdownItems) === false)
            result.push(...addlDropdownItems);
        result.push(labelSignOut);
        return result;
    }

    private getAdditionalUserDropdownItems(sharedProps: Partial<LabelProps>): Label[] {
        return PageHeaderProvider.get()?.getUserDropdownMenuItems(sharedProps);
    }

    private handleKey(event: KeyboardEvent) {
        const ctrlKeyOnly: KeyModifiers = { ctrlKey: true, shiftKey: false, altKey: false };
        if (event.key === "m" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.displayMenu();
            event.preventDefault();
        }
        else if (event.key >= "0" && event.key <= "9" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.menu.showToolbox(event);
            event.stopPropagation();
            event.preventDefault();
        }
        else if (KeyModifiers.hasModifiers({ ctrlKey: true, shiftKey: true, altKey: false }, event) &&
            ((event.key >= "0" && event.key <= "9") || ShiftedNumbers[event.key] != null)) {
            let numberKey: string;
            if (ShiftedNumbers[event.key] != null)
                numberKey = ShiftedNumbers[event.key];
            else
                numberKey = event.key;
            this.menu.showFavorite(event, numberKey);
            event.stopPropagation();
            event.preventDefault();
        }
        else if (event.key === "h" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            Navigation.navigateTo("");
            event.preventDefault();
        }
        else if (event.key === Keys.E.toLowerCase() && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.displayMenu();
            this.menu.selectFirstToolboxItem();
            event.preventDefault();
        }
        else if (event.key === Keys.D.toLowerCase() && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.displayMenu();
            this.menu.selectFirstFavorite();
            event.preventDefault();
        }
        else if (event.key === "F10" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true)
            new DiagnosticDialog().show();
        else if (event.key === "F11" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true)
            this.openInModelDesigner();
        else if (event.key === "F12" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true)
            this.openInUIDesigner();
    }

    private openInModelDesigner() {
        if (GeneralSettings.getSingleton().isRunningInIDE()) {
            const layout = ScreenStack.getCurrentLayout();
            if (layout == null) {
                CommonDialogs.showMessage("Could not discover current layout.");
            } else {
                const url = layout.mainDataSource?.url;
                if (url == null)
                    CommonDialogs.showMessage("The layout doesn't have a model.");
                else
                    Navigation.navigateTo("designer/model/ModelDesigner?open=" + url, { newTab: true });
            }
        }
    }

    private openInUIDesigner() {
        const layout = ScreenStack.getCurrentLayout();
        if (layout == null) {
            CommonDialogs.showMessage("Could not discover current layout.");
        } else {
            let path = "designer/ui/custom/McLeodTailor";
            if (GeneralSettings.getSingleton().isRunningInIDE())
                path = "designer/ui/UIDesigner";
            Navigation.navigateTo(`${path}?open=${layout.layoutName}`, { newTab: true });
        }
    }
}
