import { AuthToken } from "../AuthToken";
import { Logger } from "../logging/Logger";
import { LogManager } from "../logging/LogManager";
import { AbstractObjectRegistry } from "../registry/AbstractObjectRegistry";
import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";

export class SettingsRegistry extends AbstractObjectRegistry<AbstractSettings> {
    private static registry: SettingsRegistry;
    private static log: Logger;

    public static get(): SettingsRegistry {
        this.initialize();
        return this.registry;
    }

    private static initialize() {
        if (this.registry == null)
            this.registry = new SettingsRegistry();
    }

    // It's very unlikely that an authenticated user is getting a live update for some unauthenticated setting,
    // so if we don't know if we are setting unauth or auth settings we will go off the presence of an auth token.
    public static update(type: string, newValues: AbstractSettingsDef,
        isAuthSet: boolean = AuthToken.isAuthenticated()) {
        this.getLog().debug("Updating settings of type [%o] with data %o", type, newValues);
        if (this.get().isRegistered(type) !== true) {
            this.getLog().debug("Not updating settings, settings object is not registered");
            return;
        }
        const singleton = this.get().getObject(type);
        if (singleton == null) {
            this.getLog().debug("Not updating settings, registered settings singleton is null");
            return;
        }
        singleton.set(newValues, isAuthSet);
        this.getLog().debug("Finished replacing settings value for key [%s]", type);
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("core.settings.SettingsRegistry");
        return this.log;
    }
}
