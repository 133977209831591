import { UserSettings } from "./src";
import { ExecutionLog } from "./src/logging/ExecutionLog";
import { ClientActivityTracker } from "./src/client/ClientActivityTracker";

export class ProcessInfo {
    private executionLog?: string; //appears to be unused, but it can be included in serialized data sent to the server
    private programDisplayName: string;
    private lastActive: number;

    constructor(detail: boolean = false) {
        this.programDisplayName = this.programName + " " + UserSettings.get().company_id;
        this.lastActive = ClientActivityTracker.getlastActive();
        // TODO: populate properties and diagnostic tabs of client admin
        if (detail === true)
            this.executionLog = ExecutionLog.getAsString();
    }

    public get programName(): string {
        return "//web";
    }
}
