import { VerticalAlignment } from "@mcleod/core";
import { Button } from "../button/Button";
import { ButtonProps } from "../button/ButtonProps";
import { Component } from "../../base/Component";
import { ComponentProps } from "../../base/ComponentProps";
import { Cursor } from "../../base/Cursor";
import { Image } from "../image/Image";
import { ImageProps } from "../image/ImageProps";
import { Panel } from "../panel/Panel";

export class TableRowToolsViewer extends Panel {
    private ellipsisAction: "persist" | "hide" = "persist";
    private hideFunction: () => void;
    private tools: Component[] = [];

    constructor(tools: Component[], rowEllipsisButton: Button, hideFunction: () => void) {
        super({
            rowBreakDefault: false,
            borderRadius: 20,
            backgroundColor: "subtle.lightest",
            verticalAlign: VerticalAlignment.CENTER
        });
        this.hideFunction = hideFunction;
        this.addMouseLeaveListener(this.hideFunction);
        const ellipsisButton = this.createEllipsisButton(rowEllipsisButton);
        this.tools.push(...tools);
        this.addTools(...tools, ellipsisButton);
    }

    private createEllipsisButton(rowEllipsisButton: Button): Button {
        return new Button({
            imageName: rowEllipsisButton.imageName,
            color: rowEllipsisButton.color,
            imageRotation: rowEllipsisButton.imageRotation,
            borderWidth: rowEllipsisButton.borderWidth,
            cursor: Cursor.DEFAULT,
            onClick: () => this.persistOrHide()
        });
    }

    reset() {
        this.ellipsisAction = "persist";
        this.addMouseLeaveListener(this.hideFunction);
        this.tools.forEach(tool => tool.revertTempState());
    }

    persistOrHide() {
        if (this.ellipsisAction === "persist") {
            this.ellipsisAction = "hide";
            this.removeMouseLeaveListener(this.hideFunction);
        }
        else {
            this.reset();
            this.hideFunction();
        }
    }

    private addTools(...tools: Component[]) {
        tools.forEach(tool => {
            tool.margin = 4;
            tool.padding = 0;
            this.add(tool);
        })
    }

    syncTools() {
        this.tools.forEach(tool => this.setToolHoverProps(tool));
    }

    setToolHoverProps(tool: Component) {
        tool.revertToOriginalState();
        //only show tool's provided colors when we hover over that tool
        const hoverProps: Partial<ComponentProps> = {};
        const nonHoverProps: Partial<ComponentProps> = {};
        this.gatherToolProps(tool, hoverProps, nonHoverProps);
        tool.applyTempState(nonHoverProps);

        tool._element.onmouseenter = () => tool.applyTempState(hoverProps);
        tool._element.onmouseleave = () => tool.revertTempState();
    }

    private gatherToolProps(tool: Component, hoverProps: Partial<ComponentProps>,
        nonHoverProps: Partial<ComponentProps>) {
        const nonHoverColor = "subtle.darker";
        const nonHoverReverse = "subtle.reverse";
        if (tool instanceof Button) {
            const buttonHoverProps = hoverProps as Partial<ButtonProps>;
            const buttonNonHoverProps = nonHoverProps as Partial<ButtonProps>;
            if (tool.imageProps != null) {
                buttonHoverProps.imageProps = { ...tool.imageProps };
                buttonNonHoverProps.imageProps = {
                    name: tool.imageProps.name,
                    fill: nonHoverColor,
                    stroke: nonHoverReverse
                };
            }
            if (tool.imageColor != null) {
                buttonHoverProps.imageColor = tool.imageColor;
                buttonNonHoverProps.imageColor = nonHoverColor;
            }
            if (tool.imageStroke != null) {
                buttonHoverProps.imageStroke = tool.imageStroke;
                buttonNonHoverProps.imageStroke = nonHoverReverse;
            }
        }
        else if (tool instanceof Image) {
            const imageHoverProps = hoverProps as Partial<ImageProps>;
            const imageNonHoverProps = nonHoverProps as Partial<ImageProps>;
            if (tool.fill != null) {
                imageHoverProps.fill = tool.fill;
                imageNonHoverProps.fill = nonHoverColor;
            }
            if (tool.stroke != null) {
                imageHoverProps.stroke = tool.stroke;
                imageNonHoverProps.stroke = nonHoverReverse;
            }
        }
        if (tool.color != null) {
            hoverProps.color = tool.color;
            nonHoverProps.color = nonHoverColor;
        }
        if (tool.backgroundColor != null) {
            hoverProps.backgroundColor = tool.backgroundColor;
            nonHoverProps.backgroundColor = null;
        }
    }
}
