/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelResponsibleHist.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/responsible-hist";

export class AutogenModelResponsibleHist<RowType extends ModelRow> extends Endpoint<RowType, RowResponsibleHistFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowResponsibleHist extends ModelRow<RowResponsibleHistFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowResponsibleHistFields {
    company_id: string;
    hierarchy_level_1: string;
    hierarchy_level_2: string;
    hierarchy_level_3: string;
    hierarchy_level_4: string;
    hierarchy_level_5: string;
    commission_role_type: string;
    commissions_split_percent: string;
    order_id: string;
    xfer2commission_date: string;
    id: string;
    responsible_role: string;
    row_id: string;
    row_type: string;
}

