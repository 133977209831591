import { DOMUtil } from "@mcleod/core";
import { Component, ComponentDeserializer, Container } from "../..";
import { ComponentTypes } from "../../base/ComponentTypes";
import { HeadingTableCell } from "./HeadingTableCell";
import { SortFieldInfo } from "./SortFieldInfo";
import { TableCellProps } from "./TableCellProps";
import { TableColumnProps } from "./TableColumnProps";

export class TableColumn implements TableColumnProps {
    id: string;
    index: number;
    headingDef: HeadingTableCell | Partial<TableCellProps>;
    _headingCell: HeadingTableCell;
    cell: any;
    cellDef: any;

    constructor(props?: Partial<TableColumnProps>) {
        if (props != null)
            for (const key in props)
                this[key] = props[key];
    }

    get headingCell(): HeadingTableCell {
        return this._headingCell;
    }

    set headingCell(value: HeadingTableCell) {
        this._headingCell = value;
        this.deleteHeadingDef();
    }

    async getFields(): Promise<string[]> {
        const result: string[] = [];
        if (this.cellDef != null)
            await this.addFieldsFromCellDef(result, this.cellDef.def);
        else if (typeof this.cell === "function")
            this.addFieldsFromComponents(result, this.cell());
        return result;
    }

    private async addFieldsFromCellDef(target: string[], def: any) {
        if (!ComponentTypes.getComponentType(def.type)?.isCompound) {
            if (def.field != null && !target.includes(def.field))
                target.push(def.field);
        }
        else {
            //we have to deserialize compound components to get their field list
            const compoundComponent = await new ComponentDeserializer({owner: null, def}).deserializeSingleComponent();
            const fields = compoundComponent.getFieldNames();
            if (fields != null) {
                for (const fieldName of fields) {
                    if (fieldName != null && !target.includes(fieldName))
                        target.push(fieldName);
                }
            }
        }
        if (def.components != null)
            for (const comp of def.components)
                this.addFieldsFromCellDef(target, comp);
    }

    private addFieldsFromComponents(target: string[], components: any) {
        if (components instanceof Component) {
            if (components.field != null && !target.includes(components.field))
                target.push(components.field);
        }
        else if (components instanceof Container)
            this.addFieldsFromComponents(target, components.getRecursiveChildren());
        else if (Array.isArray(components)) {
            for (const comp of components) {
                this.addFieldsFromComponents(target, comp);
            }
        }
    }

    async getSortFields(): Promise<SortFieldInfo[]> {
        // Using a map (instead of the array we return) to make sure we only return one SortFieldInfo object per field
        const result: Map<string, SortFieldInfo> = new Map();
        if (this.cellDef != null)
            await this.addSortFieldsFromCellDef(result, this.cellDef.def);
        else if (typeof this.cell === "function")
            this.addSortFieldsFromComponents(result, this.cell());
        this.addExtraSortFields(result);
        return Array.from(result.values());
    }

    private async addSortFieldsFromCellDef(collectedInfo: Map<string, SortFieldInfo>, def: any) {
        if (!ComponentTypes.getComponentType(def.type)?.isCompound) {
            const sortFieldInfo = SortFieldInfo.createFromCellDef(def);
            this.collectSortFieldInfo(collectedInfo, sortFieldInfo);
        }
        else {
            //we have to deserialize compound components to get their field list
            const compoundComponent = await new ComponentDeserializer({owner: null, def}).deserializeSingleComponent()
            this.pushCompSortFields(collectedInfo, compoundComponent);
        }
        if (def.components != null)
            for (const comp of def.components)
                this.addSortFieldsFromCellDef(collectedInfo, comp);
    }

    private addSortFieldsFromComponents(collectedInfo: Map<string, SortFieldInfo>,
        components: Component | Component[]) {
        if (components instanceof Component)
            this.pushCompSortFields(collectedInfo, components);
        else if (components instanceof Container)  // this condition will never pass because we checked instanceof Component already
            this.addSortFieldsFromComponents(collectedInfo, components.getRecursiveChildren());
        else if (Array.isArray(components))
            for (const comp of components)
                this.addSortFieldsFromComponents(collectedInfo, comp);
    }

    private pushCompSortFields(collectedInfo: Map<string, SortFieldInfo>, component: Component) {
        const sortFieldInfo = SortFieldInfo.createFromComponent(component);
        this.collectSortFieldInfo(collectedInfo, ...sortFieldInfo);
    }

    private collectSortFieldInfo(collectedInfo: Map<string, SortFieldInfo>, ...sortFieldInfo: SortFieldInfo[]) {
        if (sortFieldInfo != null) {
            for (const sfi of sortFieldInfo) {
                if (sfi?.field != null && collectedInfo.has(sfi.field) === false)
                    collectedInfo.set(sfi.field, sfi);
            }
        }
    }

    private addExtraSortFields(collectedInfo: Map<string, SortFieldInfo>) {
        if (this.headingCell.extraSortFields != null) {
            for (const sfi of this.headingCell.resolvedExtraSortFields) {
                this.collectSortFieldInfo(collectedInfo, sfi);
            }
        }
    }

    /**
     * Delete the headingDef from this column after the column has been created.  It's not needed,
     * and can only confuse people.  Anyone trying to use it will (and should) get an error.
     */
    deleteHeadingDef() {
        delete this.headingDef;
    }

    getCopyForTableConfig(): TableColumn {
        const result = new TableColumn();
        result.id = this.id;
        result.index = this.index;
        result._headingCell = new HeadingTableCell({
            id: this.headingCell.id,
            caption: this.headingCell.caption,
            width: DOMUtil.getElementWidth(this._headingCell._element)
        });
        if (this.cellDef != null)
            result.cellDef = this.cellDef;
        else
            result.cell = this.cell;
        return result;
    }
}
