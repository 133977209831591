import { DesignerInterface, PanelProps, PropType, Textbox } from "@mcleod/components";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";
import { SpecialtyEditorDef } from "./SpecialtyEditorDef";

/**
 * This class creates the specialty editor dialog that is launched by the button next to the property editor
 * component.  Examples would include the layout selector, the color selector, etc.
 */
export class SpecialtyEditorFactory {
    private static readonly registry: Map<string, SpecialtyEditorDef> = SpecialtyEditorFactory.createRegistry();

    private static createRegistry(): Map<string, SpecialtyEditorDef> {
        const result = new Map<string, SpecialtyEditorDef>();
        result.set(PropType.helpLink, new SpecialtyEditorDef(PropType.helpLink));
        result.set(PropType.layout, new SpecialtyEditorDef(PropType.layout, true));
        result.set(PropType.fieldList, new SpecialtyEditorDef(PropType.fieldList, true));
        result.set(PropType.endpoint, new SpecialtyEditorDef(PropType.endpoint, true));
        result.set(PropType.tailorExtension, new SpecialtyEditorDef(PropType.tailorExtension));
        result.set(PropType.image, new SpecialtyEditorDef(PropType.image, true));
        result.set(PropType.color, new SpecialtyEditorDef(PropType.color, true));
        result.set(PropType.stringArray, new SpecialtyEditorDef(PropType.stringArray));
        result.set(PropType.multilineString, new SpecialtyEditorDef(PropType.multilineString, true));
        result.set(PropType.dropdownItem, new SpecialtyEditorDef(PropType.dropdownItem));
        result.set(PropType.actionPermission, new SpecialtyEditorDef(PropType.actionPermission));
        result.set(PropType.contextMenu, new SpecialtyEditorDef(PropType.contextMenu));
        result.set(PropType.tableExtraSortFields, new SpecialtyEditorDef(PropType.tableExtraSortFields));
        return result;
    }

    getEditor(propType: PropType, textbox: Textbox, editingDesigner: DesignerInterface, panelProps?: Partial<PanelProps>): SpecialtyPropertyEditor {
        const typeDef = SpecialtyEditorFactory.registry.get(propType);
        return typeDef.construct(textbox.text, editingDesigner, panelProps);
    }

    allowsEnabledTextbox(propType: PropType): boolean {
        const typeDef = SpecialtyEditorFactory.registry.get(propType);
        return typeDef != null ? typeDef.textboxEnabled : true;
    }

    getButtonImage(propType: PropType): string {
        const typeDef = SpecialtyEditorFactory.registry.get(propType);
        return typeDef != null ? typeDef.buttonImage : null;
    }
}
