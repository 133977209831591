import { ArrayUtil, StringUtil } from ".";

export type ObjectFilterFunction<ObjectType> = (object: ObjectType) => boolean;

export class ObjectUtil {
    public static isEmptyObject(obj: any): boolean {
        return obj == null || Object.entries(obj).length === 0;
    }

    public static containsKey(obj: any, key: any): boolean {
        if (obj == null)
            return false;
        return Object.keys(obj).includes(key);
    }

    public static isObject(a: any): boolean {
        return a && typeof a === "object" && !Array.isArray(a);
    }

    public static deepCopy(inObject: any, excluded?: string[], returnDateToString: boolean = false): any {
        return ObjectUtil.internalDeepCopy(inObject, excluded, returnDateToString);
    }

    private static internalDeepCopy(inObject: any, excluded?: string[], returnDateToString: boolean = false, seenObjects = new WeakMap()): any {
        if (seenObjects.has(inObject)) {
            return seenObjects.get(inObject);
        }

        if (typeof inObject !== "object" || inObject === null || inObject === undefined)
            return inObject // Return the value if inObject is not an object

        let result = Array.isArray(inObject) ? [] : {}

        seenObjects.set(inObject, result);

        if (!(inObject instanceof Date)) {
            for (const key in inObject) {
                if (excluded != null && excluded.includes(key))
                    continue;
                result[key] = ObjectUtil.internalDeepCopy(inObject[key], excluded, returnDateToString, seenObjects);
            }
        }
        else {
            if (returnDateToString === false)
                result = new Date(inObject);
            else
                result = inObject.toString();
        }
        return result;
    }

    public static deepEqual(x: any, y: any): boolean {
        return ObjectUtil.internalDeepEqual(x, y);
    }

    private static internalDeepEqual(x: any, y: any, seenObjects = new WeakMap()): boolean {
        if (seenObjects.has(x) && seenObjects.get(x) === y) {
            return true;
        }

        if (x === y) return true;

        const ok = Object.keys, tx = typeof x, ty = typeof y;

        if (x && y && tx === 'object' && tx === ty) {
            seenObjects.set(x, y);

            return ok(x).length === ok(y).length &&
                ok(x).every(key => ObjectUtil.internalDeepEqual(x[key], y[key], seenObjects));
        }

        return x === y;
    }

    public static appendObject(targetObject: any, srcObject: any, returnOriginal = true): any {
        const result = returnOriginal ? {} : undefined;
        if (srcObject == null)
            return targetObject;
        if (targetObject == null)
            targetObject = {};
        for (const key in srcObject) {
            if (returnOriginal)
                result[key] = targetObject[key];
            targetObject[key] = srcObject[key];
        }
        return result;
    }

    /**
     * This function gets a member of a object by its string key.  The difference between this and a standard
     * object[key] is that "key" can contain dots that allow us to return a deeply nested child.
     *
     * Consider this example object:

        const mainObject = {
          someChild: {
            someNestedChild: {
              value: "Test"
            }
          }
        }
     *
     * We could call getNestedObject(mainObject, "someChild.someNestedChild.value") to return the "Test" value.
     * If any of the tokens in the nesting are null/undefined, null or undefined.
     *
     * @param object
     * @param key
     */
    public static getNestedObject(object: any, key: string): any {
        if (object == null)
            return object;
        if (key == null)
            return undefined;
        let evalObject = object;
        const tokens = key.split(".");
        for (const token of tokens) {
            evalObject = evalObject[token];
            if (evalObject == null)
                return evalObject;
        }
        return evalObject;
    }

    public static getObjectProps(o: any, ...propKeys: string[]): any {
        const result = {};
        for (const key of propKeys)
            if (key in o)
                result[key] = o[key]
        return result;
    }

    /**
     * This method is used to set multiple members of an object and return the original values
     * of the members of the object.  It is useful to save the properties of an object, change
     * them temporarily, and then easily set them back to their original values.
     *
     * For example, consider this block that sets a few styles in the body, slides another
     * element into the body, and then resets those properties when finished.

        const bodyStyle = document.body.style;
          const savedStyles = ObjectUtil.replaceObjectProps(bodyStyle, {
            overflow: "hidden",
            overflowY: "hidden",
            padding: 0,
            margin: 0
          });
          document.body.appendChild(someOtherElement);
          someOtherElement.slideIn().then(() => {
            ObjectUtil.replaceObjectProps(bodyStyle, savedStyles);
          });
     */
    public static replaceObjectProps(targetObject: any, replaceWith: any): any {
        const result = {};
        for (const key in replaceWith) {
            result[key] = targetObject[key];
            targetObject[key] = replaceWith[key];
        }
        return result;
    }

    /**
    * This accepts an array of objects (specified by the ObjectType generic) that contain arrays of those same objects and will return an array of those objects
    * where the provided filterFunction returns true.  The filterFunction will be called for each recusrive array element.
    *
    * @param array The top-level array of ObjectType that we want to filter
    * @param nestedKeyName The key name of ObjectType that contains the nested array
    * @param filterFunction The function that will be called for each element to determine whether that element should be included in the result.  Return true
    * from the filterFunction to indicate that the element should be in the result; return false if not.
    * @returns an array of ObjectType that has been filtered as described.
    */
    public static filterRecursiveArray<ObjectType = any>(array: ObjectType[], nestedKeyName: string, filterFunction: ObjectFilterFunction<ObjectType>, removeEmptyParents: boolean = false): ObjectType[] {
        let result = array.filter(o => {
            const nested: any[] = o[nestedKeyName];
            if (nested != null)
                o[nestedKeyName] = ObjectUtil.filterRecursiveArray(nested, nestedKeyName, filterFunction);
            if (nested != null && nested.length === 0)
                return false;
            return filterFunction(o);
        });
        if (removeEmptyParents === true)
            result = ObjectUtil.filterRecursiveArrayWithZeroLength(array, nestedKeyName);
        return result;
    }

    /**
     * Sorry about the obnoxious method name.  Suggestions are welcome.
     *
     * This accepts an array of objects (specified by the ObjectType generic) that contain arrays of those same objects and will return an array of those objects
     * where only the leaf elements matching a filter string.
     *
     * That's a long way to say, "this method allows filtering data in a Tree component," usually by the content of a Textbox used for searching.
     *
     * @param array The top-level array of ObjectType that we want to filter
     * @param nestedKeyName The key name of ObjectType that contains the nested array
     * @param filterKeyName The key name of ObjectType (for the leaf elements) whose value will be used for filtering
     * @param mustContain The string that each ObjectType must contain in the filterKeyName value
     * @param ignoreCase Whether or not this filter should ignore whether the case of strings being compared
     * @returns an array of ObjectType that has been filtered as described.
     */
    public static filterRecursiveArrayContainingString<ObjectType = any>(array: ObjectType[], nestedKeyName: string, filterKeyName: string, mustContain: string, ignoreParents: boolean = true, ignoreCase: boolean = true): ObjectType[] {
        if (ignoreCase && mustContain != null)
            mustContain = mustContain.toLowerCase();
        const result = ObjectUtil.filterRecursiveArray(array, nestedKeyName, (objectToFilter: any) => {
            let objectValue = objectToFilter[filterKeyName]?.toString();
            if (ignoreParents === true && objectToFilter[nestedKeyName] != null)
                return true;
            if (mustContain == null && objectValue == null)
                return true;
            else if (mustContain == null || objectValue == null)
                return false;
            if (ignoreCase)
                objectValue = objectValue.toLowerCase();
            return objectValue.indexOf(mustContain) >= 0;
        });
        return ObjectUtil.filterRecursiveArrayWithZeroLength(result, nestedKeyName);
    }

    /**
     * Sorry about the obnoxious method name. I'm just copying whoever created filterRecursiveArrayContainingString, suggestions are welcome.
     *
     * This accepts an array of objects (specified by the ObjectType generic) that contain arrays of those same objects and will return an array of those objects
     * where only the leaf elements match based on the supplied filterFunction.
     *
     * @param array The top-level array of ObjectType that we want to filter
     * @param nestedKeyName The key name of ObjectType that contains the nested array
     * @param filterFunction The function that will be called for each element to determine whether that element should be included in the result.
     * @param ignoreParents When true, objects containing nested arrays will not be filtered
     * @returns an array of ObjectType that has been filtered as described.
     */
    public static filterRecursiveArrayWithFunction<ObjectType = any>( array: ObjectType[], nestedKeyName: string, filterFunction: ObjectFilterFunction<ObjectType>, ignoreParents: boolean = true ): ObjectType[] {
        const result = array.filter((objectToFilter: any) => {
            const nestedArray: ObjectType[] = objectToFilter[nestedKeyName];
            if (nestedArray != null) {
                objectToFilter[nestedKeyName] = ObjectUtil.filterRecursiveArrayWithFunction(
                    nestedArray,
                    nestedKeyName,
                    filterFunction,
                    ignoreParents
                );
            }

            if (ignoreParents && nestedArray != null) {
                return true;
            }

            return filterFunction(objectToFilter);
        });

        return ObjectUtil.filterRecursiveArrayWithZeroLength(result, nestedKeyName);
    }

    /**
    * This accepts an array of objects (specified by the ObjectType generic) that contain arrays of those same objects and will remove any elements that have
    * the array member, but where the array is empty.  This is often used after executing some other filter function to remove the parent items
    * where all the children have been filtered out.
    *
    * @param array The top-level array of ObjectType that we want to filter
    * @param nestedKeyName The key name of ObjectType that contains the nested array
    * @returns an array of ObjectType that has been filtered as described.
    */
    public static filterRecursiveArrayWithZeroLength<ObjectType = any>(array: ObjectType[], nestedKeyName: string): ObjectType[] {
        return array.filter(o => {
            const nested = o[nestedKeyName];
            if (nested != null) {
                o[nestedKeyName] = ObjectUtil.filterRecursiveArrayWithZeroLength(nested, nestedKeyName);

                //remove any child arrays that are empty
                for (let x = nested.length - 1; x >= 0; x--) {
                    if (nested[x] == null || (nested[x][nestedKeyName] != null && ArrayUtil.isEmptyArray(nested[x][nestedKeyName]) == true))
                        nested.splice(x, 1);
                }
            }
            return nested == null || nested.length > 0;
        });
    }

    /**
     * This function is used to get a string representation of an object, avoiding the "Object object" result that is returned by the default toString method.
     *
     * @param obj The object that we want to get a string representation of.
     * @param valueIfEmpty The string that will be returned if the object is null, if the object doesn't override toString, or if the object's toString method returns an empty string.
     * @returns {string} The string representation of the object or the provided fallback string.
     */
    public static toString(obj: any, valueIfEmpty: string): string {
        if (obj == null || (typeof obj === 'object' && obj.toString === Object.prototype.toString)) {
            return valueIfEmpty;
        }

        const result = obj.toString();
        return StringUtil.isEmptyString(result) ? valueIfEmpty : result;
    }

    public static deleteNullProps(obj: any): any {
        if (obj == null)
            return;
        for (const key in obj) {
            if (obj[key] == null)
                delete obj[key];
        }
        return obj;
    }

    public static isEnumValue<T>(enumObj: T, value: unknown): value is T[keyof T] {
        if (!enumObj || Object.keys(enumObj).length === 0)
            return false;

        if (value == null)
            return false;

        if (typeof value !== "string" && typeof value !== "number")
            return false;

        return Object.values(enumObj).includes(value as T[keyof T]);
    }
}
