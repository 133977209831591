import { ArrayUtil, Navigation } from "@mcleod/core";
import { ListItem } from "../list/ListItem";
import { ContextMenuProps } from "./ContextMenu";
import { ContextMenuLabel } from "./ContextMenuLabel";
import { ContextMenuItemsProvider, ContextMenuLabelProps } from "./ContextMenuLabelProps";
import { Button } from "../button/Button";
import { ClickEvent } from "../../events/ClickEvent";

export class ContextMenuItemCreator {

    static createListItems(props: ContextMenuProps): ListItem[] {
        const { sourceComponent,  designer, itemProvider = props.sourceComponent.contextMenuItems } = props;

        const items = ContextMenuItemCreator.resolveContextMenuItemProps(itemProvider);
        if (items == null) {
            return null;
        }
        return items.map(itemProps => {
            const comp = new ContextMenuLabel(sourceComponent, {_designer: designer, ...itemProps });
            return new ListItem(comp);
        });
    }

    static resolveContextMenuItemProps(provider: ContextMenuItemsProvider): Partial<ContextMenuLabelProps>[] {
        const items = typeof provider === "function" ? provider() : provider;
        if (ArrayUtil.isEmptyArray(items)) {
            return null;
        }
        return items;
    }

    /**
     * Creates an array of ContextMenuLabelProps from a list of button configurations.
     * Each configuration includes a required `button` and optional properties to override the defaults.
     *
     * @param buttonConfigs - A list of configurations where each item contains:
     *   - `button`: The source button to derive the default properties.
     *   - Other optional properties to override the defaults (e.g., `text`, `visible`).
     *
     * @returns An array of context menu label properties based on the provided buttons and overrides.
     */
    static fromButtons(
        ...buttonConfigs: ({ button: Button } & Partial<ContextMenuLabelProps>)[]
    ): Partial<ContextMenuLabelProps>[] {
        return buttonConfigs.map(({ button, ...props }: { button: Button } & Partial<ContextMenuLabelProps>) =>
            ContextMenuItemCreator.fromButton(button, props)
        );
    }

    /**
    * Creates a single ContextMenuLabelProps object from a button.
    * Default properties are derived from the button, and any provided overrides are applied.
    *
    * @param button - The source button to derive the default properties.
    * @param overrideProps - Optional properties to override the defaults (e.g., `text`, `visible`).
    *
    * @returns A context menu label property object with the merged properties.
    */
    static fromButton(button: Button, overrideProps?: Partial<ContextMenuLabelProps>): Partial<ContextMenuLabelProps> {
        return {
            text: button.caption ?? button.displayLabel,
            onClick: (event: ClickEvent) => button.clicked(event),
            visible: button.parent != null && button.visible && button.enabled,
            imageName: button.imageName,
            ...overrideProps,
        };
    }

    /**
     * Creates context menu items for Opening a layout in a new tab or window.
     *
     * @param layoutName - The name of the layout used to construct the navigation path.
     * @param key - The key to include in the navigation path query string.
     *
     * @returns An array of ContextMenuLabelProps or empty array if the key is not provided.
     */
    public static createNavigationContextMenuItems(layoutName: string, key: string): Partial<ContextMenuLabelProps>[] {
        if (!key) return [];
        const path = `${layoutName}?mode=update&key=${key}`;
        return [
            {
                text: "Open in New Tab",
                id: "openInNewTab",
                onClick: () => Navigation.navigateTo(path, { newTab: true })
            },
            {
                text: "Open in New Window",
                id: "openInNewWindow",
                onClick: () => Navigation.navigateTo(path, { newTab: true, windowDecorators: false })
            }
        ];
    }
}
