import { AddType, DesignerInterface, DialogProps, DropdownItem, Label, PanelProps, TableProps } from "@mcleod/components";
import { AbstractValueDisplayValueEditor } from "./AbstractValueDisplayValueEditor";
import { ArrayUtil } from "@mcleod/core";

export class DropdownItemEditor extends AbstractValueDisplayValueEditor {
    private isReadOnly: boolean;

    constructor(currentValueJson: string, editingDesigner: DesignerInterface, props?: Partial<PanelProps>) {
        super(currentValueJson, props, null, editingDesigner);
        if (this.isReadOnly) {
            const readOnlyLabel = new Label({
                text: "Dropdown items are system-defined and cannot be edited",
                fontSize: "large",
                imageName: "warning",
                color: "warning",
                paddingBottom: 24
            });
            this.insert(readOnlyLabel, 0);
        }
    }

    protected get editingDesigner(): DesignerInterface {
        return super.editingDesigner;
    }

    protected set editingDesigner(value: DesignerInterface) {
        super.editingDesigner = value;

        this.isReadOnly = this.editingDesigner?.selectedComponents.some(
            (comp) => comp["_boundField"]?.dbDisplayValues != null
        );
    }

    override getTableProps(): Partial<TableProps> {
        if (this.isReadOnly) {
            return {
                ...super.getTableProps(),
                addType: AddType.NOT_ALLOWED,
                allowEdit: false,
                allowDelete: false
            }
        }
        return super.getTableProps();
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            okVisible: !this.isReadOnly,
            okDefault: !this.isReadOnly
        }
    }

    get valueCaption(): string {
        return "Value";
    }

    get valueName(): string {
        return "value";
    }

    get displayValueCaption(): string {
        return "Display Value";
    }

    get displayValueName(): string {
        return "displayValue";
    }

    get emptyCaption(): string {
        return "Database / Display pairs can be defined in this table";
    }

    getResultValue(): any[] {
        if (ArrayUtil.isEmptyArray(this.tableRows))
            return null;
        const result: any[] = [];
        for (const row of this.tableRows) {
            const item = new DropdownItem(row.data.get(this.valueName, null), row.data.get(this.displayValueName, null));
            result.push(item);
        }
        return result;
    }
}
