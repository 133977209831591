import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";
import { SettingsRegistry } from "./SettingsRegistry";

export interface CompanySettingsDef extends AbstractSettingsDef {
    //unauth
    allow_keep_signed_in: boolean;
    enable_phone_format: boolean;
    header_bg_color: string;
    header_logo: string; // base64
    id: string;
    is_multi_currency: boolean;
    name: string;
    phone_format: string;
    text_color: string;
    scac: string;
    company_type: string;

    //auth
    earliest_pickup: Date;
    enforce_credit: string;
    enforce_shipper_id: boolean;
    is_asset: boolean;
    is_asset_ltl: boolean;
    is_brokerage: boolean;
    is_brokerage_ltl: boolean;
    is_ltl: boolean;
    ltl_freight_dimension_uom: string;
    ltl_max_weight: number;
    order_contact_name: string;
    order_contact_phone: string;
    ord_latest_pickup_time: Date;
    carrier_user_group: string;
    customer_user_group: string;
    driver_user_group: string;
    location_user_group: string;
}

export class CompanySettings extends AbstractSettings {
    constructor() {
        super("company_settings");
    }

    public static getSingleton(): CompanySettings {
        return SettingsRegistry.get().getObject("company_settings") as CompanySettings;
    }

    public static get(): CompanySettingsDef {
        return CompanySettings.getSingleton()?.values as CompanySettingsDef;
    }

    public override get values(): CompanySettingsDef {
        return super.values as CompanySettingsDef;
    }

    protected getValuesInternal(): CompanySettingsDef {
        return this._values as CompanySettingsDef;
    }

    public isAsset(): boolean {
        return this.getValuesInternal()?.is_asset === true;
    }

    public isAssetLtl(): boolean {
        return this.getValuesInternal()?.is_asset_ltl === true;
    }

    public isBrokerage(): boolean {
        return this.getValuesInternal()?.is_brokerage === true;
    }

    public isBrokerageLtl(): boolean {
        return this.getValuesInternal()?.is_brokerage_ltl === true;
    }

    public isLtl(): boolean {
        return this.getValuesInternal()?.is_ltl === true;
    }

    public isBoth(): boolean {
        return this.getValuesInternal()?.is_asset === true && this.getValuesInternal()?.is_brokerage === true;
    }

}
