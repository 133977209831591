import { Button, Checkbox, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, SaveButton, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutUserSettings extends Layout {
    buttonAuthorize: Button;
    buttonClearEvenColor: Button;
    buttonClearOddColor: Button;
    buttonEvenColor: Button;
    buttonOddColor: Button;
    buttonSave: SaveButton;
    checkboxAllowNewDevices: Checkbox;
    checkboxSelTextOnFocus: Checkbox;
    horizontalspacer2: HorizontalSpacer;
    imageTitle: Image;
    labelAdditionalProfiles: Label;
    labelBrokeragePlanningProfiles: Label;
    labelCustomerServiceProfiles: Label;
    labelDATResult: Label;
    labelDevices: Label;
    labelDriverManagerProfiles: Label;
    labelEvenColorCaption: Label;
    labelInvoiceProcessingProfile: Label;
    labelMapOptions: Label;
    labelOddColorCaption: Label;
    labelOrderEntryDefaults: Label;
    labelOrderPayablesTitle: Label;
    labelOrderPlanningProfiles: Label;
    labelSample: Label;
    labelSampleDateTime: Label;
    labelTenderExpressProfile: Label;
    labelTruckstopResult: Label;
    labelUserSettings: Label;
    panelAdditionalProfiles: Panel;
    panelBpProfiles: Panel;
    panelCustomerServiceProfiles: Panel;
    panelDATFields: Panel;
    panelDriverManagerProfiles: Panel;
    panelEvenColorDisplay: Panel;
    panelEvenColorOuter: Panel;
    panelInvoicingProfile: Panel;
    panelOddColorDisplay: Panel;
    panelOddColorOuter: Panel;
    panelOrderEntryDefaults: Panel;
    panelOrderPayableProfiles: Panel;
    panelOrderPlanningProfiles: Panel;
    panelSampleDateTime: Panel;
    panelTenderExpressProfile: Panel;
    panelTruckstopFields: Panel;
    sourceCurrentUserAsset: DataSource;
    sourceCurrentUserPowerbroker: DataSource;
    sourceEdiUser: DataSource;
    sourcePbwLoadboardServices: DataSource;
    sourceSettings: DataSource;
    sourceUserRecognizedDevice: DataSource;
    sourceUsers: DataSource;
    switchGoogleMapLayer: Switch;
    switchGoogleShowDisclaimer: Switch;
    switchTrimbleMapTraffic: Switch;
    tabBackOffice: Tab;
    tabGeneral: Tab;
    tabLoadboardServices: Tab;
    tabLogin: Tab;
    tabMapping: Tab;
    tabOperations: Tab;
    tabTemplates: Tab;
    tableUserDevices: Table;
    tabsetUsers: Tabset;
    textDateFormat: Textbox;
    textTheme: Textbox;
    textTimeFormat: Textbox;
    textboxAssetOrderNextAction: Textbox;
    textboxBpProfile2: Textbox;
    textboxBpProfile3: Textbox;
    textboxBpProfile4: Textbox;
    textboxBpProfile: Textbox;
    textboxBrokerageOrderNextAction: Textbox;
    textboxCellPhone: Textbox;
    textboxConfTemplate: Textbox;
    textboxCustomerServiceProfile2: Textbox;
    textboxCustomerServiceProfile3: Textbox;
    textboxCustomerServiceProfile4: Textbox;
    textboxCustomerServiceProfile: Textbox;
    textboxDATService: Textbox;
    textboxDatPassword: Textbox;
    textboxDatUserId: Textbox;
    textboxDefMoveType: Textbox;
    textboxDefaultDispatchLayout: Textbox;
    textboxDefaultDistanceMarginPercentage: Textbox;
    textboxDefaultWirecode: Textbox;
    textboxDistanceCalcVendor: Textbox;
    textboxDriverManagerProfile2: Textbox;
    textboxDriverManagerProfile3: Textbox;
    textboxDriverManagerProfile4: Textbox;
    textboxDriverManagerProfile: Textbox;
    textboxEmailAddress: Textbox;
    textboxExtension: Textbox;
    textboxFindnearVendor: Textbox;
    textboxGoogleMapDisplay: Textbox;
    textboxInvoiceProcessingProfile2: Textbox;
    textboxInvoiceProcessingProfile3: Textbox;
    textboxInvoiceProcessingProfile4: Textbox;
    textboxInvoiceProcessingProfile: Textbox;
    textboxLtexpressProfId: Textbox;
    textboxLtlQuoteTemplate: Textbox;
    textboxMessageVisibility: Textbox;
    textboxMovementMapVendor: Textbox;
    textboxOperationsUserId: Textbox;
    textboxOrderPayablesProfile2: Textbox;
    textboxOrderPayablesProfile3: Textbox;
    textboxOrderPayablesProfile4: Textbox;
    textboxOrderPayablesProfile: Textbox;
    textboxOrderPlanningProfile2: Textbox;
    textboxOrderPlanningProfile3: Textbox;
    textboxOrderPlanningProfile4: Textbox;
    textboxOrderPlanningProfile: Textbox;
    textboxPhone: Textbox;
    textboxQuoteTemplate: Textbox;
    textboxRevenueCodeId: Textbox;
    textboxSegAllocCode: Textbox;
    textboxSinglePointVendor: Textbox;
    textboxSmsMessageLimit: Textbox;
    textboxTmProfile: Textbox;
    textboxTruckstopPassword: Textbox;
    textboxTruckstopRESTService: Textbox;
    textboxTruckstopUserId: Textbox;
    textboxViewMovementMapVendor: Textbox;
    textboxVsProfile: Textbox;
    titlePanel: Panel;
}
