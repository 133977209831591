export const props = { viewBox: "0 0 22 26" };
export const innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9619 26L0 20V8L10.9619 2L21.913 8V20L10.9619 26ZM15.8581 21.3357L20.0825 18.9932V9.00691L10.9512 4.05486L1.83067 9.00691V18.9932L6.16345 21.3768L7.32247 13.846C7.4574 13.442 7.73151 13.0927 8.1008 12.8543C8.4701 12.6158 8.91316 12.5019 9.35888 12.5309H12.511C12.923 12.5262 13.3247 12.6534 13.651 12.8921C13.9773 13.1307 14.2092 13.4668 14.3091 13.846C14.6665 15.2535 15.8581 21.3357 15.8581 21.3357ZM12.9983 14.5341L14.4823 21.9621L10.9402 23.7806V14.082H12.2401C12.2401 14.082 12.89 13.9998 12.9983 14.5341Z" fill="#137CDD"/>
<path d="M11.6439 5.94531H10.3008V10.9282H11.6439V5.94531Z" fill="#137CDD"/>
<path d="M5.64221 8.24887L4.54587 8.98486L6.96763 12.2302L8.06397 11.4943L5.64221 8.24887Z" fill="#137CDD"/>
<path d="M3.12318 14.0287L2.89249 15.2837L5.88036 15.7778L6.11106 14.5227L3.12318 14.0287Z" fill="#137CDD"/>
<path d="M13.7437 11.4994L14.84 12.2354L17.2618 8.98997L16.1654 8.25397L13.7437 11.4994Z" fill="#137CDD"/>
<path d="M15.7094 14.5243L15.9401 15.7793L18.9279 15.2852L18.6972 14.0302L15.7094 14.5243Z" fill="#137CDD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17 0C19.7617 0 22 2.23828 22 5C22 7.76172 19.7617 10 17 10C14.2383 10 12 7.76172 12 5C12 2.23828 14.2383 0 17 0Z" fill="#B60909"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.9999 1.05469C17.2891 1.05469 17.5233 1.28242 17.5233 1.56359V6.25818C17.5233 6.53934 17.2891 6.76708 16.9999 6.76708C16.7108 6.76708 16.4766 6.53934 16.4766 6.25818V1.56359C16.4766 1.28242 16.7108 1.05469 16.9999 1.05469Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.9999 7.76758C17.3251 7.76758 17.5887 8.0312 17.5887 8.35638C17.5887 8.68157 17.3251 8.94519 16.9999 8.94519C16.6748 8.94519 16.4111 8.68157 16.4111 8.35638C16.4111 8.0312 16.6748 7.76758 16.9999 7.76758Z" fill="white"/>
</svg>
`
