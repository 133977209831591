import { Styles } from "@mcleod/core";

export const MainPageStyles = Styles.make(null, {
    html: {
        boxSizing: "border-box",
        overflow: "hidden",
        height: "100%",
        minWidth: "100%"
    },
    body: {
        height: "100%",
        minWidth: "100%",
        fontSize: "14px",
        fontFamily: "Roboto",
        margin: "0px",
        color: "#000000DD"
    },
    "*": {
        boxSizing: "inherit"
    },
    "*:before": {
        boxSizing: "inherit"
    },
    "*:after": {
        boxSizing: "inherit"
    }
});
