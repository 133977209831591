import { IFrame, Image, ImageType, refreshKeyListeners } from "@mcleod/components";
import {
    Alignment, Api, AuthToken, BrowserType, ClientActivityTracker, CompanySettings, GeneralSettings, LogManager, Navigation, UUID, getForwardDestination
} from "@mcleod/core";
import { Router } from "./router/Router";
import { AutogenLayoutLogin } from "./autogen/AutogenLayoutLogin";

const log = LogManager.getLogger("common.Login");

export class Login extends AutogenLayoutLogin {
    public static displayCallback: (login: Login) => void;
    forwardDest: string;
    private static ssoLogoutComplete: boolean;
    private static debug: boolean;

    override async onLoad() {
        Login.debug = this["debug"] === "true"; // property can be set by adding &debug=true to the URL
        if (GeneralSettings.get()?.allow_sso !== false &&  await this.handleSSOSignin()) {
            return;
        }
        if (GeneralSettings.get()?.use_sso_page === true) {
            this.loadSSOLogin();
            return;
        }
        if (localStorage.getItem("deviceId") == null)
            localStorage.setItem("deviceId", UUID.randomUUID());
        this.showContentLogin();
        this.forwardDest = getForwardDestination();
        // HACK!  (because I don't have a way or know how to affect the parent row height)
        this.panelMain._element.parentElement.style.height = "100%";
        const settings = CompanySettings.get();
        const backgroundColor = "backgroundSubtle";
        this.panelContentLogin.backgroundColor = backgroundColor;
        this.panelContentMFA.backgroundColor = backgroundColor;
        if (settings != null) {
            if (settings.header_bg_color != null)
                this.panelHeader.backgroundColor = settings.header_bg_color;
            if (settings.header_logo != null)
                this.panelLogo.add(new Image({ imageType: ImageType.IMG, imageBytes: settings.header_logo, height: 60, width: "auto", maxWidth: 380 }))
            this.checkKeepSignedIn.visible = settings.allow_keep_signed_in || false;
        }
        this.textPassword.allowAutocomplete();
        this.setBackgroundImage();
        if (Login.displayCallback != null)
            Login.displayCallback(this);
    }

    private loadSSOLogin() {
        this.removeAll();
        const iframe = new IFrame({ fillHeight: true, fillRow: true, isRow: true });
        iframe.src = Login.getSSOUrl("origin=" + window.location.origin);
        this.add(iframe);
        const eventListener = (event) => {
            log.debug("Received message", event);
            if (event.origin === "https://onemcleod.com" && typeof(event.data) === "object") {
                if (event.data.token != null) {
                    window.removeEventListener("message", eventListener);
                    log.debug("Received token from login iframe", event.data);
                    AuthToken.set(event.data.token, true);
                    if (this.forwardDest == null || this.isDisallowedForward(this.forwardDest))
                        Navigation.navigateTo("");
                    else
                        Navigation.navigateTo(this.forwardDest);
                }
            }
        };
        window.addEventListener("message", eventListener);
    }

    setBackgroundImage() {
        const bytes = localStorage.getItem("loginBackground");
        if (bytes != null)
            this.panelPage.backgroundImageBytes = bytes;
        Api.search("lib/login-background", { cached_length: bytes?.length || -1 }).then(response => {
            const data = response?.login_background;
            if (data != null) {
                this.panelPage.backgroundImageBytes = data;
                localStorage.setItem("loginBackground", data);
            }
        });
    }

    getRouterProps() {
        return { padding: 0 };
    }

    buttonSignInClicked() {
        if (this.textUserId.text.length === 0)
            this.textUserId.showTooltip("You need to enter a login id to sign in.", { position: Alignment.RIGHT, shaking: true, timeout: 3000 });
        else if (this.textPassword.text.length === 0)
            this.textPassword.showTooltip("You need to enter a password to sign in.", { position: Alignment.RIGHT, shaking: true, timeout: 3000 });
        else {
            this.buttonSignIn.busy = true;
            this.login(this.textUserId.text, this.textPassword.text, this.checkKeepSignedIn.checked);
        }
    }

    buttonMFASubmitClicked() {
        if (this.textMFACode.text.length === 0)
            this.textMFACode.showTooltip("You need to enter an MFA code to sign in.", { position: Alignment.RIGHT, shaking: true, timeout: 3000 });
        else if (this.textMFACode.text.length !== 6)
            this.textMFACode.showTooltip("Your MFA code must be exactly 6-digits.", { position: Alignment.RIGHT, shaking: true, timeout: 3000 });
        else {
            this.buttonMFASubmit.busy = true;
            this.login(this.textUserId.text, this.textPassword.text, this.checkKeepSignedIn.checked, this.textMFACode.text);
        }
    }

    isDisallowedForward(path: string) {
        return (path === "common/SessionExpired" ||
            path === "common/SignInNeeded" ||
            path === "common/Login" ||
            path === "common/NoLicensesAvailable");
    }

    login(login_id: string, password: string, keep_signed_in: boolean, mfa_code?: string,) {
        const device_id = localStorage.getItem("deviceId");
        Api.post("login", {
            login_id, password, keep_signed_in, mfa_code, device_id,
            browser: BrowserType.getBrowser(), platform: BrowserType.getPlatform()
        }).then(response => {
            const data = response.data[0];
            if (data.mfa_qr_data != null) {
                // MFA Setup mode
                this.panelQRCode.add(new Image({ imageType: ImageType.IMG, imageBytes: data.mfa_qr_data, height: "250px", width: "auto" }));
                this.labelMFASecret.text = data.mfa_secret;
                this.showContentMFASetup();
            }
            else if (data.mfa_verify === true) {
                // MFA Verify mode
                this.showContentMFAVerify();
            }
            else {
                ClientActivityTracker.track();
                AuthToken.set(data.token);
                if (this.forwardDest == null || this.isDisallowedForward(this.forwardDest))
                    Navigation.navigateTo("");
                else
                    Navigation.navigateTo(this.forwardDest);
            }
        }).catch(err => {
            if (this.panelContentMFA.visible === true) {
                this.buttonMFASubmit.busy = false;
                this.buttonMFASubmit.showTooltip("Something went wrong.  Check your MFA code and try again.", { shaking: true, timeout: 4000, position: Alignment.RIGHT });
            }
            else {
                this.buttonSignIn.busy = false;
                this.buttonSignIn.showTooltip("Something went wrong.  Check your account information and try again.", { shaking: true, timeout: 4000, position: Alignment.RIGHT });
            }
            log.error("Login error", err)
        })
    }

    public static async logout() {
        await this.logoutFromSSO();
        try {
            const response = await Api.post("logout");
            log.info("Logout response %o", response);
        } catch(err) {
            log.error("Error", err);
        }
        AuthToken.clear();
        Navigation.navigateTo(Router.loginRoute, { hardRefresh: true });
    }

    showContentLogin() {
        this.hideContentPanels();
        this.panelContentLogin.visible = true;
        this.textUserId.focus();
        this.buttonSignIn.default = true;
        refreshKeyListeners(this);
    }

    showContentMFAVerify() {
        this.hideContentPanels();
        this.setupContentMFA();
        this.panelMFAVerify.visible = true;
    }

    showContentMFASetup() {
        this.hideContentPanels();
        this.setupContentMFA();
        this.panelMFASetup.visible = true;
    }

    setupContentMFA() {
        this.panelContentMFA.visible = true;
        this.textMFACode.focus();
        this.buttonMFASubmit.default = true;
        refreshKeyListeners(this);
    }

    hideContentPanels() {
        this.panelContentLogin.visible = false;
        this.panelContentMFA.visible = false;
        this.panelMFASetup.visible = false;
        this.panelMFAVerify.visible = false;
        this.buttonSignIn.default = false;
        this.buttonMFASubmit.default = false;
    }

    private static getSSOUrl(params: string) {
        let result = "https://onemcleod.com/login.html?";
        if (params != null)
            result += params;
        if (Login.debug === true)
            result += "&debug=true";
        return result;
    }

    private static async logoutFromSSO() {
        localStorage.removeItem("idpToken");
        const token = AuthToken.get();
        if (token != null) {
            const contents = AuthToken.parseToken(token);
            if (contents.tenant_id != null) {
                const eventListener = (event) => {
                    log.debug("Received message", event);
                    if (event.origin === "https://onemcleod.com" && typeof(event.data) === "object") {
                        if (event.data.status === "logout") {
                            log.debug("Received logout from login iframe");
                            this.ssoLogoutComplete = true;
                        }
                    }
                };
                window.addEventListener("message", eventListener);
                const iframe = new IFrame();
                iframe.src = Login.getSSOUrl("logout=true");
                document.body.appendChild(iframe._element);
                let count = 0;
                return new Promise((resolve, reject) => {
                    const checkInterval = setInterval(() => {
                        if (this.ssoLogoutComplete === true) {
                            clearInterval(checkInterval);
                            window.removeEventListener("message", eventListener);
                            resolve(this.ssoLogoutComplete);
                        }
                        count++;
                        if (count > 100) { // wait up to 5 seconds
                            clearInterval(checkInterval);
                            window.removeEventListener("message", eventListener);
                            reject(false);
                        }
                    }, 50);
                });
            }
        }
    }

    private async handleSSOSignin(): Promise<boolean> {
        const token = new URLSearchParams(window.location.search).get("auth");
        if (token != null) {
            try {
                const response = await Api.post("validate-token", { token });
                if (response.data[0].success !== true) {
                    throw new Error("Token failed validation");
                }
                AuthToken.set(token);
                if (this.forwardDest == null || this.isDisallowedForward(this.forwardDest))
                    Navigation.navigateTo("");
                else
                    Navigation.navigateTo(this.forwardDest);
                return true;
            } catch (err) {
                log.error("Error setting token passed in URL - proceeding to normal login", err);
            }
        }
        return false;
    }
}
