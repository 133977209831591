import { setThemeNameOrValue } from "../Theme";
import { AbstractSettings, AbstractSettingsDef, SettingsChangeListener } from "./AbstractSettings";
import { SettingsRegistry } from "./SettingsRegistry";

export interface ConfigSettingsDef extends AbstractSettingsDef {
    theme?: string;
    snackbar_time?: number;
    use_smooth_transitions: string;
    [key: string]: any;
}

export class ConfigSettings extends AbstractSettings {
    private configCameFromAuth: boolean;

    constructor() {
        super("config_settings");
    }

    public static getSingleton(): ConfigSettings {
        return SettingsRegistry.get().getObject("config_settings") as ConfigSettings;
    }

    public static get(): ConfigSettingsDef {
        return ConfigSettings.getSingleton().values as ConfigSettingsDef;
    }

    public override get values(): ConfigSettingsDef {
        return super.values as ConfigSettingsDef;
    }

    protected getValuesInternal(): ConfigSettingsDef {
        return this._values as ConfigSettingsDef;
    }

    /**
     * Sets the system-level configuration.  Configuration will be sent with the unauthenticated settings that won't have any user-specific overrides.
     * Once a user is authenticated, the user-specific overrides will be added and the configuration will be sent again.
     *
     * @param value
     * @param isAuthSet
     */
    set(newValues: ConfigSettingsDef, isAuthSet: boolean) {
        if (isAuthSet || this.configCameFromAuth !== true) {
            this.configCameFromAuth = isAuthSet;
            super.set(newValues, isAuthSet);
            if (newValues != null && newValues.theme != null)
                setThemeNameOrValue(newValues.theme);
        }
    }

    /**
     * Adds a callback function to be called when the configuration changes.  This is needed by most things that store a configuration setting during
     * initialization because the configuration hasn't been loaded yet.
     *
     * NOTE: The callback will be called immediately upon adding the listener to handle the case where the configuration has already been loaded.
     *
     * @param listener
     */
    public override addSettingsChangeListener(listener: SettingsChangeListener) {
        super.addSettingsChangeListener(listener);
        listener(ConfigSettings.get(), undefined, false);
    }
}
