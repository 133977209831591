import { JSUtil } from "../JSUtil";

export class ClientActivityTracker {
    private static lastActive: number;
    private static trackDebounced = JSUtil.debounce(() => this.updateLastActivity(), 500);

    public static initializeGlobalListeners(): void {
        document.addEventListener("click", (event: MouseEvent) => {
            this.track();
        });

        document.addEventListener("wheel", (event: WheelEvent) => {
            this.track();
        });

        document.addEventListener("keydown", (event: KeyboardEvent) => {
            this.track();
        });
    }

    public static track(): void {
        this.trackDebounced();
    }

    private static updateLastActivity(): void {
        const now = Date.now();
        // Update last active timestamp only if the last activity is more than 30 seconds ago
        if (!this.lastActive || now - this.lastActive > 30000) {
            this.lastActive = now;
        }
    }

    public static getlastActive(): number {
        return this.lastActive;
    }
}
