import { MainPage } from "./MainPage";
import { MainPageProps } from "./MainPageProps";
import { WebPageHeader } from "../pageheader/WebPageHeader";
import { WebRouter } from "../router/WebRouter";
import { Layout, Panel } from "@mcleod/components";

export class WebMainPage extends MainPage {

    constructor(props: Partial<MainPageProps>) {
        super(props);
    }

    public override get pageHeader(): WebPageHeader {
        return WebPageHeader.instance;
    }

    public override get router(): WebRouter {
        return WebRouter.instance;
    }

    public override evaluateExternalLinkVisibility(topMostPanel: Panel, currentLayouts: Layout[]) {
        this.pageHeader.panelHelp.evaluateExternalLinkVisibility(topMostPanel, currentLayouts);
    }
}
