import { Textbox } from "@mcleod/components";
import { DateUtil } from "@mcleod/core";

export class UnparsedDateUtil {
    public static configureUnparsedDateTextbox(tb: Textbox) {
        if(!tb) return;

        tb.addlValidationCallback = (value) => {
            const parsed = DateUtil.parseDateWithKeywords(value, true, false);
            const valid = parsed != null && DateUtil.isDateValid(parsed)
            return {
                component: tb,
                isValid: valid,
                validationMessage: valid ? "" : "Please enter a valid date."
            }
        };

        tb.addBlurListener(() => {
            if (tb.text) {
                const parsed = DateUtil.parseDateWithPersistentKeywords(tb.text)
                if (parsed) {
                    if (tb.field) tb.dataSource?.activeRow?.set(tb.field, parsed);
                    tb.text = DateUtil.parseDateWithPersistentKeywords(tb.text);
                }
            }
        });
    }
}