import { Button } from "../components/button/Button";
import { ButtonProps } from "../components/button/ButtonProps";
import { Table } from "../components/table/Table";
import { DomEvent } from "./DomEvent";
import { EventListener } from "./Event";
import { TableRowDisplayEvent } from "./TableRowDisplayEvent";

export interface TableRowAdditionalToolsDisplayListener extends EventListener {
    (event: TableRowAdditionalToolsDisplayEvent): void;
}

export class TableRowAdditionalToolsDisplayEvent extends TableRowDisplayEvent {

    constructor(component: any, table: Table, domEvent?: DomEvent) {
        super(component, table, domEvent);
    }

    public getAdditionalToolButtons(): Button[] {
        return this.getTableRow()?.additionalToolButtons;
    }

    setAdditionalToolProps(id: string, props: Partial<ButtonProps>) {
        this.getTableRow().setAdditionalToolProps(id, props);
    }
}
