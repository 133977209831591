import { ArrayUtil } from "../ArrayUtil";
import { Logger } from "../logging/Logger";
import { LogManager } from "../logging/LogManager";
import { RegistryObject } from "../registry/RegistryObject";
import { SettingsRegistry } from "./SettingsRegistry";

export type SettingsChangeListener = (newConfig: any, oldConfig: any, isAuthSet: boolean) => void;
export interface AbstractSettingsDef {
}

export abstract class AbstractSettings extends RegistryObject{
    private static log: Logger;
    private _type: string;
    protected _values: AbstractSettingsDef;
    private unauthSettingsAttributes: string[] = [];
    private retrievedAuthSettings: boolean = false;
    private settingsChangeListeners: SettingsChangeListener[] = [];

    constructor(type: string) {
        super();
        this._type = type;
    }

    get type(): string {
        return this._type
    }

    get values(): AbstractSettingsDef {
        return { ...this._values };
    }
    
    private updateValues(newValues: any, isAuthSet: boolean) {
        if (newValues == null || Object.keys(newValues).length === 0) {
            this.clear();
            return;
        }
        AbstractSettings.getLog().debug("Replacing %s settings %o of with data: %o", this.type, this._values, newValues);
        const childSettingsClassNames: string[] = [];
        this._values = { ...this._values, ...newValues };
        for (const settingsElementName of Object.keys(this._values)) {
            if (SettingsRegistry.get().isRegistered(settingsElementName) === true)
                childSettingsClassNames.push(settingsElementName);
        }
        AbstractSettings.getLog().debug("Finished replacing %s settings, now updating child settings %o", this.type, childSettingsClassNames);
        for (const settingsElementName of childSettingsClassNames) {
            SettingsRegistry.update(settingsElementName, newValues[settingsElementName], isAuthSet);
            delete this._values[settingsElementName];
        }
        AbstractSettings.getLog().debug("Finished replacing child settings of %s", this.type);
    }

    private clear(attributesToClear?: string[]) {
        AbstractSettings.getLog().debug("Clearing values from %s settings, attributes: %o, old value: %o", this.type, attributesToClear, this._values);
        if (this._values != null) {
            for (const key of Object.keys(this._values)) {
                if (attributesToClear == null || attributesToClear.includes(key) === true)
                    this._values[key] = undefined;
            }
        }
    }

    clearAuthSettings() {
        if (this.unauthSettingsAttributes == null)
            return;
        const authSettingsAttributes: string[] = [];
        if (this.unauthSettingsAttributes.length != 0) {
            for (const attribute of Object.keys(this._values)) {
                if (this.unauthSettingsAttributes.includes(attribute) !== true)
                    authSettingsAttributes.push(attribute);
            }
        }
        const oldValues = this.values;
        this.clear(authSettingsAttributes);
        this.retrievedAuthSettings = false;
        this.fireSettingsChangeListeners(this.values, oldValues, true);
    }

    set(newValues: any, isAuthSet: boolean) {
        const oldValues = this.values;
        this.updateValues(newValues, isAuthSet);
        this._doAfterSet(newValues, isAuthSet);
        this.fireSettingsChangeListeners(this.values, oldValues, isAuthSet);
    }

    _doAfterSet(newValues: any, isAuthSet: boolean) {
        if (isAuthSet === true)
            this.retrievedAuthSettings = true;
        else
            this.unauthSettingsAttributes = [...Object.keys(newValues)];
    }

    public authSettingsPopulated(): boolean {
        return this.retrievedAuthSettings === true;
    }

    public addSettingsChangeListener(listener: SettingsChangeListener) {
        this.settingsChangeListeners.push(listener);
    }

    public removeSettingsChangeListener(listener: SettingsChangeListener) {
        ArrayUtil.removeFromArray(this.settingsChangeListeners, listener);
    }

    private fireSettingsChangeListeners(newConfig: AbstractSettingsDef, oldConfig: AbstractSettingsDef, isAuthSet: boolean) {
        for (const listener of this.settingsChangeListeners) {
            listener(newConfig, oldConfig, isAuthSet);
        }
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("core.settings.AbstractSettings");
        return this.log;
    }
}
