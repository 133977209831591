import { DOMUtil, HorizontalAlignment, VerticalAlignment } from "@mcleod/core";
import { ClickEvent } from "../../events/ClickEvent";
import { LookupModelSearchEvent } from "../../events/LookupModelSearchEvent";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Label } from "../label/Label";
import { Layout } from "../layout/Layout";
import { LayoutProps } from "../layout/LayoutProps";
import { Panel } from "../panel/Panel";
import { Table } from "../table/Table";
import { Textbox } from "./Textbox";
const log = DesignableObjectLogManager.getLogger("components.LookupModelLayoutManager");

export class LookupModelLayoutManager {
    private textbox: Textbox;
    private layout: Layout;
    private table: Table;
    private panelActions: Panel;
    private panelButtons: Panel;
    private buttonShowAllResults: Button;
    private buttonManualAdd: Button;
    private runningShowAllSearch = false;
    private emptyPanel: Panel;
    private emptyPanelLabel: Label;

    constructor(textbox: Textbox, layout: Layout, table: Table) {
        this.textbox = textbox;
        this.layout = layout;
        this.table = table;
        this.table.setProps({
            fillHeight: true,
            virtualized: true,
            rowBreak: true,
            busyWhenDataSourceBusy: true,
            maxHeight: this.defaultTableMaxHeight,
            margin: 0,
            padding: 0
        });
        this.createPanelButtons();
        this.createTableEmptyPanel();
    }

    private get defaultTableMaxHeight() : string | number {
        return this.layout.maxHeight ?? this.layout.height;
    }

    private createPanelButtons() {
        this.layout.setLastComponentRowBreak(true);
        this.panelButtons = Panel.createNoMarginNoPaddingPanel({ fillRow: true });
        this.textbox.dropdownAdditionalActions?.forEach(button => this.addDropdownAction(button));
        this.createManuallyAddButton();
        this.panelButtons.setLastComponentRowBreak(true);
        this.createButtonShowAllResults();
    }

    createManuallyAddButton() {
        if (this.textbox.manualAddLayout != null) {
            this.buttonManualAdd = new Button({
                caption: "Manually Add",
                color: "primary",
                variant: ButtonVariant.text,
                visible: false,
                rowBreak: false,
                onClick: () => this.textbox.showManualAddDropdown(null)
            });
            this.addDropdownAction(this.buttonManualAdd);
        }
    }

    private addDropdownAction(button: Button) {
        if (this.panelActions == null) {
            this.panelActions = Panel.createNoMarginNoPaddingPanel({ fillRow: true, align: HorizontalAlignment.RIGHT });
            this.layout.add(this.panelActions);
        }
        this.panelActions.add(button);
    }

    private createButtonShowAllResults() {
        this.buttonShowAllResults = new Button({
            caption: "Show All Results",
            color: "primary.reverse",
            backgroundColor: "primary",
            fillRow: true
        });
        this.buttonShowAllResults.addClickListener((event: ClickEvent) => {
            this.runningShowAllSearch = true;
            this.textbox.showAllLookupModelResults();
        });
        this.panelButtons.add(this.buttonShowAllResults);
    }

    checkButtonAvailability() {
        try {
            const showAllResultsVisible = this.textbox.lookupModelAllowShowAllResults === true &&
                this.table.dataSource.actualRowCount > this.table.dataSource.rowCount;
            showAllResultsVisible === true ? this.showButtons() : this.hideButtons();

            if (this.buttonManualAdd != null)
                this.buttonManualAdd.visible = this.table.dataSource.rowCount > 0;
        }
        finally {
            this.runningShowAllSearch = false;
        }
    }

    private showButtons() {
        if (this.runningShowAllSearch === false) {
            this.layout.addIfNotPresent(this.panelButtons);
        }
    }

    hideButtons() {
        this.layout.remove(this.panelButtons);
    }

    // Called within a MutationObserver attached to the layout element - see Overlay.alignAndObserve()
    adjustTableHeight() {
        if (!this.table.isMounted || this.table.busy || this.runningShowAllSearch)
            return;

        this.table.height = null;
        this.table.maxHeight = DOMUtil.getAvailableHeight(this.layout._element, (child: Element) => DOMUtil.isOrContains(child, this.table._element) !== true);
    }

    private createTableEmptyPanel() {
        this.emptyPanel = new Panel({ fillHeight: true, fillRow: true });
        this.emptyPanelLabel = new Label({
            fillRow: true,
            height: "100%",
            fontSize: "large",
            color: "subtle.light",
            align: HorizontalAlignment.CENTER,
            verticalAlign: VerticalAlignment.TOP,
            text: this.table.emptyCaption,
            paddingTop: 12,
            paddingBottom: 12
        });
        this.emptyPanel.add(this.emptyPanelLabel);

        if (this.textbox.manualAddLayout) {
            const manualAddButton = new Button({
                caption: "Manually Add", color: "primary", align: HorizontalAlignment.RIGHT, variant: ButtonVariant.text,
                onClick: (event: ClickEvent) => {
                    this.textbox.showManualAddDropdown("");
                }
            });
            this.emptyPanelLabel.align = HorizontalAlignment.LEFT;
            this.emptyPanelLabel.rowBreak = false;
            this.emptyPanel.add(manualAddButton);
        }
        if (this.textbox.lookupModelAllowSearchAll === true) {
            const searchAllLabel = new Label({
                text: "Show all records", color: "primary", fillRow: true,
                height: "100%",
                fontSize: "large",
                align: HorizontalAlignment.CENTER,
                verticalAlign: VerticalAlignment.TOP,
                paddingTop: 12,
                paddingBottom: 12
            });
            searchAllLabel.addClickListener((event: ClickEvent) => {
                this.textbox._input.value = "";
                this.textbox.hideDropdown(true);
                this.textbox.showLookupModelDropdown("");
            });
            this.emptyPanel.add(searchAllLabel);
        }
        this.table.emptyComponent = this.emptyPanel;
    }

    syncTableEmptyPanel(event: LookupModelSearchEvent) {
        this.emptyPanelLabel.text = event?.preventReason ?? this.table.emptyCaption;
        this.emptyPanel.components.forEach(comp => {
            if (comp !== this.emptyPanelLabel) {
                comp.visible = event?.defaultPrevented === false;
            }
        });
    }

    async search(event: LookupModelSearchEvent): Promise<any> {
        this.table.maxHeight = this.defaultTableMaxHeight;
        this.syncTableEmptyPanel(event);
        if (event?.defaultPrevented === true) {
            log.debug(event.target, "Lookup model search was prevented");
            return;
        }

        let loadStartTime = null;
        if (log.isDebugEnabled) {
            log.debug(event.target, "Lookup model load started");
            loadStartTime = new Date().getTime();
        }
        await this.table.dataSource.search(event.filter, null, this.textbox.lookupModelFieldListInfo);
        if (log.isDebugEnabled) {
            const loadTime = new Date().getTime() - loadStartTime;
            log.debug(event.target, "Lookup model load time: %o", loadTime);
        }
    }

    static getLayout(textbox: Textbox): Layout {
        const props: Partial<LayoutProps> = {
            scrollY: false,
            fillHeight: false,
            padding: 0,
            margin: 0,
            maxHeight: 320
        };

        if (textbox.lookupModelLayoutHeight) {
            props.height = textbox.lookupModelLayoutHeight;
            props.maxHeight = undefined;
            props.minHeight = undefined;
        }

        if (textbox.lookupModelLayoutWidth) {
            props.width = textbox.lookupModelLayoutWidth;
            props.maxWidth = undefined;
            props.minWidth = undefined;
        }

        return Layout.getLayout(textbox.lookupModelLayout, props);
    }
}
