import { ArrayUtil, HorizontalAlignment, StringUtil } from "@mcleod/core";
import { Component, ComponentTypes, Label } from "../..";
import { HeaderTableCellPropDefinitions, HeadingTableCellProps } from "./HeadingTableCellProps";
import { SortFieldInfo } from "./SortFieldInfo";
import { SortSelector } from "./SortSelector";
import { TableCell } from "./TableCell";
import { TableCellProps } from "./TableCellProps";
import { DeserializeProps } from "../../serializer/ComponentDeserializer";

export class HeadingTableCell extends TableCell implements HeadingTableCellProps {
    private _extraSortFields: SortFieldInfo[];

    constructor(props?: Partial<TableCellProps>) {
        super(props);
        this.allowSelect = false;
    }

    override async _deserializeSpecialProps(props: DeserializeProps): Promise<string[]> {
        // A heading cell should never have components defined in the layout. This also avoids
        // calling TableCell.populateSimpleComponent in case this cells field is set for some reason.
        return ["dataSource"];
    }

    // Extra sort fields are handled oddly only because of the limitations of how PropertiesTable
    // handles property values from specialty editors.  We can eventually make this look not-so-dodgy.
    public get extraSortFields(): string {
        if (this._extraSortFields == null)
            return null;
        return JSON.stringify(this._extraSortFields);
    }

    public set extraSortFields(value: string) {
        if (StringUtil.isEmptyString(value) === true)
            this._extraSortFields = null;
        const defs = JSON.parse(value);
        if (ArrayUtil.isEmptyArray(defs) === false) {
            const validFields: Set<string> = this.gatherFieldsForRelatedDataSource() as Set<string>;
            this._extraSortFields = [];
            for (const def of defs) {
                if (validFields == null || validFields.has(def.field))
                    this.addExtraSortField(new SortFieldInfo(def.field, def.caption, def.sortDescendingByDefault,
                        def.sortNullsAtEnd));
            }
        }
        else
            this._extraSortFields = null;
    }

    get resolvedExtraSortFields(): SortFieldInfo[] {
        return this._extraSortFields;
    }

    private addExtraSortField(...sortFieldInfo: SortFieldInfo[]) {
        if (sortFieldInfo != null) {
            this.initExtraSortFields();
            for (const sfi of sortFieldInfo) {
                if (sfi?.field != null &&
                    ArrayUtil.arrayIncludesObjectWithValue(this._extraSortFields, "field", sfi.field) === false) {
                    this._extraSortFields.push(sfi);
                }
            }
        }
    }

    private initExtraSortFields() {
        if (this._extraSortFields == null)
            this._extraSortFields = [];
    }

    override get idPrefix(): string {
        return "columnHeader";
    }

    // Ideally we would use the headingcell serialization name, but that would require updating every existing layout
    // that contains a table.  I didn't want to introduce that many merge conflicts in the early-adopter era, so instead
    // we are deserializing heading cells with a custom componentCreationCallback in Table.ts.
    /*
    override get serializationName() {
        return "headingcell";
    }
     */

    override get properName(): string {
        return "Heading Cell";
    }

    override getPropertyDefinitions() {
        return HeaderTableCellPropDefinitions.getDefinitions();
    }

    override allowDropInDesigner(_component: Component): boolean {
        return false;
    }

    override set align(value: HorizontalAlignment) {
        super.align = value;
        this.matchHeadingAlignment();
    }

    override get components(): Component[] {
        return super.components;
    }

    override set components(value: Component[]) {
        super.components = value;
        this.matchHeadingAlignment();
    }

    override add(...components: Component[]): Component {
        const result = super.add(...components);
        this.matchHeadingAlignment();
        return result;
    }

    private matchHeadingAlignment() {
        if (this.components.length > 0 && this.align != null)
            this.components[0].align = this.align;
    }

    override _serializeNonProps(): string {
        return "";
    }

    getSortSelector(): SortSelector {
        return this.findComponentByType(SortSelector);
    }

    public getSortSelectorLabel(): Label {
        return this.getSortSelector()?.getFieldLabel();
    }

    gatherFieldsForRelatedDataSource(returnAsSet: boolean = true): Set<string>|string[] {
        const selectedCellDataSource = this.table?.dataSource;
        const dataSourceOutputMetadata = selectedCellDataSource?.getMetadataFromCache()?.output;
        if (dataSourceOutputMetadata != null) {
            const resultAsArray = Object.keys(dataSourceOutputMetadata);
            return (returnAsSet === true) ? new Set(resultAsArray) : resultAsArray;
        }
        return null;
    }
}

ComponentTypes.registerComponentType("headingcell", HeadingTableCell.prototype.constructor);
