import { CrudDecorator, DataSourceMode, Layout, Overlay, Panel, ScreenStack } from "@mcleod/components";
import { NavigationEvent, NavigationListener, handleError } from "@mcleod/core";
import { CommonDialogs, DiscardSaveCancelChoice } from "./CommonDialogs";
import { MainPage } from "./mainpage/MainPage";

export class UnsavedDataListener {
    private static navigationListener: NavigationListener;

    public static init() {
        // if (UnsavedDataListener.navigationListener == null) {
        //     UnsavedDataListener.navigationListener = {
        //         onNavigate: UnsavedDataListener.handleNavigateAway,
        //         persistAcrossPages: true
        //     };
        //     Navigation.addNavigationListener(UnsavedDataListener.navigationListener);
        //   }
    }

    private static getTopLayout(): Layout {
        const overlay: Overlay = ScreenStack.getNewestOverlay();
        let crudDecorator: CrudDecorator;
        const overlayWrapper = overlay?.component;
        if (overlayWrapper instanceof Panel)
            crudDecorator = overlayWrapper?.components[0] as CrudDecorator;
        return crudDecorator?.layout || MainPage.mainLayout;
    }

    private static async handleNavigateAway(event: NavigationEvent) {
        const layout: Layout = UnsavedDataListener.getTopLayout();
        const ds = layout?.mainDataSource;
        if (ds == null)
            return;
        if ((ds.mode === DataSourceMode.ADD || ds.mode === DataSourceMode.UPDATE) && ds.hasChanged(true)) {
            if (event.navOptions?.hardRefresh)
                event.preventDefault();
            else {
                const userChoice = await CommonDialogs.showDiscardSaveCancelDialog();
                if (userChoice === DiscardSaveCancelChoice.CANCEL)
                    event.preventDefault();
                else if (userChoice === DiscardSaveCancelChoice.SAVE) {
                    try {
                        // might be nicer to popup a global page spinner
                        await layout.mainDataSource.execute();
                    } catch (error) {
                        handleError(error);
                        event.preventDefault();
                    }
                }
                // else we are discarding the changes, so do nothing
            }
        }
    }
}
