import { Component, Container, DesignerStyles, Layout, ResourceFileProps, Table, TabProps } from "@mcleod/components";
import { Collection } from "@mcleod/core";
import { ResourceDesignerTab } from "@mcleod/designer";
import { ModelPermsSecureItem, RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { PermissionsDesigner } from "./PermissionsDesigner";

export class PermissionsDesignerTab extends ResourceDesignerTab {
    designerPanel: Layout;
    designer: PermissionsDesigner;
    permsByItem: Collection<RowPermsSecureItem> = {};

    constructor(designer: PermissionsDesigner, props: ResourceFileProps, tabProps?: Partial<TabProps>) {
        super("layout", props, tabProps);
        this.designer = designer;
        this.designerPanel = new Layout({
            fillHeight: true,
            fillRow: true,
            _designer: designer,
            allowDecoratorOverride: true,
            applyFieldLevelPermissions: false,
            applyFieldLevelLicensing: false
        });
        this.add(this.designerPanel);
    }

    getSearchRequestEndpointAndBody(): [string, any] {
        const filter: any = {
            ...this.getSearchRequestBody(),
            apply_field_level_perms: false,
            apply_field_level_licensing: false,
            apply_field_level_company_type: false,
            apply_field_level_ltl_type: false,
            apply_field_level_experiment: false,
            apply_field_level_multi_currency: false
        };
        return [this.designer.endpointPath, filter];
    }

    async initilaizeAndGetDefinition(): Promise<any> {
        const definition = await super.initilaizeAndGetDefinition();
        const rows = await new ModelPermsSecureItem().search({ screen_class: this.path });

        rows.modelRows.forEach((row: RowPermsSecureItem) => {
            this.permsByItem[row.get("item_name") + ":" + row.get("security_type")] = row;
        });

        return definition;
    }

    addPermedClassToComponents(components: Component[] = this.designerPanel.getRecursiveChildren(false)) {
        if (this.permsByItem == null)
            return null;

        for (const key in this.permsByItem) {
            const compId = key.substring(0, key.indexOf(":"));
            if (RowPermsSecureItem.ENTIRE_SCREEN === compId) {
                this.designerPanel.setClassIncluded(DesignerStyles.permed)
            } else {
                this.findComponent(compId, components)?.setClassIncluded(DesignerStyles.permed);
            }
        }
    }

    findComponent(id: string, components: Component[]): Component {
        for (const child of components) {
            if (id === child.id) {
                return child;
            }

            if (child instanceof Table) {
                const tableComponents = child.discoverIncludedComponents();

                for (const tableComp of tableComponents) {
                    if (id === tableComp.id) {
                        return tableComp;
                    }

                    if (tableComp instanceof Container) {
                        const nestedChild = tableComp.getRecursiveChildren(false).find(nested => nested.id === id);

                        if (nestedChild) {
                            return nestedChild;
                        }
                    }
                }
            }
        }
    }
}
