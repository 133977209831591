/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelStates.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/states";

export class AutogenModelStates<RowType extends ModelRow> extends Endpoint<RowType, RowStatesFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowStates extends ModelRow<RowStatesFields> {
	constructor(values?: Partial<RowStatesFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowStatesFields {
	company_id: string;
	country_id: string;
	id: string;
	manh_road_rate: number;
	manh_toll_rate: number;
	name: string;
}

