import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { TableCellPropDefinitions, TableCellProps } from "./TableCellProps";

export interface HeadingTableCellProps extends TableCellProps {
    extraSortFields: string;
}

let headerTableCellPropDefs: ComponentPropDefinitions;

export class HeaderTableCellPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (headerTableCellPropDefs == null) {
            headerTableCellPropDefs = {
                extraSortFields: {
                    type: PropType.tableExtraSortFields,
                    description: "Specifies fields that should be sortable in the column, in addition to the fields that are visible in that column."
                },
                ...TableCellPropDefinitions.cloneDefs(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions()
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(headerTableCellPropDefs);
        }
        return headerTableCellPropDefs;
    }
}
