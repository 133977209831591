import { Component, MouseEvent } from "@mcleod/components";
import { GeneralSettings, LogManager } from "@mcleod/core";
import { LocalStorageManager } from "../common/LocalStorageManager";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { LayoutDesignerTab } from "./LayoutDesignerTab";

const log = LogManager.getLogger("common.designer.UIDesigner");

export class UIDesigner extends AbstractUIDesigner {
    constructor(props?) {
        super({
            ...props,
            endpointPath:"layout",
            localStorageManager: new LocalStorageManager("designer.ui.open", "designer.ui.selected"),
            title: "UI Designer"
        })
    }

    get allowedDesignerTools(): string[] {
        return [
            "label",
            "textbox",
            "button",
            "checkbox",
            "radio",
            "switch",
            "table",
            "image",
            "citystate",
            "splitter",
            "tree",
            "map",
            "panel",
            "stepper",
            "tabset",
            "repeater",
            "list",
            "attachment",
            "layout",
            "layoutheader",
            "horizontalspacer",
            "searchbutton",
            "savebutton",
            "dataheader",
            "numbereditor",
            "multiswitch",
            "starrating",
            "progressbar",
            "chart",
            "location",
            "iframe",
            "excludabletextbox"
        ];
    }

    override filterProps(props: any, selectedComponent: Component) {
        super.filterProps(props, selectedComponent);
        for (const key of Object.keys(props)) {
            log.debug("Evaluating property for UI Designer visibility: %o for component", props[key]);
            let visibleInDesigner = props[key].visibleInDesigner;
            if (typeof visibleInDesigner === "function")
                visibleInDesigner = visibleInDesigner();
            if (visibleInDesigner === false) {
                log.debug("Removing property from UI Designer: %o", props[key]);
                delete props[key];
            }
        }
    }

    override disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {
        log.debug("Evaluating property for UI Designer editability: %o for component %o", prop, selectedComponent);
        const isEditable = prop.editableInDesigner == null ? true : prop.editableInDesigner;
        if (isEditable !== true) {
            log.debug("Disabling edit of property within UI Designer: %o", prop);
            for (const component of editorComponents) {
                component.enabled = false;
            }
        }
    }

    override updateToolbarButtonVisibility(tab: LayoutDesignerTab) {
        if (GeneralSettings.getSingleton().isRunningInIDE() === true) {
            this.buttonManageVersions.visible = false;
            this.buttonSaveNewVersion.visible = false;
        }
        else
            super.updateToolbarButtonVisibility(tab);
    }
}
