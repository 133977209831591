import { AutogenLayoutReportPreview } from "./autogen/AutogenLayoutReportPreview";
import { Api, DateUtil, FileUtil, ModelRow } from "@mcleod/core";
import { Image, ImageType, DataSourceMode, Panel, Button, SearchButton, HorizontalSpacer } from "@mcleod/components";
import { ReportUtil } from "./ReportUtil";
import { CommonDialogs } from "./CommonDialogs";

export class ReportPreview extends AutogenLayoutReportPreview {
    private _isSetup = false;
    private _dataAppender: DataAppender = () => ({});
    private _bindControlFilterInfo: InfoBinder = () => {};
    private _imageBytes = "";
    private _excelBytes = "";
    private _paramPanel = null;
    private _defaultState = {};
    private _endpointPath = "";
    private _reportName = "";
    private _pdfFieldName = "";
    private _excelFieldName = "";
    private _generateReportButton = null;
    private _validator = () => true;

    /**
     * Setup constructor to provide the nested layout with the necessary information to generate the report
     *
     * - Abstracts clear form, generate report, and download buttons.
     * - ReportUtil.bindDefaultFilterInfos is called on the paramPanel before bindControlFilterInfo. Overriding default bindings may be necessary.
     *
     * @param endpointPath - The path of the ReportEndpoint to post to.
     * @param reportName - The name of the report (i.e. "Brokerage Revenue").
     * @param paramPanel - The panel containing the controls that will be used to generate the report, a clear form and generate report button will be appended to this panel.
     * @param bindControlFilterInfo - A function that binds the filter info objects to the ModelRow to be used in filter/prop generation in ReportEndpoint.
     * @param dataAppender - A function that returns an object containing additional data to be sent to the endpoint as input fields.
     * @param validator - A function that returns true if the report can be generated, false otherwise. Should also set appropriate validation warnings.
     */
    setupWithParent(endpointPath: string, reportName: string, paramPanel: Panel, bindControlFilterInfo?: InfoBinder, dataAppender?: DataAppender, validator?: () => boolean) {
        if(dataAppender) this._dataAppender = dataAppender
        this._bindControlFilterInfo = bindControlFilterInfo
        this._validator = validator || (() => true)

        this._endpointPath = endpointPath
        this._reportName = reportName
        this._defaultState = ReportUtil.getDefaultState(paramPanel)
        this._pdfFieldName = reportName.split(" ").join("_").toLowerCase()
        this._excelFieldName = this._pdfFieldName + "_excel"
        this.layoutheaderReportPreview.title = reportName

        paramPanel.widthFillWeight = 25
        paramPanel.marginRight = 20
        this.widthFillWeight = 75
        const wrapperPanel = new Panel({ fillHeight: true, fillRow: true, scrollY: true })
        wrapperPanel.add(...paramPanel.components)
        paramPanel.removeAll()
        paramPanel.add(wrapperPanel)
        this._paramPanel = wrapperPanel

        const buttonPanel = new Panel({ fillRow: true, borderTopWidth: 1, paddingTop: 20, marginTop: 10, borderTopColor: "subtle.light" })
        const clearFormButton = new Button({ caption: "Clear Form", rowBreak: false, color: "primary", borderWidth: 0, onClick: this.buttonClearOnClick.bind(this) })
        const generateReportButton = new SearchButton({ caption: "Generate Report", rowBreak: false, backgroundColor: "primary", width: 130, color: "default.reverse", onClick: this.generateReport.bind(this) })
        this._generateReportButton = generateReportButton
        buttonPanel.add(clearFormButton, new HorizontalSpacer({ fillRow: true, rowBreak: false }), generateReportButton)
        paramPanel.add(buttonPanel)

        this._isSetup = true
    }

    /**
     * Alternate setup option that doesn't require a paramPanel, opts out of all form related abstractions in this class.
     *
     * - Has not been extensively tested, so use with caution.
     * - To use this while also taking advantage of ReportEndpoint's abstract filtering, bindControlFilterInfo must be passed in.
     * - generateReport will need to be called manually.
     *
     * @param endpointPath - The path of the ReportEndpoint to post to.
     * @param reportName - The name of the report (i.e. "Brokerage Revenue").
     * @param bindControlFilterInfo - A function that binds the filter info objects to the ModelRow to be used in filter/prop generation in ReportEndpoint.
     * @param dataAppender - A function that returns an object containing additional data to be sent to the endpoint as input fields.
     * @param validator - A function that returns true if the report can be generated, false otherwise. Should also set appropriate validation warnings.
     */
    setupWithoutParent(endpointPath: string, reportName: string, bindControlFilterInfo?: InfoBinder, dataAppender?: DataAppender, validator?: () => boolean) {
        this._dataAppender = dataAppender
        this._bindControlFilterInfo = bindControlFilterInfo
        this._validator = validator || (() => true)

        this._endpointPath = endpointPath
        this._reportName = reportName
        this._pdfFieldName = reportName.split(" ").join("_").toLowerCase()
        this._excelFieldName = this._pdfFieldName + "_excel"
        this.layoutheaderReportPreview.title = reportName
        this._isSetup = true
    }

    override onLoad(): void {
        this.buttonIsBusy.busy = true
    }

    generateReport() {
        if(!this._isSetup) {
            CommonDialogs.showError("ReportPreview is not setup correctly. Please call setupWithParent or setupWithoutParent once before generating reports.")
            return
        }

        if((this._paramPanel && !ReportUtil.isUserInputValid(this._paramPanel)) || !this._validator()) return

        const row = new ModelRow(null)
        this.updateBoundData(row, DataSourceMode.SEARCH)
        this.bindDefaultControlFilterInfo(row)

        this.toggleBusy()
        this.buttonDownloadPdf.enabled = false;
        this.buttonDownloadExcel.enabled = false;
        this.buttonDownloadPdf.imageName = "pdfDisabled"
        this.buttonDownloadExcel.imageName = "excelDisabled"

        const body = JSON.stringify([{
            data: row.data,
            ...(this._dataAppender ? this._dataAppender() : {})
        }]);

        Api.post(this._endpointPath, body).then(response => {
            if (response != null) {
                const imgPdf = new Image({ imageType: ImageType.PDF, fillHeight: true, fillRow: true, id: "image_" + this._pdfFieldName });
                imgPdf.imageBytes = response.data[0][this._pdfFieldName];
                this.panelPdfPreview.removeAll();
                this.panelPdfPreview.add(imgPdf);

                this._imageBytes = response.data[0][this._pdfFieldName]
                this._excelBytes = response.data[0][this._excelFieldName]
                this.toggleBusy()
            }
        });
    }

    bindDefaultControlFilterInfo(row: ModelRow): void {
        if(this._paramPanel) ReportUtil.bindDefaultFilterInfos(row, this._paramPanel);
        row.data["pdfFieldName"] = this._pdfFieldName;
        row.data["excelFieldName"] = this._excelFieldName;
        if(this._bindControlFilterInfo) this._bindControlFilterInfo(row)
    }

    buttonDownloadPdfOnClick() {
        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        FileUtil.downloadBase64AsFile(this._imageBytes, `Report ${this._reportName} ${todaysDate}.pdf`);
    }

    buttonDownloadExcelOnClick() {
        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        FileUtil.downloadBase64AsFile(this._excelBytes, `Report ${this._reportName} ${todaysDate}.xlsx`);
    }

    buttonClearOnClick() {
        if(!this._isSetup) {
            CommonDialogs.showError("ReportPreview is not setup correctly. Please call setupWithParent or setupWithoutParent once before clearing form.")
            return
        }
        ReportUtil.applyLayoutDefaultState(this._paramPanel, this._defaultState)
    }

    toggleBusy() {
        if(this._generateReportButton) {
            this._generateReportButton.busy = !this._generateReportButton.busy
            this._generateReportButton.caption = this._generateReportButton.busy ? "" : "Generate Report"
        }
        this.buttonIsBusy.visible = !this.buttonIsBusy.visible
        this.panelPdfPreview.visible = !this.panelPdfPreview.visible
        this.buttonDownloadPdf.enabled = !this.buttonDownloadPdf.enabled
        this.buttonDownloadExcel.enabled = !this.buttonDownloadExcel.enabled
        this.buttonDownloadPdf.imageName = this.buttonDownloadPdf.enabled ? "pdf" : "pdfDisabled"
        this.buttonDownloadExcel.imageName = this.buttonDownloadExcel.enabled ? "excel" : "excelDisabled"
    }
}

interface DataAppender {
    (): { [key: string]: any };
}
interface InfoBinder {
    (row: ModelRow): void;
}
