import {
    AddType, Component, DataSource, DataSourceMode, DesignerInterface, DialogProps, DropdownItem, DropdownItemResolver, PanelProps, Table, TableColumn, TableProps, Textbox
} from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";

export abstract class AbstractValueDisplayValueEditor extends SpecialtyPropertyEditor {
    private ds: DataSource;
    protected table: Table;
    private valueItems: DropdownItem[];
    private _editingDesigner?: DesignerInterface;

    constructor(currentValueJson: string, props?: Partial<PanelProps>, valueItems?: (string|DropdownItem)[], editingDesigner?: DesignerInterface) {
        super({ fillRow: true, fillHeight: true, minWidth: 500, minHeight: 500, ...props });
        this.editingDesigner = editingDesigner;
        this.ds = new DataSource();
        this.valueItems = DropdownItemResolver.createDropdownItems(valueItems);
        this.createTable();
        this.add(this.table);
        const tableData = this.readData(currentValueJson);
        this.ds.setRowsAndMode(DataSourceMode.UPDATE, tableData);
    }

    protected get editingDesigner(): DesignerInterface {
        return this._editingDesigner;
    }

    protected set editingDesigner(value: DesignerInterface) {
        this._editingDesigner = value;
    }

    abstract get valueCaption(): string;

    abstract get valueName(): string;

    abstract get displayValueCaption(): string;

    abstract get displayValueName(): string;

    abstract get emptyCaption(): string;

    get tableRows(): any[] {
        return this.table.rows;
    }

    private readData(currentValueJson: string): ModelRow[] {
        const result: ModelRow[] = [];
        if (!StringUtil.isEmptyString(currentValueJson)) {
            const dbDisplayDefs: any[] = JSON.parse(currentValueJson);
            if (dbDisplayDefs != null) {
                for (const def of dbDisplayDefs) {
                    const mappedDef: { [key: string]: any } = {};
                    for (const key in def) {
                        if (def.hasOwnProperty(key)) {
                            mappedDef[key] = def[key];
                        }
                    }
                    result.push(new ModelRow("", false, mappedDef));
                }
            }
        }
        return result;
    }

    protected getTableProps(): Partial<TableProps> {
        return {
            id: "itemsTable",
            headerVisible: false,
            addType: AddType.QUICK,
            allowEdit: true,
            allowDelete: true,
            fillRow: true,
            fillHeight: true,
            rowBreak: false,
            allowDbSearch: false,
            emptyCaption: this.emptyCaption,
            dataSource: this.ds
        };
    }

    protected createTable() {
        this.table = new Table(this.getTableProps());
        const displayValueColumn = new TableColumn({
            id: "columnDisplayValue",
            index: 0,
            headingDef: {
                caption: this.displayValueCaption
            },
            cell: this.createDisplayValueComponent.bind(this)
        });
        const valueColumn = new TableColumn({
            id: "columnValue",
            index: 1,
            headingDef: {
                caption: this.valueCaption
            },
            cell: this.createValueComponent.bind(this)
        });

        this.table.addColumn(displayValueColumn, true, false);
        this.table.addColumn(valueColumn, false, true);
    }

    private createDisplayValueComponent(): Component {
        return new Textbox({
            caption: this.displayValueCaption,
            field: this.displayValueName,
            id: "textboxDisplayValue",
            required: true,
            placeholder: "Required",
            captionVisible: false,
            fillRow: true
        });
    }

    protected createValueComponent(): Component {
        const result = new Textbox({
            caption: this.valueCaption,
            field: this.valueName,
            id: "textboxValue",
            required: true,
            placeholder: "Required",
            captionVisible: false,
            fillRow: true,
            items: this.valueItems
        });
        return result;
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Display Value / Value Setup",
            okDefault: false,
            cursor: null
        }
    }
}
