import { Component } from "../..";

export class SortFieldInfo {
    private _field: string;
    private _caption?: string;
    private _sortDescendingByDefault: boolean;
    private _sortNullsAtEnd: boolean;

    constructor(field: string, caption?: string, sortDescendingByDefault?: boolean, sortNullsAtEnd?: boolean) {
        this.field = field;
        this.caption = caption;
        this.sortDescendingByDefault = sortDescendingByDefault;
        this.sortNullsAtEnd = sortNullsAtEnd;
    }

    static createFromCellDef(def: any): SortFieldInfo {
        const fieldName = def.sortField ?? def.field;
        if (this.sortFieldExcluded(fieldName, def.excludeFromSortFields) !== true) {
            const caption = def.displayLabel || def.caption;
            return new SortFieldInfo(fieldName, caption, def.sortDescendingByDefault, def.sortNullsAtEnd);
        }
        return null;
    }

    static createFromComponent(component: Component): SortFieldInfo[] {
        const sortFields = component.getSortFieldNames();
        const result: SortFieldInfo[] = [];
        if (sortFields != null) {
            for (const sortField of sortFields) {
                if (this.sortFieldExcluded(sortField, component.excludeFromSortFields) !== true) {
                    // We need to unify this display label logic for compound components, and use here, and in table
                    // filtering, and in table export logic
                    const caption = component.getDisplayLabel(sortField, /*this.headingCell?.caption*/ "");
                    const sortFieldInfo = new SortFieldInfo(sortField, caption, component.sortDescendingByDefault,
                        component.sortNullsAtEnd);
                    result.push(sortFieldInfo);
                }
            }
        }
        return result;
    }

    private static sortFieldExcluded(field: string, excludeFromSortFields: string[]) {
        return excludeFromSortFields != null &&
            Array.isArray(excludeFromSortFields) &&
            excludeFromSortFields.includes(field);
    }

    get field(): string {
        return this._field;
    }

    private set field(value: string) {
        this._field = value;
    }

    get caption(): string {
        return this._caption;
    }

    private set caption(value: string) {
        this._caption = value;
    }

    get sortDescendingByDefault(): boolean {
        return this._sortDescendingByDefault ?? false;
    }

    private set sortDescendingByDefault(value: boolean) {
        this._sortDescendingByDefault = value;
    }

    get sortNullsAtEnd(): boolean {
        return this._sortNullsAtEnd ?? false;
    }

    private set sortNullsAtEnd(value: boolean) {
        this._sortNullsAtEnd = value;
    }

    /**
     * Implement toJSON so that the info is serialized in the desired format.
     * @returns any
     */
    public toJSON(): any {
        const result: any = {
            field: this.field,
            caption: this.caption
        };
        // Only serialize non-default values for other attributes
        if (this.sortDescendingByDefault !== false)
            result.sortDescendingByDefault = this.sortDescendingByDefault;
        if (this.sortNullsAtEnd !== false)
            result.sortNullsAtEnd = this.sortNullsAtEnd;
        return result;
    }
}
