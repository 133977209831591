import { ConfigSettings, LicenseSettings, SettingsRegistry } from "@mcleod/core";
import { TenantGeneralSettings } from "./TenantGeneralSettings";
import { TenantUserSettings } from "./TenantUserSettings";
import { TenantCompanySettings } from "./TenantCompanySettings";

export class TenantSettingsInitializer {
    public initialize() {
        SettingsRegistry.get().register(
            new ConfigSettings(),
            new LicenseSettings(),
            new TenantCompanySettings(),
            new TenantGeneralSettings(),
            new TenantUserSettings()
        );
    }
}
