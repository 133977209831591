import { ArrayUtil, AutomationUtil, Collection, StringUtil } from "@mcleod/core";
import { Component } from "../base/Component";
import { ComponentTypes } from "../base/ComponentTypes";
import { DesignerInterface } from "../base/DesignerInterface";
import { DataSource } from "../databinding/DataSource";
import { DesignableObjectCreationMethod } from "../base/DesignableObjectCreationMethod";

export type ComponentCreationCallback = (componentType: string, props: any) => Component;

export interface DeserializeProps {
    owner: Component;
    def: any | any[];
    designer?: DesignerInterface;
    dataSources?: Collection<DataSource>;
    componentCreationCallback?: ComponentCreationCallback;
    defaultPropValues?: any;
}

export class ComponentDeserializer implements DeserializeProps {
    owner: Component;
    def: any[];
    designer: DesignerInterface;
    defaultPropValues: any;
    dataSources: Collection<DataSource>;
    componentCreationCallback: ComponentCreationCallback;

    constructor(props: DeserializeProps) {
        this.owner = props.owner;
        this.def = ArrayUtil.getAsArray(props.def, true);
        this.designer = props.designer;
        this.defaultPropValues = {...props.defaultPropValues};
        this.dataSources = props.dataSources;
        this.componentCreationCallback = props.componentCreationCallback ?? ComponentTypes.createComponentOfType;
    }

    async deserializeSingleComponent(): Promise<Component> {
        const result = await this.deserialize();
        return result.length > 0 ? result[0] : null;
    }

    async deserialize(): Promise<Component[]> {
        const result = this.internalDeserialize();
        AutomationUtil.addLoadPromise(result);
        return result;
    }

    private async internalDeserialize(): Promise<Component[]> {
        const result = [];
        if (this.def == null)
            return result;
        for (const compDef of this.def) {
            const comp = await this.deserializeComponent({ ...this, def: compDef });
            if (comp != null)
                result.push(comp);
        }
        return result;
    }

    private async deserializeComponent(props: DeserializeProps): Promise<Component> {
        const compDef = props.def;
        let callbackProps = {
            _creationMethod: DesignableObjectCreationMethod.DESERIALIZING,
            ...this.getConstructorProps(compDef),
            ...this.defaultPropValues
        };
        if (this.designer?.setPropsForDeserialization != null)
            callbackProps = this.designer.setPropsForDeserialization(compDef.type, callbackProps);
        const comp = this.componentCreationCallback(compDef.type, callbackProps);
        if (comp == null)
            return null;
        comp.setDesigner(this.designer);
        comp.owner = this.owner;
        if (this.owner != null)
            this.owner[compDef.id] = comp;

        const handledProps = [];
        if (comp._deserializeSpecialProps != null) {
            const propIds = await comp._deserializeSpecialProps({...props});
            handledProps.push(...propIds);
        }

        this.deserializeBaseVersionProps(comp, compDef);

        const defKeys = Object.keys(compDef);
        const compProps = comp.getPropertyDefinitions();
        for (let i = defKeys.length - 1; i >= 0; i--) {
            const key = defKeys[i];
            if (compProps[key] == null || handledProps.includes(key))
                defKeys.splice(i, 1);
        }

        for (const key of defKeys) {
            comp.setPropUsingPropsCollection(compDef[key], key, compProps);
        }

        if (this.defaultPropValues != null)
            for (const key in this.defaultPropValues)
                if (compDef[key] === undefined)
                    comp[key] = this.defaultPropValues[key];

        comp.clearCreationMethod();
        comp.doAfterDeserialize();
        comp.deserialized = true;
        await comp.ensureLoaded();
        return comp;
    }

    private getConstructorProps(compDef: any): any {
        const result = { id: compDef.id };
        const propNames = ComponentTypes.getComponentType(compDef.type)?.constructorProps;
        if (propNames != null)
            for (const propName of propNames)
                result[propName] = compDef[propName];
        return result;
    }

    private deserializeBaseVersionProps(comp: Component, compDef: any) {
        const baseVersionProps = compDef.baseVersionProps;
        if (baseVersionProps != null) {
            for (const key in baseVersionProps) {
                // null is serialized as an empty string because null values aren't serialized on the server
                if (StringUtil.isEmptyString(baseVersionProps[key]))
                    baseVersionProps[key] = null;
            }
            comp.baseVersionProps = baseVersionProps;
        }
    }
}
